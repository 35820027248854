import { Component, OnInit } from '@angular/core'
import { select } from '@ngneat/elf'
import { map } from 'rxjs'
import { PluginService } from '../../service/manageable-plugin.service'

@Component({
  selector: 'app-plugins',
  templateUrl: './plugins.component.html',
  styleUrl: './plugins.component.less',
})
export class PluginsComponent implements OnInit {
  plugins = [
    {
      name: 'Plugin 1',
      description: 'Description 1',
      tags: ['Tag1', 'Tag2'],
      logo: 'https://via.placeholder.com/150',
      status: true,
    },
    {
      name: 'Plugin 2',
      description: 'Description 2',
      tags: ['Tag3', 'Tag4'],
      logo: 'https://via.placeholder.com/150',
      status: false,
    },
  ]

  checkedTag = 'all'

  plugins$ = this.pluginService.pluginsStore$.pipe(
    select((state) => state.plugins)
  )
  channelPlugins$ = this.pluginService.pluginsStore$.pipe(
    select((state) => state.channelPlugins)
  )
  constructor(public pluginService: PluginService) {}

  ngOnInit(): void {
    this.pluginService.getChannelPlugins().subscribe(() => {
      this.pluginService.getPlugins().subscribe()
    })
  }

  onTagChecked(tag: string): void {
    this.checkedTag = tag
  }

  checkIsActive(manageablePluginId: string) {
    return this.channelPlugins$
      .pipe(
        select((channelPlugins) =>
          channelPlugins.find(
            (channelPlugin) =>
              channelPlugin.manageablePlugin.id === manageablePluginId
          )
        )
      )
      .pipe(map((channelPlugin) => channelPlugin?.status === 'enabled'))
  }
}
