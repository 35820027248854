import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NzTableQueryParams } from 'ng-zorro-antd/table'
import { tap } from 'rxjs'
import { ProductList, ProductVariant } from '../../gql/shop/generated'
import { AppService } from '../../service/app.service'
import { ProductService } from '../../service/product.service'

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrl: './products.component.less',
})
export class ProductsComponent implements OnInit {
  activeTab = 'All'

  products: ProductList = {
    items: [],
    totalItems: 0,
  }

  pageSize = 10
  pageIndex = 0

  setOfCheckedId = new Set<string>()
  checked = false
  indeterminate = false

  constructor(
    public appService: AppService,
    private productService: ProductService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setupListeners()

    this.productService.fetchProducts().subscribe()
  }

  setupListeners() {
    this.productService.products$
      .pipe(
        tap((products) => {
          console.log({ products })

          this.products = products
        })
      )
      .subscribe()
  }

  setActiveTab(tab: string) {
    this.activeTab = tab
  }

  getVariantSize(variants: ProductVariant[]) {
    return variants.length + 1
  }

  getTotalStock(variants: ProductVariant[]) {
    let total = 0

    variants.forEach((item: ProductVariant) => {
      item.stockLevels.forEach((sL) => {
        total += sL.stockOnHand
      })
    })

    return total
  }

  onAllChecked(value: boolean): void {
    this.products.items.forEach((item) => this.updateCheckedSet(item.id, value))
    this.refreshCheckedStatus()
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id)
    } else {
      this.setOfCheckedId.delete(id)
    }
  }

  refreshCheckedStatus(): void {
    this.checked = this.products.items.every((item) =>
      this.setOfCheckedId.has(item.id)
    )
    this.indeterminate =
      this.products.items.some((item) => this.setOfCheckedId.has(item.id)) &&
      !this.checked
  }

  onItemChecked(id, $event) {
    console.log(id, $event)
  }

  goToProduct(id: string) {
    this.router.navigate([`/admin/products/${id}`])
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    console.log(params)
    const { pageSize, pageIndex, sort, filter } = params

    const currentSort = sort.find((item) => item.value !== null)
    const sortField = currentSort && currentSort.key
    const sortOrder = currentSort && currentSort.value
    this.pageIndex = pageIndex
    this.pageSize = pageSize

    this.productService.updatePagination(pageIndex, pageSize)

    if (sortField && sortOrder) {
      this.productService.updateSort(sortField, sortOrder)
    }

    this.productService.goToPage(pageIndex).subscribe()
  }
}
