<nz-avatar
  [nzSize]="32"
  nzIcon="user"
  nz-dropdown
  nzTrigger="click"
  [nzDropdownMenu]="menu"
  [nzPlacement]="'bottomRight'"
  class="hidden-md-down profile-button"
></nz-avatar>

<nz-avatar
  [nzSize]="32"
  nzIcon="user"
  (click)="openProfile()"
  class="hidden-md-up profile-button"
></nz-avatar>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu class="alpha">
    <li nz-menu-item>
      <nz-avatar [nzSize]="20" nzIcon="user"></nz-avatar>
      <p>John Doe</p>
    </li>
    <li nz-menu-item>
      <span nz-icon nzType="ng-zorro:stores"></span>
      <p>All Stores</p>
    </li>
    <nz-divider></nz-divider>
    <li nz-menu-item>
      <span nz-icon nzType="ng-zorro:help"></span>
      <p>Help Center</p>
    </li>
    <li nz-menu-item>
      <span nz-icon nzType="ng-zorro:change-log"></span>
      <p>Changelog</p>
    </li>
    <li nz-menu-item>
      <span nz-icon nzType="ng-zorro:community"></span>
      <p>Community Forums</p>
    </li>
    <li nz-menu-item>
      <span nz-icon nzType="ng-zorro:hire-partner"></span>
      <p>Hire Shopzo Partner</p>
    </li>
    <li nz-menu-item>
      <span nz-icon nzType="ng-zorro:keyboard"></span>
      <p>Keyboard Shortcuts</p>
    </li>
    <nz-divider></nz-divider>
    <li nz-menu-item>
      <a [routerLink]="['/login']" (click)="logout()">
        <span nz-icon nzType="ng-zorro:settings"></span>
        <p>Manage Account</p>
      </a>
    </li>
    <li nz-menu-item>
      <span nz-icon nzType="ng-zorro:logout"></span>
      <p>Logout</p>
    </li>
  </ul>
</nz-dropdown-menu>

<nz-drawer
  [nzClosable]="false"
  [nzVisible]="isProfileVisible"
  [nzPlacement]="'right'"
  (nzOnClose)="closeProfile()"
>
  <ng-container *nzDrawerContent>
    <nz-sider class="menu-sidebar" nzCollapsible [nzTrigger]="null">
      <button (click)="closeProfile()">Close</button>

      <ul nz-menu class="alpha">
        <li nz-menu-item>
          <nz-avatar [nzSize]="20" nzIcon="user"></nz-avatar>
          <p>John Doe</p>
        </li>
        <li nz-menu-item>
          <span nz-icon nzType="ng-zorro:stores"></span>
          <p>All Stores</p>
        </li>
        <nz-divider></nz-divider>
        <li nz-menu-item>
          <span nz-icon nzType="ng-zorro:help"></span>
          <p>Help Center</p>
        </li>
        <li nz-menu-item>
          <span nz-icon nzType="ng-zorro:change-log"></span>
          <p>Changelog</p>
        </li>
        <li nz-menu-item>
          <span nz-icon nzType="ng-zorro:community"></span>
          <p>Community Forums</p>
        </li>
        <li nz-menu-item>
          <span nz-icon nzType="ng-zorro:hire-partner"></span>
          <p>Hire Shopzo Partner</p>
        </li>
        <li nz-menu-item>
          <span nz-icon nzType="ng-zorro:keyboard"></span>
          <p>Keyboard Shortcuts</p>
        </li>
        <nz-divider></nz-divider>
        <li nz-menu-item>
          <span nz-icon nzType="ng-zorro:settings"></span>
          <p>Manage Account</p>
        </li>
        <li nz-menu-item>
          <span nz-icon nzType="ng-zorro:logout"></span>
          <p>Logout</p>
        </li>
      </ul>
    </nz-sider>
  </ng-container>
</nz-drawer>
