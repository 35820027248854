
import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { AcfService } from '../../service/acf.service';
import { AcfFieldDefinitionUnion, AcfValueUnion, Asset, GroupFieldDefinition, ListRelationFieldDefinition } from '../../gql/shop/generated';
import { catchError, firstValueFrom, forkJoin, map, mergeMap, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { FormArray, FormControl, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { AssetService } from '../../service/asset.service';
import { v4 } from 'uuid'
import { FacetService } from '../../service/facet.service';
import { ProductService } from '../../service/product.service'
import { NzMessageService } from 'ng-zorro-antd/message'

interface AssetForm {
  name: FormControl<string>
  file: FormControl<File>
  type: FormControl<string>
  binary: FormControl<string>
  id: FormControl<string>
}

@Component({
  selector: 'app-acf-components',
  templateUrl: './advanced-custom-fields.component.html',
  styleUrl: './advanced-custom-fields.component.less',
})
export class AdvancedCustomFieldsComponent implements OnInit, OnDestroy {
  constructor(
    private acfService: AcfService,
    private facetService: FacetService,
    private formBuilder: NonNullableFormBuilder,
    private assetService: AssetService,
    private productService: ProductService,
    private messageService: NzMessageService
  ) {}

  // dynamic form group
  validateForm = this.formBuilder.group({

  });
  
  // Store field definitions don't belong to any group
  fieldDefinitions: AcfFieldDefinitionUnion[] = [];

  // Store groups
  groups: GroupFieldDefinition[] = [];

  // store list values
  listValues: { [fieldName: string]: string[] } = {};

  // store paginated assets
  paginatedAssets: {id: string, name: string, source: string}[] = [];

  // page size
  pageSize = 8;

  // page number
  pageNum = 1;

  // total assets
  totalAssets = 0;

  // store entities
  entities: { [fieldName: string]: { id: string, label: string }[] } = {};

  // uploding message
  mediaUploadingMessage = ''
  
  // store assets id and source to display image
  assetSources: { [key: string]: string } = {};

  // whether show asset modal
  showAssetModal = false;

  // selected assets
  selectedAssets: any[] = [];

  // current field name for asset picker modal
  currentFieldName  = '';

  // search asset value, used in asset picker modal
  searchAssetControl = new FormControl('');

  // store origin group field definitions
  originGroupFieldDefinitions: { [key: string]: AcfFieldDefinitionUnion[] } = {};
  
  @Input() entityName!: string;
  @Input() entityId: string | undefined | null;

  ngOnDestroy() {
    this.validateForm.reset();
  }

  ngOnInit() {
    this.initData()
  }

  initData(){
    // get all field definitions and bind form controls
    this.acfService.getAll({entityName: this.entityName}).subscribe((response) => {
      // ============================groups============================
      this.groups = response.filter(group => (group.__typename as string) === 'GroupFieldDefinition') as GroupFieldDefinition[];
      this.groups.forEach(group => {
        this.storeOriginGroupFieldDefinitions(group);
      });

       // rename group field name
       this.groups = response
       .filter(group => (group.__typename as string) === 'GroupFieldDefinition') 
       .map(group => this.updateGroupFieldname(group as GroupFieldDefinition));
       // rename field definition field name
       this.groups = this.groups.map(group => this.updateFieldDefinitionFieldname(group as GroupFieldDefinition));
      // populate group data by group value or list group value
      this.groups.forEach(group => {
        this.populateGroupData(group);
      });
      // ========================field definitions==========================
      this.fieldDefinitions = response.filter(field => (field.__typename as string) !== 'GroupFieldDefinition') as AcfFieldDefinitionUnion[];
      this.populateFieldData(this.fieldDefinitions);

    });
  }


  populateFieldData(fieldDefinitions: AcfFieldDefinitionUnion[]){
    fieldDefinitions.forEach((field) => {
      if((field.__typename as string) === 'StringFieldDefinition'){
        const defaultValue = '';
        this.validateForm.addControl(field.fieldName, new FormControl(defaultValue));
        if (this.entityId) {
          this.acfService.getStringValue({entityId: this.entityId, entityName: this.entityName, fieldName: field.fieldName}).subscribe(response => {
            this.validateForm.get(field.fieldName)?.setValue(response || defaultValue);
          });
        }
      }else if((field.__typename as string) === 'BooleanFieldDefinition'){
        const defaultValue = null;
        this.validateForm.addControl(field.fieldName, new FormControl(defaultValue));
        if (this.entityId) {
          this.acfService.getBooleanValue({entityId: this.entityId, entityName: this.entityName, fieldName: field.fieldName}).subscribe(response => {
            this.validateForm.get(field.fieldName)?.setValue(response || defaultValue);
          });
        }
      }else if((field.__typename as string) === 'DateFieldDefinition'){
        const defaultDate = null;
        this.validateForm.addControl(field.fieldName, new FormControl(defaultDate));
        if (this.entityId) {
          this.acfService.getDateValue({entityId: this.entityId, entityName: this.entityName, fieldName: field.fieldName}).subscribe(response => {
            response ? this.validateForm.get(field.fieldName)?.setValue(new Date(response)) : this.validateForm.get(field.fieldName)?.setValue(defaultDate);
          });
        }
      }else if((field.__typename as string) === 'ListFieldDefinition'){
        const defaultValue: string[] = [];
        this.validateForm.addControl(field.fieldName, new FormControl(defaultValue));
        if (this.entityId) {
          this.acfService.getListValue({entityId: this.entityId, entityName: this.entityName, fieldName: field.fieldName}).subscribe(response => {
            this.storeListValues(field, response || defaultValue)
            this.validateForm.get(field.fieldName)?.setValue(response || defaultValue);
          });
        }
      } else if((field.__typename as string) === 'ListRelationFieldDefinition'){
        const isAssetField = (field as ListRelationFieldDefinition).relatedEntityName === 'Asset';
        if (!this.entityId) {
          // Initialize empty form control
          this.validateForm.addControl(
            field.fieldName, 
            isAssetField ? new FormArray([]) : new FormControl([])
          );
          if (!isAssetField) {
            this.storeAllEntities(field);
          }
          return;
        }

        this.acfService.getListRelationValue({
          entityId: this.entityId,
          entityName: this.entityName,
          fieldName: field.fieldName
        }).subscribe(async (response) => {
          if(response && response?.entities){
            const parsedValue = response.entities
            if (isAssetField) {
              const assetControls = await Promise.all(
                parsedValue.map(async (asset: any) => {
                  const response = await firstValueFrom(this.assetService.getAsset(asset.id));
                  const file = await this.assetService.fetchFile(response?.data?.asset?.source as string, asset.name);
                  
                  return this.formBuilder.group<AssetForm>({
                    id: this.formBuilder.control(asset.id),
                    file: this.formBuilder.control(file),
                    binary: this.formBuilder.control(await this.assetService.fileToBase64(file)),
                    name: this.formBuilder.control(asset.name),
                    type: this.formBuilder.control(asset.mimeType),
                  });
                })
                );
              this.validateForm.addControl(field.fieldName, new FormArray(assetControls));
            } else {
              this.storeAllEntities(field);
              const selectedIds = parsedValue.map((entity: any) => entity.id);
              this.validateForm.addControl(field.fieldName, new FormControl(selectedIds));
            }
          } else{
            if(isAssetField){
              this.validateForm.addControl(field.fieldName, new FormArray([]));
            }else{
              this.storeAllEntities(field);
              this.validateForm.addControl(field.fieldName, new FormControl([]));
            }
          }
        });
      }
    });
  }

  populateGroupData(group: GroupFieldDefinition){
    if(this.entityId){
      if(group.list){
        // fetch list group form control value
        this.acfService.getListGroupValue({entityId: this.entityId, entityName: this.entityName, fieldName: group.fieldName}).subscribe(
          /***
           * 
           * repeat a group means repeat the child elements of the group
           *
           * groupB: [field1]
           * [{"field1": "fieldValue"}, {"field1": "fieldValue"}]
           * groupB.fields: [field1, field1]
           * 
           * groupB has field2 and groupC: [field2, groupC]
           * repeat field2 and groupC
           * groupB: [{"field2": "fieldValue", "groupC": [{"field1": "fieldValue"}, {"field1": "fieldValue"}]}, {"field2": "fieldValue", "groupC": [{"field1": "fieldValue"}, {"field1": "fieldValue"}]}]
           * groupB.fields: [field2, groupC, field2, groupC]
           * 
           * groupA has field1 and groupC: [field1, groupC]
           * groupA: just repeat groupC because groupA is a group not a list group
           * {"field1": "fieldValue", "groupC": [{"field2": "fieldValue"}, {"field2": "fieldValue"}]}
           * groupA.fileds:[fieldId1, groupC: [fieldId2, fieldId2] ]
           * 
           */
            async response => {
              if(response && response?.value){
                  const values = JSON.parse(response.value);
                  const updatedGroup = await this.processGroupDefinitions(group, values);
                  // Update groups array
                  this.groups = this.groups.map(g => 
                    g.fieldName === group.fieldName ? updatedGroup : g
                  );
                }else{
                  // return null, set default value
                  this.initializeEmptyFormControls(group)
                }
            }
        )
      }else{
        // fetch group form control value
        this.acfService.getGroupValue({entityId: this.entityId, entityName: this.entityName, fieldName: group.fieldName}).subscribe(
          async response => {
            if (response && response?.value) {
              const value = [JSON.parse(response.value)];
              const updatedGroup = await this.processGroupDefinitions(group, value);
              // Update groups array
              this.groups = this.groups.map(g => 
                g.fieldName === group.fieldName ? updatedGroup : g
              );
            }else{
              // return null, set default value
              this.initializeEmptyFormControls(group)
            }
          }
        )
      }
    }else{
      // initialize empty form controls
      this.initializeEmptyFormControls(group)
    }
  }

  private initializeEmptyFormControls(group: GroupFieldDefinition){
    group.fields.forEach((field) => {
      if(field.__typename === 'StringFieldDefinition'){
        const defaultValue = '';
        this.validateForm.addControl(field.fieldName, new FormControl(defaultValue));
      }else if(field.__typename === 'BooleanFieldDefinition'){
        const defaultValue = null;
        this.validateForm.addControl(field.fieldName, new FormControl(defaultValue));
      }else if(field.__typename === 'DateFieldDefinition'){
        const defaultDate = null;
        this.validateForm.addControl(field.fieldName, new FormControl(defaultDate));
      }else if(field.__typename === 'ListFieldDefinition'){
        const defaultValue: string[] = [];
        this.validateForm.addControl(field.fieldName, new FormControl(defaultValue));
      }else if(field.__typename === 'ListRelationFieldDefinition'){
        const isAssetField = field.relatedEntityName === 'Asset';
        // Initialize empty form control
        this.validateForm.addControl(
          field.fieldName, 
          isAssetField ? new FormArray([]) : new FormControl([])
        );
        if (!isAssetField) {
          this.storeAllEntities(field);
        }
      }
    });
    // Recursively process nested groups
    group.fields.forEach((field: any) => {
      if (field.__typename === 'GroupFieldDefinition') {
        this.initializeEmptyFormControls(field)
      }
    });
  }

  private storeOriginGroupFieldDefinitions(group: any) {
    this.originGroupFieldDefinitions[group.fieldName] = group.fields;
    // Recursively process nested groups
    group.fields.forEach((field: any) => {
      if (field.__typename === 'GroupFieldDefinition') {
        this.storeOriginGroupFieldDefinitions(field);
      }
    });
  }

  // the logic of processGroupDefinitions
  // 1. get original field definition by current group fieldName
  // 2. copy origin field definition
  // 3. get group value
  // 4. iterate both value and object keys
  // 5. extend the copy definition array, rename fieldName, if it's group, recursion
  // 6. add form controls and set value
  private async processGroupDefinitions(group: GroupFieldDefinition, values: any[]): Promise<GroupFieldDefinition> {
    // 1. Get original fields by groupname
    const searchGroupFieldName = group.fieldName.substring(0, group.fieldName.indexOf('_index') === -1 ? 
      group.fieldName.length : 
      group.fieldName.lastIndexOf('_index'));
    const originalFields = this.originGroupFieldDefinitions[searchGroupFieldName];
    // 2. Deep copy and expand fields based on values
    const newFields: AcfFieldDefinitionUnion[] = [];
    for (const [valueIndex, valueObj] of values.entries()) {
      let suffix = '';
      if(valueIndex != undefined){
        suffix = `_index${valueIndex}`;
      }
      for (const [definitionId, value] of Object.entries(valueObj)) {
        const originalField = originalFields.find(field => field.id === definitionId);
        if (originalField) {
          // 3. Add index for list groups
          const newField = {
            ...originalField,
            fieldName: group.list ? 
              `${originalField.fieldName}${suffix}` : 
              originalField.fieldName
          };
          if (originalField.__typename === 'GroupFieldDefinition' && value) {
            // Recursively process nested groups
            const nestedValues = Array.isArray(value) ? value : [value];
            const processedGroup = await this.processGroupDefinitions(
              newField as GroupFieldDefinition,
              nestedValues
            );
            newFields.push(processedGroup);
          } else {
            // Add form control for non-group fields
            const controlName = group.list ? 
              `${group.fieldName}_${originalField.fieldName}${suffix}` : 
              `${group.fieldName}_${originalField.fieldName}`;
            newField.fieldName = controlName;
            if(newField.__typename === 'ListRelationFieldDefinition'){
              const isAssetField = (newField as ListRelationFieldDefinition).relatedEntityName === 'Asset';
              if (isAssetField) {
                const assetControls = await Promise.all(
                  (value as string[]).map(async (assetId: string) => {
                    const response = await firstValueFrom(this.assetService.getAsset(assetId));
                    const file = await this.assetService.fetchFile(response?.data?.asset?.source as string, response?.data?.asset?.name as string);
                    
                    return this.formBuilder.group<AssetForm>({
                      id: this.formBuilder.control(assetId),
                      file: this.formBuilder.control(file),
                      binary: this.formBuilder.control(await this.assetService.fileToBase64(file)),
                      name: this.formBuilder.control(response?.data?.asset?.name as string),
                      type: this.formBuilder.control(response?.data?.asset?.mimeType as string),
                    });
                  })
                  );
                this.validateForm.addControl(controlName, new FormArray(assetControls));
              } else {
                this.storeAllEntities(newField);
                const selectedIds = (value as string[]).map(value => value);
                this.validateForm.addControl(controlName, new FormControl(selectedIds));
              }
            }else if (newField.__typename === 'ListFieldDefinition'){
              this.storeListValues(newField, value as string[])
              this.validateForm.addControl(controlName, new FormControl(value));
            }else {
              this.validateForm.addControl(controlName, new FormControl(value));
            }
            newFields.push(newField);
          }
        }
      }
    }

    // Return updated group with new fields
    return {
      ...group,
      fields: newFields
    };
  }

  /**
   * 
   * @param group current group
   * @param list the `list` of parent element
   * @returns 
   */
  // rename group field name
  private updateGroupFieldname(group: GroupFieldDefinition, list?: boolean): GroupFieldDefinition {
    return {
      ...group,
      fieldName: list != undefined ? list ? `${group.fieldName}_index0` : group.fieldName : group.fieldName,
      fields: group.fields.map(field => {
        if (field.__typename === 'GroupFieldDefinition') {
          // Recursively map nested groups
          return this.updateGroupFieldname(field as GroupFieldDefinition, group.list);
        }
        return {
          ...field
        };
      })
    };
  }

  /**
   * 
   * @param group current group
   * @param list the `list` of parent element
   * @returns 
   */
  // rename field definition field name
  private updateFieldDefinitionFieldname(group: GroupFieldDefinition): GroupFieldDefinition {
    return {
      ...group,
      fields: group.fields.map(field => {
        if (field.__typename === 'GroupFieldDefinition') {
          // Recursively map nested groups
          return this.updateFieldDefinitionFieldname(field as GroupFieldDefinition);
        }
        return {
          ...field,
          fieldName: group.list ? `${group.fieldName}_${field.fieldName}_index0` : `${group.fieldName}_${field.fieldName}`
        };
      })
    };
  }
  
  /**
   * Insert or update ACF values
   * If the form control value is not null, it will insert/update the value
   * If the form control value is null and the entityId is not null, it will delete the value
   * If the form control value is null and the entityId is null, it will do nothing
   * But GroupValue and ListGroupValue will be insert/update anyway, even the value is null, because we need to render the field defitions by value
   * 
   * @param entityId - The ID of the entity
   * @returns An observable that emits the result of the insert/update/delete operation
   */
  insertOrUpdate(entityId: string): Observable<any> {
    // insert values
    this.validateForm.markAllAsTouched()
    this.validateForm.updateValueAndValidity()
    this.validateForm.validate()
    if(this.validateForm.valid){
      // iterate through the form controls and insert values
      const insertOrUpdateFieldsOptions$ = this.fieldDefinitions.map((field) => {
        if(field.__typename === 'StringFieldDefinition'){
          return this.validateForm.controls[field.fieldName].value
            ? this.acfService.insertStringValue({entityId, entityName: this.entityName, fieldName: field.fieldName, value: this.validateForm.controls[field.fieldName].value})
            : this.entityId ? this.acfService.deleteStringValue({entityId, entityName: this.entityName, fieldName: field.fieldName}) : of(null)
        }else if(field.__typename === 'BooleanFieldDefinition'){
          return this.validateForm.controls[field.fieldName].value !== null
            ? this.acfService.insertBooleanValue({entityId, entityName: this.entityName, fieldName: field.fieldName, value: this.validateForm.controls[field.fieldName].value})
            : this.entityId ? this.acfService.deleteBooleanValue({entityId, entityName: this.entityName, fieldName: field.fieldName}) : of(null)
        }else if(field.__typename === 'DateFieldDefinition'){
          return this.validateForm.controls[field.fieldName].value
            ? this.acfService.insertDateValue({entityId, entityName: this.entityName, fieldName: field.fieldName, value: this.validateForm.controls[field.fieldName].value})
            : this.entityId ? this.acfService.deleteDateValue({entityId, entityName: this.entityName, fieldName: field.fieldName}) : of(null)
        }else if(field.__typename === 'ListFieldDefinition'){
          return this.validateForm.controls[field.fieldName].value && this.validateForm.controls[field.fieldName].value.length
            ? this.acfService.insertListValue({entityId, entityName: this.entityName, fieldName: field.fieldName, value: this.validateForm.controls[field.fieldName].value})
            : this.entityId ? this.acfService.deleteListValue({entityId, entityName: this.entityName, fieldName: field.fieldName}) : of(null)
        }else if(field.__typename === 'ListRelationFieldDefinition'){
          if(field.relatedEntityName === 'Asset'){
            // upload new assets
            return this.assetService.createAssets(this.validateForm.controls[field.fieldName].getRawValue().filter((asset) => asset.id.indexOf('local-') !== -1).map((asset) => ({ file: asset.file }))).pipe(
              map(
                (assetsResponse) =>
                  assetsResponse.data?.createAssets
                    .filter((asset) => asset.__typename === 'Asset')
                    .map((asset) => (asset as Asset).id) || []
              ),
              mergeMap((assetIds) => {
                const relatedAssetIds = [...assetIds, ...this.validateForm.controls[field.fieldName].getRawValue().filter((asset) => asset.id.indexOf('local-') === -1).map((asset) => asset.id)];
                return relatedAssetIds.length ? this.acfService.insertListRelationValue({entityId, entityName: this.entityName, fieldName: field.fieldName, targetEntityIds: relatedAssetIds}) :
                  this.entityId ? this.acfService.deleteListRelationValue({entityId, entityName: this.entityName, fieldName: field.fieldName}) : of(null)
              })
            )
          }else{
            return this.validateForm.controls[field.fieldName].value && this.validateForm.controls[field.fieldName].value.length
              ? this.acfService.insertListRelationValue({entityId, entityName: this.entityName, fieldName: field.fieldName, targetEntityIds: this.validateForm.controls[field.fieldName].value})
              : this.entityId ? this.acfService.deleteListRelationValue({entityId, entityName: this.entityName, fieldName: field.fieldName}) : of(null)
          }
        }else {
          return of(null)
        }
      })

      // add group fields
      const insertOrUpdateGroupFieldsOptions$ = this.groups.map((rootGroup) => {
        if (rootGroup.list) {
          return this.getGroupValues(rootGroup).pipe(
            mergeMap(valueSets => {
              return this.acfService.insertListGroupValue({
                entityId, 
                entityName: this.entityName, 
                fieldName: rootGroup.fieldName, 
                valueSets: JSON.stringify(valueSets)
              })
            })
          );
        } else {
          return this.getGroupValues(rootGroup).pipe(
            mergeMap(values => {
              return this.acfService.insertGroupValue({
                entityId, 
                entityName: this.entityName, 
                fieldName: rootGroup.fieldName, 
                values: JSON.stringify(values)
               })}
            )
          );
        }
      })

      // Use forkJoin to wait for all insert/update operations to complete
      return forkJoin([...insertOrUpdateFieldsOptions$, ...insertOrUpdateGroupFieldsOptions$]).pipe(
        catchError(error => {
          console.error('Error inserting/updating ACF values:', error);
          return throwError(() => error);
        })
      )
    }
    return of(null)
  }

  private findGroupByFieldName(fieldName: string, groups = this.groups): GroupFieldDefinition | undefined {
    for (const group of groups) {
      if (group.fieldName === fieldName) {
        return group;
      }
      
      // Search in group's fields for nested groups
      const nestedGroups = group.fields.filter(
        field => field.__typename === 'GroupFieldDefinition'
      ) as GroupFieldDefinition[];
      
      if (nestedGroups.length) {
        const found = this.findGroupByFieldName(fieldName, nestedGroups);
        if (found) return found;
      }
    }
    return undefined;
  }

  // the logic of addObjectToListGroup
  // 1. get the nextIndex in this group
  // 2. get child fields, and pass current group fieldName and list
  // 3. iterate fields. if item is group, determine if parentGroup.list == true and update group fieldName, then iterate child fields(recursion)
  // 4. iterate fields. if item is field, update fieldName by parentGroupName and add new form control
  // 5. return fileds array, push into this group
  addObjectToListGroup(group: GroupFieldDefinition) {
    const currentIndex = this.getCurrentMaxIndex(group);
    const nextIndex = currentIndex + 1;
  
    // Helper function to create new fields with updated names
    const createNewFields = (
      fields: AcfFieldDefinitionUnion[], 
      parentGroupName: string, 
      index: number,
      parentIsList: boolean
    ): AcfFieldDefinitionUnion[] => {
      return fields.map(field => {
        if (field.__typename === 'GroupFieldDefinition') {
          // Handle nested group
          const newGroupName = parentIsList ? 
            `${field.fieldName}_index${index}` : 
            `${field.fieldName}`;
          
          return {
            ...field,
            fieldName: newGroupName,
            fields: createNewFields(
              field.fields, 
              newGroupName, 
              0, 
              field.list // Pass current group's list property for nested fields
            )
          };
        } else {
          // Handle regular field
          const newFieldName = parentIsList ? 
            `${parentGroupName}_${field.fieldName}_index${index}` : 
            `${parentGroupName}_${field.fieldName}`;
          
          const newField = { ...field, fieldName: newFieldName };
  
          // Add form control based on field type
          if (field.__typename === 'StringFieldDefinition') {
            this.validateForm.addControl(newFieldName, new FormControl(''));
          } else if (field.__typename === 'BooleanFieldDefinition') {
            this.validateForm.addControl(newFieldName, new FormControl(null));
          } else if (field.__typename === 'DateFieldDefinition') {
            this.validateForm.addControl(newFieldName, new FormControl(null));
          } else if (field.__typename === 'ListFieldDefinition') {
            this.validateForm.addControl(newFieldName, new FormControl([]));
          } else if (field.__typename === 'ListRelationFieldDefinition') {
            const isAssetField = field.relatedEntityName === 'Asset'
            this.validateForm.addControl(
              newFieldName,
              isAssetField ? new FormArray([]) : new FormControl([])
            );
            if (!isAssetField) {
              this.storeAllEntities(newField);
            }
          }
  
          return newField;
        }
      });
    };

    const searchGroupFieldName = group.fieldName.substring(0, group.fieldName.indexOf('_index') === -1 ? 
      group.fieldName.length : 
      group.fieldName.lastIndexOf('_index'))
    // Create new fields with updated names
    const newFields = createNewFields(
      this.originGroupFieldDefinitions[searchGroupFieldName],
      group.fieldName,
      nextIndex,
      group.list // Pass the current group's list property
    );
  
    // Update the groups array
    const updateNestedGroup = (groups: any[]): any[] => {
      return groups.map(g => {
        // If this is the group we're looking for
        if (g.fieldName === group.fieldName) {
          return {
            ...g,
            fields: [...g.fields, ...newFields]
          };
        }
        // If this group has fields that might contain nested groups
        if (g.fields && g.fields.length > 0) {
          return {
            ...g,
            fields: updateNestedGroup(g.fields)
          };
        }
        return g;
      });
    };

    this.groups = updateNestedGroup(this.groups);
  }
  
  private getCurrentMaxIndex(group: GroupFieldDefinition): number {
    // Find the group in the groups array
    const currentGroup = this.findGroupByFieldName(group.fieldName)
    if (!currentGroup || !currentGroup.fields.length) {
      return -1;
    }
  
    // Extract indices from field names and find the maximum
    const indices = currentGroup.fields
      .map(field => {
        const match = field.fieldName.match(/_index(\d+)$/);
        return match ? parseInt(match[1], 10) : -1;
      })
      .filter(index => index !== -1);
  
    return indices.length ? Math.max(...indices) : -1;
  }

  // the logic of removeObjectFromListGroup
  // 1. get currentMaxIndex in this group
  // 2. get all fields and remove fieldName contains _index${maxIndex}, recursion match group and filter its child fields
  // 3. update this.group
  // 4. remove corresponding form controls
  removeObjectFromListGroup(group: GroupFieldDefinition) {
    // Find the current maximum index
    const currentMaxIndex = this.getCurrentMaxIndex(group);
    
    if (currentMaxIndex <= 0) {
      this.messageService.error('Cannot remove the last group');
      return;
    }
  
    // Update the groups array by filtering out fields with the max index
    const updateNestedGroup = (groups: any[]): any[] => {
      return groups.map(g => {
        // If this is the group we're looking for
        if (g.fieldName === group.fieldName) {
          return {
            ...g,
            fields: g.fields.filter(field => {
              const indexMatch = field.fieldName.match(/_index(\d+)$/);
              const fieldIndex = indexMatch ? parseInt(indexMatch[1], 10) : -1;
              return fieldIndex !== currentMaxIndex;
            })
          };
        }
        // If this has fields that might contain nested groups
        if (g.fields && g.fields.length > 0) {
          return {
            ...g,
            fields: updateNestedGroup(g.fields)
          };
        }
        return g;
      });
    };

    this.groups = updateNestedGroup(this.groups);
  
    // Remove corresponding form controls
    Object.keys(this.validateForm.controls).forEach(controlName => {
      if (controlName.includes(`${group.fieldName}_`) && controlName.endsWith(`_index${currentMaxIndex}`)) {
        this.validateForm.removeControl(controlName);
      }
    });
  }

  // the logic of get group value and list group value
  // 1. if group.list == true, get array index, map(index) finally get an array; if group.list == false, call process directly
  // 2. get group.fields and iterate fields, if it's a group, recursion, if it's a fieldDefinition, get the value and return { "fieldId" : "id", value: value }, we use Observable.map so finnaly we get an object(resuleObject), so one group with one index will return one object
  // 3. if group.list == true, we will have many indices in the same group, so we will get an object array, because one group is also a fieldDefinition, we can repeat it, so one group value is an object: { "groupId":[resultobj1,resultobj2] }, if we repeat the parent group, we will get [{ "groupId":[resultobj1,resultobj2] },{ "groupId":[resultobj1,resultobj2] }]
  private getGroupValues(group: GroupFieldDefinition): Observable<any> {    
    if (group.list) {
      // Get all unique indices from the field names
      const indices = Array.from(new Set(
        group?.fields
          .map(field => {
            const match = field.fieldName.match(/_index(\d+)$/);
            return match ? parseInt(match[1], 10) : -1;
          })
          .filter(index => index !== -1)
      )).sort((a, b) => a - b);

      if (indices.length === 0) {
        return of([]);
      }
  
      // Process each index
      return forkJoin(
        indices.map(index => this.processGroupIndex(group, index))
      ).pipe(
        map(results => {
          const filteredResults = results.filter(result => Object.keys(result).length > 0);
          return filteredResults;
        })
      );
    } else {
      return this.processGroupIndex(group);
    }
  }
  
  private processGroupIndex(
    group: GroupFieldDefinition,
    index?: number
  ): Observable<any> {
    const fieldObservables = group.fields.filter(field => {
      if(index != undefined){
        const lastNumber = field.fieldName.match(/\d+$/)?.[0];
        return lastNumber && parseInt(lastNumber, 10) === index;
      }else{
        return true
      } 
    }).map(field => {
      if (field.__typename === 'GroupFieldDefinition') {
        // For nested groups, find the nested group in the current group's fields
        // const currentGroup = this.findGroupByFieldName(group.fieldName);
        const nestedGroupField = group.fields.find(f => 
          f.fieldName === field.fieldName
        );
  
        if (nestedGroupField) {
          return this.getGroupValues(nestedGroupField as GroupFieldDefinition).pipe(
            map(nestedValue => ({
              fieldId: field.id,
              value: nestedValue
            }))
          );
        }
      } else {
        // For regular fields
        const fieldName = field.fieldName
        const control = this.validateForm.controls[fieldName];
        const value = control?.value;
  
        if (field.__typename === 'ListRelationFieldDefinition' && field.relatedEntityName === 'Asset') {
          // Process asset fields to handle new uploads
          return this.processAssetField(fieldName).pipe(
            map(assetIds => ({
              fieldId: field.id,
              value: assetIds
            }))
          );
        } else {
          // For all other fields, include value even if null
          return of({
            fieldId: field.id,
            value: value
          });
        }
      }
      return of(null);
    }).filter(obs => obs !== null);
  
    return forkJoin(fieldObservables).pipe(
      map(results => {
        const resultObject: { [key: string]: any } = {};
        results.forEach(result => {
          if (result) {
            resultObject[result.fieldId] = result.value;
          }
        });
        return resultObject;
      })
    );
  }
  
  /**
   * Process an asset field to get all asset IDs
   * @param fieldName - The name of the asset field
   * @returns Observable of asset IDs
   */
  private processAssetField(fieldName: string): Observable<string[]> {
    const control = this.validateForm.controls[fieldName];
    if (!control) return of([]);
  
    const assets = control.getRawValue();
    if (!assets || !assets.length) return of([]);
  
    // Handle new (local) assets
    const localAssets = assets
      .filter(asset => asset.id.indexOf('local-') !== -1)
      .map(asset => ({ file: asset.file }));
  
    if (localAssets.length) {
      return this.assetService.createAssets(localAssets).pipe(
        map(response => {
          const newAssetIds = response.data?.createAssets
            .filter(asset => asset.__typename === 'Asset')
            .map(asset => (asset as Asset).id) || [];
          
          // Combine with existing asset IDs
          const existingAssetIds = assets
            .filter(asset => asset.id.indexOf('local-') === -1)
            .map(asset => asset.id);
          
          return [...newAssetIds, ...existingAssetIds];
        })
      );
    }
  
    // Return existing asset IDs if no new assets
    return of(assets.map(asset => asset.id));
  }

  /**
   * Delete ACF values
   * If the form control value is not null, it will delete the value
   * If the form control value is null, it will do nothing
   * 
   * @param entityId - The ID of the entity
   * @returns An observable that emits the result of the delete operation
   */
  deleteValues(entityId: string): Observable<any> {
    // Collect all delete operations
    const deleteFieldsOptions$ = this.fieldDefinitions.map((field) => {
      if (field.__typename === 'StringFieldDefinition') {
        return this.validateForm.controls[field.fieldName].value
          ? this.acfService.deleteStringValue({entityId, entityName: this.entityName, fieldName: field.fieldName})
          : of(null)
      } else if (field.__typename === 'BooleanFieldDefinition') {
        return this.acfService.deleteBooleanValue({entityId, entityName: this.entityName, fieldName: field.fieldName})
      } else if (field.__typename === 'DateFieldDefinition') {
        return this.validateForm.controls[field.fieldName].value
          ? this.acfService.deleteDateValue({entityId, entityName: this.entityName, fieldName: field.fieldName})
          : of(null)
      }else if(field.__typename === 'ListFieldDefinition'){
        return this.validateForm.controls[field.fieldName].value && this.validateForm.controls[field.fieldName].value.length
          ? this.acfService.deleteListValue({entityId, entityName: this.entityName, fieldName: field.fieldName})
          : of(null)
      } else if (field.__typename === 'ListRelationFieldDefinition') {
        return this.validateForm.controls[field.fieldName].value && this.validateForm.controls[field.fieldName].value.length
          ? this.acfService.deleteListRelationValue({entityId, entityName: this.entityName, fieldName: field.fieldName})
          : of(null)
      } else {
        return of(null);
      }
    });

    const deleteGroupFieldsOptions$ = this.groups.map((group) => {
      if(group.list){
        return this.acfService.deleteListGroupValue({entityId, entityName: this.entityName, fieldName: group.fieldName})
      }else{
        return this.acfService.deleteGroupValue({entityId, entityName: this.entityName, fieldName: group.fieldName})
      }
    })

    // Use forkJoin to wait for all delete operations to complete
    return forkJoin([...deleteFieldsOptions$, ...deleteGroupFieldsOptions$]).pipe(
      catchError(error => {
        console.error('Error deleting ACF values:', error);
        return throwError(() => error);
      })
    );
  }

  // ==========================List===========================
  
  storeListValues(field: AcfFieldDefinitionUnion, listValues: string[]) {
    this.listValues[field.fieldName] = listValues
  }

  addItem(input: HTMLInputElement, fieldName: string){
    const value = input.value
    if(!value || value.trim() === ''){
      return
    }
    this.listValues = {
      ...this.listValues,
      [fieldName]: Array.from(new Set([...(this.listValues[fieldName] || []), value]))
    }
    input.value = ''
  }

  // =======================ListRelation======================

  storeAllEntities(field: AcfFieldDefinitionUnion) {
    const relatedEntityName = field.__typename === 'ListRelationFieldDefinition' ? field.relatedEntityName : null
    if(relatedEntityName === 'Facet'){
      this.facetService.fetchFacets().subscribe((response) => {
        this.entities[field.fieldName] = response.data.facets.items.map((facet) => ({
          id: facet.id,
          label: facet.name
        }))
      });
    }else if(relatedEntityName === 'Product'){
      this.productService.fetchProducts().subscribe((response) => {
        this.entities[field.fieldName] = response.data.products.items.map((product) => ({
          id: product.id,
          label: product.name
        }))
      });
    }else if(relatedEntityName === 'ProductVariant'){
      this.productService.fetchProductVariants().subscribe((response) => {
        this.entities[field.fieldName] = response.data.productVariants.items.map((productVariant) => ({
          id: productVariant.id,
          label: productVariant.name
        }))
      });
    }
  }

  // ==========================Asset==========================

  openAssetModal(fieldName: string){
    this.getPaginatedAssets();
    this.currentFieldName = fieldName;
    this.showAssetModal = true;
  }

  getPaginatedAssets(){
    this.assetService.getPaginatedAssets(this.pageSize, this.pageNum, this.searchAssetControl.value || '').subscribe((response) => {
      this.paginatedAssets = response.data.assets.items.map((asset) => ({
        id: asset.id,
        name: asset.name,
        source: asset.source
      }))
      this.totalAssets = response.data.assets.totalItems
    })
  }

  searchAssetsByName(){
    // reset pageNum
    this.pageNum = 1;
    this.getPaginatedAssets();
  }

  onPageIndexChange(page: number){
    this.pageNum = page;
    this.getPaginatedAssets();
  }

  onPageSizeChange(pageSize: number){
    // reset pageNum
    this.pageNum = 1;
    this.pageSize = pageSize;
    this.getPaginatedAssets();
  }

  selectAsset(asset: any) {
    const index = this.selectedAssets.findIndex(item => item.id === asset.id);
    if (index === -1) {
        if (!this.selectedAssets.some(item => item.id === asset.id)) {
          this.selectedAssets.push(asset);
        }
    } else {
        this.selectedAssets.splice(index, 1);
    }
  }

  isAssetSelected(asset: any): boolean {
    return this.selectedAssets.some(item => item.id === asset.id)
  }

  cancelAssetSelection(){
    this.showAssetModal = false;
    this.selectedAssets = [];
    this.searchAssetControl.setValue('');
    this.paginatedAssets = [];
    // reset pageNum
    this.pageNum = 1;
  }

  completeAssetSelection(fieldName: string){
    this.showAssetModal = false;
    this.selectedAssets.forEach(async (asset) => {
      const file = await this.assetService.fetchFile(asset.source, asset.name);
      // Check if asset already exists in form array
      const existingIndex = (this.validateForm.controls[fieldName] as FormArray)
        .controls.findIndex(control => control.get('id')?.value === asset.id);
      if (existingIndex === -1) {
        this.validateForm.controls[fieldName].push(
          this.formBuilder.group<AssetForm>({
            id: this.formBuilder.control(asset.id),
            file: this.formBuilder.control(file), 
            binary: this.formBuilder.control(await this.assetService.fileToBase64(file)),
            name: this.formBuilder.control(asset.name),
            type: this.formBuilder.control(asset.mimeType),
          })
        );
      }
    });
    this.selectedAssets = [];
    this.searchAssetControl.setValue('');
    this.paginatedAssets = [];
    // reset pageNum
    this.pageNum = 1;
  }

  // check image size
  onFileSelected(fieldName: string, event: any) {
    this.showAssetModal = false;
    const file: File = event.target.files[0]
    if (!file) {
      this.mediaUploadingMessage = 'Please select an image'
      return
    }

    // Check file type
    const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/svg+xml']
    if (!allowedTypes.includes(file.type)) {
      this.mediaUploadingMessage = 'Only PNG, JPG, JPEG, GIF, and SVG files are allowed'
      // Clear the file input
      event.target.value = '' 
      return
    }

    const id = `local-${v4()}`
    const fileName: string = file.name
    const fileType: string = file.type
    // define file information
    this.mediaUploadingMessage = ''

    // Convert to base64
    const reader = new FileReader()
    reader.onload = (e: any) => {
      const img = new Image()
      img.src = e.target.result

      img.onload = () => {
       this.validateForm.controls[fieldName].push(
          this.formBuilder.group({
            id,
            name: fileName,
            type: fileType,
            file,
            binary: e.target.result as string,
          })
        )
      }
    }

    reader.readAsDataURL(file)
  }

  displayImage(asset: FormGroup<AssetForm>) {
    if (asset.getRawValue().id.indexOf('local-') !== -1) {
      return asset.getRawValue().binary;
    }

    const assetId = asset.getRawValue().id;
    if (!this.assetSources[assetId]) {
      this.assetService.getAsset(assetId).pipe(
        map(response => response.data?.asset?.source || '')
      ).subscribe(source => {
        this.assetSources[assetId] = source;
      });
    }
    
    return this.assetSources[assetId] || '';
  }
}
