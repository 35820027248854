import { Component, OnDestroy, OnInit } from '@angular/core'
import {
  FormArray,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { cloneDeep, flatMap } from 'lodash'
import { NzFormLayoutType } from 'ng-zorro-antd/form'
import { NzMessageService } from 'ng-zorro-antd/message'
import {
  combineLatest,
  concat,
  concatMap,
  defaultIfEmpty,
  delay,
  filter,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
  tap,
  toArray,
} from 'rxjs'
import { v4 } from 'uuid'
import {
  Asset,
  CreateProductMutation,
  CreateProductVariantInput,
  CurrencyCode,
  FacetValue,
  GlobalFlag,
  LanguageCode,
  Product,
  ProductOption,
  ProductOptionGroup,
  ProductVariant,
  SortOrder,
  StockLevel,
  StockLocation,
  TaxCategory,
  UpdateProductVariantInput,
} from '../../gql/shop/generated'
import { AssetService } from '../../service/asset.service'
import { FacetService } from '../../service/facet.service'
import { ProductService } from '../../service/product.service'
import { StockService } from '../../service/stock-location.service'
import { ArrayValidators } from '../../utils/angular-patch/form-validation'
import { GlobalService } from '../../service/global.service'

interface FacetValueForm {
  id: FormControl<string>
  code: FormControl<string>
  name: FormControl<string>
}
interface TranslationForm {
  id: FormControl<string>
  name: FormControl<string>
  languageCode: FormControl<string>
}

interface VariantForm {
  id: FormControl<string>;
  languageCode: FormControl<string>;
  enabled: FormControl<boolean>;
  assets: FormArray<FormGroup<AssetForm>>;
  sku: FormControl<string>;
  name: FormControl<string>;
  taxCategoryId: FormControl<string>;
  stockOnHand: FormControl<number>;
  useGlobalOutOfStockThreshold: FormControl<boolean>;
  outOfStockThreshold: FormControl<number>;
  trackInventory: FormControl<GlobalFlag>;
  facetValues: FormArray<FormGroup<FacetValueForm>>;
  stockLocations: FormArray<FormGroup<StockLocationForm>>;
  pricesForm: FormGroup<PriceForm>;
}

interface VariantOptionForm {
  id: FormControl<string>
  code: FormControl<string>
  name: FormControl<string>
  translations: FormArray<FormGroup<TranslationForm>>
}

interface VariantOptionGroupForm {
  id: FormControl<string>
  code: FormControl<string>
  name: FormControl<string>
  options: FormArray<FormGroup<VariantOptionForm>>
  newItem: FormControl<string>
  selectedItem: FormControl<VariantOptionForm | null>
  translations: FormArray<FormGroup<TranslationForm>>
}

interface AssetForm {
  name: FormControl<string>
  file: FormControl<File>
  type: FormControl<string>
  binary: FormControl<string>
  id: FormControl<string>
}

interface TaxCategoryForm {
  id: FormControl<string>
  name: FormControl<string>
}

interface StockLocationForm {
  id: FormControl<string>
  name: FormControl<string>
  stock: FormControl<number>
  allocated: FormControl<number>
}

interface PriceForm {
  price: FormControl<number | null>;
  currencyCode: FormControl<CurrencyCode | null>;
}

interface TrackInventoryOptionForm {
  name: FormControl<GlobalFlag>
}

@Component({
  selector: 'app-product-variant',
  templateUrl: './product-variant.component.html',
  styleUrls: ['./product-variant.component.less']
})
export class ProductVariantComponent implements OnInit, OnDestroy {
  validateForm: FormGroup<{
    formLayout: FormControl<NzFormLayoutType>,
    variant: FormGroup<VariantForm>,
    selectedFacetValue: FormControl<FacetValueForm | null>,
    taxCategories: FormArray<FormGroup<TaxCategoryForm>>,
    selectedStockLocation: FormControl<StockLocationForm | null>,
    stockLocations: FormArray<FormGroup<StockLocationForm>>,
    trackInventoryOptions: FormArray<FormGroup<TrackInventoryOptionForm>>,
    taxRate: FormControl<number | 0>,
  }> = this.formBuilder.group({
    formLayout: 'horizontal' as NzFormLayoutType,
    variant: this.formBuilder.group({
      id: this.formBuilder.control(''),
      languageCode: this.formBuilder.control(''),
      assets: this.formBuilder.array<FormGroup<AssetForm>>([]),
      name: this.formBuilder.control('',Validators.required),
      sku: this.formBuilder.control('',Validators.required),
      enabled: this.formBuilder.control(true, Validators.required),
      taxCategoryId: this.formBuilder.control(''),
      stockOnHand: this.formBuilder.control(0),
      useGlobalOutOfStockThreshold: this.formBuilder.control(false),
      outOfStockThreshold: this.formBuilder.control(0),
      trackInventory: this.formBuilder.control(GlobalFlag.FALSE),
      facetValues: this.formBuilder.array<FormGroup<FacetValueForm>>([]),
      stockLocations: this.formBuilder.array<FormGroup<StockLocationForm>>([]),
      pricesForm: this.formBuilder.group<PriceForm>({
        price: this.formBuilder.control<number | null>(null),
        currencyCode: this.formBuilder.control<CurrencyCode | null>(null),
      }),
    }),
    selectedFacetValue: this.formBuilder.control<FacetValueForm | null>(null),
    taxCategories: this.formBuilder.array<FormGroup<TaxCategoryForm>>([]),
    selectedStockLocation: this.formBuilder.control<StockLocationForm | null>(null),
    stockLocations: this.formBuilder.array<FormGroup<StockLocationForm>>([]),
    trackInventoryOptions: this.formBuilder.array<FormGroup<TrackInventoryOptionForm>>([]),
    taxRate: this.formBuilder.control<number | 0>(0),
  })

  showRemoveProductVariantModal = false;
  finishSubmit = false
  name = '';
  variantId: string | null = null;
  isDropZoneDragging = false
  mediaUploadingMessage = ''
  // store assets
  assetSources: { [key: string]: string } = {};
  // currency symbol
  currencySymbol = ''
  // global out of stock threshold
  globalOutOfStockThreshold = 0
  // set product id
  productId = ''
  // price with tax
  priceWithTax = 0

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    public facetService: FacetService,
    private assetService: AssetService,
    private messageService: NzMessageService,
    private globalService: GlobalService
  ){}

  ngOnDestroy(): void {
    // TODO: do nothing, didn't use state
    console.log('ngOnDestroy')
  }
  
  ngOnInit() {
    // Get both productId and variantId from the route parameters
    this.route.paramMap.subscribe(params => {
      this.variantId = params.get('id');
      
      if (this.variantId) {
        // Load variant data
        this.initData();
      }
    });
  }

  private initData() {
    this.globalService.fetchGlobalSettings().subscribe((response) => {
      this.globalOutOfStockThreshold = response?.data?.globalSettings?.outOfStockThreshold ?? 0
    })
    this.productService.fetchProductVariant(this.variantId as string).subscribe((response) => {
      const productVariant = response?.data?.productVariant
      this.productId = productVariant?.product?.id ?? ''
      // set product variant name sku and enabled
      this.name = productVariant?.name ?? '';
      this.validateForm.controls.variant.controls.id.setValue(this.variantId as string)
      this.validateForm.controls.variant.controls.name.setValue(this.name)
      this.validateForm.controls.variant.controls.sku.setValue(productVariant?.sku ?? '')
      this.validateForm.controls.variant.controls.enabled.setValue(productVariant?.enabled ?? false)
      // set variant assets
      const assets = this.validateForm.controls.variant.controls.assets
      assets.clear()
      productVariant?.assets.forEach(async (asset: any) => {
        const file = await this.assetService.fetchFile(
          asset.source,
          asset.name
        )
        const binary = await this.assetService.fileToBase64(file)

        assets.push(
          this.formBuilder.group<AssetForm>({
            id: this.formBuilder.control(asset.id),
            file: this.formBuilder.control(file),
            binary: this.formBuilder.control(binary),
            name: this.formBuilder.control(asset.name),
            type: this.formBuilder.control(asset.mimeType),
          })
        )
      })
      // set variant facet values
      const facetValues =
            this.validateForm.controls.variant.controls.facetValues
      facetValues.clear()
      productVariant?.facetValues.forEach((item: any) => {
        const value = {
          name: this.formBuilder.control(item.name, [Validators.required]),
          code: this.formBuilder.control(item.code, [Validators.required]),
          id: this.formBuilder.control(item.id),
        }
        const newGroup = this.formBuilder.group<FacetValueForm>(value)
        facetValues.push(newGroup)
      })

      this.validateForm.patchValue({
        variant: this.formBuilder
          .group({facetValues})
          .getRawValue(),
      })
      // set varinat tax categories options
      response?.data?.taxCategories.items.forEach((taxCategory: TaxCategory) => {
        this.validateForm.controls.taxCategories.push(this.formBuilder.group({
          id: this.formBuilder.control(taxCategory.id),
          name: this.formBuilder.control(taxCategory.name),
        }))
      })
      // set variant tax category
      this.validateForm.controls.variant.controls.taxCategoryId.setValue(productVariant?.taxCategory?.id ?? '')
      // set variant tax rate
      this.validateForm.controls.taxRate.setValue(productVariant?.taxRateApplied?.value ?? 0)
      // set variant price with tax
      this.priceWithTax = (productVariant?.prices[0]?.price ?? 0) / 100 * ( 1 + (this.validateForm.controls.taxRate.getRawValue() / 100))
      // set variant price
      this.validateForm.controls.variant.controls.pricesForm.setValue({
        price: (productVariant?.prices[0]?.price ?? 0) / 100,
        currencyCode: productVariant?.prices[0]?.currencyCode ?? CurrencyCode.USD,
      })
      // set track inventory options
      this.validateForm.controls.trackInventoryOptions.push(this.formBuilder.group({
        name: this.formBuilder.control(GlobalFlag.FALSE)
      }))
      this.validateForm.controls.trackInventoryOptions.push(this.formBuilder.group({
        name: this.formBuilder.control(GlobalFlag.INHERIT)
      }))
      this.validateForm.controls.trackInventoryOptions.push(this.formBuilder.group({
        name: this.formBuilder.control(GlobalFlag.TRUE)
      }))
      // set selected track inventory option
      this.validateForm.controls.variant.controls.trackInventory.setValue(productVariant?.trackInventory as GlobalFlag)
      // set use global out of stock threshold
      this.validateForm.controls.variant.controls.useGlobalOutOfStockThreshold.setValue(productVariant?.useGlobalOutOfStockThreshold ?? false)
      // set out of stock threshold
      this.validateForm.controls.variant.controls.outOfStockThreshold.setValue(productVariant?.outOfStockThreshold ?? 0)
      // set variant stock locations options
      response?.data?.stockLocations.items.forEach((stockLocation: StockLocation) => {
        this.validateForm.controls.stockLocations.push(this.formBuilder.group({
          id: this.formBuilder.control(stockLocation.id),
          name: this.formBuilder.control(stockLocation.name),
          stock: productVariant?.stockLevels?.find(stockLevel => stockLevel.stockLocationId === stockLocation.id)?.stockOnHand ?? 0,
          allocated: productVariant?.stockLevels?.find(stockLevel => stockLevel.stockLocationId === stockLocation.id)?.stockAllocated ?? 0,
        }))
      })
      // set variant stock locations
      const stockLocations = productVariant?.stockLevels?.map(stockLevel => 
        this.formBuilder.group({
          id: this.formBuilder.control(stockLevel.stockLocationId),
          name: this.formBuilder.control(stockLevel.stockLocation?.name ?? ''),
          stock: this.formBuilder.control(stockLevel.stockOnHand),
          allocated: this.formBuilder.control(stockLevel.stockAllocated),
        })
      ) ?? [];
      
      this.validateForm.controls.variant.controls.stockLocations.clear();
      stockLocations.forEach(location => 
        this.validateForm.controls.variant.controls.stockLocations.push(location)
      );
      // update stock locations
      this.validateForm.controls.stockLocations.controls.forEach((stockLocation, index) => {
        console.log(stockLocation.getRawValue());
        if (stockLocation.getRawValue().id === productVariant?.stockLevels?.find(stockLevel => stockLevel.stockLocationId === stockLocation.getRawValue().id)?.stockLocationId) {
          this.validateForm.controls.stockLocations.removeAt(index)
        }
      })
      // set currency symbol
      const locale = productVariant?.languageCode.replace(/_/g, '-');
      this.validateForm.controls.variant.controls.languageCode.setValue(productVariant?.languageCode ?? '')
      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: productVariant?.prices[0]?.currencyCode ?? ''
      });
      this.currencySymbol = formatter.format(0).charAt(0);
    });

    // Setup SelectedFacetValueControl Listener
    this.validateForm.controls.selectedFacetValue.valueChanges.subscribe(
      (item) => {
        if (item) {
          const value = {
            name: this.formBuilder.control(item.name, [Validators.required]),
            code: this.formBuilder.control(item.code, [Validators.required]),
            id: this.formBuilder.control(item.id, [Validators.required]),
          }
          const newGroup = this.formBuilder.group<FacetValueForm>(value)
          // if the facet value is already in the form, do not add it again
          if (this.validateForm.controls.variant.controls.facetValues.controls.find(fv => fv.controls.id.value === item.id.toString())) {
            return
          }
          this.validateForm.controls.variant.controls.facetValues.push(newGroup)
        }
      }
    )
  }

  submitForm() {
    // check if there's at least one stock location
    if (this.validateForm.controls.variant.controls.stockLocations.controls.length === 0) {
      this.messageService.error('Please add at least one stock location')
      return
    }

    const variant = this.validateForm.controls.variant.getRawValue()

    this.validateForm.markAllAsTouched()
    this.validateForm.updateValueAndValidity()
    this.validateForm.validate()

    if(this.validateForm.valid){
      this.finishSubmit = true
      const newAssetsFile = variant.assets
        .filter((asset) => asset.id.indexOf('local') > -1)
        .map((asset) => ({ file: asset.file }))
      const oldAssets = variant.assets.filter(
        (asset) => asset.id.indexOf('local') === -1
      )
      
      this.assetService
        .createAssets(newAssetsFile)
        .pipe(
          map(
            (assetsResponse) => {
              if (!assetsResponse.data?.createAssets) {
                return [];
              }
        
              const newAssets = assetsResponse.data.createAssets
                .filter((asset) => asset.__typename === 'Asset')
                .map((asset) => asset as Asset);
        
              // Update form control values for each new asset
              newAssets.forEach((newAsset) => {
                const assetControls = this.validateForm.controls.variant.controls.assets.controls;
                const localAssetControl = assetControls.find(control => 
                  control.getRawValue().id.includes('local')
                );
        
                if (localAssetControl) {
                  localAssetControl.patchValue({ id: newAsset.id });
                }
              });
        
              return newAssets.map(asset => asset.id);
            }
          ),
          mergeMap((newAssetIds: string[]) => {
            const oldAssetsIds = oldAssets.map((oldAsset) => oldAsset.id)

            const input: UpdateProductVariantInput = {
              enabled: variant.enabled,
              assetIds: newAssetIds.concat(oldAssetsIds),
              facetValueIds: variant.facetValues.map(fv => fv.id),
              id: variant.id,
              outOfStockThreshold: variant.outOfStockThreshold,
              sku: variant.sku,
              prices: [{
                price: (variant.pricesForm.price ?? 0) * 100,
                currencyCode: variant.pricesForm.currencyCode ?? CurrencyCode.USD,
                delete: false
              }],
              stockLevels: variant.stockLocations.map(sl => ({
                stockLocationId: sl.id,
                stockOnHand: +sl.stock,
              })),
              taxCategoryId: variant.taxCategoryId,
              trackInventory: variant.trackInventory,
              translations: [{
                id: variant.id,
                languageCode: variant.languageCode as LanguageCode,
                name: variant.name,
              }],
              useGlobalOutOfStockThreshold: variant.useGlobalOutOfStockThreshold,
            }
            return this.productService.updateProductVariants(input)
          })
        ).subscribe({
          next: () => {
            console.log(
              'Product variant updated successfully'
            )
            // update price with tax after updating product variant
            this.priceWithTax = (this.validateForm.controls.variant.controls.pricesForm.getRawValue().price ?? 0) * ( 1 + (this.validateForm.controls.taxRate.getRawValue() / 100))
            this.finishSubmit = false
          },
          error: (err) => {
            console.log(err)
            console.error('Error during product creation:', err)
          },
      })
    }
  }

  deleteProductVariant() {
    this.productService.deleteProductVariant(this.variantId as string).subscribe({
      next: (response) => {
        this.messageService.success('Product variant deleted successfully')
        this.router.navigate(['/admin/products/', this.productId]).then(() => window.location.reload());
      },
      error: (error) => {
        this.messageService.error('Failed to delete product variant')
        this.showRemoveProductVariantModal = false
      }
    })
  }

  onDropZoneDrop(event: DragEvent) {
    event.preventDefault()
    if (event.dataTransfer?.files) {
      const files = Array.from(event.dataTransfer.files)
      this.handleFiles(files)
    }
    this.isDropZoneDragging = false
  }

  onDropZoneDragOver(event: DragEvent) {
    event.preventDefault()
    this.isDropZoneDragging = true
  }

  handleFiles(files: File[]) {
    if(!files.length){
      this.mediaUploadingMessage = 'Please select an image'
      return
    }

    const id = `local-${v4()}`

    // Check file type
    const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/svg+xml']
    if (!allowedTypes.includes(files[0].type)) {
      this.mediaUploadingMessage = 'Only PNG, JPG, JPEG, GIF, and SVG files are allowed'
      return
    }

    files.forEach((file) => {
      const reader = new FileReader()
      reader.onload = (e: any) => {
        const img = new Image()
        img.src = e.target.result
        img.onload = () => {
          this.validateForm.controls.variant.controls.assets.push(
            this.formBuilder.group({
              id,
              name: file.name,
              type: file.type,
              file,
              binary: e.target.result as string,
            })
          )
        }
      }
      reader.readAsDataURL(file)
    })
  }

  onDropZoneDragLeave(event: DragEvent) {
    event.preventDefault()
    this.isDropZoneDragging = false
  }

  // check image size
  onFileSelected(event: any) {
    const file: File = event.target.files[0]
    if (!file) {
      this.mediaUploadingMessage = 'Please select an image'
      return
    }

    // Check file type
    const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/svg+xml']
    if (!allowedTypes.includes(file.type)) {
      this.mediaUploadingMessage = 'Only PNG, JPG, JPEG, GIF, and SVG files are allowed'
      event.target.value = '' // Clear the file input
      return
    }

    // define file information
    const id = `local-${v4()}`
    const fileName: string = file.name
    const fileType: string = file.type
    this.mediaUploadingMessage = ''

    // Convert to base64
    const reader = new FileReader()
    reader.onload = (e: any) => {
      const img = new Image()
      img.src = e.target.result

      img.onload = () => {
        this.validateForm.controls.variant.controls.assets.push(
          this.formBuilder.group({
            id,
            name: fileName,
            type: fileType,
            file,
            binary: e.target.result as string,
          })
        )
      }
    }

    reader.readAsDataURL(file)
  }

  displayImage(asset: FormGroup<AssetForm>) {
    if (asset.getRawValue().id.indexOf('local-') !== -1) {
      return asset.getRawValue().binary;
    }

    const assetId = asset.getRawValue().id;
    if (!this.assetSources[assetId]) {
      this.assetService.getAsset(assetId).pipe(
        map(response => response.data?.asset?.source || '')
      ).subscribe(source => {
        this.assetSources[assetId] = source;
      });
    }
    
    return this.assetSources[assetId] || '';
  }

  searchFacet(term: string) {
    this.facetService
      .fetchFacetValues({
        filter: {
          name: {
            contains: term,
          },
        },
      })
      .subscribe()
  }

  onPriceInput(event: any, control: FormControl) {
    const input = event.target;
    const value = parseFloat(input.value);
    
    if (!isNaN(value)) {
      if (value > 999999) {
        // If exceeds maximum, set to maximum
        control.setValue(999999);
        this.calculatePriceWithTax(999999)
        return;
      }
      const formattedValue = Math.floor(value * 100) / 100;
      // Only update if the value has changed
      if (formattedValue !== value) {
        control.setValue(formattedValue);
        this.calculatePriceWithTax(formattedValue)
        return;
      }
    }
    this.calculatePriceWithTax(value)
  }

  calculatePriceWithTax(value: number){
    this.priceWithTax = value * ( 1 + (this.validateForm.controls.taxRate.getRawValue() / 100))
  }

  createStockLocation(){
    // selected stock location  
    const selectedStockLocation = this.validateForm.controls.selectedStockLocation.getRawValue()

    if(!selectedStockLocation){
      return
    }
    // push to variant stock locations
    this.validateForm.controls.variant.controls.stockLocations.push(
      this.formBuilder.group(selectedStockLocation)
    );
    // this will trigger `ngModelChange` event again
    this.validateForm.controls.selectedStockLocation.setValue(null)

    // remove stock location from stock locations
    this.validateForm.controls.stockLocations.controls.forEach((stockLocation, index) => {
      if (stockLocation.controls.id.getRawValue() === selectedStockLocation.id.getRawValue()) {
        this.validateForm.controls.stockLocations.removeAt(index)
      }
    })
  }

  removeStockLocation(stockLocation: FormGroup<StockLocationForm>){
    this.validateForm.controls.variant.controls.stockLocations.removeAt(this.validateForm.controls.variant.controls.stockLocations.controls.indexOf(stockLocation))
    this.validateForm.controls.stockLocations.push(stockLocation)
  }

  goBack(){
    this.router.navigate(['/admin/products/', this.productId]).then();
  }
}
