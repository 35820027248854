import { Injectable } from '@angular/core'
import { createStore, select, withProps } from '@ngneat/elf'
import { tap } from 'rxjs'
import {
  InputMaybe,
  StockLocationList,
  StockLocationListOptions,
  StockLocationsGQL,
} from '../gql/shop/generated'
import { AppService } from './app.service'
import { AuthService } from './auth.service'

interface StockState {
  isLoading: boolean
  stockLocations: StockLocationList
}

@Injectable({
  providedIn: 'root',
})
export class StockService {
  private store = createStore(
    { name: 'stock' },
    withProps<StockState>({
      isLoading: false,
      stockLocations: {
        items: [],
        totalItems: 0,
      },
    })
  )

  stockLocations$ = this.store.pipe(select((state) => state.stockLocations))

  constructor(
    private stockLocationsGQL: StockLocationsGQL,
    private appService: AppService,
    private authService: AuthService
  ) {}

  fetchStockLocations(input: InputMaybe<StockLocationListOptions>) {
    return this.appService.withLoader(() =>
      this.stockLocationsGQL
        .fetch(
          {
            input,
          },
          {
            context: {
              headers: {
                'vendure-token':
                  this.authService.authStore$.getValue().currentUserChannel
                    ?.token,
              },
            },
          }
        )
        .pipe(
          tap({
            next: (response) => {
              this.store.update((state) => {
                return {
                  ...state,
                  stockLocations: response.data
                    .stockLocations as StockLocationList,
                }
              })
            },
          })
        )
    )
  }

  clearStockLocations() {
    this.store.update((state) => {
      return {
        ...state,
        stockLocations: {
          items: [],
          totalItems: 0,
        },
      }
    })
  }
}
