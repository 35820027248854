import { createStore } from "@ngneat/elf";
import { Injectable } from "@angular/core";
import { GlobalSettingsGQL } from "../gql/shop/generated";
import { AppService } from "./app.service";
import { tap } from "rxjs/operators";
import { AuthService } from "./auth.service";


@Injectable({ providedIn: 'root' })
export class GlobalService {
    constructor(
        private appService: AppService,
        private authService: AuthService,
        private globalSettingsGQL: GlobalSettingsGQL,
    ) {}

    fetchGlobalSettings() {
        return this.appService.withLoader(() =>
            this.globalSettingsGQL.fetch(
                {},
                {
                    context: {
                        headers: {
                            'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                        },
                    },
                }
            ).pipe(
                tap((result) => {
                    console.log(result);
                })
            )
        )
    }
}   