import { Injectable } from '@angular/core'
import { AppService } from './app.service'
import { AuthService } from './auth.service'
import { GetAllInput, GetAllGQL, InsertGroupGQL, InsertGroupInput, UpdateGroupGQL, UpdateGroupInput, InsertStringValueInput, InsertStringValueGQL, InsertBooleanValueGQL, InsertDateValueGQL, InsertListGroupValueGQL, DeleteListGroupValueGQL, InsertGroupValueGQL, InsertListRelationValueGQL, InsertListValueGQL, DeleteGroupValueGQL, DeleteStringValueGQL, DeleteBooleanValueGQL, DeleteDateValueGQL, DeleteListRelationValueGQL, DeleteListValueGQL, InsertBooleanValueInput, InsertListValueInput, InsertDateValueInput, InsertGroupValueInput, InsertListGroupValueInput, GetStringValueInput, GetDateValueGQL, GetBooleanValueGQL, GetStringValueGQL, GetListRelationValueGQL, GetGroupValueGQL, GetListValueGQL, GetListGroupValueGQL, GetBooleanValueInput, GetDateValueInput, GetListValueInput, GetListRelationValueInput, GetGroupValueInput, GetListGroupValueInput, DeleteListValueInput, DeleteStringValueInput, DeleteDateValueInput, DeleteListRelationInput, DeleteListGroupValueInput, DeleteGroupValueInput, DeleteBooleanValueInput, InsertListRelationValueInput } from '../gql/shop/generated';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AcfService {
    constructor(
        private appService: AppService,
        private authService: AuthService,
        private insertGroupGQL: InsertGroupGQL, 
        private updateGroupGQL: UpdateGroupGQL,
        private getAllGQL: GetAllGQL,
        private insertStringValueGQL: InsertStringValueGQL,
        private insertBooleanValueGQL: InsertBooleanValueGQL,
        private insertDateValueGQL: InsertDateValueGQL,
        private insertListValueGQL: InsertListValueGQL,
        private insertListRelationValueGQL: InsertListRelationValueGQL,
        private insertGroupValueGQL: InsertGroupValueGQL,
        private insertListGroupValueGQL: InsertListGroupValueGQL,
        private getStringValueGQL: GetStringValueGQL,
        private getBooleanValueGQL: GetBooleanValueGQL, 
        private getDateValueGQL: GetDateValueGQL,
        private getListValueGQL: GetListValueGQL,
        private getListRelationValueGQL: GetListRelationValueGQL,
        private getGroupValueGQL: GetGroupValueGQL,
        private getListGroupValueGQL: GetListGroupValueGQL,
        private deleteStringValueGQL: DeleteStringValueGQL,
        private deleteBooleanValueGQL: DeleteBooleanValueGQL,
        private deleteDateValueGQL: DeleteDateValueGQL,
        private deleteListValueGQL: DeleteListValueGQL,
        private deleteListRelationValueGQL: DeleteListRelationValueGQL,
        private deleteGroupValueGQL: DeleteGroupValueGQL,
        private deleteListGroupValueGQL: DeleteListGroupValueGQL,
    ) {}


    insertGroup(input: InsertGroupInput) {
        return this.appService.withLoader(() => this.insertGroupGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }
    
    updateGroup(input: UpdateGroupInput) {
        return this.appService.withLoader(() => this.updateGroupGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }

    getAll(input: GetAllInput) {
        return this.appService.withLoader(() => this.getAllGQL.fetch(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ).pipe(map((res) => res.data.getAll)));
    }

    insertStringValue(input: InsertStringValueInput) {
        return this.appService.withLoader(() => this.insertStringValueGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }

    insertBooleanValue(input: InsertBooleanValueInput) {
        return this.appService.withLoader(() => this.insertBooleanValueGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }

    insertDateValue(input: InsertDateValueInput) {
        return this.appService.withLoader(() => this.insertDateValueGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }

    insertListValue(input: InsertListValueInput) {
        return this.appService.withLoader(() => this.insertListValueGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }

    insertListRelationValue(input: InsertListRelationValueInput) {
        return this.appService.withLoader(() => this.insertListRelationValueGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }

    insertGroupValue(input: InsertGroupValueInput) {
        return this.appService.withLoader(() => this.insertGroupValueGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }

    insertListGroupValue(input: InsertListGroupValueInput) {
        return this.appService.withLoader(() => this.insertListGroupValueGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }

    getStringValue(input: GetStringValueInput) {
        return this.appService.withLoader(() => this.getStringValueGQL.fetch({ input }, {
            context: {
                headers: {
                    'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                },
            },
        }).pipe(map((res) => res.data.getStringValue?.value)));
    }

    getBooleanValue(input: GetBooleanValueInput) {
        return this.appService.withLoader(() => this.getBooleanValueGQL.fetch({ input }, {
            context: {
                headers: {
                    'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                },
            },
        }).pipe(map((res) => res.data.getBooleanValue?.value)));
    }

    getDateValue(input: GetDateValueInput) {
        return this.appService.withLoader(() => this.getDateValueGQL.fetch({ input }, {
            context: {
                headers: {
                    'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                },
            },
        }).pipe(map((res) => res.data.getDateValue?.value)));
    }

    getListValue(input: GetListValueInput) {
        return this.appService.withLoader(() => this.getListValueGQL.fetch({ input }, {
            context: {
                headers: {
                    'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                },
            },
        }).pipe(map((res) => res.data.getListValue?.value)));
    }

    getListRelationValue(input: GetListRelationValueInput) {
        return this.appService.withLoader(() => this.getListRelationValueGQL.fetch({ input }, {
            context: {
                headers: {
                    'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                },
            },
        }).pipe(map((res) => res.data.getListRelationValue)));
    }

    getGroupValue(input: GetGroupValueInput) {
        return this.appService.withLoader(() => this.getGroupValueGQL.fetch({ input }, {
            context: {
                headers: {
                    'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                },
            },
        }).pipe(map((res) => res.data.getGroupValue)));
    }

    getListGroupValue(input: GetListGroupValueInput) {
        return this.appService.withLoader(() => this.getListGroupValueGQL.fetch({ input }, {
            context: {
                headers: {
                    'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                },
            },
        }).pipe(map((res) => res.data.getListGroupValue)));
    }

    deleteStringValue(input: DeleteStringValueInput) {
        return this.appService.withLoader(() => this.deleteStringValueGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }

    deleteBooleanValue(input: DeleteBooleanValueInput) {
        return this.appService.withLoader(() => this.deleteBooleanValueGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }

    deleteDateValue(input: DeleteDateValueInput) {
        return this.appService.withLoader(() => this.deleteDateValueGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }

    deleteListValue(input: DeleteListValueInput) {
        return this.appService.withLoader(() => this.deleteListValueGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }

    deleteListRelationValue(input: DeleteListRelationInput) {
        return this.appService.withLoader(() => this.deleteListRelationValueGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }

    deleteGroupValue(input: DeleteGroupValueInput) {
        return this.appService.withLoader(() => this.deleteGroupValueGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }

    deleteListGroupValue(input: DeleteListGroupValueInput) {
        return this.appService.withLoader(() => this.deleteListGroupValueGQL.mutate(
            { input },
            {
                context: {
                    headers: {
                        'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
                    },
                },
            }
        ));
    }


}
