import { createStore, select, withProps } from "@ngneat/elf"
import { Injectable } from "@angular/core"
import { CustomersGQL, LogicalOperator, CreateCustomerGQL, CreateCustomerInput, GetCustomerAddressesGQL } from "../gql/shop/generated"
import { Customer } from "../gql/shop/generated"
import { CustomerListOptions } from "../gql/shop/generated"
import { CustomerList } from "../gql/shop/generated"
import { AuthService } from "./auth.service"
import { AppService } from "./app.service"
import { tap } from "rxjs"


interface CustomersState {
    customers: CustomerList
    params: CustomerListOptions
    currentPage: number
    pageSize: number
    customer: Customer | undefined
  }
  
  const initialState = withProps<CustomersState>({
    customer: undefined,
    customers: {
      items: [],
      totalItems: 0,
    },
    params: {
      filter: undefined,
      filterOperator: LogicalOperator.AND,
      skip: 0,
      take: 10,
      sort: undefined,
    },
    currentPage: 1,
    pageSize: 10,
  })

@Injectable({ providedIn: 'root' })
export class CustomerService {
  private store = createStore({ name: 'customer' }, initialState)

  customers$ = this.store.pipe(select((state) => state.customers))
  customer$ = this.store.pipe(select((state) => state.customer))
  currentPage$ = this.store.pipe(select((state) => state.currentPage))
  pageSize$ = this.store.pipe(select((state) => state.pageSize))

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private customersGQL: CustomersGQL,
    private createCustomerGQL: CreateCustomerGQL,
    private getCustomerAddressesGQL: GetCustomerAddressesGQL
  ) {}
  
  fetchCustomers() {
    return this.appService.withLoader(() =>
      this.customersGQL.fetch(
        {},
        { context: 
            { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } },
            fetchPolicy: 'network-only',
        }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          }
        })
      )
    )
  }

  createCustomer(input: CreateCustomerInput) {
    return this.appService.withLoader(() =>
      this.createCustomerGQL.mutate(
        { input: input },
        { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          }
        })
      )
    )
  }

  getCustomerAddresses(customerId: string) {
    return this.appService.withLoader(() =>
      this.getCustomerAddressesGQL.fetch(
        { customerId: customerId },
        { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } },
            fetchPolicy: 'network-only', 
        }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          }
        })
      )
    )
  }
}