import { Injectable } from '@angular/core'
import * as Apollo from 'apollo-angular'
import { gql } from 'apollo-angular'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any }
  /** The `Money` scalar type represents monetary values and supports signed double-precision fractional values as specified by [IEEE 754](https://en.wikipedia.org/wiki/IEEE_floating_point). */
  Money: { input: number; output: number }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any }
}

export type AcfFieldDefinitionUnion =
  | BooleanFieldDefinition
  | DateFieldDefinition
  | GroupFieldDefinition
  | ListFieldDefinition
  | ListRelationFieldDefinition
  | StringFieldDefinition

export type AcfValueUnion =
  | BooleanValue
  | DateValue
  | GroupValue
  | ListGroupValue
  | ListRelationValue
  | ListValue
  | StringValue

export type AddFulfillmentToOrderResult =
  | CreateFulfillmentError
  | EmptyOrderLineSelectionError
  | Fulfillment
  | FulfillmentStateTransitionError
  | InsufficientStockOnHandError
  | InvalidFulfillmentHandlerError
  | ItemsAlreadyFulfilledError

export type AddItemInput = {
  productVariantId: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
}

export type AddItemToDraftOrderInput = {
  productVariantId: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
}

export type AddManualPaymentToOrderResult = ManualPaymentStateError | Order

export type AddNoteToCustomerInput = {
  id: Scalars['ID']['input']
  isPublic: Scalars['Boolean']['input']
  note: Scalars['String']['input']
}

export type AddNoteToOrderInput = {
  id: Scalars['ID']['input']
  isPublic: Scalars['Boolean']['input']
  note: Scalars['String']['input']
}

export type Address = Node & {
  __typename?: 'Address'
  city?: Maybe<Scalars['String']['output']>
  company?: Maybe<Scalars['String']['output']>
  country: Country
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  defaultBillingAddress?: Maybe<Scalars['Boolean']['output']>
  defaultShippingAddress?: Maybe<Scalars['Boolean']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
  postalCode?: Maybe<Scalars['String']['output']>
  province?: Maybe<Scalars['String']['output']>
  streetLine1: Scalars['String']['output']
  streetLine2?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type AdjustDraftOrderLineInput = {
  orderLineId: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
}

export type Adjustment = {
  __typename?: 'Adjustment'
  adjustmentSource: Scalars['String']['output']
  amount: Scalars['Money']['output']
  data?: Maybe<Scalars['JSON']['output']>
  description: Scalars['String']['output']
  type: AdjustmentType
}

export enum AdjustmentType {
  DISTRIBUTED_ORDER_PROMOTION = 'DISTRIBUTED_ORDER_PROMOTION',
  OTHER = 'OTHER',
  PROMOTION = 'PROMOTION',
}

export type Administrator = Node & {
  __typename?: 'Administrator'
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  emailAddress: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  user: User
}

export type AdministratorFilterParameter = {
  _and?: InputMaybe<Array<AdministratorFilterParameter>>
  _or?: InputMaybe<Array<AdministratorFilterParameter>>
  createdAt?: InputMaybe<DateOperators>
  emailAddress?: InputMaybe<StringOperators>
  firstName?: InputMaybe<StringOperators>
  id?: InputMaybe<IdOperators>
  lastName?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type AdministratorList = PaginatedList & {
  __typename?: 'AdministratorList'
  items: Array<Administrator>
  totalItems: Scalars['Int']['output']
}

export type AdministratorListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<AdministratorFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<AdministratorSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type AdministratorPaymentInput = {
  metadata?: InputMaybe<Scalars['JSON']['input']>
  paymentMethod?: InputMaybe<Scalars['String']['input']>
}

export type AdministratorRefundInput = {
  /**
   * The amount to be refunded to this particular Payment. This was introduced in
   * v2.2.0 as the preferred way to specify the refund amount. The `lines`, `shipping` and `adjustment`
   * fields will be removed in a future version.
   */
  amount?: InputMaybe<Scalars['Money']['input']>
  paymentId: Scalars['ID']['input']
  reason?: InputMaybe<Scalars['String']['input']>
}

export type AdministratorSortParameter = {
  createdAt?: InputMaybe<SortOrder>
  emailAddress?: InputMaybe<SortOrder>
  firstName?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  lastName?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type Allocation = Node &
  StockMovement & {
    __typename?: 'Allocation'
    createdAt: Scalars['DateTime']['output']
    customFields?: Maybe<Scalars['JSON']['output']>
    id: Scalars['ID']['output']
    orderLine: OrderLine
    productVariant: ProductVariant
    quantity: Scalars['Int']['output']
    type: StockMovementType
    updatedAt: Scalars['DateTime']['output']
  }

/** Returned if an attempting to refund an OrderItem which has already been refunded */
export type AlreadyRefundedError = ErrorResult & {
  __typename?: 'AlreadyRefundedError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
  refundId: Scalars['ID']['output']
}

export type ApplyCouponCodeResult =
  | CouponCodeExpiredError
  | CouponCodeInvalidError
  | CouponCodeLimitError
  | Order

export type Asset = Node & {
  __typename?: 'Asset'
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  fileSize: Scalars['Int']['output']
  focalPoint?: Maybe<Coordinate>
  height: Scalars['Int']['output']
  id: Scalars['ID']['output']
  mimeType: Scalars['String']['output']
  name: Scalars['String']['output']
  preview: Scalars['String']['output']
  source: Scalars['String']['output']
  tags: Array<Tag>
  thumbnail: Scalars['String']['output']
  type: AssetType
  updatedAt: Scalars['DateTime']['output']
  width: Scalars['Int']['output']
}

export type AssetFilterParameter = {
  _and?: InputMaybe<Array<AssetFilterParameter>>
  _or?: InputMaybe<Array<AssetFilterParameter>>
  createdAt?: InputMaybe<DateOperators>
  fileSize?: InputMaybe<NumberOperators>
  height?: InputMaybe<NumberOperators>
  id?: InputMaybe<IdOperators>
  mimeType?: InputMaybe<StringOperators>
  name?: InputMaybe<StringOperators>
  preview?: InputMaybe<StringOperators>
  source?: InputMaybe<StringOperators>
  thumbnail?: InputMaybe<StringOperators>
  type?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
  width?: InputMaybe<NumberOperators>
}

export type AssetList = PaginatedList & {
  __typename?: 'AssetList'
  items: Array<Asset>
  totalItems: Scalars['Int']['output']
}

export type AssetListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<AssetFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<AssetSortParameter>
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  tagsOperator?: InputMaybe<LogicalOperator>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type AssetSortParameter = {
  createdAt?: InputMaybe<SortOrder>
  fileSize?: InputMaybe<SortOrder>
  height?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  mimeType?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  preview?: InputMaybe<SortOrder>
  source?: InputMaybe<SortOrder>
  thumbnail?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
  width?: InputMaybe<SortOrder>
}

export enum AssetType {
  BINARY = 'BINARY',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export type AssignAssetsToChannelInput = {
  assetIds: Array<Scalars['ID']['input']>
  channelId: Scalars['ID']['input']
}

export type AssignCollectionsToChannelInput = {
  channelId: Scalars['ID']['input']
  collectionIds: Array<Scalars['ID']['input']>
}

export type AssignFacetsToChannelInput = {
  channelId: Scalars['ID']['input']
  facetIds: Array<Scalars['ID']['input']>
}

export type AssignPaymentMethodsToChannelInput = {
  channelId: Scalars['ID']['input']
  paymentMethodIds: Array<Scalars['ID']['input']>
}

export type AssignProductVariantsToChannelInput = {
  channelId: Scalars['ID']['input']
  priceFactor?: InputMaybe<Scalars['Float']['input']>
  productVariantIds: Array<Scalars['ID']['input']>
}

export type AssignProductsToChannelInput = {
  channelId: Scalars['ID']['input']
  priceFactor?: InputMaybe<Scalars['Float']['input']>
  productIds: Array<Scalars['ID']['input']>
}

export type AssignPromotionsToChannelInput = {
  channelId: Scalars['ID']['input']
  promotionIds: Array<Scalars['ID']['input']>
}

export type AssignShippingMethodsToChannelInput = {
  channelId: Scalars['ID']['input']
  shippingMethodIds: Array<Scalars['ID']['input']>
}

export type AssignStockLocationsToChannelInput = {
  channelId: Scalars['ID']['input']
  stockLocationIds: Array<Scalars['ID']['input']>
}

export type AuthenticationInput = {
  google?: InputMaybe<GoogleAuthInput>
  native?: InputMaybe<SellerAuthInput>
}

export type AuthenticationMethod = Node & {
  __typename?: 'AuthenticationMethod'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  strategy: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type AuthenticationRegisterInput = {
  channelName: Scalars['String']['input']
  email: Scalars['String']['input']
  name: Scalars['String']['input']
  password: Scalars['String']['input']
  surname: Scalars['String']['input']
}

export type AuthenticationResult = CurrentUser | InvalidCredentialsError

export type BooleanCustomFieldConfig = CustomField & {
  __typename?: 'BooleanCustomFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  internal?: Maybe<Scalars['Boolean']['output']>
  label?: Maybe<Array<LocalizedString>>
  list: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  nullable?: Maybe<Scalars['Boolean']['output']>
  readonly?: Maybe<Scalars['Boolean']['output']>
  requiresPermission?: Maybe<Array<Permission>>
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type BooleanFieldDefinition = {
  __typename?: 'BooleanFieldDefinition'
  description: Scalars['String']['output']
  fieldName: Scalars['String']['output']
  id: Scalars['String']['output']
  sortOrder: Scalars['Int']['output']
  title: Scalars['String']['output']
}

/** Operators for filtering on a list of Boolean fields */
export type BooleanListOperators = {
  inList: Scalars['Boolean']['input']
}

/** Operators for filtering on a Boolean field */
export type BooleanOperators = {
  eq?: InputMaybe<Scalars['Boolean']['input']>
  isNull?: InputMaybe<Scalars['Boolean']['input']>
}

export type BooleanStructFieldConfig = StructField & {
  __typename?: 'BooleanStructFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  label?: Maybe<Array<LocalizedString>>
  list: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type BooleanValue = {
  __typename?: 'BooleanValue'
  field: BooleanFieldDefinition
  id: Scalars['String']['output']
  value: Scalars['Boolean']['output']
}

/** Returned if an attempting to cancel lines from an Order which is still active */
export type CancelActiveOrderError = ErrorResult & {
  __typename?: 'CancelActiveOrderError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
  orderState: Scalars['String']['output']
}

export type CancelOrderInput = {
  /** Specify whether the shipping charges should also be cancelled. Defaults to false */
  cancelShipping?: InputMaybe<Scalars['Boolean']['input']>
  /** Optionally specify which OrderLines to cancel. If not provided, all OrderLines will be cancelled */
  lines?: InputMaybe<Array<OrderLineInput>>
  /** The id of the order to be cancelled */
  orderId: Scalars['ID']['input']
  reason?: InputMaybe<Scalars['String']['input']>
}

export type CancelOrderResult =
  | CancelActiveOrderError
  | EmptyOrderLineSelectionError
  | MultipleOrderError
  | Order
  | OrderStateTransitionError
  | QuantityTooGreatError

/** Returned if the Payment cancellation fails */
export type CancelPaymentError = ErrorResult & {
  __typename?: 'CancelPaymentError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
  paymentErrorMessage: Scalars['String']['output']
}

export type CancelPaymentResult =
  | CancelPaymentError
  | Payment
  | PaymentStateTransitionError

export type Cancellation = Node &
  StockMovement & {
    __typename?: 'Cancellation'
    createdAt: Scalars['DateTime']['output']
    customFields?: Maybe<Scalars['JSON']['output']>
    id: Scalars['ID']['output']
    orderLine: OrderLine
    productVariant: ProductVariant
    quantity: Scalars['Int']['output']
    type: StockMovementType
    updatedAt: Scalars['DateTime']['output']
  }

export type Channel = Node & {
  __typename?: 'Channel'
  availableCurrencyCodes: Array<CurrencyCode>
  availableLanguageCodes?: Maybe<Array<LanguageCode>>
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  /** @deprecated Use defaultCurrencyCode instead */
  currencyCode: CurrencyCode
  customFields?: Maybe<ChannelCustomFields>
  defaultCurrencyCode: CurrencyCode
  defaultLanguageCode: LanguageCode
  defaultShippingZone?: Maybe<Zone>
  defaultTaxZone?: Maybe<Zone>
  id: Scalars['ID']['output']
  /** Not yet used - will be implemented in a future release. */
  outOfStockThreshold?: Maybe<Scalars['Int']['output']>
  pricesIncludeTax: Scalars['Boolean']['output']
  seller?: Maybe<Seller>
  token: Scalars['String']['output']
  /** Not yet used - will be implemented in a future release. */
  trackInventory?: Maybe<Scalars['Boolean']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type ChannelCustomFields = {
  __typename?: 'ChannelCustomFields'
  name?: Maybe<Scalars['String']['output']>
}

/**
 * Returned when the default LanguageCode of a Channel is no longer found in the `availableLanguages`
 * of the GlobalSettings
 */
export type ChannelDefaultLanguageError = ErrorResult & {
  __typename?: 'ChannelDefaultLanguageError'
  channelCode: Scalars['String']['output']
  errorCode: ErrorCode
  language: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type ChannelFilterParameter = {
  _and?: InputMaybe<Array<ChannelFilterParameter>>
  _or?: InputMaybe<Array<ChannelFilterParameter>>
  code?: InputMaybe<StringOperators>
  createdAt?: InputMaybe<DateOperators>
  currencyCode?: InputMaybe<StringOperators>
  defaultCurrencyCode?: InputMaybe<StringOperators>
  defaultLanguageCode?: InputMaybe<StringOperators>
  id?: InputMaybe<IdOperators>
  name?: InputMaybe<StringOperators>
  outOfStockThreshold?: InputMaybe<NumberOperators>
  pricesIncludeTax?: InputMaybe<BooleanOperators>
  token?: InputMaybe<StringOperators>
  trackInventory?: InputMaybe<BooleanOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type ChannelList = PaginatedList & {
  __typename?: 'ChannelList'
  items: Array<Channel>
  totalItems: Scalars['Int']['output']
}

export type ChannelListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ChannelFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ChannelSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type ChannelPlugin = {
  __typename?: 'ChannelPlugin'
  channel: Channel
  configuration: Scalars['JSON']['output']
  id: Scalars['ID']['output']
  manageablePlugin: ManageablePlugin
  status: Scalars['String']['output']
}

export type ChannelSortParameter = {
  code?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  outOfStockThreshold?: InputMaybe<SortOrder>
  token?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type Collection = Node & {
  __typename?: 'Collection'
  assets: Array<Asset>
  breadcrumbs: Array<CollectionBreadcrumb>
  children?: Maybe<Array<Collection>>
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  description: Scalars['String']['output']
  featuredAsset?: Maybe<Asset>
  filters: Array<ConfigurableOperation>
  id: Scalars['ID']['output']
  inheritFilters: Scalars['Boolean']['output']
  isPrivate: Scalars['Boolean']['output']
  languageCode?: Maybe<LanguageCode>
  name: Scalars['String']['output']
  parent?: Maybe<Collection>
  parentId: Scalars['ID']['output']
  position: Scalars['Int']['output']
  productVariants: ProductVariantList
  slug: Scalars['String']['output']
  translations: Array<CollectionTranslation>
  updatedAt: Scalars['DateTime']['output']
}

export type CollectionProductVariantsArgs = {
  options?: InputMaybe<ProductVariantListOptions>
}

export type CollectionBreadcrumb = {
  __typename?: 'CollectionBreadcrumb'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
}

export type CollectionFilterParameter = {
  _and?: InputMaybe<Array<CollectionFilterParameter>>
  _or?: InputMaybe<Array<CollectionFilterParameter>>
  createdAt?: InputMaybe<DateOperators>
  description?: InputMaybe<StringOperators>
  id?: InputMaybe<IdOperators>
  inheritFilters?: InputMaybe<BooleanOperators>
  isPrivate?: InputMaybe<BooleanOperators>
  languageCode?: InputMaybe<StringOperators>
  name?: InputMaybe<StringOperators>
  parentId?: InputMaybe<IdOperators>
  position?: InputMaybe<NumberOperators>
  slug?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type CollectionList = PaginatedList & {
  __typename?: 'CollectionList'
  items: Array<Collection>
  totalItems: Scalars['Int']['output']
}

export type CollectionListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<CollectionFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<CollectionSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
  topLevelOnly?: InputMaybe<Scalars['Boolean']['input']>
}

/**
 * Which Collections are present in the products returned
 * by the search, and in what quantity.
 */
export type CollectionResult = {
  __typename?: 'CollectionResult'
  collection: Collection
  count: Scalars['Int']['output']
}

export type CollectionSortParameter = {
  createdAt?: InputMaybe<SortOrder>
  description?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  parentId?: InputMaybe<SortOrder>
  position?: InputMaybe<SortOrder>
  slug?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type CollectionTranslation = {
  __typename?: 'CollectionTranslation'
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ConfigArg = {
  __typename?: 'ConfigArg'
  name: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type ConfigArgDefinition = {
  __typename?: 'ConfigArgDefinition'
  defaultValue?: Maybe<Scalars['JSON']['output']>
  description?: Maybe<Scalars['String']['output']>
  label?: Maybe<Scalars['String']['output']>
  list: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  required: Scalars['Boolean']['output']
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type ConfigArgInput = {
  name: Scalars['String']['input']
  /** A JSON stringified representation of the actual value */
  value: Scalars['String']['input']
}

export type ConfigurableOperation = {
  __typename?: 'ConfigurableOperation'
  args: Array<ConfigArg>
  code: Scalars['String']['output']
}

export type ConfigurableOperationDefinition = {
  __typename?: 'ConfigurableOperationDefinition'
  args: Array<ConfigArgDefinition>
  code: Scalars['String']['output']
  description: Scalars['String']['output']
}

export type ConfigurableOperationInput = {
  arguments: Array<ConfigArgInput>
  code: Scalars['String']['input']
}

export type Coordinate = {
  __typename?: 'Coordinate'
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type CoordinateInput = {
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
}

/**
 * A Country of the world which your shop operates in.
 *
 * The `code` field is typically a 2-character ISO code such as "GB", "US", "DE" etc. This code is used in certain inputs such as
 * `UpdateAddressInput` and `CreateAddressInput` to specify the country.
 */
export type Country = Node &
  Region & {
    __typename?: 'Country'
    code: Scalars['String']['output']
    createdAt: Scalars['DateTime']['output']
    customFields?: Maybe<Scalars['JSON']['output']>
    enabled: Scalars['Boolean']['output']
    id: Scalars['ID']['output']
    languageCode: LanguageCode
    name: Scalars['String']['output']
    parent?: Maybe<Region>
    parentId?: Maybe<Scalars['ID']['output']>
    translations: Array<RegionTranslation>
    type: Scalars['String']['output']
    updatedAt: Scalars['DateTime']['output']
  }

export type CountryFilterParameter = {
  _and?: InputMaybe<Array<CountryFilterParameter>>
  _or?: InputMaybe<Array<CountryFilterParameter>>
  code?: InputMaybe<StringOperators>
  createdAt?: InputMaybe<DateOperators>
  enabled?: InputMaybe<BooleanOperators>
  id?: InputMaybe<IdOperators>
  languageCode?: InputMaybe<StringOperators>
  name?: InputMaybe<StringOperators>
  parentId?: InputMaybe<IdOperators>
  type?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type CountryList = PaginatedList & {
  __typename?: 'CountryList'
  items: Array<Country>
  totalItems: Scalars['Int']['output']
}

export type CountryListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<CountryFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<CountrySortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type CountrySortParameter = {
  code?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  parentId?: InputMaybe<SortOrder>
  type?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type CountryTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  languageCode: LanguageCode
  name?: InputMaybe<Scalars['String']['input']>
}

/** Returned if the provided coupon code is invalid */
export type CouponCodeExpiredError = ErrorResult & {
  __typename?: 'CouponCodeExpiredError'
  couponCode: Scalars['String']['output']
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

/** Returned if the provided coupon code is invalid */
export type CouponCodeInvalidError = ErrorResult & {
  __typename?: 'CouponCodeInvalidError'
  couponCode: Scalars['String']['output']
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

/** Returned if the provided coupon code is invalid */
export type CouponCodeLimitError = ErrorResult & {
  __typename?: 'CouponCodeLimitError'
  couponCode: Scalars['String']['output']
  errorCode: ErrorCode
  limit: Scalars['Int']['output']
  message: Scalars['String']['output']
}

/**
 * Input used to create an Address.
 *
 * The countryCode must correspond to a `code` property of a Country that has been defined in the
 * Vendure server. The `code` property is typically a 2-character ISO code such as "GB", "US", "DE" etc.
 * If an invalid code is passed, the mutation will fail.
 */
export type CreateAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  countryCode: Scalars['String']['input']
  customFields?: InputMaybe<Scalars['JSON']['input']>
  defaultBillingAddress?: InputMaybe<Scalars['Boolean']['input']>
  defaultShippingAddress?: InputMaybe<Scalars['Boolean']['input']>
  fullName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  postalCode?: InputMaybe<Scalars['String']['input']>
  province?: InputMaybe<Scalars['String']['input']>
  streetLine1: Scalars['String']['input']
  streetLine2?: InputMaybe<Scalars['String']['input']>
}

export type CreateAdministratorInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  emailAddress: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  password: Scalars['String']['input']
  roleIds: Array<Scalars['ID']['input']>
}

export type CreateAssetInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  file: Scalars['Upload']['input']
  tags?: InputMaybe<Array<Scalars['String']['input']>>
}

export type CreateAssetResult = Asset | MimeTypeError

export type CreateChannelCustomFieldsInput = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type CreateChannelInput = {
  availableCurrencyCodes?: InputMaybe<Array<CurrencyCode>>
  availableLanguageCodes?: InputMaybe<Array<LanguageCode>>
  code: Scalars['String']['input']
  customFields?: InputMaybe<CreateChannelCustomFieldsInput>
  defaultCurrencyCode?: InputMaybe<CurrencyCode>
  defaultLanguageCode: LanguageCode
  defaultShippingZoneId: Scalars['ID']['input']
  defaultTaxZoneId: Scalars['ID']['input']
  outOfStockThreshold?: InputMaybe<Scalars['Int']['input']>
  pricesIncludeTax: Scalars['Boolean']['input']
  sellerId?: InputMaybe<Scalars['ID']['input']>
  token: Scalars['String']['input']
  trackInventory?: InputMaybe<Scalars['Boolean']['input']>
}

export type CreateChannelResult = Channel | LanguageNotAvailableError

export type CreateCollectionInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']['input']>>
  customFields?: InputMaybe<Scalars['JSON']['input']>
  featuredAssetId?: InputMaybe<Scalars['ID']['input']>
  filters: Array<ConfigurableOperationInput>
  inheritFilters?: InputMaybe<Scalars['Boolean']['input']>
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>
  parentId?: InputMaybe<Scalars['ID']['input']>
  translations: Array<CreateCollectionTranslationInput>
}

export type CreateCollectionTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  description: Scalars['String']['input']
  languageCode: LanguageCode
  name: Scalars['String']['input']
  slug: Scalars['String']['input']
}

export type CreateCountryInput = {
  code: Scalars['String']['input']
  customFields?: InputMaybe<Scalars['JSON']['input']>
  enabled: Scalars['Boolean']['input']
  translations: Array<CountryTranslationInput>
}

export type CreateCustomerGroupInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  customerIds?: InputMaybe<Array<Scalars['ID']['input']>>
  name: Scalars['String']['input']
}

export type CreateCustomerInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  emailAddress: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type CreateCustomerResult = Customer | EmailAddressConflictError

export type CreateFacetInput = {
  code: Scalars['String']['input']
  customFields?: InputMaybe<Scalars['JSON']['input']>
  isPrivate: Scalars['Boolean']['input']
  translations: Array<FacetTranslationInput>
  values?: InputMaybe<Array<CreateFacetValueWithFacetInput>>
}

export type CreateFacetValueInput = {
  code: Scalars['String']['input']
  customFields?: InputMaybe<Scalars['JSON']['input']>
  facetId: Scalars['ID']['input']
  translations: Array<FacetValueTranslationInput>
}

export type CreateFacetValueWithFacetInput = {
  code: Scalars['String']['input']
  translations: Array<FacetValueTranslationInput>
}

/** Returned if an error is thrown in a FulfillmentHandler's createFulfillment method */
export type CreateFulfillmentError = ErrorResult & {
  __typename?: 'CreateFulfillmentError'
  errorCode: ErrorCode
  fulfillmentHandlerError: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type CreateGroupOptionInput = {
  code: Scalars['String']['input']
  translations: Array<ProductOptionGroupTranslationInput>
}

export type CreatePaymentMethodInput = {
  checker?: InputMaybe<ConfigurableOperationInput>
  code: Scalars['String']['input']
  customFields?: InputMaybe<Scalars['JSON']['input']>
  enabled: Scalars['Boolean']['input']
  handler: ConfigurableOperationInput
  translations: Array<PaymentMethodTranslationInput>
}

export type CreateProductCustomFieldsInput = {
  bestSeller?: InputMaybe<Scalars['Boolean']['input']>
  discountPercentage?: InputMaybe<Scalars['Float']['input']>
  dlcIds?: InputMaybe<Array<Scalars['ID']['input']>>
  mainAssetId?: InputMaybe<Scalars['ID']['input']>
  new?: InputMaybe<Scalars['Boolean']['input']>
  primaryColor?: InputMaybe<Scalars['String']['input']>
  secondaryColor?: InputMaybe<Scalars['String']['input']>
  videoAssetId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateProductInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']['input']>>
  customFields?: InputMaybe<CreateProductCustomFieldsInput>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  facetValueIds?: InputMaybe<Array<Scalars['ID']['input']>>
  featuredAssetId?: InputMaybe<Scalars['ID']['input']>
  translations: Array<ProductTranslationInput>
}

export type CreateProductOptionGroupInput = {
  code: Scalars['String']['input']
  customFields?: InputMaybe<Scalars['JSON']['input']>
  options: Array<CreateGroupOptionInput>
  translations: Array<ProductOptionGroupTranslationInput>
}

export type CreateProductOptionInput = {
  code: Scalars['String']['input']
  customFields?: InputMaybe<Scalars['JSON']['input']>
  productOptionGroupId: Scalars['ID']['input']
  translations: Array<ProductOptionGroupTranslationInput>
}

export type CreateProductVariantCustomFieldsInput = {
  discountPercentage?: InputMaybe<Scalars['Float']['input']>
  g2aId?: InputMaybe<Scalars['String']['input']>
  k4gId?: InputMaybe<Scalars['String']['input']>
  kinguinId?: InputMaybe<Scalars['String']['input']>
  mainAssetId?: InputMaybe<Scalars['ID']['input']>
  steamAppId?: InputMaybe<Scalars['String']['input']>
  videoAssetId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateProductVariantInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']['input']>>
  customFields?: InputMaybe<CreateProductVariantCustomFieldsInput>
  facetValueIds?: InputMaybe<Array<Scalars['ID']['input']>>
  featuredAssetId?: InputMaybe<Scalars['ID']['input']>
  optionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  outOfStockThreshold?: InputMaybe<Scalars['Int']['input']>
  price?: InputMaybe<Scalars['Money']['input']>
  productId: Scalars['ID']['input']
  sku: Scalars['String']['input']
  stockLevels?: InputMaybe<Array<StockLevelInput>>
  stockOnHand?: InputMaybe<Scalars['Int']['input']>
  taxCategoryId?: InputMaybe<Scalars['ID']['input']>
  trackInventory?: InputMaybe<GlobalFlag>
  translations: Array<ProductVariantTranslationInput>
  useGlobalOutOfStockThreshold?: InputMaybe<Scalars['Boolean']['input']>
}

export type CreateProductVariantOptionInput = {
  code: Scalars['String']['input']
  optionGroupId: Scalars['ID']['input']
  translations: Array<ProductOptionTranslationInput>
}

export type CreatePromotionInput = {
  actions: Array<ConfigurableOperationInput>
  conditions: Array<ConfigurableOperationInput>
  couponCode?: InputMaybe<Scalars['String']['input']>
  customFields?: InputMaybe<Scalars['JSON']['input']>
  enabled: Scalars['Boolean']['input']
  endsAt?: InputMaybe<Scalars['DateTime']['input']>
  perCustomerUsageLimit?: InputMaybe<Scalars['Int']['input']>
  startsAt?: InputMaybe<Scalars['DateTime']['input']>
  translations: Array<PromotionTranslationInput>
  usageLimit?: InputMaybe<Scalars['Int']['input']>
}

export type CreatePromotionResult = MissingConditionsError | Promotion

export type CreateProvinceInput = {
  code: Scalars['String']['input']
  customFields?: InputMaybe<Scalars['JSON']['input']>
  enabled: Scalars['Boolean']['input']
  translations: Array<ProvinceTranslationInput>
}

export type CreateRoleInput = {
  channelIds?: InputMaybe<Array<Scalars['ID']['input']>>
  code: Scalars['String']['input']
  description: Scalars['String']['input']
  permissions: Array<Permission>
}

export type CreateSellerInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  name: Scalars['String']['input']
}

export type CreateShippingMethodInput = {
  calculator: ConfigurableOperationInput
  checker: ConfigurableOperationInput
  code: Scalars['String']['input']
  customFields?: InputMaybe<Scalars['JSON']['input']>
  fulfillmentHandler: Scalars['String']['input']
  translations: Array<ShippingMethodTranslationInput>
}

export type CreateStockLocationInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type CreateTagInput = {
  value: Scalars['String']['input']
}

export type CreateTaxCategoryInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  isDefault?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
}

export type CreateTaxRateInput = {
  categoryId: Scalars['ID']['input']
  customFields?: InputMaybe<Scalars['JSON']['input']>
  customerGroupId?: InputMaybe<Scalars['ID']['input']>
  enabled: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  value: Scalars['Float']['input']
  zoneId: Scalars['ID']['input']
}

export type CreateZoneInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  memberIds?: InputMaybe<Array<Scalars['ID']['input']>>
  name: Scalars['String']['input']
}

/**
 * @description
 * ISO 4217 currency code
 *
 * @docsCategory common
 */
export enum CurrencyCode {
  /** United Arab Emirates dirham */
  AED = 'AED',
  /** Afghan afghani */
  AFN = 'AFN',
  /** Albanian lek */
  ALL = 'ALL',
  /** Armenian dram */
  AMD = 'AMD',
  /** Netherlands Antillean guilder */
  ANG = 'ANG',
  /** Angolan kwanza */
  AOA = 'AOA',
  /** Argentine peso */
  ARS = 'ARS',
  /** Australian dollar */
  AUD = 'AUD',
  /** Aruban florin */
  AWG = 'AWG',
  /** Azerbaijani manat */
  AZN = 'AZN',
  /** Bosnia and Herzegovina convertible mark */
  BAM = 'BAM',
  /** Barbados dollar */
  BBD = 'BBD',
  /** Bangladeshi taka */
  BDT = 'BDT',
  /** Bulgarian lev */
  BGN = 'BGN',
  /** Bahraini dinar */
  BHD = 'BHD',
  /** Burundian franc */
  BIF = 'BIF',
  /** Bermudian dollar */
  BMD = 'BMD',
  /** Brunei dollar */
  BND = 'BND',
  /** Boliviano */
  BOB = 'BOB',
  /** Brazilian real */
  BRL = 'BRL',
  /** Bahamian dollar */
  BSD = 'BSD',
  /** Bhutanese ngultrum */
  BTN = 'BTN',
  /** Botswana pula */
  BWP = 'BWP',
  /** Belarusian ruble */
  BYN = 'BYN',
  /** Belize dollar */
  BZD = 'BZD',
  /** Canadian dollar */
  CAD = 'CAD',
  /** Congolese franc */
  CDF = 'CDF',
  /** Swiss franc */
  CHF = 'CHF',
  /** Chilean peso */
  CLP = 'CLP',
  /** Renminbi (Chinese) yuan */
  CNY = 'CNY',
  /** Colombian peso */
  COP = 'COP',
  /** Costa Rican colon */
  CRC = 'CRC',
  /** Cuban convertible peso */
  CUC = 'CUC',
  /** Cuban peso */
  CUP = 'CUP',
  /** Cape Verde escudo */
  CVE = 'CVE',
  /** Czech koruna */
  CZK = 'CZK',
  /** Djiboutian franc */
  DJF = 'DJF',
  /** Danish krone */
  DKK = 'DKK',
  /** Dominican peso */
  DOP = 'DOP',
  /** Algerian dinar */
  DZD = 'DZD',
  /** Egyptian pound */
  EGP = 'EGP',
  /** Eritrean nakfa */
  ERN = 'ERN',
  /** Ethiopian birr */
  ETB = 'ETB',
  /** Euro */
  EUR = 'EUR',
  /** Fiji dollar */
  FJD = 'FJD',
  /** Falkland Islands pound */
  FKP = 'FKP',
  /** Pound sterling */
  GBP = 'GBP',
  /** Georgian lari */
  GEL = 'GEL',
  /** Ghanaian cedi */
  GHS = 'GHS',
  /** Gibraltar pound */
  GIP = 'GIP',
  /** Gambian dalasi */
  GMD = 'GMD',
  /** Guinean franc */
  GNF = 'GNF',
  /** Guatemalan quetzal */
  GTQ = 'GTQ',
  /** Guyanese dollar */
  GYD = 'GYD',
  /** Hong Kong dollar */
  HKD = 'HKD',
  /** Honduran lempira */
  HNL = 'HNL',
  /** Croatian kuna */
  HRK = 'HRK',
  /** Haitian gourde */
  HTG = 'HTG',
  /** Hungarian forint */
  HUF = 'HUF',
  /** Indonesian rupiah */
  IDR = 'IDR',
  /** Israeli new shekel */
  ILS = 'ILS',
  /** Indian rupee */
  INR = 'INR',
  /** Iraqi dinar */
  IQD = 'IQD',
  /** Iranian rial */
  IRR = 'IRR',
  /** Icelandic króna */
  ISK = 'ISK',
  /** Jamaican dollar */
  JMD = 'JMD',
  /** Jordanian dinar */
  JOD = 'JOD',
  /** Japanese yen */
  JPY = 'JPY',
  /** Kenyan shilling */
  KES = 'KES',
  /** Kyrgyzstani som */
  KGS = 'KGS',
  /** Cambodian riel */
  KHR = 'KHR',
  /** Comoro franc */
  KMF = 'KMF',
  /** North Korean won */
  KPW = 'KPW',
  /** South Korean won */
  KRW = 'KRW',
  /** Kuwaiti dinar */
  KWD = 'KWD',
  /** Cayman Islands dollar */
  KYD = 'KYD',
  /** Kazakhstani tenge */
  KZT = 'KZT',
  /** Lao kip */
  LAK = 'LAK',
  /** Lebanese pound */
  LBP = 'LBP',
  /** Sri Lankan rupee */
  LKR = 'LKR',
  /** Liberian dollar */
  LRD = 'LRD',
  /** Lesotho loti */
  LSL = 'LSL',
  /** Libyan dinar */
  LYD = 'LYD',
  /** Moroccan dirham */
  MAD = 'MAD',
  /** Moldovan leu */
  MDL = 'MDL',
  /** Malagasy ariary */
  MGA = 'MGA',
  /** Macedonian denar */
  MKD = 'MKD',
  /** Myanmar kyat */
  MMK = 'MMK',
  /** Mongolian tögrög */
  MNT = 'MNT',
  /** Macanese pataca */
  MOP = 'MOP',
  /** Mauritanian ouguiya */
  MRU = 'MRU',
  /** Mauritian rupee */
  MUR = 'MUR',
  /** Maldivian rufiyaa */
  MVR = 'MVR',
  /** Malawian kwacha */
  MWK = 'MWK',
  /** Mexican peso */
  MXN = 'MXN',
  /** Malaysian ringgit */
  MYR = 'MYR',
  /** Mozambican metical */
  MZN = 'MZN',
  /** Namibian dollar */
  NAD = 'NAD',
  /** Nigerian naira */
  NGN = 'NGN',
  /** Nicaraguan córdoba */
  NIO = 'NIO',
  /** Norwegian krone */
  NOK = 'NOK',
  /** Nepalese rupee */
  NPR = 'NPR',
  /** New Zealand dollar */
  NZD = 'NZD',
  /** Omani rial */
  OMR = 'OMR',
  /** Panamanian balboa */
  PAB = 'PAB',
  /** Peruvian sol */
  PEN = 'PEN',
  /** Papua New Guinean kina */
  PGK = 'PGK',
  /** Philippine peso */
  PHP = 'PHP',
  /** Pakistani rupee */
  PKR = 'PKR',
  /** Polish złoty */
  PLN = 'PLN',
  /** Paraguayan guaraní */
  PYG = 'PYG',
  /** Qatari riyal */
  QAR = 'QAR',
  /** Romanian leu */
  RON = 'RON',
  /** Serbian dinar */
  RSD = 'RSD',
  /** Russian ruble */
  RUB = 'RUB',
  /** Rwandan franc */
  RWF = 'RWF',
  /** Saudi riyal */
  SAR = 'SAR',
  /** Solomon Islands dollar */
  SBD = 'SBD',
  /** Seychelles rupee */
  SCR = 'SCR',
  /** Sudanese pound */
  SDG = 'SDG',
  /** Swedish krona/kronor */
  SEK = 'SEK',
  /** Singapore dollar */
  SGD = 'SGD',
  /** Saint Helena pound */
  SHP = 'SHP',
  /** Sierra Leonean leone */
  SLL = 'SLL',
  /** Somali shilling */
  SOS = 'SOS',
  /** Surinamese dollar */
  SRD = 'SRD',
  /** South Sudanese pound */
  SSP = 'SSP',
  /** São Tomé and Príncipe dobra */
  STN = 'STN',
  /** Salvadoran colón */
  SVC = 'SVC',
  /** Syrian pound */
  SYP = 'SYP',
  /** Swazi lilangeni */
  SZL = 'SZL',
  /** Thai baht */
  THB = 'THB',
  /** Tajikistani somoni */
  TJS = 'TJS',
  /** Turkmenistan manat */
  TMT = 'TMT',
  /** Tunisian dinar */
  TND = 'TND',
  /** Tongan paʻanga */
  TOP = 'TOP',
  /** Turkish lira */
  TRY = 'TRY',
  /** Trinidad and Tobago dollar */
  TTD = 'TTD',
  /** New Taiwan dollar */
  TWD = 'TWD',
  /** Tanzanian shilling */
  TZS = 'TZS',
  /** Ukrainian hryvnia */
  UAH = 'UAH',
  /** Ugandan shilling */
  UGX = 'UGX',
  /** United States dollar */
  USD = 'USD',
  /** Uruguayan peso */
  UYU = 'UYU',
  /** Uzbekistan som */
  UZS = 'UZS',
  /** Venezuelan bolívar soberano */
  VES = 'VES',
  /** Vietnamese đồng */
  VND = 'VND',
  /** Vanuatu vatu */
  VUV = 'VUV',
  /** Samoan tala */
  WST = 'WST',
  /** CFA franc BEAC */
  XAF = 'XAF',
  /** East Caribbean dollar */
  XCD = 'XCD',
  /** CFA franc BCEAO */
  XOF = 'XOF',
  /** CFP franc (franc Pacifique) */
  XPF = 'XPF',
  /** Yemeni rial */
  YER = 'YER',
  /** South African rand */
  ZAR = 'ZAR',
  /** Zambian kwacha */
  ZMW = 'ZMW',
  /** Zimbabwean dollar */
  ZWL = 'ZWL',
}

export type CurrentUser = {
  __typename?: 'CurrentUser'
  channels: Array<CurrentUserChannel>
  id: Scalars['ID']['output']
  identifier: Scalars['String']['output']
}

export type CurrentUserChannel = {
  __typename?: 'CurrentUserChannel'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
  permissions: Array<Permission>
  token: Scalars['String']['output']
}

export type CustomField = {
  description?: Maybe<Array<LocalizedString>>
  internal?: Maybe<Scalars['Boolean']['output']>
  label?: Maybe<Array<LocalizedString>>
  list: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  nullable?: Maybe<Scalars['Boolean']['output']>
  readonly?: Maybe<Scalars['Boolean']['output']>
  requiresPermission?: Maybe<Array<Permission>>
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type CustomFieldConfig =
  | BooleanCustomFieldConfig
  | DateTimeCustomFieldConfig
  | FloatCustomFieldConfig
  | IntCustomFieldConfig
  | LocaleStringCustomFieldConfig
  | LocaleTextCustomFieldConfig
  | RelationCustomFieldConfig
  | StringCustomFieldConfig
  | StructCustomFieldConfig
  | TextCustomFieldConfig

/**
 * This type is deprecated in v2.2 in favor of the EntityCustomFields type,
 * which allows custom fields to be defined on user-supplied entities.
 */
export type CustomFields = {
  __typename?: 'CustomFields'
  Address: Array<CustomFieldConfig>
  Administrator: Array<CustomFieldConfig>
  Asset: Array<CustomFieldConfig>
  Channel: Array<CustomFieldConfig>
  Collection: Array<CustomFieldConfig>
  Customer: Array<CustomFieldConfig>
  CustomerGroup: Array<CustomFieldConfig>
  Facet: Array<CustomFieldConfig>
  FacetValue: Array<CustomFieldConfig>
  Fulfillment: Array<CustomFieldConfig>
  GlobalSettings: Array<CustomFieldConfig>
  HistoryEntry: Array<CustomFieldConfig>
  Order: Array<CustomFieldConfig>
  OrderLine: Array<CustomFieldConfig>
  Payment: Array<CustomFieldConfig>
  PaymentMethod: Array<CustomFieldConfig>
  Product: Array<CustomFieldConfig>
  ProductOption: Array<CustomFieldConfig>
  ProductOptionGroup: Array<CustomFieldConfig>
  ProductVariant: Array<CustomFieldConfig>
  ProductVariantPrice: Array<CustomFieldConfig>
  Promotion: Array<CustomFieldConfig>
  Refund: Array<CustomFieldConfig>
  Region: Array<CustomFieldConfig>
  Seller: Array<CustomFieldConfig>
  Session: Array<CustomFieldConfig>
  ShippingLine: Array<CustomFieldConfig>
  ShippingMethod: Array<CustomFieldConfig>
  StockLevel: Array<CustomFieldConfig>
  StockLocation: Array<CustomFieldConfig>
  StockMovement: Array<CustomFieldConfig>
  TaxCategory: Array<CustomFieldConfig>
  TaxRate: Array<CustomFieldConfig>
  User: Array<CustomFieldConfig>
  Zone: Array<CustomFieldConfig>
}

export type Customer = Node & {
  __typename?: 'Customer'
  addresses?: Maybe<Array<Address>>
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  emailAddress: Scalars['String']['output']
  firstName: Scalars['String']['output']
  groups: Array<CustomerGroup>
  history: HistoryEntryList
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  orders: OrderList
  phoneNumber?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
  user?: Maybe<User>
}

export type CustomerHistoryArgs = {
  options?: InputMaybe<HistoryEntryListOptions>
}

export type CustomerOrdersArgs = {
  options?: InputMaybe<OrderListOptions>
}

export type CustomerFilterParameter = {
  _and?: InputMaybe<Array<CustomerFilterParameter>>
  _or?: InputMaybe<Array<CustomerFilterParameter>>
  createdAt?: InputMaybe<DateOperators>
  emailAddress?: InputMaybe<StringOperators>
  firstName?: InputMaybe<StringOperators>
  id?: InputMaybe<IdOperators>
  lastName?: InputMaybe<StringOperators>
  phoneNumber?: InputMaybe<StringOperators>
  postalCode?: InputMaybe<StringOperators>
  title?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type CustomerGroup = Node & {
  __typename?: 'CustomerGroup'
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  customers: CustomerList
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type CustomerGroupCustomersArgs = {
  options?: InputMaybe<CustomerListOptions>
}

export type CustomerGroupFilterParameter = {
  _and?: InputMaybe<Array<CustomerGroupFilterParameter>>
  _or?: InputMaybe<Array<CustomerGroupFilterParameter>>
  createdAt?: InputMaybe<DateOperators>
  id?: InputMaybe<IdOperators>
  name?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type CustomerGroupList = PaginatedList & {
  __typename?: 'CustomerGroupList'
  items: Array<CustomerGroup>
  totalItems: Scalars['Int']['output']
}

export type CustomerGroupListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<CustomerGroupFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<CustomerGroupSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type CustomerGroupSortParameter = {
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type CustomerList = PaginatedList & {
  __typename?: 'CustomerList'
  items: Array<Customer>
  totalItems: Scalars['Int']['output']
}

export type CustomerListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<CustomerFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<CustomerSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type CustomerSortParameter = {
  createdAt?: InputMaybe<SortOrder>
  emailAddress?: InputMaybe<SortOrder>
  firstName?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  lastName?: InputMaybe<SortOrder>
  phoneNumber?: InputMaybe<SortOrder>
  title?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type DateFieldDefinition = {
  __typename?: 'DateFieldDefinition'
  description: Scalars['String']['output']
  fieldName: Scalars['String']['output']
  id: Scalars['String']['output']
  sortOrder: Scalars['Int']['output']
  title: Scalars['String']['output']
}

/** Operators for filtering on a list of Date fields */
export type DateListOperators = {
  inList: Scalars['DateTime']['input']
}

/** Operators for filtering on a DateTime field */
export type DateOperators = {
  after?: InputMaybe<Scalars['DateTime']['input']>
  before?: InputMaybe<Scalars['DateTime']['input']>
  between?: InputMaybe<DateRange>
  eq?: InputMaybe<Scalars['DateTime']['input']>
  isNull?: InputMaybe<Scalars['Boolean']['input']>
}

export type DateRange = {
  end: Scalars['DateTime']['input']
  start: Scalars['DateTime']['input']
}

/**
 * Expects the same validation formats as the `<input type="datetime-local">` HTML element.
 * See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local#Additional_attributes
 */
export type DateTimeCustomFieldConfig = CustomField & {
  __typename?: 'DateTimeCustomFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  internal?: Maybe<Scalars['Boolean']['output']>
  label?: Maybe<Array<LocalizedString>>
  list: Scalars['Boolean']['output']
  max?: Maybe<Scalars['String']['output']>
  min?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  nullable?: Maybe<Scalars['Boolean']['output']>
  readonly?: Maybe<Scalars['Boolean']['output']>
  requiresPermission?: Maybe<Array<Permission>>
  step?: Maybe<Scalars['Int']['output']>
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

/**
 * Expects the same validation formats as the `<input type="datetime-local">` HTML element.
 * See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local#Additional_attributes
 */
export type DateTimeStructFieldConfig = StructField & {
  __typename?: 'DateTimeStructFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  label?: Maybe<Array<LocalizedString>>
  list: Scalars['Boolean']['output']
  max?: Maybe<Scalars['String']['output']>
  min?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  step?: Maybe<Scalars['Int']['output']>
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type DateValue = {
  __typename?: 'DateValue'
  field: DateFieldDefinition
  id: Scalars['String']['output']
  value: Scalars['DateTime']['output']
}

export type DeleteAssetInput = {
  assetId: Scalars['ID']['input']
  deleteFromAllChannels?: InputMaybe<Scalars['Boolean']['input']>
  force?: InputMaybe<Scalars['Boolean']['input']>
}

export type DeleteAssetsInput = {
  assetIds: Array<Scalars['ID']['input']>
  deleteFromAllChannels?: InputMaybe<Scalars['Boolean']['input']>
  force?: InputMaybe<Scalars['Boolean']['input']>
}

export type DeleteBooleanValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
}

export type DeleteDateValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
}

export type DeleteGroupInput = {
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
}

export type DeleteGroupValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
}

export type DeleteListGroupValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
}

export type DeleteListRelationInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
}

export type DeleteListValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
}

export type DeleteStockLocationInput = {
  id: Scalars['ID']['input']
  transferToLocationId?: InputMaybe<Scalars['ID']['input']>
}

export type DeleteStringValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
}

export type DeletionResponse = {
  __typename?: 'DeletionResponse'
  message?: Maybe<Scalars['String']['output']>
  result: DeletionResult
}

export enum DeletionResult {
  /** The entity was successfully deleted */
  DELETED = 'DELETED',
  /** Deletion did not take place, reason given in message */
  NOT_DELETED = 'NOT_DELETED',
}

export type Discount = {
  __typename?: 'Discount'
  adjustmentSource: Scalars['String']['output']
  amount: Scalars['Money']['output']
  amountWithTax: Scalars['Money']['output']
  description: Scalars['String']['output']
  type: AdjustmentType
}

export type DuplicateEntityError = ErrorResult & {
  __typename?: 'DuplicateEntityError'
  duplicationError: Scalars['String']['output']
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

export type DuplicateEntityInput = {
  duplicatorInput: ConfigurableOperationInput
  entityId: Scalars['ID']['input']
  entityName: Scalars['String']['input']
}

export type DuplicateEntityResult =
  | DuplicateEntityError
  | DuplicateEntitySuccess

export type DuplicateEntitySuccess = {
  __typename?: 'DuplicateEntitySuccess'
  newEntityId: Scalars['ID']['output']
}

/** Returned when attempting to create a Customer with an email address already registered to an existing User. */
export type EmailAddressConflictError = ErrorResult & {
  __typename?: 'EmailAddressConflictError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

/** Returned if no OrderLines have been specified for the operation */
export type EmptyOrderLineSelectionError = ErrorResult & {
  __typename?: 'EmptyOrderLineSelectionError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

export type EntityCustomFields = {
  __typename?: 'EntityCustomFields'
  customFields: Array<CustomFieldConfig>
  entityName: Scalars['String']['output']
}

export type EntityDuplicatorDefinition = {
  __typename?: 'EntityDuplicatorDefinition'
  args: Array<ConfigArgDefinition>
  code: Scalars['String']['output']
  description: Scalars['String']['output']
  forEntities: Array<Scalars['String']['output']>
  requiresPermission: Array<Permission>
}

export enum ErrorCode {
  ALREADY_REFUNDED_ERROR = 'ALREADY_REFUNDED_ERROR',
  CANCEL_ACTIVE_ORDER_ERROR = 'CANCEL_ACTIVE_ORDER_ERROR',
  CANCEL_PAYMENT_ERROR = 'CANCEL_PAYMENT_ERROR',
  CHANNEL_DEFAULT_LANGUAGE_ERROR = 'CHANNEL_DEFAULT_LANGUAGE_ERROR',
  COUPON_CODE_EXPIRED_ERROR = 'COUPON_CODE_EXPIRED_ERROR',
  COUPON_CODE_INVALID_ERROR = 'COUPON_CODE_INVALID_ERROR',
  COUPON_CODE_LIMIT_ERROR = 'COUPON_CODE_LIMIT_ERROR',
  CREATE_FULFILLMENT_ERROR = 'CREATE_FULFILLMENT_ERROR',
  DUPLICATE_ENTITY_ERROR = 'DUPLICATE_ENTITY_ERROR',
  EMAIL_ADDRESS_CONFLICT_ERROR = 'EMAIL_ADDRESS_CONFLICT_ERROR',
  EMPTY_ORDER_LINE_SELECTION_ERROR = 'EMPTY_ORDER_LINE_SELECTION_ERROR',
  FACET_IN_USE_ERROR = 'FACET_IN_USE_ERROR',
  FULFILLMENT_STATE_TRANSITION_ERROR = 'FULFILLMENT_STATE_TRANSITION_ERROR',
  GUEST_CHECKOUT_ERROR = 'GUEST_CHECKOUT_ERROR',
  INELIGIBLE_SHIPPING_METHOD_ERROR = 'INELIGIBLE_SHIPPING_METHOD_ERROR',
  INSUFFICIENT_STOCK_ERROR = 'INSUFFICIENT_STOCK_ERROR',
  INSUFFICIENT_STOCK_ON_HAND_ERROR = 'INSUFFICIENT_STOCK_ON_HAND_ERROR',
  INVALID_CREDENTIALS_ERROR = 'INVALID_CREDENTIALS_ERROR',
  INVALID_FULFILLMENT_HANDLER_ERROR = 'INVALID_FULFILLMENT_HANDLER_ERROR',
  ITEMS_ALREADY_FULFILLED_ERROR = 'ITEMS_ALREADY_FULFILLED_ERROR',
  LANGUAGE_NOT_AVAILABLE_ERROR = 'LANGUAGE_NOT_AVAILABLE_ERROR',
  MANUAL_PAYMENT_STATE_ERROR = 'MANUAL_PAYMENT_STATE_ERROR',
  MIME_TYPE_ERROR = 'MIME_TYPE_ERROR',
  MISSING_CONDITIONS_ERROR = 'MISSING_CONDITIONS_ERROR',
  MULTIPLE_ORDER_ERROR = 'MULTIPLE_ORDER_ERROR',
  NATIVE_AUTH_STRATEGY_ERROR = 'NATIVE_AUTH_STRATEGY_ERROR',
  NEGATIVE_QUANTITY_ERROR = 'NEGATIVE_QUANTITY_ERROR',
  NOTHING_TO_REFUND_ERROR = 'NOTHING_TO_REFUND_ERROR',
  NO_ACTIVE_ORDER_ERROR = 'NO_ACTIVE_ORDER_ERROR',
  NO_CHANGES_SPECIFIED_ERROR = 'NO_CHANGES_SPECIFIED_ERROR',
  ORDER_INTERCEPTOR_ERROR = 'ORDER_INTERCEPTOR_ERROR',
  ORDER_LIMIT_ERROR = 'ORDER_LIMIT_ERROR',
  ORDER_MODIFICATION_ERROR = 'ORDER_MODIFICATION_ERROR',
  ORDER_MODIFICATION_STATE_ERROR = 'ORDER_MODIFICATION_STATE_ERROR',
  ORDER_STATE_TRANSITION_ERROR = 'ORDER_STATE_TRANSITION_ERROR',
  PAYMENT_METHOD_MISSING_ERROR = 'PAYMENT_METHOD_MISSING_ERROR',
  PAYMENT_ORDER_MISMATCH_ERROR = 'PAYMENT_ORDER_MISMATCH_ERROR',
  PAYMENT_STATE_TRANSITION_ERROR = 'PAYMENT_STATE_TRANSITION_ERROR',
  PRODUCT_OPTION_IN_USE_ERROR = 'PRODUCT_OPTION_IN_USE_ERROR',
  QUANTITY_TOO_GREAT_ERROR = 'QUANTITY_TOO_GREAT_ERROR',
  REFUND_AMOUNT_ERROR = 'REFUND_AMOUNT_ERROR',
  REFUND_ORDER_STATE_ERROR = 'REFUND_ORDER_STATE_ERROR',
  REFUND_PAYMENT_ID_MISSING_ERROR = 'REFUND_PAYMENT_ID_MISSING_ERROR',
  REFUND_STATE_TRANSITION_ERROR = 'REFUND_STATE_TRANSITION_ERROR',
  SETTLE_PAYMENT_ERROR = 'SETTLE_PAYMENT_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type ErrorResult = {
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

export type Facet = Node & {
  __typename?: 'Facet'
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  id: Scalars['ID']['output']
  isPrivate: Scalars['Boolean']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  translations: Array<FacetTranslation>
  updatedAt: Scalars['DateTime']['output']
  /** Returns a paginated, sortable, filterable list of the Facet's values. Added in v2.1.0. */
  valueList: FacetValueList
  values: Array<FacetValue>
}

export type FacetValueListArgs = {
  options?: InputMaybe<FacetValueListOptions>
}

export type FacetFilterParameter = {
  _and?: InputMaybe<Array<FacetFilterParameter>>
  _or?: InputMaybe<Array<FacetFilterParameter>>
  code?: InputMaybe<StringOperators>
  createdAt?: InputMaybe<DateOperators>
  id?: InputMaybe<IdOperators>
  isPrivate?: InputMaybe<BooleanOperators>
  languageCode?: InputMaybe<StringOperators>
  name?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type FacetInUseError = ErrorResult & {
  __typename?: 'FacetInUseError'
  errorCode: ErrorCode
  facetCode: Scalars['String']['output']
  message: Scalars['String']['output']
  productCount: Scalars['Int']['output']
  variantCount: Scalars['Int']['output']
}

export type FacetList = PaginatedList & {
  __typename?: 'FacetList'
  items: Array<Facet>
  totalItems: Scalars['Int']['output']
}

export type FacetListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<FacetFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<FacetSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type FacetSortParameter = {
  code?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type FacetTranslation = {
  __typename?: 'FacetTranslation'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type FacetTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  languageCode: LanguageCode
  name?: InputMaybe<Scalars['String']['input']>
}

export type FacetValue = Node & {
  __typename?: 'FacetValue'
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  facet: Facet
  facetId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  translations: Array<FacetValueTranslation>
  updatedAt: Scalars['DateTime']['output']
}

/**
 * Used to construct boolean expressions for filtering search results
 * by FacetValue ID. Examples:
 *
 * * ID=1 OR ID=2: `{ facetValueFilters: [{ or: [1,2] }] }`
 * * ID=1 AND ID=2: `{ facetValueFilters: [{ and: 1 }, { and: 2 }] }`
 * * ID=1 AND (ID=2 OR ID=3): `{ facetValueFilters: [{ and: 1 }, { or: [2,3] }] }`
 */
export type FacetValueFilterInput = {
  and?: InputMaybe<Scalars['ID']['input']>
  or?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type FacetValueFilterParameter = {
  _and?: InputMaybe<Array<FacetValueFilterParameter>>
  _or?: InputMaybe<Array<FacetValueFilterParameter>>
  code?: InputMaybe<StringOperators>
  createdAt?: InputMaybe<DateOperators>
  facetId?: InputMaybe<IdOperators>
  id?: InputMaybe<IdOperators>
  languageCode?: InputMaybe<StringOperators>
  name?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type FacetValueList = PaginatedList & {
  __typename?: 'FacetValueList'
  items: Array<FacetValue>
  totalItems: Scalars['Int']['output']
}

export type FacetValueListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<FacetValueFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<FacetValueSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

/**
 * Which FacetValues are present in the products returned
 * by the search, and in what quantity.
 */
export type FacetValueResult = {
  __typename?: 'FacetValueResult'
  count: Scalars['Int']['output']
  facetValue: FacetValue
}

export type FacetValueSortParameter = {
  code?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  facetId?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type FacetValueTranslation = {
  __typename?: 'FacetValueTranslation'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type FacetValueTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  languageCode: LanguageCode
  name?: InputMaybe<Scalars['String']['input']>
}

export type FloatCustomFieldConfig = CustomField & {
  __typename?: 'FloatCustomFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  internal?: Maybe<Scalars['Boolean']['output']>
  label?: Maybe<Array<LocalizedString>>
  list: Scalars['Boolean']['output']
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  name: Scalars['String']['output']
  nullable?: Maybe<Scalars['Boolean']['output']>
  readonly?: Maybe<Scalars['Boolean']['output']>
  requiresPermission?: Maybe<Array<Permission>>
  step?: Maybe<Scalars['Float']['output']>
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type FloatStructFieldConfig = StructField & {
  __typename?: 'FloatStructFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  label?: Maybe<Array<LocalizedString>>
  list: Scalars['Boolean']['output']
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  name: Scalars['String']['output']
  step?: Maybe<Scalars['Float']['output']>
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type FulfillOrderInput = {
  handler: ConfigurableOperationInput
  lines: Array<OrderLineInput>
}

export type Fulfillment = Node & {
  __typename?: 'Fulfillment'
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  id: Scalars['ID']['output']
  lines: Array<FulfillmentLine>
  method: Scalars['String']['output']
  nextStates: Array<Scalars['String']['output']>
  state: Scalars['String']['output']
  /** @deprecated Use the `lines` field instead */
  summary: Array<FulfillmentLine>
  trackingCode?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type FulfillmentLine = {
  __typename?: 'FulfillmentLine'
  fulfillment: Fulfillment
  fulfillmentId: Scalars['ID']['output']
  orderLine: OrderLine
  orderLineId: Scalars['ID']['output']
  quantity: Scalars['Int']['output']
}

/** Returned when there is an error in transitioning the Fulfillment state */
export type FulfillmentStateTransitionError = ErrorResult & {
  __typename?: 'FulfillmentStateTransitionError'
  errorCode: ErrorCode
  fromState: Scalars['String']['output']
  message: Scalars['String']['output']
  toState: Scalars['String']['output']
  transitionError: Scalars['String']['output']
}

export type GetAllInput = {
  entityName: Scalars['String']['input']
}

export type GetBooleanValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
}

export type GetDateValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
}

export type GetGroupValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
}

export type GetListGroupValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
}

export type GetListRelationValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
}

export type GetListValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
}

export type GetStringValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
}

export enum GlobalFlag {
  FALSE = 'FALSE',
  INHERIT = 'INHERIT',
  TRUE = 'TRUE',
}

export type GlobalSettings = {
  __typename?: 'GlobalSettings'
  availableLanguages: Array<LanguageCode>
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  id: Scalars['ID']['output']
  outOfStockThreshold: Scalars['Int']['output']
  serverConfig: ServerConfig
  trackInventory: Scalars['Boolean']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type GoogleAuthInput = {
  token: Scalars['String']['input']
}

export type GroupFieldDefinition = {
  __typename?: 'GroupFieldDefinition'
  description: Scalars['String']['output']
  fieldName: Scalars['String']['output']
  fields: Array<AcfFieldDefinitionUnion>
  id: Scalars['String']['output']
  list: Scalars['Boolean']['output']
  sortOrder: Scalars['Int']['output']
  title: Scalars['String']['output']
}

export type GroupValue = {
  __typename?: 'GroupValue'
  field: GroupFieldDefinition
  id: Scalars['String']['output']
  value: Scalars['JSON']['output']
}

/** Returned when attempting to set the Customer on a guest checkout when the configured GuestCheckoutStrategy does not allow it. */
export type GuestCheckoutError = ErrorResult & {
  __typename?: 'GuestCheckoutError'
  errorCode: ErrorCode
  errorDetail: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type HistoryEntry = Node & {
  __typename?: 'HistoryEntry'
  administrator?: Maybe<Administrator>
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  data: Scalars['JSON']['output']
  id: Scalars['ID']['output']
  isPublic: Scalars['Boolean']['output']
  type: HistoryEntryType
  updatedAt: Scalars['DateTime']['output']
}

export type HistoryEntryFilterParameter = {
  _and?: InputMaybe<Array<HistoryEntryFilterParameter>>
  _or?: InputMaybe<Array<HistoryEntryFilterParameter>>
  createdAt?: InputMaybe<DateOperators>
  id?: InputMaybe<IdOperators>
  isPublic?: InputMaybe<BooleanOperators>
  type?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type HistoryEntryList = PaginatedList & {
  __typename?: 'HistoryEntryList'
  items: Array<HistoryEntry>
  totalItems: Scalars['Int']['output']
}

export type HistoryEntryListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<HistoryEntryFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<HistoryEntrySortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type HistoryEntrySortParameter = {
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export enum HistoryEntryType {
  CUSTOMER_ADDED_TO_GROUP = 'CUSTOMER_ADDED_TO_GROUP',
  CUSTOMER_ADDRESS_CREATED = 'CUSTOMER_ADDRESS_CREATED',
  CUSTOMER_ADDRESS_DELETED = 'CUSTOMER_ADDRESS_DELETED',
  CUSTOMER_ADDRESS_UPDATED = 'CUSTOMER_ADDRESS_UPDATED',
  CUSTOMER_DETAIL_UPDATED = 'CUSTOMER_DETAIL_UPDATED',
  CUSTOMER_EMAIL_UPDATE_REQUESTED = 'CUSTOMER_EMAIL_UPDATE_REQUESTED',
  CUSTOMER_EMAIL_UPDATE_VERIFIED = 'CUSTOMER_EMAIL_UPDATE_VERIFIED',
  CUSTOMER_NOTE = 'CUSTOMER_NOTE',
  CUSTOMER_PASSWORD_RESET_REQUESTED = 'CUSTOMER_PASSWORD_RESET_REQUESTED',
  CUSTOMER_PASSWORD_RESET_VERIFIED = 'CUSTOMER_PASSWORD_RESET_VERIFIED',
  CUSTOMER_PASSWORD_UPDATED = 'CUSTOMER_PASSWORD_UPDATED',
  CUSTOMER_REGISTERED = 'CUSTOMER_REGISTERED',
  CUSTOMER_REMOVED_FROM_GROUP = 'CUSTOMER_REMOVED_FROM_GROUP',
  CUSTOMER_VERIFIED = 'CUSTOMER_VERIFIED',
  ORDER_CANCELLATION = 'ORDER_CANCELLATION',
  ORDER_COUPON_APPLIED = 'ORDER_COUPON_APPLIED',
  ORDER_COUPON_REMOVED = 'ORDER_COUPON_REMOVED',
  ORDER_CUSTOMER_UPDATED = 'ORDER_CUSTOMER_UPDATED',
  ORDER_FULFILLMENT = 'ORDER_FULFILLMENT',
  ORDER_FULFILLMENT_TRANSITION = 'ORDER_FULFILLMENT_TRANSITION',
  ORDER_MODIFIED = 'ORDER_MODIFIED',
  ORDER_NOTE = 'ORDER_NOTE',
  ORDER_PAYMENT_TRANSITION = 'ORDER_PAYMENT_TRANSITION',
  ORDER_REFUND_TRANSITION = 'ORDER_REFUND_TRANSITION',
  ORDER_STATE_TRANSITION = 'ORDER_STATE_TRANSITION',
}

/** Operators for filtering on a list of ID fields */
export type IdListOperators = {
  inList: Scalars['ID']['input']
}

/** Operators for filtering on an ID field */
export type IdOperators = {
  eq?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  isNull?: InputMaybe<Scalars['Boolean']['input']>
  notEq?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
}

export type ImportInfo = {
  __typename?: 'ImportInfo'
  errors?: Maybe<Array<Scalars['String']['output']>>
  imported: Scalars['Int']['output']
  processed: Scalars['Int']['output']
}

/** Returned when attempting to set a ShippingMethod for which the Order is not eligible */
export type IneligibleShippingMethodError = ErrorResult & {
  __typename?: 'IneligibleShippingMethodError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

export type InsertBooleanFieldDefinitionInput = {
  description?: InputMaybe<Scalars['String']['input']>
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
  sortOrder?: InputMaybe<Scalars['Int']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type InsertBooleanValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
  value: Scalars['Boolean']['input']
}

export type InsertDateFieldDefinitionInput = {
  description?: InputMaybe<Scalars['String']['input']>
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
  sortOrder?: InputMaybe<Scalars['Int']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type InsertDateValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
  value: Scalars['DateTime']['input']
}

export type InsertGroupInput = {
  description: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldDefinitionIds: Scalars['JSON']['input']
  fieldName: Scalars['String']['input']
  list: Scalars['Boolean']['input']
  sortOrder: Scalars['Int']['input']
  title: Scalars['String']['input']
}

export type InsertGroupValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
  values: Scalars['JSON']['input']
}

export type InsertListFieldDefinitionInput = {
  description?: InputMaybe<Scalars['String']['input']>
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
  sortOrder?: InputMaybe<Scalars['Int']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type InsertListGroupValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
  valueSets: Scalars['JSON']['input']
}

export type InsertListRelationFieldDefinitionInput = {
  description?: InputMaybe<Scalars['String']['input']>
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
  relatedEntityName: Scalars['String']['input']
  sortOrder?: InputMaybe<Scalars['Int']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type InsertListRelationValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
  targetEntityIds: Array<Scalars['String']['input']>
}

export type InsertListValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
  value: Array<Scalars['String']['input']>
}

export type InsertStringFieldDefinitionInput = {
  description?: InputMaybe<Scalars['String']['input']>
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
  maxLength?: InputMaybe<Scalars['Int']['input']>
  sortOrder?: InputMaybe<Scalars['Int']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type InsertStringValueInput = {
  entityId: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldName: Scalars['String']['input']
  value: Scalars['String']['input']
}

/** Returned when attempting to add more items to the Order than are available */
export type InsufficientStockError = ErrorResult & {
  __typename?: 'InsufficientStockError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
  order: Order
  quantityAvailable: Scalars['Int']['output']
}

/**
 * Returned if attempting to create a Fulfillment when there is insufficient
 * stockOnHand of a ProductVariant to satisfy the requested quantity.
 */
export type InsufficientStockOnHandError = ErrorResult & {
  __typename?: 'InsufficientStockOnHandError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
  productVariantId: Scalars['ID']['output']
  productVariantName: Scalars['String']['output']
  stockOnHand: Scalars['Int']['output']
}

export type IntCustomFieldConfig = CustomField & {
  __typename?: 'IntCustomFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  internal?: Maybe<Scalars['Boolean']['output']>
  label?: Maybe<Array<LocalizedString>>
  list: Scalars['Boolean']['output']
  max?: Maybe<Scalars['Int']['output']>
  min?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  nullable?: Maybe<Scalars['Boolean']['output']>
  readonly?: Maybe<Scalars['Boolean']['output']>
  requiresPermission?: Maybe<Array<Permission>>
  step?: Maybe<Scalars['Int']['output']>
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type IntStructFieldConfig = StructField & {
  __typename?: 'IntStructFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  label?: Maybe<Array<LocalizedString>>
  list: Scalars['Boolean']['output']
  max?: Maybe<Scalars['Int']['output']>
  min?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  step?: Maybe<Scalars['Int']['output']>
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

/** Returned if the user authentication credentials are not valid */
export type InvalidCredentialsError = ErrorResult & {
  __typename?: 'InvalidCredentialsError'
  authenticationError: Scalars['String']['output']
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

/** Returned if the specified FulfillmentHandler code is not valid */
export type InvalidFulfillmentHandlerError = ErrorResult & {
  __typename?: 'InvalidFulfillmentHandlerError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

/** Returned if the specified items are already part of a Fulfillment */
export type ItemsAlreadyFulfilledError = ErrorResult & {
  __typename?: 'ItemsAlreadyFulfilledError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

export type Job = Node & {
  __typename?: 'Job'
  attempts: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  data?: Maybe<Scalars['JSON']['output']>
  duration: Scalars['Int']['output']
  error?: Maybe<Scalars['JSON']['output']>
  id: Scalars['ID']['output']
  isSettled: Scalars['Boolean']['output']
  progress: Scalars['Float']['output']
  queueName: Scalars['String']['output']
  result?: Maybe<Scalars['JSON']['output']>
  retries: Scalars['Int']['output']
  settledAt?: Maybe<Scalars['DateTime']['output']>
  startedAt?: Maybe<Scalars['DateTime']['output']>
  state: JobState
}

export type JobBufferSize = {
  __typename?: 'JobBufferSize'
  bufferId: Scalars['String']['output']
  size: Scalars['Int']['output']
}

export type JobFilterParameter = {
  _and?: InputMaybe<Array<JobFilterParameter>>
  _or?: InputMaybe<Array<JobFilterParameter>>
  attempts?: InputMaybe<NumberOperators>
  createdAt?: InputMaybe<DateOperators>
  duration?: InputMaybe<NumberOperators>
  id?: InputMaybe<IdOperators>
  isSettled?: InputMaybe<BooleanOperators>
  progress?: InputMaybe<NumberOperators>
  queueName?: InputMaybe<StringOperators>
  retries?: InputMaybe<NumberOperators>
  settledAt?: InputMaybe<DateOperators>
  startedAt?: InputMaybe<DateOperators>
  state?: InputMaybe<StringOperators>
}

export type JobList = PaginatedList & {
  __typename?: 'JobList'
  items: Array<Job>
  totalItems: Scalars['Int']['output']
}

export type JobListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<JobFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<JobSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type JobQueue = {
  __typename?: 'JobQueue'
  name: Scalars['String']['output']
  running: Scalars['Boolean']['output']
}

export type JobSortParameter = {
  attempts?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  duration?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  progress?: InputMaybe<SortOrder>
  queueName?: InputMaybe<SortOrder>
  retries?: InputMaybe<SortOrder>
  settledAt?: InputMaybe<SortOrder>
  startedAt?: InputMaybe<SortOrder>
}

/**
 * @description
 * The state of a Job in the JobQueue
 *
 * @docsCategory common
 */
export enum JobState {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  RETRYING = 'RETRYING',
  RUNNING = 'RUNNING',
}

export type LandingContactForm = {
  __typename?: 'LandingContactForm'
  success: Scalars['Boolean']['output']
}

export type LandingContactFormInput = {
  email: Scalars['String']['input']
  message: Scalars['String']['input']
  name: Scalars['String']['input']
}

/**
 * @description
 * Languages in the form of a ISO 639-1 language code with optional
 * region or script modifier (e.g. de_AT). The selection available is based
 * on the [Unicode CLDR summary list](https://unicode-org.github.io/cldr-staging/charts/37/summary/root.html)
 * and includes the major spoken languages of the world and any widely-used variants.
 *
 * @docsCategory common
 */
export enum LanguageCode {
  /** Afrikaans */
  af = 'af',
  /** Akan */
  ak = 'ak',
  /** Amharic */
  am = 'am',
  /** Arabic */
  ar = 'ar',
  /** Assamese */
  as = 'as',
  /** Azerbaijani */
  az = 'az',
  /** Belarusian */
  be = 'be',
  /** Bulgarian */
  bg = 'bg',
  /** Bambara */
  bm = 'bm',
  /** Bangla */
  bn = 'bn',
  /** Tibetan */
  bo = 'bo',
  /** Breton */
  br = 'br',
  /** Bosnian */
  bs = 'bs',
  /** Catalan */
  ca = 'ca',
  /** Chechen */
  ce = 'ce',
  /** Corsican */
  co = 'co',
  /** Czech */
  cs = 'cs',
  /** Church Slavic */
  cu = 'cu',
  /** Welsh */
  cy = 'cy',
  /** Danish */
  da = 'da',
  /** German */
  de = 'de',
  /** Austrian German */
  de_AT = 'de_AT',
  /** Swiss High German */
  de_CH = 'de_CH',
  /** Dzongkha */
  dz = 'dz',
  /** Ewe */
  ee = 'ee',
  /** Greek */
  el = 'el',
  /** English */
  en = 'en',
  /** Australian English */
  en_AU = 'en_AU',
  /** Canadian English */
  en_CA = 'en_CA',
  /** British English */
  en_GB = 'en_GB',
  /** American English */
  en_US = 'en_US',
  /** Esperanto */
  eo = 'eo',
  /** Spanish */
  es = 'es',
  /** European Spanish */
  es_ES = 'es_ES',
  /** Mexican Spanish */
  es_MX = 'es_MX',
  /** Estonian */
  et = 'et',
  /** Basque */
  eu = 'eu',
  /** Persian */
  fa = 'fa',
  /** Dari */
  fa_AF = 'fa_AF',
  /** Fulah */
  ff = 'ff',
  /** Finnish */
  fi = 'fi',
  /** Faroese */
  fo = 'fo',
  /** French */
  fr = 'fr',
  /** Canadian French */
  fr_CA = 'fr_CA',
  /** Swiss French */
  fr_CH = 'fr_CH',
  /** Western Frisian */
  fy = 'fy',
  /** Irish */
  ga = 'ga',
  /** Scottish Gaelic */
  gd = 'gd',
  /** Galician */
  gl = 'gl',
  /** Gujarati */
  gu = 'gu',
  /** Manx */
  gv = 'gv',
  /** Hausa */
  ha = 'ha',
  /** Hebrew */
  he = 'he',
  /** Hindi */
  hi = 'hi',
  /** Croatian */
  hr = 'hr',
  /** Haitian Creole */
  ht = 'ht',
  /** Hungarian */
  hu = 'hu',
  /** Armenian */
  hy = 'hy',
  /** Interlingua */
  ia = 'ia',
  /** Indonesian */
  id = 'id',
  /** Igbo */
  ig = 'ig',
  /** Sichuan Yi */
  ii = 'ii',
  /** Icelandic */
  is = 'is',
  /** Italian */
  it = 'it',
  /** Japanese */
  ja = 'ja',
  /** Javanese */
  jv = 'jv',
  /** Georgian */
  ka = 'ka',
  /** Kikuyu */
  ki = 'ki',
  /** Kazakh */
  kk = 'kk',
  /** Kalaallisut */
  kl = 'kl',
  /** Khmer */
  km = 'km',
  /** Kannada */
  kn = 'kn',
  /** Korean */
  ko = 'ko',
  /** Kashmiri */
  ks = 'ks',
  /** Kurdish */
  ku = 'ku',
  /** Cornish */
  kw = 'kw',
  /** Kyrgyz */
  ky = 'ky',
  /** Latin */
  la = 'la',
  /** Luxembourgish */
  lb = 'lb',
  /** Ganda */
  lg = 'lg',
  /** Lingala */
  ln = 'ln',
  /** Lao */
  lo = 'lo',
  /** Lithuanian */
  lt = 'lt',
  /** Luba-Katanga */
  lu = 'lu',
  /** Latvian */
  lv = 'lv',
  /** Malagasy */
  mg = 'mg',
  /** Maori */
  mi = 'mi',
  /** Macedonian */
  mk = 'mk',
  /** Malayalam */
  ml = 'ml',
  /** Mongolian */
  mn = 'mn',
  /** Marathi */
  mr = 'mr',
  /** Malay */
  ms = 'ms',
  /** Maltese */
  mt = 'mt',
  /** Burmese */
  my = 'my',
  /** Norwegian Bokmål */
  nb = 'nb',
  /** North Ndebele */
  nd = 'nd',
  /** Nepali */
  ne = 'ne',
  /** Dutch */
  nl = 'nl',
  /** Flemish */
  nl_BE = 'nl_BE',
  /** Norwegian Nynorsk */
  nn = 'nn',
  /** Nyanja */
  ny = 'ny',
  /** Oromo */
  om = 'om',
  /** Odia */
  or = 'or',
  /** Ossetic */
  os = 'os',
  /** Punjabi */
  pa = 'pa',
  /** Polish */
  pl = 'pl',
  /** Pashto */
  ps = 'ps',
  /** Portuguese */
  pt = 'pt',
  /** Brazilian Portuguese */
  pt_BR = 'pt_BR',
  /** European Portuguese */
  pt_PT = 'pt_PT',
  /** Quechua */
  qu = 'qu',
  /** Romansh */
  rm = 'rm',
  /** Rundi */
  rn = 'rn',
  /** Romanian */
  ro = 'ro',
  /** Moldavian */
  ro_MD = 'ro_MD',
  /** Russian */
  ru = 'ru',
  /** Kinyarwanda */
  rw = 'rw',
  /** Sanskrit */
  sa = 'sa',
  /** Sindhi */
  sd = 'sd',
  /** Northern Sami */
  se = 'se',
  /** Sango */
  sg = 'sg',
  /** Sinhala */
  si = 'si',
  /** Slovak */
  sk = 'sk',
  /** Slovenian */
  sl = 'sl',
  /** Samoan */
  sm = 'sm',
  /** Shona */
  sn = 'sn',
  /** Somali */
  so = 'so',
  /** Albanian */
  sq = 'sq',
  /** Serbian */
  sr = 'sr',
  /** Southern Sotho */
  st = 'st',
  /** Sundanese */
  su = 'su',
  /** Swedish */
  sv = 'sv',
  /** Swahili */
  sw = 'sw',
  /** Congo Swahili */
  sw_CD = 'sw_CD',
  /** Tamil */
  ta = 'ta',
  /** Telugu */
  te = 'te',
  /** Tajik */
  tg = 'tg',
  /** Thai */
  th = 'th',
  /** Tigrinya */
  ti = 'ti',
  /** Turkmen */
  tk = 'tk',
  /** Tongan */
  to = 'to',
  /** Turkish */
  tr = 'tr',
  /** Tatar */
  tt = 'tt',
  /** Uyghur */
  ug = 'ug',
  /** Ukrainian */
  uk = 'uk',
  /** Urdu */
  ur = 'ur',
  /** Uzbek */
  uz = 'uz',
  /** Vietnamese */
  vi = 'vi',
  /** Volapük */
  vo = 'vo',
  /** Wolof */
  wo = 'wo',
  /** Xhosa */
  xh = 'xh',
  /** Yiddish */
  yi = 'yi',
  /** Yoruba */
  yo = 'yo',
  /** Chinese */
  zh = 'zh',
  /** Simplified Chinese */
  zh_Hans = 'zh_Hans',
  /** Traditional Chinese */
  zh_Hant = 'zh_Hant',
  /** Zulu */
  zu = 'zu',
}

/** Returned if attempting to set a Channel's defaultLanguageCode to a language which is not enabled in GlobalSettings */
export type LanguageNotAvailableError = ErrorResult & {
  __typename?: 'LanguageNotAvailableError'
  errorCode: ErrorCode
  languageCode: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type ListFieldDefinition = {
  __typename?: 'ListFieldDefinition'
  description: Scalars['String']['output']
  fieldName: Scalars['String']['output']
  id: Scalars['String']['output']
  sortOrder: Scalars['Int']['output']
  title: Scalars['String']['output']
}

export type ListGroupValue = {
  __typename?: 'ListGroupValue'
  field: GroupFieldDefinition
  id: Scalars['String']['output']
  value: Scalars['JSON']['output']
}

export type ListRelationFieldDefinition = {
  __typename?: 'ListRelationFieldDefinition'
  description: Scalars['String']['output']
  fieldName: Scalars['String']['output']
  id: Scalars['String']['output']
  relatedEntityName: Scalars['String']['output']
  sortOrder: Scalars['Int']['output']
  title: Scalars['String']['output']
}

export type ListRelationValue = {
  __typename?: 'ListRelationValue'
  entities: Scalars['JSON']['output']
  field: ListRelationFieldDefinition
  id: Scalars['String']['output']
  value: Array<Scalars['String']['output']>
}

export type ListValue = {
  __typename?: 'ListValue'
  field: ListFieldDefinition
  id: Scalars['String']['output']
  value: Array<Scalars['String']['output']>
}

export type LocaleStringCustomFieldConfig = CustomField & {
  __typename?: 'LocaleStringCustomFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  internal?: Maybe<Scalars['Boolean']['output']>
  label?: Maybe<Array<LocalizedString>>
  length?: Maybe<Scalars['Int']['output']>
  list: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  nullable?: Maybe<Scalars['Boolean']['output']>
  pattern?: Maybe<Scalars['String']['output']>
  readonly?: Maybe<Scalars['Boolean']['output']>
  requiresPermission?: Maybe<Array<Permission>>
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type LocaleTextCustomFieldConfig = CustomField & {
  __typename?: 'LocaleTextCustomFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  internal?: Maybe<Scalars['Boolean']['output']>
  label?: Maybe<Array<LocalizedString>>
  list: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  nullable?: Maybe<Scalars['Boolean']['output']>
  readonly?: Maybe<Scalars['Boolean']['output']>
  requiresPermission?: Maybe<Array<Permission>>
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type LocalizedString = {
  __typename?: 'LocalizedString'
  languageCode: LanguageCode
  value: Scalars['String']['output']
}

export enum LogicalOperator {
  AND = 'AND',
  OR = 'OR',
}

export type ManageablePlugin = {
  __typename?: 'ManageablePlugin'
  code: Scalars['String']['output']
  configurationItems: Array<ManageablePluginConfigItem>
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  imageUrl: Scalars['String']['output']
  name: Scalars['String']['output']
  tags: Array<ManageablePluginTags>
}

export type ManageablePluginConfigItem = {
  __typename?: 'ManageablePluginConfigItem'
  id: Scalars['ID']['output']
  translations: Array<ManageablePluginConfigItemTranslation>
  type: Scalars['String']['output']
}

export type ManageablePluginConfigItemTranslation = {
  __typename?: 'ManageablePluginConfigItemTranslation'
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  languageCode: Scalars['String']['output']
  name: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type ManageablePluginTags = {
  __typename?: 'ManageablePluginTags'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ManualPaymentInput = {
  metadata?: InputMaybe<Scalars['JSON']['input']>
  method: Scalars['String']['input']
  orderId: Scalars['ID']['input']
  transactionId?: InputMaybe<Scalars['String']['input']>
}

/**
 * Returned when a call to addManualPaymentToOrder is made but the Order
 * is not in the required state.
 */
export type ManualPaymentStateError = ErrorResult & {
  __typename?: 'ManualPaymentStateError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

export enum MetricInterval {
  Daily = 'Daily',
}

export type MetricSummary = {
  __typename?: 'MetricSummary'
  entries: Array<MetricSummaryEntry>
  interval: MetricInterval
  title: Scalars['String']['output']
  type: MetricType
}

export type MetricSummaryEntry = {
  __typename?: 'MetricSummaryEntry'
  label: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export type MetricSummaryInput = {
  interval: MetricInterval
  refresh?: InputMaybe<Scalars['Boolean']['input']>
  types: Array<MetricType>
}

export enum MetricType {
  AverageOrderValue = 'AverageOrderValue',
  OrderCount = 'OrderCount',
  OrderTotal = 'OrderTotal',
}

export type MimeTypeError = ErrorResult & {
  __typename?: 'MimeTypeError'
  errorCode: ErrorCode
  fileName: Scalars['String']['output']
  message: Scalars['String']['output']
  mimeType: Scalars['String']['output']
}

/** Returned if a PromotionCondition has neither a couponCode nor any conditions set */
export type MissingConditionsError = ErrorResult & {
  __typename?: 'MissingConditionsError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

export type ModifyOrderInput = {
  addItems?: InputMaybe<Array<AddItemInput>>
  adjustOrderLines?: InputMaybe<Array<OrderLineInput>>
  couponCodes?: InputMaybe<Array<Scalars['String']['input']>>
  dryRun: Scalars['Boolean']['input']
  note?: InputMaybe<Scalars['String']['input']>
  options?: InputMaybe<ModifyOrderOptions>
  orderId: Scalars['ID']['input']
  /**
   * Deprecated in v2.2.0. Use `refunds` instead to allow multiple refunds to be
   * applied in the case that multiple payment methods have been used on the order.
   */
  refund?: InputMaybe<AdministratorRefundInput>
  refunds?: InputMaybe<Array<AdministratorRefundInput>>
  /** Added in v2.2 */
  shippingMethodIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surcharges?: InputMaybe<Array<SurchargeInput>>
  updateBillingAddress?: InputMaybe<UpdateOrderAddressInput>
  updateShippingAddress?: InputMaybe<UpdateOrderAddressInput>
}

export type ModifyOrderOptions = {
  freezePromotions?: InputMaybe<Scalars['Boolean']['input']>
  recalculateShipping?: InputMaybe<Scalars['Boolean']['input']>
}

export type ModifyOrderResult =
  | CouponCodeExpiredError
  | CouponCodeInvalidError
  | CouponCodeLimitError
  | IneligibleShippingMethodError
  | InsufficientStockError
  | NegativeQuantityError
  | NoChangesSpecifiedError
  | Order
  | OrderLimitError
  | OrderModificationStateError
  | PaymentMethodMissingError
  | RefundPaymentIdMissingError

export type MoveCollectionInput = {
  collectionId: Scalars['ID']['input']
  index: Scalars['Int']['input']
  parentId: Scalars['ID']['input']
}

/** Returned if an operation has specified OrderLines from multiple Orders */
export type MultipleOrderError = ErrorResult & {
  __typename?: 'MultipleOrderError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Add Customers to a CustomerGroup */
  addCustomersToGroup: CustomerGroup
  addFulfillmentToOrder: AddFulfillmentToOrderResult
  /** Adds an item to the draft Order. */
  addItemToDraftOrder: UpdateOrderItemsResult
  /**
   * Used to manually create a new Payment against an Order.
   * This can be used by an Administrator when an Order is in the ArrangingPayment state.
   *
   * It is also used when a completed Order
   * has been modified (using `modifyOrder`) and the price has increased. The extra payment
   * can then be manually arranged by the administrator, and the details used to create a new
   * Payment.
   */
  addManualPaymentToOrder: AddManualPaymentToOrderResult
  /** Add members to a Zone */
  addMembersToZone: Zone
  addNoteToCustomer: Customer
  addNoteToOrder: Order
  /** Add an OptionGroup to a Product */
  addOptionGroupToProduct: Product
  addTestDefinitions: Scalars['Boolean']['output']
  /** Adjusts a draft OrderLine. If custom fields are defined on the OrderLine entity, a third argument 'customFields' of type `OrderLineCustomFieldsInput` will be available. */
  adjustDraftOrderLine: UpdateOrderItemsResult
  /** Applies the given coupon code to the draft Order */
  applyCouponCodeToDraftOrder: ApplyCouponCodeResult
  /** Assign assets to channel */
  assignAssetsToChannel: Array<Asset>
  /** Assigns Collections to the specified Channel */
  assignCollectionsToChannel: Array<Collection>
  /** Assigns Facets to the specified Channel */
  assignFacetsToChannel: Array<Facet>
  /** Assigns PaymentMethods to the specified Channel */
  assignPaymentMethodsToChannel: Array<PaymentMethod>
  /** Assigns ProductVariants to the specified Channel */
  assignProductVariantsToChannel: Array<ProductVariant>
  /** Assigns all ProductVariants of Product to the specified Channel */
  assignProductsToChannel: Array<Product>
  /** Assigns Promotions to the specified Channel */
  assignPromotionsToChannel: Array<Promotion>
  /** Assign a Role to an Administrator */
  assignRoleToAdministrator: Administrator
  /** Assigns ShippingMethods to the specified Channel */
  assignShippingMethodsToChannel: Array<ShippingMethod>
  /** Assigns StockLocations to the specified Channel */
  assignStockLocationsToChannel: Array<StockLocation>
  /** Authenticates the user using a named authentication strategy */
  authenticate: AuthenticationResult
  cancelJob: Job
  cancelOrder: CancelOrderResult
  cancelPayment: CancelPaymentResult
  /** Create a new Administrator */
  createAdministrator: Administrator
  /** Create a new Asset */
  createAssets: Array<CreateAssetResult>
  /** Create a new Channel */
  createChannel: CreateChannelResult
  createChannelPlugin: ChannelPlugin
  /** Create a new Collection */
  createCollection: Collection
  /** Create a new Country */
  createCountry: Country
  /** Create a new Customer. If a password is provided, a new User will also be created an linked to the Customer. */
  createCustomer: CreateCustomerResult
  /** Create a new Address and associate it with the Customer specified by customerId */
  createCustomerAddress: Address
  /** Create a new CustomerGroup */
  createCustomerGroup: CustomerGroup
  /** Creates a draft Order */
  createDraftOrder: Order
  /** Create a new Facet */
  createFacet: Facet
  /** Create one or more FacetValues */
  createFacetValues: Array<FacetValue>
  /** Create existing PaymentMethod */
  createPaymentMethod: PaymentMethod
  /** Create a new Product */
  createProduct: Product
  /** Create a new ProductOption within a ProductOptionGroup */
  createProductOption: ProductOption
  /** Create a new ProductOptionGroup */
  createProductOptionGroup: ProductOptionGroup
  /** Create a set of ProductVariants based on the OptionGroups assigned to the given Product */
  createProductVariants: Array<Maybe<ProductVariant>>
  createPromotion: CreatePromotionResult
  /** Create a new Province */
  createProvince: Province
  /** Create a new Role */
  createRole: Role
  /** Create a new Seller */
  createSeller: Seller
  /** Create a new ShippingMethod */
  createShippingMethod: ShippingMethod
  createStockLocation: StockLocation
  /** Create a new Tag */
  createTag: Tag
  /** Create a new TaxCategory */
  createTaxCategory: TaxCategory
  /** Create a new TaxRate */
  createTaxRate: TaxRate
  /** Create a new Zone */
  createZone: Zone
  /** Delete an Administrator */
  deleteAdministrator: DeletionResponse
  /** Delete multiple Administrators */
  deleteAdministrators: Array<DeletionResponse>
  /** Delete an Asset */
  deleteAsset: DeletionResponse
  /** Delete multiple Assets */
  deleteAssets: DeletionResponse
  deleteBooleanValue: Scalars['Boolean']['output']
  /** Delete a Channel */
  deleteChannel: DeletionResponse
  deleteChannelPlugin: ChannelPlugin
  /** Delete multiple Channels */
  deleteChannels: Array<DeletionResponse>
  /** Delete a Collection and all of its descendants */
  deleteCollection: DeletionResponse
  /** Delete multiple Collections and all of their descendants */
  deleteCollections: Array<DeletionResponse>
  /** Delete multiple Countries */
  deleteCountries: Array<DeletionResponse>
  /** Delete a Country */
  deleteCountry: DeletionResponse
  /** Delete a Customer */
  deleteCustomer: DeletionResponse
  /** Update an existing Address */
  deleteCustomerAddress: Success
  /** Delete a CustomerGroup */
  deleteCustomerGroup: DeletionResponse
  /** Delete multiple CustomerGroups */
  deleteCustomerGroups: Array<DeletionResponse>
  deleteCustomerNote: DeletionResponse
  /** Deletes Customers */
  deleteCustomers: Array<DeletionResponse>
  deleteDateValue: Scalars['Boolean']['output']
  /** Deletes a draft Order */
  deleteDraftOrder: DeletionResponse
  /** Delete an existing Facet */
  deleteFacet: DeletionResponse
  /** Delete one or more FacetValues */
  deleteFacetValues: Array<DeletionResponse>
  /** Delete multiple existing Facets */
  deleteFacets: Array<DeletionResponse>
  deleteGroup: Scalars['Boolean']['output']
  deleteGroupValue: Scalars['Boolean']['output']
  deleteListGroupValue: Scalars['Boolean']['output']
  deleteListRelationValue: Scalars['Boolean']['output']
  deleteListValue: Scalars['Boolean']['output']
  deleteOrderNote: DeletionResponse
  /** Delete a PaymentMethod */
  deletePaymentMethod: DeletionResponse
  /** Delete multiple PaymentMethods */
  deletePaymentMethods: Array<DeletionResponse>
  /** Delete a Product */
  deleteProduct: DeletionResponse
  /** Delete a ProductOption */
  deleteProductOption: DeletionResponse
  /** Delete a ProductVariant */
  deleteProductVariant: DeletionResponse
  /** Delete multiple ProductVariants */
  deleteProductVariants: Array<DeletionResponse>
  /** Delete multiple Products */
  deleteProducts: Array<DeletionResponse>
  deletePromotion: DeletionResponse
  deletePromotions: Array<DeletionResponse>
  /** Delete a Province */
  deleteProvince: DeletionResponse
  /** Delete an existing Role */
  deleteRole: DeletionResponse
  /** Delete multiple Roles */
  deleteRoles: Array<DeletionResponse>
  /** Delete a Seller */
  deleteSeller: DeletionResponse
  /** Delete multiple Sellers */
  deleteSellers: Array<DeletionResponse>
  /** Delete a ShippingMethod */
  deleteShippingMethod: DeletionResponse
  /** Delete multiple ShippingMethods */
  deleteShippingMethods: Array<DeletionResponse>
  deleteStockLocation: DeletionResponse
  deleteStockLocations: Array<DeletionResponse>
  deleteStringValue: Scalars['Boolean']['output']
  /** Delete an existing Tag */
  deleteTag: DeletionResponse
  /** Deletes multiple TaxCategories */
  deleteTaxCategories: Array<DeletionResponse>
  /** Deletes a TaxCategory */
  deleteTaxCategory: DeletionResponse
  /** Delete a TaxRate */
  deleteTaxRate: DeletionResponse
  /** Delete multiple TaxRates */
  deleteTaxRates: Array<DeletionResponse>
  deleteTestDefinitions: Scalars['Boolean']['output']
  /** Delete a Zone */
  deleteZone: DeletionResponse
  /** Delete a Zone */
  deleteZones: Array<DeletionResponse>
  disableChannelPlugin: ChannelPlugin
  /**
   * Duplicate an existing entity using a specific EntityDuplicator.
   * Since v2.2.0.
   */
  duplicateEntity: DuplicateEntityResult
  enableChannelPlugin: ChannelPlugin
  flushBufferedJobs: Success
  importProducts?: Maybe<ImportInfo>
  insertBooleanFieldDefinition: BooleanFieldDefinition
  insertBooleanValue: BooleanValue
  insertDateFieldDefinition: DateFieldDefinition
  insertDateValue: DateValue
  insertGroup: GroupFieldDefinition
  insertGroupValue: GroupValue
  insertListFieldDefinition: ListFieldDefinition
  insertListGroupValue: ListGroupValue
  insertListRelationFieldDefinition: ListRelationFieldDefinition
  insertListRelationValue: ListRelationValue
  insertListValue: ListValue
  insertStringFieldDefinition: StringFieldDefinition
  insertStringValue: StringValue
  /**
   * Authenticates the user using the native authentication strategy. This mutation is an alias for authenticate({ native: { ... }})
   *
   * The `rememberMe` option applies when using cookie-based sessions, and if `true` it will set the maxAge of the session cookie
   * to 1 year.
   */
  login: NativeAuthenticationResult
  logout: Success
  /**
   * Allows an Order to be modified after it has been completed by the Customer. The Order must first
   * be in the `Modifying` state.
   */
  modifyOrder: ModifyOrderResult
  /** Move a Collection to a different parent or index */
  moveCollection: Collection
  refundOrder: RefundOrderResult
  register?: Maybe<RegisterSellerResponse>
  reindex: Job
  /** Removes Collections from the specified Channel */
  removeCollectionsFromChannel: Array<Collection>
  /** Removes the given coupon code from the draft Order */
  removeCouponCodeFromDraftOrder?: Maybe<Order>
  /** Remove Customers from a CustomerGroup */
  removeCustomersFromGroup: CustomerGroup
  /** Remove an OrderLine from the draft Order */
  removeDraftOrderLine: RemoveOrderItemsResult
  /** Removes Facets from the specified Channel */
  removeFacetsFromChannel: Array<RemoveFacetFromChannelResult>
  /** Remove members from a Zone */
  removeMembersFromZone: Zone
  /**
   * Remove an OptionGroup from a Product. If the OptionGroup is in use by any ProductVariants
   * the mutation will return a ProductOptionInUseError, and the OptionGroup will not be removed.
   * Setting the `force` argument to `true` will override this and remove the OptionGroup anyway,
   * as well as removing any of the group's options from the Product's ProductVariants.
   */
  removeOptionGroupFromProduct: RemoveOptionGroupFromProductResult
  /** Removes PaymentMethods from the specified Channel */
  removePaymentMethodsFromChannel: Array<PaymentMethod>
  /** Removes ProductVariants from the specified Channel */
  removeProductVariantsFromChannel: Array<ProductVariant>
  /** Removes all ProductVariants of Product from the specified Channel */
  removeProductsFromChannel: Array<Product>
  /** Removes Promotions from the specified Channel */
  removePromotionsFromChannel: Array<Promotion>
  /** Remove all settled jobs in the given queues older than the given date. Returns the number of jobs deleted. */
  removeSettledJobs: Scalars['Int']['output']
  /** Removes ShippingMethods from the specified Channel */
  removeShippingMethodsFromChannel: Array<ShippingMethod>
  /** Removes StockLocations from the specified Channel */
  removeStockLocationsFromChannel: Array<StockLocation>
  runPendingSearchIndexUpdates: Success
  setCustomerForDraftOrder: SetCustomerForDraftOrderResult
  /** Sets the billing address for a draft Order */
  setDraftOrderBillingAddress: Order
  /** Allows any custom fields to be set for the active order */
  setDraftOrderCustomFields: Order
  /** Sets the shipping address for a draft Order */
  setDraftOrderShippingAddress: Order
  /** Sets the shipping method by id, which can be obtained with the `eligibleShippingMethodsForDraftOrder` query */
  setDraftOrderShippingMethod: SetOrderShippingMethodResult
  setOrderCustomFields?: Maybe<Order>
  /** Allows a different Customer to be assigned to an Order. Added in v2.2.0. */
  setOrderCustomer?: Maybe<Order>
  settlePayment: SettlePaymentResult
  settleRefund: SettleRefundResult
  submitLandingContactForm: LandingContactForm
  transitionFulfillmentToState: TransitionFulfillmentToStateResult
  transitionOrderToState?: Maybe<TransitionOrderToStateResult>
  transitionPaymentToState: TransitionPaymentToStateResult
  /** Unsets the billing address for a draft Order */
  unsetDraftOrderBillingAddress: Order
  /** Unsets the shipping address for a draft Order */
  unsetDraftOrderShippingAddress: Order
  /** Update the active (currently logged-in) Administrator */
  updateActiveAdministrator: Administrator
  /** Update an existing Administrator */
  updateAdministrator: Administrator
  /** Update an existing Asset */
  updateAsset: Asset
  /** Update an existing Channel */
  updateChannel: UpdateChannelResult
  updateChannelPluginConfiguration: ChannelPlugin
  /** Update an existing Collection */
  updateCollection: Collection
  /** Update an existing Country */
  updateCountry: Country
  /** Update an existing Customer */
  updateCustomer: UpdateCustomerResult
  /** Update an existing Address */
  updateCustomerAddress: Address
  /** Update an existing CustomerGroup */
  updateCustomerGroup: CustomerGroup
  updateCustomerNote: HistoryEntry
  /** Update an existing Facet */
  updateFacet: Facet
  /** Update one or more FacetValues */
  updateFacetValues: Array<FacetValue>
  updateGlobalSettings: UpdateGlobalSettingsResult
  updateGroup: GroupFieldDefinition
  updateOrderNote: HistoryEntry
  /** Update an existing PaymentMethod */
  updatePaymentMethod: PaymentMethod
  /** Update an existing Product */
  updateProduct: Product
  /** Create a new ProductOption within a ProductOptionGroup */
  updateProductOption: ProductOption
  /** Update an existing ProductOptionGroup */
  updateProductOptionGroup: ProductOptionGroup
  /** Update existing ProductVariants */
  updateProductVariants: Array<Maybe<ProductVariant>>
  /** Update multiple existing Products */
  updateProducts: Array<Product>
  updatePromotion: UpdatePromotionResult
  /** Update an existing Province */
  updateProvince: Province
  /** Update an existing Role */
  updateRole: Role
  /** Update an existing Seller */
  updateSeller: Seller
  /** Update an existing ShippingMethod */
  updateShippingMethod: ShippingMethod
  updateStockLocation: StockLocation
  /** Update an existing Tag */
  updateTag: Tag
  /** Update an existing TaxCategory */
  updateTaxCategory: TaxCategory
  /** Update an existing TaxRate */
  updateTaxRate: TaxRate
  /** Update an existing Zone */
  updateZone: Zone
  verify?: Maybe<VerifySellerResponse>
}

export type MutationAddCustomersToGroupArgs = {
  customerGroupId: Scalars['ID']['input']
  customerIds: Array<Scalars['ID']['input']>
}

export type MutationAddFulfillmentToOrderArgs = {
  input: FulfillOrderInput
}

export type MutationAddItemToDraftOrderArgs = {
  input: AddItemToDraftOrderInput
  orderId: Scalars['ID']['input']
}

export type MutationAddManualPaymentToOrderArgs = {
  input: ManualPaymentInput
}

export type MutationAddMembersToZoneArgs = {
  memberIds: Array<Scalars['ID']['input']>
  zoneId: Scalars['ID']['input']
}

export type MutationAddNoteToCustomerArgs = {
  input: AddNoteToCustomerInput
}

export type MutationAddNoteToOrderArgs = {
  input: AddNoteToOrderInput
}

export type MutationAddOptionGroupToProductArgs = {
  optionGroupId: Scalars['ID']['input']
  productId: Scalars['ID']['input']
}

export type MutationAdjustDraftOrderLineArgs = {
  input: AdjustDraftOrderLineInput
  orderId: Scalars['ID']['input']
}

export type MutationApplyCouponCodeToDraftOrderArgs = {
  couponCode: Scalars['String']['input']
  orderId: Scalars['ID']['input']
}

export type MutationAssignAssetsToChannelArgs = {
  input: AssignAssetsToChannelInput
}

export type MutationAssignCollectionsToChannelArgs = {
  input: AssignCollectionsToChannelInput
}

export type MutationAssignFacetsToChannelArgs = {
  input: AssignFacetsToChannelInput
}

export type MutationAssignPaymentMethodsToChannelArgs = {
  input: AssignPaymentMethodsToChannelInput
}

export type MutationAssignProductVariantsToChannelArgs = {
  input: AssignProductVariantsToChannelInput
}

export type MutationAssignProductsToChannelArgs = {
  input: AssignProductsToChannelInput
}

export type MutationAssignPromotionsToChannelArgs = {
  input: AssignPromotionsToChannelInput
}

export type MutationAssignRoleToAdministratorArgs = {
  administratorId: Scalars['ID']['input']
  roleId: Scalars['ID']['input']
}

export type MutationAssignShippingMethodsToChannelArgs = {
  input: AssignShippingMethodsToChannelInput
}

export type MutationAssignStockLocationsToChannelArgs = {
  input: AssignStockLocationsToChannelInput
}

export type MutationAuthenticateArgs = {
  input: AuthenticationInput
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationCancelJobArgs = {
  jobId: Scalars['ID']['input']
}

export type MutationCancelOrderArgs = {
  input: CancelOrderInput
}

export type MutationCancelPaymentArgs = {
  id: Scalars['ID']['input']
}

export type MutationCreateAdministratorArgs = {
  input: CreateAdministratorInput
}

export type MutationCreateAssetsArgs = {
  input: Array<CreateAssetInput>
}

export type MutationCreateChannelArgs = {
  input: CreateChannelInput
}

export type MutationCreateChannelPluginArgs = {
  code: Scalars['String']['input']
}

export type MutationCreateCollectionArgs = {
  input: CreateCollectionInput
}

export type MutationCreateCountryArgs = {
  input: CreateCountryInput
}

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput
  password?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateCustomerAddressArgs = {
  customerId: Scalars['ID']['input']
  input: CreateAddressInput
}

export type MutationCreateCustomerGroupArgs = {
  input: CreateCustomerGroupInput
}

export type MutationCreateFacetArgs = {
  input: CreateFacetInput
}

export type MutationCreateFacetValuesArgs = {
  input: Array<CreateFacetValueInput>
}

export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput
}

export type MutationCreateProductArgs = {
  input: CreateProductInput
}

export type MutationCreateProductOptionArgs = {
  input: CreateProductOptionInput
}

export type MutationCreateProductOptionGroupArgs = {
  input: CreateProductOptionGroupInput
}

export type MutationCreateProductVariantsArgs = {
  input: Array<CreateProductVariantInput>
}

export type MutationCreatePromotionArgs = {
  input: CreatePromotionInput
}

export type MutationCreateProvinceArgs = {
  input: CreateProvinceInput
}

export type MutationCreateRoleArgs = {
  input: CreateRoleInput
}

export type MutationCreateSellerArgs = {
  input: CreateSellerInput
}

export type MutationCreateShippingMethodArgs = {
  input: CreateShippingMethodInput
}

export type MutationCreateStockLocationArgs = {
  input: CreateStockLocationInput
}

export type MutationCreateTagArgs = {
  input: CreateTagInput
}

export type MutationCreateTaxCategoryArgs = {
  input: CreateTaxCategoryInput
}

export type MutationCreateTaxRateArgs = {
  input: CreateTaxRateInput
}

export type MutationCreateZoneArgs = {
  input: CreateZoneInput
}

export type MutationDeleteAdministratorArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteAdministratorsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteAssetArgs = {
  input: DeleteAssetInput
}

export type MutationDeleteAssetsArgs = {
  input: DeleteAssetsInput
}

export type MutationDeleteBooleanValueArgs = {
  input: DeleteBooleanValueInput
}

export type MutationDeleteChannelArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteChannelPluginArgs = {
  channelPluginId: Scalars['String']['input']
}

export type MutationDeleteChannelsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteCollectionArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCollectionsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteCountriesArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteCountryArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCustomerArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCustomerAddressArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCustomerGroupArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCustomerGroupsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteCustomerNoteArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCustomersArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteDateValueArgs = {
  input: DeleteDateValueInput
}

export type MutationDeleteDraftOrderArgs = {
  orderId: Scalars['ID']['input']
}

export type MutationDeleteFacetArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
}

export type MutationDeleteFacetValuesArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteFacetsArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput
}

export type MutationDeleteGroupValueArgs = {
  input: DeleteGroupValueInput
}

export type MutationDeleteListGroupValueArgs = {
  input: DeleteListGroupValueInput
}

export type MutationDeleteListRelationValueArgs = {
  input: DeleteListRelationInput
}

export type MutationDeleteListValueArgs = {
  input: DeleteListValueInput
}

export type MutationDeleteOrderNoteArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeletePaymentMethodArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
}

export type MutationDeletePaymentMethodsArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteProductArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteProductOptionArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteProductVariantArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteProductVariantsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteProductsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeletePromotionArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeletePromotionsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteProvinceArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteRoleArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteRolesArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteSellerArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteSellersArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteShippingMethodArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteShippingMethodsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteStockLocationArgs = {
  input: DeleteStockLocationInput
}

export type MutationDeleteStockLocationsArgs = {
  input: Array<DeleteStockLocationInput>
}

export type MutationDeleteStringValueArgs = {
  input: DeleteStringValueInput
}

export type MutationDeleteTagArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteTaxCategoriesArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteTaxCategoryArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteTaxRateArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteTaxRatesArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteZoneArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteZonesArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDisableChannelPluginArgs = {
  channelPluginId: Scalars['ID']['input']
}

export type MutationDuplicateEntityArgs = {
  input: DuplicateEntityInput
}

export type MutationEnableChannelPluginArgs = {
  channelPluginId: Scalars['ID']['input']
}

export type MutationFlushBufferedJobsArgs = {
  bufferIds?: InputMaybe<Array<Scalars['String']['input']>>
}

export type MutationImportProductsArgs = {
  csvFile: Scalars['Upload']['input']
}

export type MutationInsertBooleanFieldDefinitionArgs = {
  input: InsertBooleanFieldDefinitionInput
}

export type MutationInsertBooleanValueArgs = {
  input: InsertBooleanValueInput
}

export type MutationInsertDateFieldDefinitionArgs = {
  input: InsertDateFieldDefinitionInput
}

export type MutationInsertDateValueArgs = {
  input: InsertDateValueInput
}

export type MutationInsertGroupArgs = {
  input: InsertGroupInput
}

export type MutationInsertGroupValueArgs = {
  input: InsertGroupValueInput
}

export type MutationInsertListFieldDefinitionArgs = {
  input: InsertListFieldDefinitionInput
}

export type MutationInsertListGroupValueArgs = {
  input: InsertListGroupValueInput
}

export type MutationInsertListRelationFieldDefinitionArgs = {
  input: InsertListRelationFieldDefinitionInput
}

export type MutationInsertListRelationValueArgs = {
  input: InsertListRelationValueInput
}

export type MutationInsertListValueArgs = {
  input: InsertListValueInput
}

export type MutationInsertStringFieldDefinitionArgs = {
  input: InsertStringFieldDefinitionInput
}

export type MutationInsertStringValueArgs = {
  input: InsertStringValueInput
}

export type MutationLoginArgs = {
  password: Scalars['String']['input']
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>
  username: Scalars['String']['input']
}

export type MutationModifyOrderArgs = {
  input: ModifyOrderInput
}

export type MutationMoveCollectionArgs = {
  input: MoveCollectionInput
}

export type MutationRefundOrderArgs = {
  input: RefundOrderInput
}

export type MutationRegisterArgs = {
  input: AuthenticationRegisterInput
}

export type MutationRemoveCollectionsFromChannelArgs = {
  input: RemoveCollectionsFromChannelInput
}

export type MutationRemoveCouponCodeFromDraftOrderArgs = {
  couponCode: Scalars['String']['input']
  orderId: Scalars['ID']['input']
}

export type MutationRemoveCustomersFromGroupArgs = {
  customerGroupId: Scalars['ID']['input']
  customerIds: Array<Scalars['ID']['input']>
}

export type MutationRemoveDraftOrderLineArgs = {
  orderId: Scalars['ID']['input']
  orderLineId: Scalars['ID']['input']
}

export type MutationRemoveFacetsFromChannelArgs = {
  input: RemoveFacetsFromChannelInput
}

export type MutationRemoveMembersFromZoneArgs = {
  memberIds: Array<Scalars['ID']['input']>
  zoneId: Scalars['ID']['input']
}

export type MutationRemoveOptionGroupFromProductArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>
  optionGroupId: Scalars['ID']['input']
  productId: Scalars['ID']['input']
}

export type MutationRemovePaymentMethodsFromChannelArgs = {
  input: RemovePaymentMethodsFromChannelInput
}

export type MutationRemoveProductVariantsFromChannelArgs = {
  input: RemoveProductVariantsFromChannelInput
}

export type MutationRemoveProductsFromChannelArgs = {
  input: RemoveProductsFromChannelInput
}

export type MutationRemovePromotionsFromChannelArgs = {
  input: RemovePromotionsFromChannelInput
}

export type MutationRemoveSettledJobsArgs = {
  olderThan?: InputMaybe<Scalars['DateTime']['input']>
  queueNames?: InputMaybe<Array<Scalars['String']['input']>>
}

export type MutationRemoveShippingMethodsFromChannelArgs = {
  input: RemoveShippingMethodsFromChannelInput
}

export type MutationRemoveStockLocationsFromChannelArgs = {
  input: RemoveStockLocationsFromChannelInput
}

export type MutationSetCustomerForDraftOrderArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>
  input?: InputMaybe<CreateCustomerInput>
  orderId: Scalars['ID']['input']
}

export type MutationSetDraftOrderBillingAddressArgs = {
  input: CreateAddressInput
  orderId: Scalars['ID']['input']
}

export type MutationSetDraftOrderCustomFieldsArgs = {
  input: UpdateOrderInput
  orderId: Scalars['ID']['input']
}

export type MutationSetDraftOrderShippingAddressArgs = {
  input: CreateAddressInput
  orderId: Scalars['ID']['input']
}

export type MutationSetDraftOrderShippingMethodArgs = {
  orderId: Scalars['ID']['input']
  shippingMethodId: Scalars['ID']['input']
}

export type MutationSetOrderCustomFieldsArgs = {
  input: UpdateOrderInput
}

export type MutationSetOrderCustomerArgs = {
  input: SetOrderCustomerInput
}

export type MutationSettlePaymentArgs = {
  id: Scalars['ID']['input']
}

export type MutationSettleRefundArgs = {
  input: SettleRefundInput
}

export type MutationSubmitLandingContactFormArgs = {
  input: LandingContactFormInput
}

export type MutationTransitionFulfillmentToStateArgs = {
  id: Scalars['ID']['input']
  state: Scalars['String']['input']
}

export type MutationTransitionOrderToStateArgs = {
  id: Scalars['ID']['input']
  state: Scalars['String']['input']
}

export type MutationTransitionPaymentToStateArgs = {
  id: Scalars['ID']['input']
  state: Scalars['String']['input']
}

export type MutationUnsetDraftOrderBillingAddressArgs = {
  orderId: Scalars['ID']['input']
}

export type MutationUnsetDraftOrderShippingAddressArgs = {
  orderId: Scalars['ID']['input']
}

export type MutationUpdateActiveAdministratorArgs = {
  input: UpdateActiveAdministratorInput
}

export type MutationUpdateAdministratorArgs = {
  input: UpdateAdministratorInput
}

export type MutationUpdateAssetArgs = {
  input: UpdateAssetInput
}

export type MutationUpdateChannelArgs = {
  input: UpdateChannelInput
}

export type MutationUpdateChannelPluginConfigurationArgs = {
  channelPluginId: Scalars['ID']['input']
  configuration: Scalars['JSON']['input']
}

export type MutationUpdateCollectionArgs = {
  input: UpdateCollectionInput
}

export type MutationUpdateCountryArgs = {
  input: UpdateCountryInput
}

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput
}

export type MutationUpdateCustomerAddressArgs = {
  input: UpdateAddressInput
}

export type MutationUpdateCustomerGroupArgs = {
  input: UpdateCustomerGroupInput
}

export type MutationUpdateCustomerNoteArgs = {
  input: UpdateCustomerNoteInput
}

export type MutationUpdateFacetArgs = {
  input: UpdateFacetInput
}

export type MutationUpdateFacetValuesArgs = {
  input: Array<UpdateFacetValueInput>
}

export type MutationUpdateGlobalSettingsArgs = {
  input: UpdateGlobalSettingsInput
}

export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput
}

export type MutationUpdateOrderNoteArgs = {
  input: UpdateOrderNoteInput
}

export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput
}

export type MutationUpdateProductArgs = {
  input: UpdateProductInput
}

export type MutationUpdateProductOptionArgs = {
  input: UpdateProductOptionInput
}

export type MutationUpdateProductOptionGroupArgs = {
  input: UpdateProductOptionGroupInput
}

export type MutationUpdateProductVariantsArgs = {
  input: Array<UpdateProductVariantInput>
}

export type MutationUpdateProductsArgs = {
  input: Array<UpdateProductInput>
}

export type MutationUpdatePromotionArgs = {
  input: UpdatePromotionInput
}

export type MutationUpdateProvinceArgs = {
  input: UpdateProvinceInput
}

export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput
}

export type MutationUpdateSellerArgs = {
  input: UpdateSellerInput
}

export type MutationUpdateShippingMethodArgs = {
  input: UpdateShippingMethodInput
}

export type MutationUpdateStockLocationArgs = {
  input: UpdateStockLocationInput
}

export type MutationUpdateTagArgs = {
  input: UpdateTagInput
}

export type MutationUpdateTaxCategoryArgs = {
  input: UpdateTaxCategoryInput
}

export type MutationUpdateTaxRateArgs = {
  input: UpdateTaxRateInput
}

export type MutationUpdateZoneArgs = {
  input: UpdateZoneInput
}

export type MutationVerifyArgs = {
  token?: InputMaybe<Scalars['String']['input']>
}

/** Returned when attempting an operation that relies on the NativeAuthStrategy, if that strategy is not configured. */
export type NativeAuthStrategyError = ErrorResult & {
  __typename?: 'NativeAuthStrategyError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

export type NativeAuthenticationResult =
  | CurrentUser
  | InvalidCredentialsError
  | NativeAuthStrategyError

/** Returned when attempting to set a negative OrderLine quantity. */
export type NegativeQuantityError = ErrorResult & {
  __typename?: 'NegativeQuantityError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

/**
 * Returned when invoking a mutation which depends on there being an active Order on the
 * current session.
 */
export type NoActiveOrderError = ErrorResult & {
  __typename?: 'NoActiveOrderError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

/** Returned when a call to modifyOrder fails to specify any changes */
export type NoChangesSpecifiedError = ErrorResult & {
  __typename?: 'NoChangesSpecifiedError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

export type Node = {
  id: Scalars['ID']['output']
}

/** Returned if an attempting to refund an Order but neither items nor shipping refund was specified */
export type NothingToRefundError = ErrorResult & {
  __typename?: 'NothingToRefundError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

/** Operators for filtering on a list of Number fields */
export type NumberListOperators = {
  inList: Scalars['Float']['input']
}

/** Operators for filtering on a Int or Float field */
export type NumberOperators = {
  between?: InputMaybe<NumberRange>
  eq?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  isNull?: InputMaybe<Scalars['Boolean']['input']>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
}

export type NumberRange = {
  end: Scalars['Float']['input']
  start: Scalars['Float']['input']
}

export type Order = Node & {
  __typename?: 'Order'
  /** An order is active as long as the payment process has not been completed */
  active: Scalars['Boolean']['output']
  aggregateOrder?: Maybe<Order>
  aggregateOrderId?: Maybe<Scalars['ID']['output']>
  billingAddress?: Maybe<OrderAddress>
  channels: Array<Channel>
  /** A unique code for the Order */
  code: Scalars['String']['output']
  /** An array of all coupon codes applied to the Order */
  couponCodes: Array<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  currencyCode: CurrencyCode
  customFields?: Maybe<Scalars['JSON']['output']>
  customer?: Maybe<Customer>
  discounts: Array<Discount>
  fulfillments?: Maybe<Array<Fulfillment>>
  history: HistoryEntryList
  id: Scalars['ID']['output']
  lines: Array<OrderLine>
  modifications: Array<OrderModification>
  nextStates: Array<Scalars['String']['output']>
  /**
   * The date & time that the Order was placed, i.e. the Customer
   * completed the checkout and the Order is no longer "active"
   */
  orderPlacedAt?: Maybe<Scalars['DateTime']['output']>
  payments?: Maybe<Array<Payment>>
  /** Promotions applied to the order. Only gets populated after the payment process has completed. */
  promotions: Array<Promotion>
  sellerOrders?: Maybe<Array<Order>>
  shipping: Scalars['Money']['output']
  shippingAddress?: Maybe<OrderAddress>
  shippingLines: Array<ShippingLine>
  shippingWithTax: Scalars['Money']['output']
  state: Scalars['String']['output']
  /**
   * The subTotal is the total of all OrderLines in the Order. This figure also includes any Order-level
   * discounts which have been prorated (proportionally distributed) amongst the items of each OrderLine.
   * To get a total of all OrderLines which does not account for prorated discounts, use the
   * sum of `OrderLine.discountedLinePrice` values.
   */
  subTotal: Scalars['Money']['output']
  /** Same as subTotal, but inclusive of tax */
  subTotalWithTax: Scalars['Money']['output']
  /**
   * Surcharges are arbitrary modifications to the Order total which are neither
   * ProductVariants nor discounts resulting from applied Promotions. For example,
   * one-off discounts based on customer interaction, or surcharges based on payment
   * methods.
   */
  surcharges: Array<Surcharge>
  /** A summary of the taxes being applied to this Order */
  taxSummary: Array<OrderTaxSummary>
  /** Equal to subTotal plus shipping */
  total: Scalars['Money']['output']
  totalQuantity: Scalars['Int']['output']
  /** The final payable amount. Equal to subTotalWithTax plus shippingWithTax */
  totalWithTax: Scalars['Money']['output']
  type: OrderType
  updatedAt: Scalars['DateTime']['output']
}

export type OrderHistoryArgs = {
  options?: InputMaybe<HistoryEntryListOptions>
}

export type OrderAddress = {
  __typename?: 'OrderAddress'
  city?: Maybe<Scalars['String']['output']>
  company?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  countryCode?: Maybe<Scalars['String']['output']>
  customFields?: Maybe<Scalars['JSON']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
  postalCode?: Maybe<Scalars['String']['output']>
  province?: Maybe<Scalars['String']['output']>
  streetLine1?: Maybe<Scalars['String']['output']>
  streetLine2?: Maybe<Scalars['String']['output']>
}

export type OrderFilterParameter = {
  _and?: InputMaybe<Array<OrderFilterParameter>>
  _or?: InputMaybe<Array<OrderFilterParameter>>
  active?: InputMaybe<BooleanOperators>
  aggregateOrderId?: InputMaybe<IdOperators>
  code?: InputMaybe<StringOperators>
  createdAt?: InputMaybe<DateOperators>
  currencyCode?: InputMaybe<StringOperators>
  customerLastName?: InputMaybe<StringOperators>
  id?: InputMaybe<IdOperators>
  orderPlacedAt?: InputMaybe<DateOperators>
  shipping?: InputMaybe<NumberOperators>
  shippingWithTax?: InputMaybe<NumberOperators>
  state?: InputMaybe<StringOperators>
  subTotal?: InputMaybe<NumberOperators>
  subTotalWithTax?: InputMaybe<NumberOperators>
  total?: InputMaybe<NumberOperators>
  totalQuantity?: InputMaybe<NumberOperators>
  totalWithTax?: InputMaybe<NumberOperators>
  transactionId?: InputMaybe<StringOperators>
  type?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

/** Returned when an order operation is rejected by an OrderInterceptor method. */
export type OrderInterceptorError = ErrorResult & {
  __typename?: 'OrderInterceptorError'
  errorCode: ErrorCode
  interceptorError: Scalars['String']['output']
  message: Scalars['String']['output']
}

/** Returned when the maximum order size limit has been reached. */
export type OrderLimitError = ErrorResult & {
  __typename?: 'OrderLimitError'
  errorCode: ErrorCode
  maxItems: Scalars['Int']['output']
  message: Scalars['String']['output']
}

export type OrderLine = Node & {
  __typename?: 'OrderLine'
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  /** The price of the line including discounts, excluding tax */
  discountedLinePrice: Scalars['Money']['output']
  /** The price of the line including discounts and tax */
  discountedLinePriceWithTax: Scalars['Money']['output']
  /**
   * The price of a single unit including discounts, excluding tax.
   *
   * If Order-level discounts have been applied, this will not be the
   * actual taxable unit price (see `proratedUnitPrice`), but is generally the
   * correct price to display to customers to avoid confusion
   * about the internal handling of distributed Order-level discounts.
   */
  discountedUnitPrice: Scalars['Money']['output']
  /** The price of a single unit including discounts and tax */
  discountedUnitPriceWithTax: Scalars['Money']['output']
  discounts: Array<Discount>
  featuredAsset?: Maybe<Asset>
  fulfillmentLines?: Maybe<Array<FulfillmentLine>>
  id: Scalars['ID']['output']
  /** The total price of the line excluding tax and discounts. */
  linePrice: Scalars['Money']['output']
  /** The total price of the line including tax but excluding discounts. */
  linePriceWithTax: Scalars['Money']['output']
  /** The total tax on this line */
  lineTax: Scalars['Money']['output']
  order: Order
  /** The quantity at the time the Order was placed */
  orderPlacedQuantity: Scalars['Int']['output']
  productVariant: ProductVariant
  /**
   * The actual line price, taking into account both item discounts _and_ prorated (proportionally-distributed)
   * Order-level discounts. This value is the true economic value of the OrderLine, and is used in tax
   * and refund calculations.
   */
  proratedLinePrice: Scalars['Money']['output']
  /** The proratedLinePrice including tax */
  proratedLinePriceWithTax: Scalars['Money']['output']
  /**
   * The actual unit price, taking into account both item discounts _and_ prorated (proportionally-distributed)
   * Order-level discounts. This value is the true economic value of the OrderItem, and is used in tax
   * and refund calculations.
   */
  proratedUnitPrice: Scalars['Money']['output']
  /** The proratedUnitPrice including tax */
  proratedUnitPriceWithTax: Scalars['Money']['output']
  /** The quantity of items purchased */
  quantity: Scalars['Int']['output']
  taxLines: Array<TaxLine>
  taxRate: Scalars['Float']['output']
  /** The price of a single unit, excluding tax and discounts */
  unitPrice: Scalars['Money']['output']
  /** Non-zero if the unitPrice has changed since it was initially added to Order */
  unitPriceChangeSinceAdded: Scalars['Money']['output']
  /** The price of a single unit, including tax but excluding discounts */
  unitPriceWithTax: Scalars['Money']['output']
  /** Non-zero if the unitPriceWithTax has changed since it was initially added to Order */
  unitPriceWithTaxChangeSinceAdded: Scalars['Money']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type OrderLineInput = {
  orderLineId: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
}

export type OrderList = PaginatedList & {
  __typename?: 'OrderList'
  items: Array<Order>
  totalItems: Scalars['Int']['output']
}

export type OrderListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<OrderFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<OrderSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type OrderModification = Node & {
  __typename?: 'OrderModification'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isSettled: Scalars['Boolean']['output']
  lines: Array<OrderModificationLine>
  note: Scalars['String']['output']
  payment?: Maybe<Payment>
  priceChange: Scalars['Money']['output']
  refund?: Maybe<Refund>
  surcharges?: Maybe<Array<Surcharge>>
  updatedAt: Scalars['DateTime']['output']
}

/** Returned when attempting to modify the contents of an Order that is not in the `AddingItems` state. */
export type OrderModificationError = ErrorResult & {
  __typename?: 'OrderModificationError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

export type OrderModificationLine = {
  __typename?: 'OrderModificationLine'
  modification: OrderModification
  modificationId: Scalars['ID']['output']
  orderLine: OrderLine
  orderLineId: Scalars['ID']['output']
  quantity: Scalars['Int']['output']
}

/** Returned when attempting to modify the contents of an Order that is not in the `Modifying` state. */
export type OrderModificationStateError = ErrorResult & {
  __typename?: 'OrderModificationStateError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

export type OrderProcessState = {
  __typename?: 'OrderProcessState'
  name: Scalars['String']['output']
  to: Array<Scalars['String']['output']>
}

export type OrderSortParameter = {
  aggregateOrderId?: InputMaybe<SortOrder>
  code?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  customerLastName?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  orderPlacedAt?: InputMaybe<SortOrder>
  shipping?: InputMaybe<SortOrder>
  shippingWithTax?: InputMaybe<SortOrder>
  state?: InputMaybe<SortOrder>
  subTotal?: InputMaybe<SortOrder>
  subTotalWithTax?: InputMaybe<SortOrder>
  total?: InputMaybe<SortOrder>
  totalQuantity?: InputMaybe<SortOrder>
  totalWithTax?: InputMaybe<SortOrder>
  transactionId?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

/** Returned if there is an error in transitioning the Order state */
export type OrderStateTransitionError = ErrorResult & {
  __typename?: 'OrderStateTransitionError'
  errorCode: ErrorCode
  fromState: Scalars['String']['output']
  message: Scalars['String']['output']
  toState: Scalars['String']['output']
  transitionError: Scalars['String']['output']
}

/**
 * A summary of the taxes being applied to this order, grouped
 * by taxRate.
 */
export type OrderTaxSummary = {
  __typename?: 'OrderTaxSummary'
  /** A description of this tax */
  description: Scalars['String']['output']
  /** The total net price of OrderLines to which this taxRate applies */
  taxBase: Scalars['Money']['output']
  /** The taxRate as a percentage */
  taxRate: Scalars['Float']['output']
  /** The total tax being applied to the Order at this taxRate */
  taxTotal: Scalars['Money']['output']
}

export enum OrderType {
  Aggregate = 'Aggregate',
  Regular = 'Regular',
  Seller = 'Seller',
}

export type PaginatedList = {
  items: Array<Node>
  totalItems: Scalars['Int']['output']
}

export type Payment = Node & {
  __typename?: 'Payment'
  amount: Scalars['Money']['output']
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  errorMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  metadata?: Maybe<Scalars['JSON']['output']>
  method: Scalars['String']['output']
  nextStates: Array<Scalars['String']['output']>
  refunds: Array<Refund>
  state: Scalars['String']['output']
  transactionId?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type PaymentMethod = Node & {
  __typename?: 'PaymentMethod'
  checker?: Maybe<ConfigurableOperation>
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  description: Scalars['String']['output']
  enabled: Scalars['Boolean']['output']
  handler: ConfigurableOperation
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  translations: Array<PaymentMethodTranslation>
  updatedAt: Scalars['DateTime']['output']
}

export type PaymentMethodFilterParameter = {
  _and?: InputMaybe<Array<PaymentMethodFilterParameter>>
  _or?: InputMaybe<Array<PaymentMethodFilterParameter>>
  code?: InputMaybe<StringOperators>
  createdAt?: InputMaybe<DateOperators>
  description?: InputMaybe<StringOperators>
  enabled?: InputMaybe<BooleanOperators>
  id?: InputMaybe<IdOperators>
  name?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type PaymentMethodList = PaginatedList & {
  __typename?: 'PaymentMethodList'
  items: Array<PaymentMethod>
  totalItems: Scalars['Int']['output']
}

export type PaymentMethodListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<PaymentMethodFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<PaymentMethodSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

/**
 * Returned when a call to modifyOrder fails to include a paymentMethod even
 * though the price has increased as a result of the changes.
 */
export type PaymentMethodMissingError = ErrorResult & {
  __typename?: 'PaymentMethodMissingError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

export type PaymentMethodQuote = {
  __typename?: 'PaymentMethodQuote'
  code: Scalars['String']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  description: Scalars['String']['output']
  eligibilityMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isEligible: Scalars['Boolean']['output']
  name: Scalars['String']['output']
}

export type PaymentMethodSortParameter = {
  code?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  description?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type PaymentMethodTranslation = {
  __typename?: 'PaymentMethodTranslation'
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type PaymentMethodTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  languageCode: LanguageCode
  name?: InputMaybe<Scalars['String']['input']>
}

/** Returned if an attempting to refund a Payment against OrderLines from a different Order */
export type PaymentOrderMismatchError = ErrorResult & {
  __typename?: 'PaymentOrderMismatchError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

/** Returned when there is an error in transitioning the Payment state */
export type PaymentStateTransitionError = ErrorResult & {
  __typename?: 'PaymentStateTransitionError'
  errorCode: ErrorCode
  fromState: Scalars['String']['output']
  message: Scalars['String']['output']
  toState: Scalars['String']['output']
  transitionError: Scalars['String']['output']
}

/**
 * @description
 * Permissions for administrators and customers. Used to control access to
 * GraphQL resolvers via the {@link Allow} decorator.
 *
 * ## Understanding Permission.Owner
 *
 * `Permission.Owner` is a special permission which is used in some Vendure resolvers to indicate that that resolver should only
 * be accessible to the "owner" of that resource.
 *
 * For example, the Shop API `activeCustomer` query resolver should only return the Customer object for the "owner" of that Customer, i.e.
 * based on the activeUserId of the current session. As a result, the resolver code looks like this:
 *
 * @example
 * ```TypeScript
 * \@Query()
 * \@Allow(Permission.Owner)
 * async activeCustomer(\@Ctx() ctx: RequestContext): Promise<Customer | undefined> {
 *   const userId = ctx.activeUserId;
 *   if (userId) {
 *     return this.customerService.findOneByUserId(ctx, userId);
 *   }
 * }
 * ```
 *
 * Here we can see that the "ownership" must be enforced by custom logic inside the resolver. Since "ownership" cannot be defined generally
 * nor statically encoded at build-time, any resolvers using `Permission.Owner` **must** include logic to enforce that only the owner
 * of the resource has access. If not, then it is the equivalent of using `Permission.Public`.
 *
 *
 * @docsCategory common
 */
export enum Permission {
  /** Authenticated means simply that the user is logged in */
  Authenticated = 'Authenticated',
  /** Grants permission to create Administrator */
  CreateAdministrator = 'CreateAdministrator',
  /** Grants permission to create Asset */
  CreateAsset = 'CreateAsset',
  /** Grants permission to create Products, Facets, Assets, Collections */
  CreateCatalog = 'CreateCatalog',
  /** Grants permission to create Channel */
  CreateChannel = 'CreateChannel',
  /** Grants permission to create Collection */
  CreateCollection = 'CreateCollection',
  /** Grants permission to create Country */
  CreateCountry = 'CreateCountry',
  /** Grants permission to create Customer */
  CreateCustomer = 'CreateCustomer',
  /** Grants permission to create CustomerGroup */
  CreateCustomerGroup = 'CreateCustomerGroup',
  /** Grants permission to create Facet */
  CreateFacet = 'CreateFacet',
  /** Grants permission to create Order */
  CreateOrder = 'CreateOrder',
  /** Grants permission to create PaymentMethod */
  CreatePaymentMethod = 'CreatePaymentMethod',
  /** Grants permission to create Product */
  CreateProduct = 'CreateProduct',
  /** Grants permission to create Promotion */
  CreatePromotion = 'CreatePromotion',
  /** Grants permission to create Seller */
  CreateSeller = 'CreateSeller',
  /** Grants permission to create PaymentMethods, ShippingMethods, TaxCategories, TaxRates, Zones, Countries, System & GlobalSettings */
  CreateSettings = 'CreateSettings',
  /** Grants permission to create ShippingMethod */
  CreateShippingMethod = 'CreateShippingMethod',
  /** Grants permission to create StockLocation */
  CreateStockLocation = 'CreateStockLocation',
  /** Grants permission to create System */
  CreateSystem = 'CreateSystem',
  /** Grants permission to create Tag */
  CreateTag = 'CreateTag',
  /** Grants permission to create TaxCategory */
  CreateTaxCategory = 'CreateTaxCategory',
  /** Grants permission to create TaxRate */
  CreateTaxRate = 'CreateTaxRate',
  /** Grants permission to create Zone */
  CreateZone = 'CreateZone',
  /** Grants permission to delete Administrator */
  DeleteAdministrator = 'DeleteAdministrator',
  /** Grants permission to delete Asset */
  DeleteAsset = 'DeleteAsset',
  /** Grants permission to delete Products, Facets, Assets, Collections */
  DeleteCatalog = 'DeleteCatalog',
  /** Grants permission to delete Channel */
  DeleteChannel = 'DeleteChannel',
  /** Grants permission to delete Collection */
  DeleteCollection = 'DeleteCollection',
  /** Grants permission to delete Country */
  DeleteCountry = 'DeleteCountry',
  /** Grants permission to delete Customer */
  DeleteCustomer = 'DeleteCustomer',
  /** Grants permission to delete CustomerGroup */
  DeleteCustomerGroup = 'DeleteCustomerGroup',
  /** Grants permission to delete Facet */
  DeleteFacet = 'DeleteFacet',
  /** Grants permission to delete Order */
  DeleteOrder = 'DeleteOrder',
  /** Grants permission to delete PaymentMethod */
  DeletePaymentMethod = 'DeletePaymentMethod',
  /** Grants permission to delete Product */
  DeleteProduct = 'DeleteProduct',
  /** Grants permission to delete Promotion */
  DeletePromotion = 'DeletePromotion',
  /** Grants permission to delete Seller */
  DeleteSeller = 'DeleteSeller',
  /** Grants permission to delete PaymentMethods, ShippingMethods, TaxCategories, TaxRates, Zones, Countries, System & GlobalSettings */
  DeleteSettings = 'DeleteSettings',
  /** Grants permission to delete ShippingMethod */
  DeleteShippingMethod = 'DeleteShippingMethod',
  /** Grants permission to delete StockLocation */
  DeleteStockLocation = 'DeleteStockLocation',
  /** Grants permission to delete System */
  DeleteSystem = 'DeleteSystem',
  /** Grants permission to delete Tag */
  DeleteTag = 'DeleteTag',
  /** Grants permission to delete TaxCategory */
  DeleteTaxCategory = 'DeleteTaxCategory',
  /** Grants permission to delete TaxRate */
  DeleteTaxRate = 'DeleteTaxRate',
  /** Grants permission to delete Zone */
  DeleteZone = 'DeleteZone',
  /** Owner means the user owns this entity, e.g. a Customer's own Order */
  Owner = 'Owner',
  /** Public means any unauthenticated user may perform the operation */
  Public = 'Public',
  /** Grants permission to read Administrator */
  ReadAdministrator = 'ReadAdministrator',
  /** Grants permission to read Asset */
  ReadAsset = 'ReadAsset',
  /** Grants permission to read Products, Facets, Assets, Collections */
  ReadCatalog = 'ReadCatalog',
  /** Grants permission to read Channel */
  ReadChannel = 'ReadChannel',
  /** Grants permission to read Collection */
  ReadCollection = 'ReadCollection',
  /** Grants permission to read Country */
  ReadCountry = 'ReadCountry',
  /** Grants permission to read Customer */
  ReadCustomer = 'ReadCustomer',
  /** Grants permission to read CustomerGroup */
  ReadCustomerGroup = 'ReadCustomerGroup',
  /** Grants permission to read Facet */
  ReadFacet = 'ReadFacet',
  /** Grants permission to read Order */
  ReadOrder = 'ReadOrder',
  /** Grants permission to read PaymentMethod */
  ReadPaymentMethod = 'ReadPaymentMethod',
  /** Grants permission to read Product */
  ReadProduct = 'ReadProduct',
  /** Grants permission to read Promotion */
  ReadPromotion = 'ReadPromotion',
  /** Grants permission to read Seller */
  ReadSeller = 'ReadSeller',
  /** Grants permission to read PaymentMethods, ShippingMethods, TaxCategories, TaxRates, Zones, Countries, System & GlobalSettings */
  ReadSettings = 'ReadSettings',
  /** Grants permission to read ShippingMethod */
  ReadShippingMethod = 'ReadShippingMethod',
  /** Grants permission to read StockLocation */
  ReadStockLocation = 'ReadStockLocation',
  /** Grants permission to read System */
  ReadSystem = 'ReadSystem',
  /** Grants permission to read Tag */
  ReadTag = 'ReadTag',
  /** Grants permission to read TaxCategory */
  ReadTaxCategory = 'ReadTaxCategory',
  /** Grants permission to read TaxRate */
  ReadTaxRate = 'ReadTaxRate',
  /** Grants permission to read Zone */
  ReadZone = 'ReadZone',
  /** SuperAdmin has unrestricted access to all operations */
  SuperAdmin = 'SuperAdmin',
  /** Grants permission to update Administrator */
  UpdateAdministrator = 'UpdateAdministrator',
  /** Grants permission to update Asset */
  UpdateAsset = 'UpdateAsset',
  /** Grants permission to update Products, Facets, Assets, Collections */
  UpdateCatalog = 'UpdateCatalog',
  /** Grants permission to update Channel */
  UpdateChannel = 'UpdateChannel',
  /** Grants permission to update Collection */
  UpdateCollection = 'UpdateCollection',
  /** Grants permission to update Country */
  UpdateCountry = 'UpdateCountry',
  /** Grants permission to update Customer */
  UpdateCustomer = 'UpdateCustomer',
  /** Grants permission to update CustomerGroup */
  UpdateCustomerGroup = 'UpdateCustomerGroup',
  /** Grants permission to update Facet */
  UpdateFacet = 'UpdateFacet',
  /** Grants permission to update GlobalSettings */
  UpdateGlobalSettings = 'UpdateGlobalSettings',
  /** Grants permission to update Order */
  UpdateOrder = 'UpdateOrder',
  /** Grants permission to update PaymentMethod */
  UpdatePaymentMethod = 'UpdatePaymentMethod',
  /** Grants permission to update Product */
  UpdateProduct = 'UpdateProduct',
  /** Grants permission to update Promotion */
  UpdatePromotion = 'UpdatePromotion',
  /** Grants permission to update Seller */
  UpdateSeller = 'UpdateSeller',
  /** Grants permission to update PaymentMethods, ShippingMethods, TaxCategories, TaxRates, Zones, Countries, System & GlobalSettings */
  UpdateSettings = 'UpdateSettings',
  /** Grants permission to update ShippingMethod */
  UpdateShippingMethod = 'UpdateShippingMethod',
  /** Grants permission to update StockLocation */
  UpdateStockLocation = 'UpdateStockLocation',
  /** Grants permission to update System */
  UpdateSystem = 'UpdateSystem',
  /** Grants permission to update Tag */
  UpdateTag = 'UpdateTag',
  /** Grants permission to update TaxCategory */
  UpdateTaxCategory = 'UpdateTaxCategory',
  /** Grants permission to update TaxRate */
  UpdateTaxRate = 'UpdateTaxRate',
  /** Grants permission to update Zone */
  UpdateZone = 'UpdateZone',
}

export type PermissionDefinition = {
  __typename?: 'PermissionDefinition'
  assignable: Scalars['Boolean']['output']
  description: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type PreviewCollectionVariantsInput = {
  filters: Array<ConfigurableOperationInput>
  inheritFilters: Scalars['Boolean']['input']
  parentId?: InputMaybe<Scalars['ID']['input']>
}

/** The price range where the result has more than one price */
export type PriceRange = {
  __typename?: 'PriceRange'
  max: Scalars['Money']['output']
  min: Scalars['Money']['output']
}

export type PriceRangeBucket = {
  __typename?: 'PriceRangeBucket'
  count: Scalars['Int']['output']
  to: Scalars['Int']['output']
}

export type PriceRangeInput = {
  max: Scalars['Int']['input']
  min: Scalars['Int']['input']
}

export type Product = Node & {
  __typename?: 'Product'
  assets: Array<Asset>
  channels: Array<Channel>
  collections: Array<Collection>
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<ProductCustomFields>
  description: Scalars['String']['output']
  enabled: Scalars['Boolean']['output']
  facetValues: Array<FacetValue>
  featuredAsset?: Maybe<Asset>
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  optionGroups: Array<ProductOptionGroup>
  slug: Scalars['String']['output']
  translations: Array<ProductTranslation>
  updatedAt: Scalars['DateTime']['output']
  /** Returns a paginated, sortable, filterable list of ProductVariants */
  variantList: ProductVariantList
  /** Returns all ProductVariants */
  variants: Array<ProductVariant>
}

export type ProductVariantListArgs = {
  options?: InputMaybe<ProductVariantListOptions>
}

export type ProductCustomFields = {
  __typename?: 'ProductCustomFields'
  bestSeller?: Maybe<Scalars['Boolean']['output']>
  contentSectionAlpha?: Maybe<Array<Scalars['String']['output']>>
  contentSectionAlphaTitle?: Maybe<Scalars['String']['output']>
  discountPercentage?: Maybe<Scalars['Float']['output']>
  dlc?: Maybe<Array<Product>>
  editionSlogan?: Maybe<Scalars['String']['output']>
  externalVideoURL?: Maybe<Array<Scalars['String']['output']>>
  faqSectionAlpha?: Maybe<Array<Scalars['String']['output']>>
  features?: Maybe<Array<Scalars['String']['output']>>
  mainAsset?: Maybe<Asset>
  new?: Maybe<Scalars['Boolean']['output']>
  primaryColor?: Maybe<Scalars['String']['output']>
  releaseDate?: Maybe<Scalars['String']['output']>
  secondaryColor?: Maybe<Scalars['String']['output']>
  slogan?: Maybe<Scalars['String']['output']>
  videoAsset?: Maybe<Asset>
}

export type ProductFilterParameter = {
  _and?: InputMaybe<Array<ProductFilterParameter>>
  _or?: InputMaybe<Array<ProductFilterParameter>>
  bestSeller?: InputMaybe<BooleanOperators>
  contentSectionAlpha?: InputMaybe<StringListOperators>
  contentSectionAlphaTitle?: InputMaybe<StringOperators>
  createdAt?: InputMaybe<DateOperators>
  description?: InputMaybe<StringOperators>
  discountPercentage?: InputMaybe<NumberOperators>
  editionSlogan?: InputMaybe<StringOperators>
  enabled?: InputMaybe<BooleanOperators>
  externalVideoURL?: InputMaybe<StringListOperators>
  facetValueId?: InputMaybe<IdOperators>
  faqSectionAlpha?: InputMaybe<StringListOperators>
  features?: InputMaybe<StringListOperators>
  id?: InputMaybe<IdOperators>
  languageCode?: InputMaybe<StringOperators>
  name?: InputMaybe<StringOperators>
  new?: InputMaybe<BooleanOperators>
  primaryColor?: InputMaybe<StringOperators>
  releaseDate?: InputMaybe<StringOperators>
  secondaryColor?: InputMaybe<StringOperators>
  sku?: InputMaybe<StringOperators>
  slogan?: InputMaybe<StringOperators>
  slug?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type ProductList = PaginatedList & {
  __typename?: 'ProductList'
  items: Array<Product>
  totalItems: Scalars['Int']['output']
}

export type ProductListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ProductFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ProductSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type ProductOption = Node & {
  __typename?: 'ProductOption'
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  group: ProductOptionGroup
  groupId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  translations: Array<ProductOptionTranslation>
  updatedAt: Scalars['DateTime']['output']
}

export type ProductOptionGroup = Node & {
  __typename?: 'ProductOptionGroup'
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  options: Array<ProductOption>
  translations: Array<ProductOptionGroupTranslation>
  updatedAt: Scalars['DateTime']['output']
}

export type ProductOptionGroupTranslation = {
  __typename?: 'ProductOptionGroupTranslation'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ProductOptionGroupTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  languageCode: LanguageCode
  name?: InputMaybe<Scalars['String']['input']>
}

export type ProductOptionInUseError = ErrorResult & {
  __typename?: 'ProductOptionInUseError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
  optionGroupCode: Scalars['String']['output']
  productVariantCount: Scalars['Int']['output']
}

export type ProductOptionTranslation = {
  __typename?: 'ProductOptionTranslation'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ProductOptionTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  languageCode: LanguageCode
  name?: InputMaybe<Scalars['String']['input']>
}

export type ProductSortParameter = {
  bestSeller?: InputMaybe<SortOrder>
  contentSectionAlphaTitle?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  description?: InputMaybe<SortOrder>
  discountPercentage?: InputMaybe<SortOrder>
  editionSlogan?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  mainAsset?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  new?: InputMaybe<SortOrder>
  primaryColor?: InputMaybe<SortOrder>
  releaseDate?: InputMaybe<SortOrder>
  secondaryColor?: InputMaybe<SortOrder>
  slogan?: InputMaybe<SortOrder>
  slug?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
  videoAsset?: InputMaybe<SortOrder>
}

export type ProductTranslation = {
  __typename?: 'ProductTranslation'
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<ProductTranslationCustomFields>
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ProductTranslationCustomFields = {
  __typename?: 'ProductTranslationCustomFields'
  contentSectionAlpha?: Maybe<Array<Scalars['String']['output']>>
  contentSectionAlphaTitle?: Maybe<Scalars['String']['output']>
  editionSlogan?: Maybe<Scalars['String']['output']>
  externalVideoURL?: Maybe<Array<Scalars['String']['output']>>
  faqSectionAlpha?: Maybe<Array<Scalars['String']['output']>>
  features?: Maybe<Array<Scalars['String']['output']>>
  releaseDate?: Maybe<Scalars['String']['output']>
  slogan?: Maybe<Scalars['String']['output']>
}

export type ProductTranslationInput = {
  customFields?: InputMaybe<ProductTranslationInputCustomFields>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  languageCode: LanguageCode
  name?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type ProductTranslationInputCustomFields = {
  contentSectionAlpha?: InputMaybe<Array<Scalars['String']['input']>>
  contentSectionAlphaTitle?: InputMaybe<Scalars['String']['input']>
  editionSlogan?: InputMaybe<Scalars['String']['input']>
  externalVideoURL?: InputMaybe<Array<Scalars['String']['input']>>
  faqSectionAlpha?: InputMaybe<Array<Scalars['String']['input']>>
  features?: InputMaybe<Array<Scalars['String']['input']>>
  releaseDate?: InputMaybe<Scalars['String']['input']>
  slogan?: InputMaybe<Scalars['String']['input']>
}

export type ProductVariant = Node & {
  __typename?: 'ProductVariant'
  assets: Array<Asset>
  channels: Array<Channel>
  createdAt: Scalars['DateTime']['output']
  currencyCode: CurrencyCode
  customFields?: Maybe<ProductVariantCustomFields>
  enabled: Scalars['Boolean']['output']
  facetValues: Array<FacetValue>
  featuredAsset?: Maybe<Asset>
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  options: Array<ProductOption>
  outOfStockThreshold: Scalars['Int']['output']
  price: Scalars['Money']['output']
  priceWithTax: Scalars['Money']['output']
  prices: Array<ProductVariantPrice>
  product: Product
  productId: Scalars['ID']['output']
  sku: Scalars['String']['output']
  /** @deprecated use stockLevels */
  stockAllocated: Scalars['Int']['output']
  stockLevel: Scalars['String']['output']
  stockLevels: Array<StockLevel>
  stockMovements: StockMovementList
  /** @deprecated use stockLevels */
  stockOnHand: Scalars['Int']['output']
  taxCategory: TaxCategory
  taxRateApplied: TaxRate
  trackInventory: GlobalFlag
  translations: Array<ProductVariantTranslation>
  updatedAt: Scalars['DateTime']['output']
  useGlobalOutOfStockThreshold: Scalars['Boolean']['output']
}

export type ProductVariantStockMovementsArgs = {
  options?: InputMaybe<StockMovementListOptions>
}

export type ProductVariantCustomFields = {
  __typename?: 'ProductVariantCustomFields'
  content?: Maybe<Scalars['String']['output']>
  discountPercentage?: Maybe<Scalars['Float']['output']>
  features?: Maybe<Array<Scalars['String']['output']>>
  g2aId?: Maybe<Scalars['String']['output']>
  k4gId?: Maybe<Scalars['String']['output']>
  kinguinId?: Maybe<Scalars['String']['output']>
  mainAsset?: Maybe<Asset>
  steamAppId?: Maybe<Scalars['String']['output']>
  videoAsset?: Maybe<Asset>
}

export type ProductVariantFilterParameter = {
  _and?: InputMaybe<Array<ProductVariantFilterParameter>>
  _or?: InputMaybe<Array<ProductVariantFilterParameter>>
  content?: InputMaybe<StringOperators>
  createdAt?: InputMaybe<DateOperators>
  currencyCode?: InputMaybe<StringOperators>
  discountPercentage?: InputMaybe<NumberOperators>
  enabled?: InputMaybe<BooleanOperators>
  facetValueId?: InputMaybe<IdOperators>
  features?: InputMaybe<StringListOperators>
  g2aId?: InputMaybe<StringOperators>
  id?: InputMaybe<IdOperators>
  k4gId?: InputMaybe<StringOperators>
  kinguinId?: InputMaybe<StringOperators>
  languageCode?: InputMaybe<StringOperators>
  name?: InputMaybe<StringOperators>
  outOfStockThreshold?: InputMaybe<NumberOperators>
  price?: InputMaybe<NumberOperators>
  priceWithTax?: InputMaybe<NumberOperators>
  productId?: InputMaybe<IdOperators>
  sku?: InputMaybe<StringOperators>
  steamAppId?: InputMaybe<StringOperators>
  stockAllocated?: InputMaybe<NumberOperators>
  stockLevel?: InputMaybe<StringOperators>
  stockOnHand?: InputMaybe<NumberOperators>
  trackInventory?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
  useGlobalOutOfStockThreshold?: InputMaybe<BooleanOperators>
}

export type ProductVariantList = PaginatedList & {
  __typename?: 'ProductVariantList'
  items: Array<ProductVariant>
  totalItems: Scalars['Int']['output']
}

export type ProductVariantListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ProductVariantFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ProductVariantSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type ProductVariantPrice = {
  __typename?: 'ProductVariantPrice'
  currencyCode: CurrencyCode
  customFields?: Maybe<Scalars['JSON']['output']>
  price: Scalars['Money']['output']
}

/**
 * Used to set up update the price of a ProductVariant in a particular Channel.
 * If the `delete` flag is `true`, the price will be deleted for the given Channel.
 */
export type ProductVariantPriceInput = {
  currencyCode: CurrencyCode
  delete?: InputMaybe<Scalars['Boolean']['input']>
  price: Scalars['Money']['input']
}

export type ProductVariantSortParameter = {
  content?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  discountPercentage?: InputMaybe<SortOrder>
  g2aId?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  k4gId?: InputMaybe<SortOrder>
  kinguinId?: InputMaybe<SortOrder>
  mainAsset?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  outOfStockThreshold?: InputMaybe<SortOrder>
  price?: InputMaybe<SortOrder>
  priceWithTax?: InputMaybe<SortOrder>
  productId?: InputMaybe<SortOrder>
  sku?: InputMaybe<SortOrder>
  steamAppId?: InputMaybe<SortOrder>
  stockAllocated?: InputMaybe<SortOrder>
  stockLevel?: InputMaybe<SortOrder>
  stockOnHand?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
  videoAsset?: InputMaybe<SortOrder>
}

export type ProductVariantTranslation = {
  __typename?: 'ProductVariantTranslation'
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<ProductVariantTranslationCustomFields>
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ProductVariantTranslationCustomFields = {
  __typename?: 'ProductVariantTranslationCustomFields'
  content?: Maybe<Scalars['String']['output']>
  features?: Maybe<Array<Scalars['String']['output']>>
}

export type ProductVariantTranslationInput = {
  customFields?: InputMaybe<ProductVariantTranslationInputCustomFields>
  id?: InputMaybe<Scalars['ID']['input']>
  languageCode: LanguageCode
  name?: InputMaybe<Scalars['String']['input']>
}

export type ProductVariantTranslationInputCustomFields = {
  content?: InputMaybe<Scalars['String']['input']>
  features?: InputMaybe<Array<Scalars['String']['input']>>
}

export type Promotion = Node & {
  __typename?: 'Promotion'
  actions: Array<ConfigurableOperation>
  conditions: Array<ConfigurableOperation>
  couponCode?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  description: Scalars['String']['output']
  enabled: Scalars['Boolean']['output']
  endsAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  perCustomerUsageLimit?: Maybe<Scalars['Int']['output']>
  startsAt?: Maybe<Scalars['DateTime']['output']>
  translations: Array<PromotionTranslation>
  updatedAt: Scalars['DateTime']['output']
  usageLimit?: Maybe<Scalars['Int']['output']>
}

export type PromotionFilterParameter = {
  _and?: InputMaybe<Array<PromotionFilterParameter>>
  _or?: InputMaybe<Array<PromotionFilterParameter>>
  couponCode?: InputMaybe<StringOperators>
  createdAt?: InputMaybe<DateOperators>
  description?: InputMaybe<StringOperators>
  enabled?: InputMaybe<BooleanOperators>
  endsAt?: InputMaybe<DateOperators>
  id?: InputMaybe<IdOperators>
  name?: InputMaybe<StringOperators>
  perCustomerUsageLimit?: InputMaybe<NumberOperators>
  startsAt?: InputMaybe<DateOperators>
  updatedAt?: InputMaybe<DateOperators>
  usageLimit?: InputMaybe<NumberOperators>
}

export type PromotionList = PaginatedList & {
  __typename?: 'PromotionList'
  items: Array<Promotion>
  totalItems: Scalars['Int']['output']
}

export type PromotionListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<PromotionFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<PromotionSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type PromotionSortParameter = {
  couponCode?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  description?: InputMaybe<SortOrder>
  endsAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  perCustomerUsageLimit?: InputMaybe<SortOrder>
  startsAt?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
  usageLimit?: InputMaybe<SortOrder>
}

export type PromotionTranslation = {
  __typename?: 'PromotionTranslation'
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type PromotionTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  languageCode: LanguageCode
  name?: InputMaybe<Scalars['String']['input']>
}

export type Province = Node &
  Region & {
    __typename?: 'Province'
    code: Scalars['String']['output']
    createdAt: Scalars['DateTime']['output']
    customFields?: Maybe<Scalars['JSON']['output']>
    enabled: Scalars['Boolean']['output']
    id: Scalars['ID']['output']
    languageCode: LanguageCode
    name: Scalars['String']['output']
    parent?: Maybe<Region>
    parentId?: Maybe<Scalars['ID']['output']>
    translations: Array<RegionTranslation>
    type: Scalars['String']['output']
    updatedAt: Scalars['DateTime']['output']
  }

export type ProvinceFilterParameter = {
  _and?: InputMaybe<Array<ProvinceFilterParameter>>
  _or?: InputMaybe<Array<ProvinceFilterParameter>>
  code?: InputMaybe<StringOperators>
  createdAt?: InputMaybe<DateOperators>
  enabled?: InputMaybe<BooleanOperators>
  id?: InputMaybe<IdOperators>
  languageCode?: InputMaybe<StringOperators>
  name?: InputMaybe<StringOperators>
  parentId?: InputMaybe<IdOperators>
  type?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type ProvinceList = PaginatedList & {
  __typename?: 'ProvinceList'
  items: Array<Province>
  totalItems: Scalars['Int']['output']
}

export type ProvinceListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ProvinceFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ProvinceSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type ProvinceSortParameter = {
  code?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  parentId?: InputMaybe<SortOrder>
  type?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type ProvinceTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  languageCode: LanguageCode
  name?: InputMaybe<Scalars['String']['input']>
}

/** Returned if the specified quantity of an OrderLine is greater than the number of items in that line */
export type QuantityTooGreatError = ErrorResult & {
  __typename?: 'QuantityTooGreatError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

export type Query = {
  __typename?: 'Query'
  activeAdministrator?: Maybe<Administrator>
  activeChannel: Channel
  administrator?: Maybe<Administrator>
  administrators: AdministratorList
  /** Get a single Asset by id */
  asset?: Maybe<Asset>
  /** Get a list of Assets */
  assets: AssetList
  channel?: Maybe<Channel>
  channels: ChannelList
  /** Get a Collection either by id or slug. If neither id nor slug is specified, an error will result. */
  collection?: Maybe<Collection>
  collectionFilters: Array<ConfigurableOperationDefinition>
  collections: CollectionList
  countries: CountryList
  country?: Maybe<Country>
  customer?: Maybe<Customer>
  customerGroup?: Maybe<CustomerGroup>
  customerGroups: CustomerGroupList
  customers: CustomerList
  /** Returns a list of eligible shipping methods for the draft Order */
  eligibleShippingMethodsForDraftOrder: Array<ShippingMethodQuote>
  /** Returns all configured EntityDuplicators. */
  entityDuplicators: Array<EntityDuplicatorDefinition>
  facet?: Maybe<Facet>
  facetValues: FacetValueList
  facets: FacetList
  fulfillmentHandlers: Array<ConfigurableOperationDefinition>
  getAll: Array<AcfFieldDefinitionUnion>
  getBooleanValue?: Maybe<BooleanValue>
  getDateValue?: Maybe<DateValue>
  getGroupValue?: Maybe<GroupValue>
  getListGroupValue?: Maybe<ListGroupValue>
  getListRelationValue?: Maybe<ListRelationValue>
  getListValue?: Maybe<ListValue>
  getStringValue?: Maybe<StringValue>
  globalSettings: GlobalSettings
  job?: Maybe<Job>
  jobBufferSize: Array<JobBufferSize>
  jobQueues: Array<JobQueue>
  jobs: JobList
  jobsById: Array<Job>
  listChannelPlugins: Array<ChannelPlugin>
  listManageablePlugins: Array<ManageablePlugin>
  me?: Maybe<CurrentUser>
  /** Get metrics for the given interval and metric types. */
  metricSummary: Array<MetricSummary>
  order?: Maybe<Order>
  orders: OrderList
  paymentMethod?: Maybe<PaymentMethod>
  paymentMethodEligibilityCheckers: Array<ConfigurableOperationDefinition>
  paymentMethodHandlers: Array<ConfigurableOperationDefinition>
  paymentMethods: PaymentMethodList
  pendingSearchIndexUpdates: Scalars['Int']['output']
  /** Used for real-time previews of the contents of a Collection */
  previewCollectionVariants: ProductVariantList
  /** Get a Product either by id or slug. If neither id nor slug is specified, an error will result. */
  product?: Maybe<Product>
  productOptionGroup?: Maybe<ProductOptionGroup>
  productOptionGroups: Array<ProductOptionGroup>
  /** Get a ProductVariant by id */
  productVariant?: Maybe<ProductVariant>
  /** List ProductVariants either all or for the specific product. */
  productVariants: ProductVariantList
  /** List Products */
  products: ProductList
  promotion?: Maybe<Promotion>
  promotionActions: Array<ConfigurableOperationDefinition>
  promotionConditions: Array<ConfigurableOperationDefinition>
  promotions: PromotionList
  province?: Maybe<Province>
  provinces: ProvinceList
  role?: Maybe<Role>
  roles: RoleList
  search: SearchResponse
  seller?: Maybe<Seller>
  sellers: SellerList
  shippingCalculators: Array<ConfigurableOperationDefinition>
  shippingEligibilityCheckers: Array<ConfigurableOperationDefinition>
  shippingMethod?: Maybe<ShippingMethod>
  shippingMethods: ShippingMethodList
  stockLocation?: Maybe<StockLocation>
  stockLocations: StockLocationList
  tag: Tag
  tags: TagList
  taxCategories: TaxCategoryList
  taxCategory?: Maybe<TaxCategory>
  taxRate?: Maybe<TaxRate>
  taxRates: TaxRateList
  testEligibleShippingMethods: Array<ShippingMethodQuote>
  testShippingMethod: TestShippingMethodResult
  zone?: Maybe<Zone>
  zones: ZoneList
}

export type QueryAdministratorArgs = {
  id: Scalars['ID']['input']
}

export type QueryAdministratorsArgs = {
  options?: InputMaybe<AdministratorListOptions>
}

export type QueryAssetArgs = {
  id: Scalars['ID']['input']
}

export type QueryAssetsArgs = {
  options?: InputMaybe<AssetListOptions>
}

export type QueryChannelArgs = {
  id: Scalars['ID']['input']
}

export type QueryChannelsArgs = {
  options?: InputMaybe<ChannelListOptions>
}

export type QueryCollectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type QueryCollectionsArgs = {
  options?: InputMaybe<CollectionListOptions>
}

export type QueryCountriesArgs = {
  options?: InputMaybe<CountryListOptions>
}

export type QueryCountryArgs = {
  id: Scalars['ID']['input']
}

export type QueryCustomerArgs = {
  id: Scalars['ID']['input']
}

export type QueryCustomerGroupArgs = {
  id: Scalars['ID']['input']
}

export type QueryCustomerGroupsArgs = {
  options?: InputMaybe<CustomerGroupListOptions>
}

export type QueryCustomersArgs = {
  options?: InputMaybe<CustomerListOptions>
}

export type QueryEligibleShippingMethodsForDraftOrderArgs = {
  orderId: Scalars['ID']['input']
}

export type QueryFacetArgs = {
  id: Scalars['ID']['input']
}

export type QueryFacetValuesArgs = {
  options?: InputMaybe<FacetValueListOptions>
}

export type QueryFacetsArgs = {
  options?: InputMaybe<FacetListOptions>
}

export type QueryGetAllArgs = {
  input: GetAllInput
}

export type QueryGetBooleanValueArgs = {
  input: GetBooleanValueInput
}

export type QueryGetDateValueArgs = {
  input: GetDateValueInput
}

export type QueryGetGroupValueArgs = {
  input: GetGroupValueInput
}

export type QueryGetListGroupValueArgs = {
  input: GetListGroupValueInput
}

export type QueryGetListRelationValueArgs = {
  input: GetListRelationValueInput
}

export type QueryGetListValueArgs = {
  input: GetListValueInput
}

export type QueryGetStringValueArgs = {
  input: GetStringValueInput
}

export type QueryJobArgs = {
  jobId: Scalars['ID']['input']
}

export type QueryJobBufferSizeArgs = {
  bufferIds?: InputMaybe<Array<Scalars['String']['input']>>
}

export type QueryJobsArgs = {
  options?: InputMaybe<JobListOptions>
}

export type QueryJobsByIdArgs = {
  jobIds: Array<Scalars['ID']['input']>
}

export type QueryMetricSummaryArgs = {
  input?: InputMaybe<MetricSummaryInput>
}

export type QueryOrderArgs = {
  id: Scalars['ID']['input']
}

export type QueryOrdersArgs = {
  options?: InputMaybe<OrderListOptions>
}

export type QueryPaymentMethodArgs = {
  id: Scalars['ID']['input']
}

export type QueryPaymentMethodsArgs = {
  options?: InputMaybe<PaymentMethodListOptions>
}

export type QueryPreviewCollectionVariantsArgs = {
  input: PreviewCollectionVariantsInput
  options?: InputMaybe<ProductVariantListOptions>
}

export type QueryProductArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type QueryProductOptionGroupArgs = {
  id: Scalars['ID']['input']
}

export type QueryProductOptionGroupsArgs = {
  filterTerm?: InputMaybe<Scalars['String']['input']>
}

export type QueryProductVariantArgs = {
  id: Scalars['ID']['input']
}

export type QueryProductVariantsArgs = {
  options?: InputMaybe<ProductVariantListOptions>
  productId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryProductsArgs = {
  options?: InputMaybe<ProductListOptions>
}

export type QueryPromotionArgs = {
  id: Scalars['ID']['input']
}

export type QueryPromotionsArgs = {
  options?: InputMaybe<PromotionListOptions>
}

export type QueryProvinceArgs = {
  id: Scalars['ID']['input']
}

export type QueryProvincesArgs = {
  options?: InputMaybe<ProvinceListOptions>
}

export type QueryRoleArgs = {
  id: Scalars['ID']['input']
}

export type QueryRolesArgs = {
  options?: InputMaybe<RoleListOptions>
}

export type QuerySearchArgs = {
  input: SearchInput
}

export type QuerySellerArgs = {
  id: Scalars['ID']['input']
}

export type QuerySellersArgs = {
  options?: InputMaybe<SellerListOptions>
}

export type QueryShippingMethodArgs = {
  id: Scalars['ID']['input']
}

export type QueryShippingMethodsArgs = {
  options?: InputMaybe<ShippingMethodListOptions>
}

export type QueryStockLocationArgs = {
  id: Scalars['ID']['input']
}

export type QueryStockLocationsArgs = {
  options?: InputMaybe<StockLocationListOptions>
}

export type QueryTagArgs = {
  id: Scalars['ID']['input']
}

export type QueryTagsArgs = {
  options?: InputMaybe<TagListOptions>
}

export type QueryTaxCategoriesArgs = {
  options?: InputMaybe<TaxCategoryListOptions>
}

export type QueryTaxCategoryArgs = {
  id: Scalars['ID']['input']
}

export type QueryTaxRateArgs = {
  id: Scalars['ID']['input']
}

export type QueryTaxRatesArgs = {
  options?: InputMaybe<TaxRateListOptions>
}

export type QueryTestEligibleShippingMethodsArgs = {
  input: TestEligibleShippingMethodsInput
}

export type QueryTestShippingMethodArgs = {
  input: TestShippingMethodInput
}

export type QueryZoneArgs = {
  id: Scalars['ID']['input']
}

export type QueryZonesArgs = {
  options?: InputMaybe<ZoneListOptions>
}

export type Refund = Node & {
  __typename?: 'Refund'
  adjustment: Scalars['Money']['output']
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  id: Scalars['ID']['output']
  items: Scalars['Money']['output']
  lines: Array<RefundLine>
  metadata?: Maybe<Scalars['JSON']['output']>
  method?: Maybe<Scalars['String']['output']>
  paymentId: Scalars['ID']['output']
  reason?: Maybe<Scalars['String']['output']>
  shipping: Scalars['Money']['output']
  state: Scalars['String']['output']
  total: Scalars['Money']['output']
  transactionId?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

/** Returned if `amount` is greater than the maximum un-refunded amount of the Payment */
export type RefundAmountError = ErrorResult & {
  __typename?: 'RefundAmountError'
  errorCode: ErrorCode
  maximumRefundable: Scalars['Int']['output']
  message: Scalars['String']['output']
}

export type RefundLine = {
  __typename?: 'RefundLine'
  orderLine: OrderLine
  orderLineId: Scalars['ID']['output']
  quantity: Scalars['Int']['output']
  refund: Refund
  refundId: Scalars['ID']['output']
}

export type RefundOrderInput = {
  /**
   * The amount to be refunded to this particular payment. This was introduced in v2.2.0 as the preferred way to specify the refund amount.
   * Can be as much as the total amount of the payment minus the sum of all previous refunds.
   */
  amount?: InputMaybe<Scalars['Money']['input']>
  paymentId: Scalars['ID']['input']
  reason?: InputMaybe<Scalars['String']['input']>
}

export type RefundOrderResult =
  | AlreadyRefundedError
  | MultipleOrderError
  | NothingToRefundError
  | OrderStateTransitionError
  | PaymentOrderMismatchError
  | QuantityTooGreatError
  | Refund
  | RefundAmountError
  | RefundOrderStateError
  | RefundStateTransitionError

/** Returned if an attempting to refund an Order which is not in the expected state */
export type RefundOrderStateError = ErrorResult & {
  __typename?: 'RefundOrderStateError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
  orderState: Scalars['String']['output']
}

/**
 * Returned when a call to modifyOrder fails to include a refundPaymentId even
 * though the price has decreased as a result of the changes.
 */
export type RefundPaymentIdMissingError = ErrorResult & {
  __typename?: 'RefundPaymentIdMissingError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
}

/** Returned when there is an error in transitioning the Refund state */
export type RefundStateTransitionError = ErrorResult & {
  __typename?: 'RefundStateTransitionError'
  errorCode: ErrorCode
  fromState: Scalars['String']['output']
  message: Scalars['String']['output']
  toState: Scalars['String']['output']
  transitionError: Scalars['String']['output']
}

export type Region = {
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  enabled: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  parent?: Maybe<Region>
  parentId?: Maybe<Scalars['ID']['output']>
  translations: Array<RegionTranslation>
  type: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type RegionTranslation = {
  __typename?: 'RegionTranslation'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type RegisterSellerResponse = {
  __typename?: 'RegisterSellerResponse'
  result: Scalars['Boolean']['output']
}

export type RelationCustomFieldConfig = CustomField & {
  __typename?: 'RelationCustomFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  entity: Scalars['String']['output']
  internal?: Maybe<Scalars['Boolean']['output']>
  label?: Maybe<Array<LocalizedString>>
  list: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  nullable?: Maybe<Scalars['Boolean']['output']>
  readonly?: Maybe<Scalars['Boolean']['output']>
  requiresPermission?: Maybe<Array<Permission>>
  scalarFields: Array<Scalars['String']['output']>
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type Release = Node &
  StockMovement & {
    __typename?: 'Release'
    createdAt: Scalars['DateTime']['output']
    customFields?: Maybe<Scalars['JSON']['output']>
    id: Scalars['ID']['output']
    productVariant: ProductVariant
    quantity: Scalars['Int']['output']
    type: StockMovementType
    updatedAt: Scalars['DateTime']['output']
  }

export type RemoveCollectionsFromChannelInput = {
  channelId: Scalars['ID']['input']
  collectionIds: Array<Scalars['ID']['input']>
}

export type RemoveFacetFromChannelResult = Facet | FacetInUseError

export type RemoveFacetsFromChannelInput = {
  channelId: Scalars['ID']['input']
  facetIds: Array<Scalars['ID']['input']>
  force?: InputMaybe<Scalars['Boolean']['input']>
}

export type RemoveOptionGroupFromProductResult =
  | Product
  | ProductOptionInUseError

export type RemoveOrderItemsResult =
  | Order
  | OrderInterceptorError
  | OrderModificationError

export type RemovePaymentMethodsFromChannelInput = {
  channelId: Scalars['ID']['input']
  paymentMethodIds: Array<Scalars['ID']['input']>
}

export type RemoveProductVariantsFromChannelInput = {
  channelId: Scalars['ID']['input']
  productVariantIds: Array<Scalars['ID']['input']>
}

export type RemoveProductsFromChannelInput = {
  channelId: Scalars['ID']['input']
  productIds: Array<Scalars['ID']['input']>
}

export type RemovePromotionsFromChannelInput = {
  channelId: Scalars['ID']['input']
  promotionIds: Array<Scalars['ID']['input']>
}

export type RemoveShippingMethodsFromChannelInput = {
  channelId: Scalars['ID']['input']
  shippingMethodIds: Array<Scalars['ID']['input']>
}

export type RemoveStockLocationsFromChannelInput = {
  channelId: Scalars['ID']['input']
  stockLocationIds: Array<Scalars['ID']['input']>
}

export type Return = Node &
  StockMovement & {
    __typename?: 'Return'
    createdAt: Scalars['DateTime']['output']
    customFields?: Maybe<Scalars['JSON']['output']>
    id: Scalars['ID']['output']
    productVariant: ProductVariant
    quantity: Scalars['Int']['output']
    type: StockMovementType
    updatedAt: Scalars['DateTime']['output']
  }

export type Role = Node & {
  __typename?: 'Role'
  channels: Array<Channel>
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  permissions: Array<Permission>
  updatedAt: Scalars['DateTime']['output']
}

export type RoleFilterParameter = {
  _and?: InputMaybe<Array<RoleFilterParameter>>
  _or?: InputMaybe<Array<RoleFilterParameter>>
  code?: InputMaybe<StringOperators>
  createdAt?: InputMaybe<DateOperators>
  description?: InputMaybe<StringOperators>
  id?: InputMaybe<IdOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type RoleList = PaginatedList & {
  __typename?: 'RoleList'
  items: Array<Role>
  totalItems: Scalars['Int']['output']
}

export type RoleListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RoleFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RoleSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type RoleSortParameter = {
  code?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  description?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type Sale = Node &
  StockMovement & {
    __typename?: 'Sale'
    createdAt: Scalars['DateTime']['output']
    customFields?: Maybe<Scalars['JSON']['output']>
    id: Scalars['ID']['output']
    productVariant: ProductVariant
    quantity: Scalars['Int']['output']
    type: StockMovementType
    updatedAt: Scalars['DateTime']['output']
  }

export type SearchInput = {
  collectionId?: InputMaybe<Scalars['ID']['input']>
  collectionSlug?: InputMaybe<Scalars['String']['input']>
  facetValueFilters?: InputMaybe<Array<FacetValueFilterInput>>
  groupByProduct?: InputMaybe<Scalars['Boolean']['input']>
  inStock?: InputMaybe<Scalars['Boolean']['input']>
  priceRange?: InputMaybe<PriceRangeInput>
  priceRangeWithTax?: InputMaybe<PriceRangeInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<SearchResultSortParameter>
  take?: InputMaybe<Scalars['Int']['input']>
  term?: InputMaybe<Scalars['String']['input']>
}

export type SearchReindexResponse = {
  __typename?: 'SearchReindexResponse'
  success: Scalars['Boolean']['output']
}

export type SearchResponse = {
  __typename?: 'SearchResponse'
  collections: Array<CollectionResult>
  facetValues: Array<FacetValueResult>
  items: Array<SearchResult>
  prices: SearchResponsePriceData
  totalItems: Scalars['Int']['output']
}

export type SearchResponsePriceData = {
  __typename?: 'SearchResponsePriceData'
  buckets: Array<PriceRangeBucket>
  bucketsWithTax: Array<PriceRangeBucket>
  range: PriceRange
  rangeWithTax: PriceRange
}

export type SearchResult = {
  __typename?: 'SearchResult'
  /** An array of ids of the Channels in which this result appears */
  channelIds: Array<Scalars['ID']['output']>
  /** An array of ids of the Collections in which this result appears */
  collectionIds: Array<Scalars['ID']['output']>
  currencyCode: CurrencyCode
  description: Scalars['String']['output']
  enabled: Scalars['Boolean']['output']
  facetIds: Array<Scalars['ID']['output']>
  facetValueIds: Array<Scalars['ID']['output']>
  inStock?: Maybe<Scalars['Boolean']['output']>
  price: SearchResultPrice
  priceWithTax: SearchResultPrice
  productAsset?: Maybe<SearchResultAsset>
  productId: Scalars['ID']['output']
  productName: Scalars['String']['output']
  productVariantAsset?: Maybe<SearchResultAsset>
  productVariantId: Scalars['ID']['output']
  productVariantName: Scalars['String']['output']
  /** A relevance score for the result. Differs between database implementations */
  score: Scalars['Float']['output']
  sku: Scalars['String']['output']
  slug: Scalars['String']['output']
}

export type SearchResultAsset = {
  __typename?: 'SearchResultAsset'
  focalPoint?: Maybe<Coordinate>
  id: Scalars['ID']['output']
  preview: Scalars['String']['output']
}

/** The price of a search result product, either as a range or as a single price */
export type SearchResultPrice = PriceRange | SinglePrice

export type SearchResultSortParameter = {
  name?: InputMaybe<SortOrder>
  price?: InputMaybe<SortOrder>
}

export type Seller = Node & {
  __typename?: 'Seller'
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type SellerAuthInput = {
  emailAddress: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type SellerFilterParameter = {
  _and?: InputMaybe<Array<SellerFilterParameter>>
  _or?: InputMaybe<Array<SellerFilterParameter>>
  createdAt?: InputMaybe<DateOperators>
  id?: InputMaybe<IdOperators>
  name?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type SellerList = PaginatedList & {
  __typename?: 'SellerList'
  items: Array<Seller>
  totalItems: Scalars['Int']['output']
}

export type SellerListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<SellerFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<SellerSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type SellerSortParameter = {
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type ServerConfig = {
  __typename?: 'ServerConfig'
  /**
   * This field is deprecated in v2.2 in favor of the entityCustomFields field,
   * which allows custom fields to be defined on user-supplies entities.
   */
  customFieldConfig: CustomFields
  entityCustomFields: Array<EntityCustomFields>
  moneyStrategyPrecision: Scalars['Int']['output']
  orderProcess: Array<OrderProcessState>
  permissions: Array<PermissionDefinition>
  permittedAssetTypes: Array<Scalars['String']['output']>
}

export type SetCustomerForDraftOrderResult = EmailAddressConflictError | Order

export type SetOrderCustomerInput = {
  customerId: Scalars['ID']['input']
  note?: InputMaybe<Scalars['String']['input']>
  orderId: Scalars['ID']['input']
}

export type SetOrderShippingMethodResult =
  | IneligibleShippingMethodError
  | NoActiveOrderError
  | Order
  | OrderModificationError

/** Returned if the Payment settlement fails */
export type SettlePaymentError = ErrorResult & {
  __typename?: 'SettlePaymentError'
  errorCode: ErrorCode
  message: Scalars['String']['output']
  paymentErrorMessage: Scalars['String']['output']
}

export type SettlePaymentResult =
  | OrderStateTransitionError
  | Payment
  | PaymentStateTransitionError
  | SettlePaymentError

export type SettleRefundInput = {
  id: Scalars['ID']['input']
  transactionId: Scalars['String']['input']
}

export type SettleRefundResult = Refund | RefundStateTransitionError

export type ShippingLine = {
  __typename?: 'ShippingLine'
  customFields?: Maybe<Scalars['JSON']['output']>
  discountedPrice: Scalars['Money']['output']
  discountedPriceWithTax: Scalars['Money']['output']
  discounts: Array<Discount>
  id: Scalars['ID']['output']
  price: Scalars['Money']['output']
  priceWithTax: Scalars['Money']['output']
  shippingMethod: ShippingMethod
}

export type ShippingMethod = Node & {
  __typename?: 'ShippingMethod'
  calculator: ConfigurableOperation
  checker: ConfigurableOperation
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  description: Scalars['String']['output']
  fulfillmentHandlerCode: Scalars['String']['output']
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  translations: Array<ShippingMethodTranslation>
  updatedAt: Scalars['DateTime']['output']
}

export type ShippingMethodFilterParameter = {
  _and?: InputMaybe<Array<ShippingMethodFilterParameter>>
  _or?: InputMaybe<Array<ShippingMethodFilterParameter>>
  code?: InputMaybe<StringOperators>
  createdAt?: InputMaybe<DateOperators>
  description?: InputMaybe<StringOperators>
  fulfillmentHandlerCode?: InputMaybe<StringOperators>
  id?: InputMaybe<IdOperators>
  languageCode?: InputMaybe<StringOperators>
  name?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type ShippingMethodList = PaginatedList & {
  __typename?: 'ShippingMethodList'
  items: Array<ShippingMethod>
  totalItems: Scalars['Int']['output']
}

export type ShippingMethodListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ShippingMethodFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ShippingMethodSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type ShippingMethodQuote = {
  __typename?: 'ShippingMethodQuote'
  code: Scalars['String']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** Any optional metadata returned by the ShippingCalculator in the ShippingCalculationResult */
  metadata?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  price: Scalars['Money']['output']
  priceWithTax: Scalars['Money']['output']
}

export type ShippingMethodSortParameter = {
  code?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  description?: InputMaybe<SortOrder>
  fulfillmentHandlerCode?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type ShippingMethodTranslation = {
  __typename?: 'ShippingMethodTranslation'
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  languageCode: LanguageCode
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ShippingMethodTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  languageCode: LanguageCode
  name?: InputMaybe<Scalars['String']['input']>
}

/** The price value where the result has a single price */
export type SinglePrice = {
  __typename?: 'SinglePrice'
  value: Scalars['Money']['output']
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type StockAdjustment = Node &
  StockMovement & {
    __typename?: 'StockAdjustment'
    createdAt: Scalars['DateTime']['output']
    customFields?: Maybe<Scalars['JSON']['output']>
    id: Scalars['ID']['output']
    productVariant: ProductVariant
    quantity: Scalars['Int']['output']
    type: StockMovementType
    updatedAt: Scalars['DateTime']['output']
  }

export type StockLevel = Node & {
  __typename?: 'StockLevel'
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  id: Scalars['ID']['output']
  stockAllocated: Scalars['Int']['output']
  stockLocation: StockLocation
  stockLocationId: Scalars['ID']['output']
  stockOnHand: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type StockLevelInput = {
  stockLocationId: Scalars['ID']['input']
  stockOnHand: Scalars['Int']['input']
}

export type StockLocation = Node & {
  __typename?: 'StockLocation'
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type StockLocationFilterParameter = {
  _and?: InputMaybe<Array<StockLocationFilterParameter>>
  _or?: InputMaybe<Array<StockLocationFilterParameter>>
  createdAt?: InputMaybe<DateOperators>
  description?: InputMaybe<StringOperators>
  id?: InputMaybe<IdOperators>
  name?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type StockLocationList = PaginatedList & {
  __typename?: 'StockLocationList'
  items: Array<StockLocation>
  totalItems: Scalars['Int']['output']
}

export type StockLocationListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<StockLocationFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<StockLocationSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type StockLocationSortParameter = {
  createdAt?: InputMaybe<SortOrder>
  description?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type StockMovement = {
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  productVariant: ProductVariant
  quantity: Scalars['Int']['output']
  type: StockMovementType
  updatedAt: Scalars['DateTime']['output']
}

export type StockMovementItem =
  | Allocation
  | Cancellation
  | Release
  | Return
  | Sale
  | StockAdjustment

export type StockMovementList = {
  __typename?: 'StockMovementList'
  items: Array<StockMovementItem>
  totalItems: Scalars['Int']['output']
}

export type StockMovementListOptions = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<StockMovementType>
}

export enum StockMovementType {
  ADJUSTMENT = 'ADJUSTMENT',
  ALLOCATION = 'ALLOCATION',
  CANCELLATION = 'CANCELLATION',
  RELEASE = 'RELEASE',
  RETURN = 'RETURN',
  SALE = 'SALE',
}

export type StringCustomFieldConfig = CustomField & {
  __typename?: 'StringCustomFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  internal?: Maybe<Scalars['Boolean']['output']>
  label?: Maybe<Array<LocalizedString>>
  length?: Maybe<Scalars['Int']['output']>
  list: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  nullable?: Maybe<Scalars['Boolean']['output']>
  options?: Maybe<Array<StringFieldOption>>
  pattern?: Maybe<Scalars['String']['output']>
  readonly?: Maybe<Scalars['Boolean']['output']>
  requiresPermission?: Maybe<Array<Permission>>
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type StringFieldDefinition = {
  __typename?: 'StringFieldDefinition'
  description: Scalars['String']['output']
  fieldName: Scalars['String']['output']
  id: Scalars['String']['output']
  maxLength?: Maybe<Scalars['Int']['output']>
  sortOrder: Scalars['Int']['output']
  title: Scalars['String']['output']
}

export type StringFieldOption = {
  __typename?: 'StringFieldOption'
  label?: Maybe<Array<LocalizedString>>
  value: Scalars['String']['output']
}

/** Operators for filtering on a list of String fields */
export type StringListOperators = {
  inList: Scalars['String']['input']
}

/** Operators for filtering on a String field */
export type StringOperators = {
  contains?: InputMaybe<Scalars['String']['input']>
  eq?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  isNull?: InputMaybe<Scalars['Boolean']['input']>
  notContains?: InputMaybe<Scalars['String']['input']>
  notEq?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  regex?: InputMaybe<Scalars['String']['input']>
}

export type StringStructFieldConfig = StructField & {
  __typename?: 'StringStructFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  label?: Maybe<Array<LocalizedString>>
  length?: Maybe<Scalars['Int']['output']>
  list: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  options?: Maybe<Array<StringFieldOption>>
  pattern?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type StringValue = {
  __typename?: 'StringValue'
  field: StringFieldDefinition
  id: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type StructCustomFieldConfig = CustomField & {
  __typename?: 'StructCustomFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  fields: Array<StructFieldConfig>
  internal?: Maybe<Scalars['Boolean']['output']>
  label?: Maybe<Array<LocalizedString>>
  list: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  nullable?: Maybe<Scalars['Boolean']['output']>
  readonly?: Maybe<Scalars['Boolean']['output']>
  requiresPermission?: Maybe<Array<Permission>>
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type StructField = {
  description?: Maybe<Array<LocalizedString>>
  label?: Maybe<Array<LocalizedString>>
  list?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type StructFieldConfig =
  | BooleanStructFieldConfig
  | DateTimeStructFieldConfig
  | FloatStructFieldConfig
  | IntStructFieldConfig
  | StringStructFieldConfig
  | TextStructFieldConfig

/** Indicates that an operation succeeded, where we do not want to return any more specific information. */
export type Success = {
  __typename?: 'Success'
  success: Scalars['Boolean']['output']
}

export type Surcharge = Node & {
  __typename?: 'Surcharge'
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  price: Scalars['Money']['output']
  priceWithTax: Scalars['Money']['output']
  sku?: Maybe<Scalars['String']['output']>
  taxLines: Array<TaxLine>
  taxRate: Scalars['Float']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type SurchargeInput = {
  description: Scalars['String']['input']
  price: Scalars['Money']['input']
  priceIncludesTax: Scalars['Boolean']['input']
  sku?: InputMaybe<Scalars['String']['input']>
  taxDescription?: InputMaybe<Scalars['String']['input']>
  taxRate?: InputMaybe<Scalars['Float']['input']>
}

export type Tag = Node & {
  __typename?: 'Tag'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
  value: Scalars['String']['output']
}

export type TagFilterParameter = {
  _and?: InputMaybe<Array<TagFilterParameter>>
  _or?: InputMaybe<Array<TagFilterParameter>>
  createdAt?: InputMaybe<DateOperators>
  id?: InputMaybe<IdOperators>
  updatedAt?: InputMaybe<DateOperators>
  value?: InputMaybe<StringOperators>
}

export type TagList = PaginatedList & {
  __typename?: 'TagList'
  items: Array<Tag>
  totalItems: Scalars['Int']['output']
}

export type TagListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<TagFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<TagSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type TagSortParameter = {
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
  value?: InputMaybe<SortOrder>
}

export type TaxCategory = Node & {
  __typename?: 'TaxCategory'
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  id: Scalars['ID']['output']
  isDefault: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type TaxCategoryFilterParameter = {
  _and?: InputMaybe<Array<TaxCategoryFilterParameter>>
  _or?: InputMaybe<Array<TaxCategoryFilterParameter>>
  createdAt?: InputMaybe<DateOperators>
  id?: InputMaybe<IdOperators>
  isDefault?: InputMaybe<BooleanOperators>
  name?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type TaxCategoryList = PaginatedList & {
  __typename?: 'TaxCategoryList'
  items: Array<TaxCategory>
  totalItems: Scalars['Int']['output']
}

export type TaxCategoryListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<TaxCategoryFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<TaxCategorySortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type TaxCategorySortParameter = {
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type TaxLine = {
  __typename?: 'TaxLine'
  description: Scalars['String']['output']
  taxRate: Scalars['Float']['output']
}

export type TaxRate = Node & {
  __typename?: 'TaxRate'
  category: TaxCategory
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  customerGroup?: Maybe<CustomerGroup>
  enabled: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  value: Scalars['Float']['output']
  zone: Zone
}

export type TaxRateFilterParameter = {
  _and?: InputMaybe<Array<TaxRateFilterParameter>>
  _or?: InputMaybe<Array<TaxRateFilterParameter>>
  createdAt?: InputMaybe<DateOperators>
  enabled?: InputMaybe<BooleanOperators>
  id?: InputMaybe<IdOperators>
  name?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
  value?: InputMaybe<NumberOperators>
}

export type TaxRateList = PaginatedList & {
  __typename?: 'TaxRateList'
  items: Array<TaxRate>
  totalItems: Scalars['Int']['output']
}

export type TaxRateListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<TaxRateFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<TaxRateSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type TaxRateSortParameter = {
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
  value?: InputMaybe<SortOrder>
}

export type TestEligibleShippingMethodsInput = {
  lines: Array<TestShippingMethodOrderLineInput>
  shippingAddress: CreateAddressInput
}

export type TestShippingMethodInput = {
  calculator: ConfigurableOperationInput
  checker: ConfigurableOperationInput
  lines: Array<TestShippingMethodOrderLineInput>
  shippingAddress: CreateAddressInput
}

export type TestShippingMethodOrderLineInput = {
  productVariantId: Scalars['ID']['input']
  quantity: Scalars['Int']['input']
}

export type TestShippingMethodQuote = {
  __typename?: 'TestShippingMethodQuote'
  metadata?: Maybe<Scalars['JSON']['output']>
  price: Scalars['Money']['output']
  priceWithTax: Scalars['Money']['output']
}

export type TestShippingMethodResult = {
  __typename?: 'TestShippingMethodResult'
  eligible: Scalars['Boolean']['output']
  quote?: Maybe<TestShippingMethodQuote>
}

export type TextCustomFieldConfig = CustomField & {
  __typename?: 'TextCustomFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  internal?: Maybe<Scalars['Boolean']['output']>
  label?: Maybe<Array<LocalizedString>>
  list: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  nullable?: Maybe<Scalars['Boolean']['output']>
  readonly?: Maybe<Scalars['Boolean']['output']>
  requiresPermission?: Maybe<Array<Permission>>
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type TextStructFieldConfig = StructField & {
  __typename?: 'TextStructFieldConfig'
  description?: Maybe<Array<LocalizedString>>
  label?: Maybe<Array<LocalizedString>>
  list: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  type: Scalars['String']['output']
  ui?: Maybe<Scalars['JSON']['output']>
}

export type TransitionFulfillmentToStateResult =
  | Fulfillment
  | FulfillmentStateTransitionError

export type TransitionOrderToStateResult = Order | OrderStateTransitionError

export type TransitionPaymentToStateResult =
  | Payment
  | PaymentStateTransitionError

export type UpdateActiveAdministratorInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
}

/**
 * Input used to update an Address.
 *
 * The countryCode must correspond to a `code` property of a Country that has been defined in the
 * Vendure server. The `code` property is typically a 2-character ISO code such as "GB", "US", "DE" etc.
 * If an invalid code is passed, the mutation will fail.
 */
export type UpdateAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  countryCode?: InputMaybe<Scalars['String']['input']>
  customFields?: InputMaybe<Scalars['JSON']['input']>
  defaultBillingAddress?: InputMaybe<Scalars['Boolean']['input']>
  defaultShippingAddress?: InputMaybe<Scalars['Boolean']['input']>
  fullName?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  postalCode?: InputMaybe<Scalars['String']['input']>
  province?: InputMaybe<Scalars['String']['input']>
  streetLine1?: InputMaybe<Scalars['String']['input']>
  streetLine2?: InputMaybe<Scalars['String']['input']>
}

export type UpdateAdministratorInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  lastName?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  roleIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UpdateAssetInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  focalPoint?: InputMaybe<CoordinateInput>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<Array<Scalars['String']['input']>>
}

export type UpdateChannelCustomFieldsInput = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateChannelInput = {
  availableCurrencyCodes?: InputMaybe<Array<CurrencyCode>>
  availableLanguageCodes?: InputMaybe<Array<LanguageCode>>
  code?: InputMaybe<Scalars['String']['input']>
  customFields?: InputMaybe<UpdateChannelCustomFieldsInput>
  defaultCurrencyCode?: InputMaybe<CurrencyCode>
  defaultLanguageCode?: InputMaybe<LanguageCode>
  defaultShippingZoneId?: InputMaybe<Scalars['ID']['input']>
  defaultTaxZoneId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  outOfStockThreshold?: InputMaybe<Scalars['Int']['input']>
  pricesIncludeTax?: InputMaybe<Scalars['Boolean']['input']>
  sellerId?: InputMaybe<Scalars['ID']['input']>
  token?: InputMaybe<Scalars['String']['input']>
  trackInventory?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateChannelResult = Channel | LanguageNotAvailableError

export type UpdateCollectionInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']['input']>>
  customFields?: InputMaybe<Scalars['JSON']['input']>
  featuredAssetId?: InputMaybe<Scalars['ID']['input']>
  filters?: InputMaybe<Array<ConfigurableOperationInput>>
  id: Scalars['ID']['input']
  inheritFilters?: InputMaybe<Scalars['Boolean']['input']>
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>
  parentId?: InputMaybe<Scalars['ID']['input']>
  translations?: InputMaybe<Array<UpdateCollectionTranslationInput>>
}

export type UpdateCollectionTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  languageCode: LanguageCode
  name?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCountryInput = {
  code?: InputMaybe<Scalars['String']['input']>
  customFields?: InputMaybe<Scalars['JSON']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  translations?: InputMaybe<Array<CountryTranslationInput>>
}

export type UpdateCustomerGroupInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCustomerInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCustomerNoteInput = {
  note: Scalars['String']['input']
  noteId: Scalars['ID']['input']
}

export type UpdateCustomerResult = Customer | EmailAddressConflictError

export type UpdateFacetInput = {
  code?: InputMaybe<Scalars['String']['input']>
  customFields?: InputMaybe<Scalars['JSON']['input']>
  id: Scalars['ID']['input']
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>
  translations?: InputMaybe<Array<FacetTranslationInput>>
}

export type UpdateFacetValueInput = {
  code?: InputMaybe<Scalars['String']['input']>
  customFields?: InputMaybe<Scalars['JSON']['input']>
  id: Scalars['ID']['input']
  translations?: InputMaybe<Array<FacetValueTranslationInput>>
}

export type UpdateGlobalSettingsInput = {
  availableLanguages?: InputMaybe<Array<LanguageCode>>
  customFields?: InputMaybe<Scalars['JSON']['input']>
  outOfStockThreshold?: InputMaybe<Scalars['Int']['input']>
  trackInventory?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateGlobalSettingsResult =
  | ChannelDefaultLanguageError
  | GlobalSettings

export type UpdateGroupInput = {
  description: Scalars['String']['input']
  entityName: Scalars['String']['input']
  fieldDefinitionIds: Scalars['JSON']['input']
  fieldName: Scalars['String']['input']
  list: Scalars['Boolean']['input']
  sortOrder: Scalars['Int']['input']
  title: Scalars['String']['input']
}

export type UpdateOrderAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  countryCode?: InputMaybe<Scalars['String']['input']>
  fullName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  postalCode?: InputMaybe<Scalars['String']['input']>
  province?: InputMaybe<Scalars['String']['input']>
  streetLine1?: InputMaybe<Scalars['String']['input']>
  streetLine2?: InputMaybe<Scalars['String']['input']>
}

export type UpdateOrderInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  id: Scalars['ID']['input']
}

export type UpdateOrderItemsResult =
  | InsufficientStockError
  | NegativeQuantityError
  | Order
  | OrderInterceptorError
  | OrderLimitError
  | OrderModificationError

export type UpdateOrderNoteInput = {
  isPublic?: InputMaybe<Scalars['Boolean']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  noteId: Scalars['ID']['input']
}

export type UpdatePaymentMethodInput = {
  checker?: InputMaybe<ConfigurableOperationInput>
  code?: InputMaybe<Scalars['String']['input']>
  customFields?: InputMaybe<Scalars['JSON']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  handler?: InputMaybe<ConfigurableOperationInput>
  id: Scalars['ID']['input']
  translations?: InputMaybe<Array<PaymentMethodTranslationInput>>
}

export type UpdateProductCustomFieldsInput = {
  bestSeller?: InputMaybe<Scalars['Boolean']['input']>
  discountPercentage?: InputMaybe<Scalars['Float']['input']>
  dlcIds?: InputMaybe<Array<Scalars['ID']['input']>>
  mainAssetId?: InputMaybe<Scalars['ID']['input']>
  new?: InputMaybe<Scalars['Boolean']['input']>
  primaryColor?: InputMaybe<Scalars['String']['input']>
  secondaryColor?: InputMaybe<Scalars['String']['input']>
  videoAssetId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateProductInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']['input']>>
  customFields?: InputMaybe<UpdateProductCustomFieldsInput>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  facetValueIds?: InputMaybe<Array<Scalars['ID']['input']>>
  featuredAssetId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  translations?: InputMaybe<Array<ProductTranslationInput>>
}

export type UpdateProductOptionGroupInput = {
  code?: InputMaybe<Scalars['String']['input']>
  customFields?: InputMaybe<Scalars['JSON']['input']>
  id: Scalars['ID']['input']
  translations?: InputMaybe<Array<ProductOptionGroupTranslationInput>>
}

export type UpdateProductOptionInput = {
  code?: InputMaybe<Scalars['String']['input']>
  customFields?: InputMaybe<Scalars['JSON']['input']>
  id: Scalars['ID']['input']
  translations?: InputMaybe<Array<ProductOptionGroupTranslationInput>>
}

export type UpdateProductVariantCustomFieldsInput = {
  discountPercentage?: InputMaybe<Scalars['Float']['input']>
  g2aId?: InputMaybe<Scalars['String']['input']>
  k4gId?: InputMaybe<Scalars['String']['input']>
  kinguinId?: InputMaybe<Scalars['String']['input']>
  mainAssetId?: InputMaybe<Scalars['ID']['input']>
  steamAppId?: InputMaybe<Scalars['String']['input']>
  videoAssetId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateProductVariantInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']['input']>>
  customFields?: InputMaybe<UpdateProductVariantCustomFieldsInput>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  facetValueIds?: InputMaybe<Array<Scalars['ID']['input']>>
  featuredAssetId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  optionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  outOfStockThreshold?: InputMaybe<Scalars['Int']['input']>
  /** Sets the price for the ProductVariant in the Channel's default currency */
  price?: InputMaybe<Scalars['Money']['input']>
  /** Allows multiple prices to be set for the ProductVariant in different currencies. */
  prices?: InputMaybe<Array<ProductVariantPriceInput>>
  sku?: InputMaybe<Scalars['String']['input']>
  stockLevels?: InputMaybe<Array<StockLevelInput>>
  stockOnHand?: InputMaybe<Scalars['Int']['input']>
  taxCategoryId?: InputMaybe<Scalars['ID']['input']>
  trackInventory?: InputMaybe<GlobalFlag>
  translations?: InputMaybe<Array<ProductVariantTranslationInput>>
  useGlobalOutOfStockThreshold?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdatePromotionInput = {
  actions?: InputMaybe<Array<ConfigurableOperationInput>>
  conditions?: InputMaybe<Array<ConfigurableOperationInput>>
  couponCode?: InputMaybe<Scalars['String']['input']>
  customFields?: InputMaybe<Scalars['JSON']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  endsAt?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  perCustomerUsageLimit?: InputMaybe<Scalars['Int']['input']>
  startsAt?: InputMaybe<Scalars['DateTime']['input']>
  translations?: InputMaybe<Array<PromotionTranslationInput>>
  usageLimit?: InputMaybe<Scalars['Int']['input']>
}

export type UpdatePromotionResult = MissingConditionsError | Promotion

export type UpdateProvinceInput = {
  code?: InputMaybe<Scalars['String']['input']>
  customFields?: InputMaybe<Scalars['JSON']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  translations?: InputMaybe<Array<ProvinceTranslationInput>>
}

export type UpdateRoleInput = {
  channelIds?: InputMaybe<Array<Scalars['ID']['input']>>
  code?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  permissions?: InputMaybe<Array<Permission>>
}

export type UpdateSellerInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateShippingMethodInput = {
  calculator?: InputMaybe<ConfigurableOperationInput>
  checker?: InputMaybe<ConfigurableOperationInput>
  code?: InputMaybe<Scalars['String']['input']>
  customFields?: InputMaybe<Scalars['JSON']['input']>
  fulfillmentHandler?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  translations: Array<ShippingMethodTranslationInput>
}

export type UpdateStockLocationInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateTagInput = {
  id: Scalars['ID']['input']
  value?: InputMaybe<Scalars['String']['input']>
}

export type UpdateTaxCategoryInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  id: Scalars['ID']['input']
  isDefault?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateTaxRateInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>
  customFields?: InputMaybe<Scalars['JSON']['input']>
  customerGroupId?: InputMaybe<Scalars['ID']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['Float']['input']>
  zoneId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateZoneInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
}

export type User = Node & {
  __typename?: 'User'
  authenticationMethods: Array<AuthenticationMethod>
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  id: Scalars['ID']['output']
  identifier: Scalars['String']['output']
  lastLogin?: Maybe<Scalars['DateTime']['output']>
  roles: Array<Role>
  updatedAt: Scalars['DateTime']['output']
  verified: Scalars['Boolean']['output']
}

export type VerifySellerResponse = {
  __typename?: 'VerifySellerResponse'
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type Zone = Node & {
  __typename?: 'Zone'
  createdAt: Scalars['DateTime']['output']
  customFields?: Maybe<Scalars['JSON']['output']>
  id: Scalars['ID']['output']
  members: Array<Region>
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ZoneFilterParameter = {
  _and?: InputMaybe<Array<ZoneFilterParameter>>
  _or?: InputMaybe<Array<ZoneFilterParameter>>
  createdAt?: InputMaybe<DateOperators>
  id?: InputMaybe<IdOperators>
  name?: InputMaybe<StringOperators>
  updatedAt?: InputMaybe<DateOperators>
}

export type ZoneList = PaginatedList & {
  __typename?: 'ZoneList'
  items: Array<Zone>
  totalItems: Scalars['Int']['output']
}

export type ZoneListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ZoneFilterParameter>
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']['input']>
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ZoneSortParameter>
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']['input']>
}

export type ZoneSortParameter = {
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type InsertGroupMutationVariables = Exact<{
  input: InsertGroupInput
}>

export type InsertGroupMutation = {
  __typename?: 'Mutation'
  insertGroup: {
    __typename?: 'GroupFieldDefinition'
    id: string
    fields: Array<
      | { __typename: 'BooleanFieldDefinition'; id: string; fieldName: string }
      | { __typename: 'DateFieldDefinition'; id: string; fieldName: string }
      | {
          __typename?: 'GroupFieldDefinition'
          id: string
          fieldName: string
          title: string
          description: string
          sortOrder: number
          list: boolean
        }
      | { __typename: 'ListFieldDefinition'; id: string; fieldName: string }
      | {
          __typename: 'ListRelationFieldDefinition'
          id: string
          fieldName: string
          relatedEntityName: string
        }
      | {
          __typename: 'StringFieldDefinition'
          id: string
          fieldName: string
          maxLength?: number | null
        }
    >
  }
}

export type UpdateGroupMutationVariables = Exact<{
  input: UpdateGroupInput
}>

export type UpdateGroupMutation = {
  __typename?: 'Mutation'
  updateGroup: {
    __typename?: 'GroupFieldDefinition'
    id: string
    fields: Array<
      | { __typename: 'BooleanFieldDefinition'; id: string; fieldName: string }
      | { __typename: 'DateFieldDefinition'; id: string; fieldName: string }
      | {
          __typename?: 'GroupFieldDefinition'
          id: string
          fieldName: string
          title: string
          description: string
          sortOrder: number
          list: boolean
        }
      | { __typename: 'ListFieldDefinition'; id: string; fieldName: string }
      | {
          __typename: 'ListRelationFieldDefinition'
          id: string
          fieldName: string
          relatedEntityName: string
        }
      | {
          __typename: 'StringFieldDefinition'
          id: string
          fieldName: string
          maxLength?: number | null
        }
    >
  }
}

export type GetAllQueryVariables = Exact<{
  input: GetAllInput
}>

export type GetAllQuery = {
  __typename?: 'Query'
  getAll: Array<
    | {
        __typename: 'BooleanFieldDefinition'
        id: string
        fieldName: string
        title: string
        description: string
        sortOrder: number
      }
    | {
        __typename: 'DateFieldDefinition'
        id: string
        fieldName: string
        title: string
        description: string
        sortOrder: number
      }
    | {
        __typename: 'GroupFieldDefinition'
        id: string
        fieldName: string
        title: string
        description: string
        sortOrder: number
        list: boolean
        fields: Array<
          | {
              __typename: 'BooleanFieldDefinition'
              id: string
              fieldName: string
              title: string
              description: string
              sortOrder: number
            }
          | {
              __typename: 'DateFieldDefinition'
              id: string
              fieldName: string
              title: string
              description: string
              sortOrder: number
            }
          | {
              __typename: 'GroupFieldDefinition'
              id: string
              fieldName: string
              title: string
              description: string
              sortOrder: number
              list: boolean
              fields: Array<
                | {
                    __typename: 'BooleanFieldDefinition'
                    id: string
                    fieldName: string
                    title: string
                    description: string
                    sortOrder: number
                  }
                | {
                    __typename: 'DateFieldDefinition'
                    id: string
                    fieldName: string
                    title: string
                    description: string
                    sortOrder: number
                  }
                | {
                    __typename: 'GroupFieldDefinition'
                    id: string
                    fieldName: string
                    title: string
                    description: string
                    sortOrder: number
                    list: boolean
                  }
                | {
                    __typename: 'ListFieldDefinition'
                    id: string
                    fieldName: string
                    title: string
                    description: string
                    sortOrder: number
                  }
                | {
                    __typename: 'ListRelationFieldDefinition'
                    id: string
                    fieldName: string
                    title: string
                    description: string
                    sortOrder: number
                    relatedEntityName: string
                  }
                | {
                    __typename: 'StringFieldDefinition'
                    id: string
                    fieldName: string
                    title: string
                    description: string
                    sortOrder: number
                    maxLength?: number | null
                  }
              >
            }
          | {
              __typename: 'ListFieldDefinition'
              id: string
              fieldName: string
              title: string
              description: string
              sortOrder: number
            }
          | {
              __typename: 'ListRelationFieldDefinition'
              id: string
              fieldName: string
              title: string
              description: string
              sortOrder: number
              relatedEntityName: string
            }
          | {
              __typename: 'StringFieldDefinition'
              id: string
              fieldName: string
              title: string
              description: string
              sortOrder: number
              maxLength?: number | null
            }
        >
      }
    | {
        __typename: 'ListFieldDefinition'
        id: string
        fieldName: string
        title: string
        description: string
        sortOrder: number
      }
    | {
        __typename: 'ListRelationFieldDefinition'
        id: string
        fieldName: string
        title: string
        description: string
        sortOrder: number
        relatedEntityName: string
      }
    | {
        __typename: 'StringFieldDefinition'
        id: string
        fieldName: string
        title: string
        description: string
        sortOrder: number
        maxLength?: number | null
      }
  >
}

export type InsertStringValueMutationVariables = Exact<{
  input: InsertStringValueInput
}>

export type InsertStringValueMutation = {
  __typename?: 'Mutation'
  insertStringValue: {
    __typename?: 'StringValue'
    id: string
    value: string
    field: {
      __typename?: 'StringFieldDefinition'
      id: string
      fieldName: string
      maxLength?: number | null
    }
  }
}

export type InsertBooleanValueMutationVariables = Exact<{
  input: InsertBooleanValueInput
}>

export type InsertBooleanValueMutation = {
  __typename?: 'Mutation'
  insertBooleanValue: {
    __typename?: 'BooleanValue'
    id: string
    value: boolean
    field: {
      __typename?: 'BooleanFieldDefinition'
      id: string
      fieldName: string
    }
  }
}

export type InsertDateValueMutationVariables = Exact<{
  input: InsertDateValueInput
}>

export type InsertDateValueMutation = {
  __typename?: 'Mutation'
  insertDateValue: {
    __typename?: 'DateValue'
    id: string
    value: any
    field: { __typename?: 'DateFieldDefinition'; id: string; fieldName: string }
  }
}

export type InsertListValueMutationVariables = Exact<{
  input: InsertListValueInput
}>

export type InsertListValueMutation = {
  __typename?: 'Mutation'
  insertListValue: {
    __typename?: 'ListValue'
    id: string
    value: Array<string>
    field: { __typename?: 'ListFieldDefinition'; id: string; fieldName: string }
  }
}

export type InsertListRelationValueMutationVariables = Exact<{
  input: InsertListRelationValueInput
}>

export type InsertListRelationValueMutation = {
  __typename?: 'Mutation'
  insertListRelationValue: {
    __typename?: 'ListRelationValue'
    id: string
    value: Array<string>
    entities: any
    field: {
      __typename?: 'ListRelationFieldDefinition'
      id: string
      fieldName: string
      relatedEntityName: string
    }
  }
}

export type InsertGroupValueMutationVariables = Exact<{
  input: InsertGroupValueInput
}>

export type InsertGroupValueMutation = {
  __typename?: 'Mutation'
  insertGroupValue: {
    __typename?: 'GroupValue'
    id: string
    value: any
    field: {
      __typename?: 'GroupFieldDefinition'
      id: string
      fieldName: string
      title: string
      description: string
      sortOrder: number
      list: boolean
    }
  }
}

export type InsertListGroupValueMutationVariables = Exact<{
  input: InsertListGroupValueInput
}>

export type InsertListGroupValueMutation = {
  __typename?: 'Mutation'
  insertListGroupValue: {
    __typename?: 'ListGroupValue'
    id: string
    value: any
    field: {
      __typename?: 'GroupFieldDefinition'
      id: string
      fieldName: string
      title: string
      description: string
      sortOrder: number
      list: boolean
    }
  }
}

export type GetStringValueQueryVariables = Exact<{
  input: GetStringValueInput
}>

export type GetStringValueQuery = {
  __typename?: 'Query'
  getStringValue?: {
    __typename?: 'StringValue'
    id: string
    value: string
    field: {
      __typename?: 'StringFieldDefinition'
      id: string
      fieldName: string
      maxLength?: number | null
    }
  } | null
}

export type GetBooleanValueQueryVariables = Exact<{
  input: GetBooleanValueInput
}>

export type GetBooleanValueQuery = {
  __typename?: 'Query'
  getBooleanValue?: {
    __typename?: 'BooleanValue'
    id: string
    value: boolean
    field: {
      __typename?: 'BooleanFieldDefinition'
      id: string
      fieldName: string
    }
  } | null
}

export type GetDateValueQueryVariables = Exact<{
  input: GetDateValueInput
}>

export type GetDateValueQuery = {
  __typename?: 'Query'
  getDateValue?: {
    __typename?: 'DateValue'
    id: string
    value: any
    field: { __typename?: 'DateFieldDefinition'; id: string; fieldName: string }
  } | null
}

export type GetListValueQueryVariables = Exact<{
  input: GetListValueInput
}>

export type GetListValueQuery = {
  __typename?: 'Query'
  getListValue?: {
    __typename?: 'ListValue'
    id: string
    value: Array<string>
    field: { __typename?: 'ListFieldDefinition'; id: string; fieldName: string }
  } | null
}

export type GetListRelationValueQueryVariables = Exact<{
  input: GetListRelationValueInput
}>

export type GetListRelationValueQuery = {
  __typename?: 'Query'
  getListRelationValue?: {
    __typename?: 'ListRelationValue'
    id: string
    value: Array<string>
    entities: any
    field: {
      __typename?: 'ListRelationFieldDefinition'
      id: string
      fieldName: string
      relatedEntityName: string
    }
  } | null
}

export type GetGroupValueQueryVariables = Exact<{
  input: GetGroupValueInput
}>

export type GetGroupValueQuery = {
  __typename?: 'Query'
  getGroupValue?: {
    __typename: 'GroupValue'
    id: string
    value: any
    field: {
      __typename?: 'GroupFieldDefinition'
      id: string
      fieldName: string
      title: string
      description: string
      sortOrder: number
      list: boolean
    }
  } | null
}

export type GetListGroupValueQueryVariables = Exact<{
  input: GetListGroupValueInput
}>

export type GetListGroupValueQuery = {
  __typename?: 'Query'
  getListGroupValue?: {
    __typename: 'ListGroupValue'
    id: string
    value: any
    field: {
      __typename?: 'GroupFieldDefinition'
      id: string
      fieldName: string
      title: string
      description: string
      sortOrder: number
      list: boolean
    }
  } | null
}

export type DeleteStringValueMutationVariables = Exact<{
  input: DeleteStringValueInput
}>

export type DeleteStringValueMutation = {
  __typename?: 'Mutation'
  deleteStringValue: boolean
}

export type DeleteBooleanValueMutationVariables = Exact<{
  input: DeleteBooleanValueInput
}>

export type DeleteBooleanValueMutation = {
  __typename?: 'Mutation'
  deleteBooleanValue: boolean
}

export type DeleteDateValueMutationVariables = Exact<{
  input: DeleteDateValueInput
}>

export type DeleteDateValueMutation = {
  __typename?: 'Mutation'
  deleteDateValue: boolean
}

export type DeleteListValueMutationVariables = Exact<{
  input: DeleteListValueInput
}>

export type DeleteListValueMutation = {
  __typename?: 'Mutation'
  deleteListValue: boolean
}

export type DeleteListRelationValueMutationVariables = Exact<{
  input: DeleteListRelationInput
}>

export type DeleteListRelationValueMutation = {
  __typename?: 'Mutation'
  deleteListRelationValue: boolean
}

export type DeleteGroupValueMutationVariables = Exact<{
  input: DeleteGroupValueInput
}>

export type DeleteGroupValueMutation = {
  __typename?: 'Mutation'
  deleteGroupValue: boolean
}

export type DeleteListGroupValueMutationVariables = Exact<{
  input: DeleteListGroupValueInput
}>

export type DeleteListGroupValueMutation = {
  __typename?: 'Mutation'
  deleteListGroupValue: boolean
}

export type CreateAssetsMutationVariables = Exact<{
  input: Array<CreateAssetInput> | CreateAssetInput
}>

export type CreateAssetsMutation = {
  __typename?: 'Mutation'
  createAssets: Array<
    | {
        __typename?: 'Asset'
        preview: string
        height: number
        name: string
        id: string
      }
    | {
        __typename?: 'MimeTypeError'
        errorCode: ErrorCode
        message: string
        fileName: string
        mimeType: string
      }
  >
}

export type AssetQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type AssetQuery = {
  __typename?: 'Query'
  asset?: {
    __typename?: 'Asset'
    id: string
    createdAt: any
    updatedAt: any
    name: string
    type: AssetType
    fileSize: number
    mimeType: string
    width: number
    height: number
    source: string
    preview: string
    thumbnail: string
    customFields?: any | null
  } | null
}

export type AssetsQueryVariables = Exact<{
  options?: InputMaybe<AssetListOptions>
}>

export type AssetsQuery = {
  __typename?: 'Query'
  assets: {
    __typename?: 'AssetList'
    totalItems: number
    items: Array<{
      __typename?: 'Asset'
      id: string
      createdAt: any
      updatedAt: any
      name: string
      type: AssetType
      fileSize: number
      mimeType: string
      width: number
      height: number
      source: string
      preview: string
      thumbnail: string
      customFields?: any | null
    }>
  }
}

export type CreateCustomerMutationVariables = Exact<{
  input: CreateCustomerInput
}>

export type CreateCustomerMutation = {
  __typename?: 'Mutation'
  createCustomer:
    | {
        __typename?: 'Customer'
        id: string
        createdAt: any
        updatedAt: any
        title?: string | null
        firstName: string
        lastName: string
        phoneNumber?: string | null
        emailAddress: string
        customFields?: any | null
      }
    | { __typename?: 'EmailAddressConflictError' }
}

export type GetCustomerAddressesQueryVariables = Exact<{
  customerId: Scalars['ID']['input']
}>

export type GetCustomerAddressesQuery = {
  __typename?: 'Query'
  customer?: {
    __typename?: 'Customer'
    id: string
    addresses?: Array<{
      __typename?: 'Address'
      id: string
      createdAt: any
      updatedAt: any
      fullName?: string | null
      company?: string | null
      streetLine1: string
      streetLine2?: string | null
      city?: string | null
      province?: string | null
      postalCode?: string | null
      phoneNumber?: string | null
      defaultShippingAddress?: boolean | null
      defaultBillingAddress?: boolean | null
      country: {
        __typename?: 'Country'
        id: string
        code: string
        name: string
      }
    }> | null
  } | null
}

export type CustomersQueryVariables = Exact<{
  options?: InputMaybe<CustomerListOptions>
}>

export type CustomersQuery = {
  __typename?: 'Query'
  customers: {
    __typename?: 'CustomerList'
    totalItems: number
    items: Array<{
      __typename?: 'Customer'
      id: string
      createdAt: any
      updatedAt: any
      title?: string | null
      firstName: string
      lastName: string
      phoneNumber?: string | null
      emailAddress: string
      customFields?: any | null
    }>
  }
}

export type CreateFacetMutationVariables = Exact<{
  input: CreateFacetInput
}>

export type CreateFacetMutation = {
  __typename?: 'Mutation'
  createFacet: {
    __typename?: 'Facet'
    code: string
    isPrivate: boolean
    name: string
    id: string
  }
}

export type CreateFacetValuesMutationVariables = Exact<{
  createFacetValuesInput: Array<CreateFacetValueInput> | CreateFacetValueInput
}>

export type CreateFacetValuesMutation = {
  __typename?: 'Mutation'
  createFacetValues: Array<{
    __typename?: 'FacetValue'
    name: string
    code: string
    id: string
  }>
}

export type FacetValuesQueryVariables = Exact<{
  options?: InputMaybe<FacetValueListOptions>
}>

export type FacetValuesQuery = {
  __typename?: 'Query'
  facetValues: {
    __typename?: 'FacetValueList'
    items: Array<{
      __typename?: 'FacetValue'
      code: string
      id: string
      name: string
      facet: {
        __typename?: 'Facet'
        id: string
        name: string
        code: string
        languageCode: LanguageCode
      }
    }>
  }
}

export type FacetQueryVariables = Exact<{
  facetId: Scalars['ID']['input']
}>

export type FacetQuery = {
  __typename?: 'Query'
  facet?: {
    __typename?: 'Facet'
    createdAt: any
    updatedAt: any
    code: string
    name: string
    isPrivate: boolean
    valueList: {
      __typename?: 'FacetValueList'
      items: Array<{
        __typename?: 'FacetValue'
        createdAt: any
        updatedAt: any
        code: string
        name: string
        id: string
      }>
    }
  } | null
}

export type UpdateFacetMutationVariables = Exact<{
  updateFacetInput: UpdateFacetInput
}>

export type UpdateFacetMutation = {
  __typename?: 'Mutation'
  updateFacet: {
    __typename?: 'Facet'
    code: string
    id: string
    name: string
    valueList: {
      __typename?: 'FacetValueList'
      items: Array<{
        __typename?: 'FacetValue'
        code: string
        id: string
        name: string
      }>
    }
  }
}

export type UpdateFacetValuesMutationVariables = Exact<{
  updateFacetValuesInput: Array<UpdateFacetValueInput> | UpdateFacetValueInput
}>

export type UpdateFacetValuesMutation = {
  __typename?: 'Mutation'
  updateFacetValues: Array<{
    __typename?: 'FacetValue'
    code: string
    name: string
    id: string
  }>
}

export type FacetsQueryVariables = Exact<{
  options?: InputMaybe<FacetListOptions>
}>

export type FacetsQuery = {
  __typename?: 'Query'
  facets: {
    __typename?: 'FacetList'
    totalItems: number
    items: Array<{
      __typename?: 'Facet'
      createdAt: any
      code: string
      id: string
      isPrivate: boolean
      languageCode: LanguageCode
      name: string
      values: Array<{
        __typename?: 'FacetValue'
        code: string
        facetId: string
        id: string
        name: string
        languageCode: LanguageCode
      }>
      translations: Array<{
        __typename?: 'FacetTranslation'
        name: string
        languageCode: LanguageCode
        id: string
      }>
    }>
  }
}

export type GlobalSettingsQueryVariables = Exact<{ [key: string]: never }>

export type GlobalSettingsQuery = {
  __typename?: 'Query'
  globalSettings: {
    __typename?: 'GlobalSettings'
    id: string
    createdAt: any
    updatedAt: any
    availableLanguages: Array<LanguageCode>
    trackInventory: boolean
    outOfStockThreshold: number
    customFields?: any | null
  }
}

export type LoginMutationVariables = Exact<{
  authenticationInput: AuthenticationInput
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  authenticate:
    | {
        __typename: 'CurrentUser'
        id: string
        identifier: string
        channels: Array<{
          __typename?: 'CurrentUserChannel'
          code: string
          token: string
          id: string
          permissions: Array<Permission>
        }>
      }
    | { __typename: 'InvalidCredentialsError'; message: string }
}

export type AuthenticateGoogleMutationVariables = Exact<{
  token: Scalars['String']['input']
}>

export type AuthenticateGoogleMutation = {
  __typename?: 'Mutation'
  authenticate:
    | { __typename: 'CurrentUser'; id: string; identifier: string }
    | { __typename: 'InvalidCredentialsError' }
}

export type ListManageablePluginsQueryVariables = Exact<{
  [key: string]: never
}>

export type ListManageablePluginsQuery = {
  __typename?: 'Query'
  listManageablePlugins: Array<{
    __typename?: 'ManageablePlugin'
    id: string
    name: string
    code: string
    description: string
    imageUrl: string
    tags: Array<{
      __typename?: 'ManageablePluginTags'
      id: string
      name: string
      code: string
    }>
    configurationItems: Array<{
      __typename?: 'ManageablePluginConfigItem'
      id: string
      type: string
      translations: Array<{
        __typename?: 'ManageablePluginConfigItemTranslation'
        id: string
        languageCode: string
        name: string
        description: string
      }>
    }>
  }>
}

export type ListChannelPluginsQueryVariables = Exact<{ [key: string]: never }>

export type ListChannelPluginsQuery = {
  __typename?: 'Query'
  listChannelPlugins: Array<{
    __typename?: 'ChannelPlugin'
    id: string
    status: string
    configuration: any
    manageablePlugin: {
      __typename?: 'ManageablePlugin'
      id: string
      name: string
      code: string
    }
  }>
}

export type CreateChannelPluginMutationVariables = Exact<{
  code: Scalars['String']['input']
}>

export type CreateChannelPluginMutation = {
  __typename?: 'Mutation'
  createChannelPlugin: {
    __typename?: 'ChannelPlugin'
    id: string
    status: string
    configuration: any
  }
}

export type EnableChannelPluginMutationVariables = Exact<{
  channelPluginId: Scalars['ID']['input']
}>

export type EnableChannelPluginMutation = {
  __typename?: 'Mutation'
  enableChannelPlugin: {
    __typename?: 'ChannelPlugin'
    id: string
    status: string
    configuration: any
  }
}

export type DisableChannelPluginMutationVariables = Exact<{
  channelPluginId: Scalars['ID']['input']
}>

export type DisableChannelPluginMutation = {
  __typename?: 'Mutation'
  disableChannelPlugin: {
    __typename?: 'ChannelPlugin'
    id: string
    status: string
    configuration: any
  }
}

export type UpdateChannelPluginMutationVariables = Exact<{
  channelPluginId: Scalars['ID']['input']
  configuration: Scalars['JSON']['input']
}>

export type UpdateChannelPluginMutation = {
  __typename?: 'Mutation'
  updateChannelPluginConfiguration: {
    __typename?: 'ChannelPlugin'
    id: string
    status: string
    configuration: any
  }
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me?: { __typename?: 'CurrentUser'; id: string; identifier: string } | null
}

export type OrderQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type OrderQuery = {
  __typename?: 'Query'
  order?: {
    __typename?: 'Order'
    nextStates: Array<string>
    aggregateOrderId?: string | null
    id: string
    createdAt: any
    updatedAt: any
    type: OrderType
    orderPlacedAt?: any | null
    code: string
    state: string
    active: boolean
    couponCodes: Array<string>
    totalQuantity: number
    subTotal: number
    subTotalWithTax: number
    currencyCode: CurrencyCode
    shipping: number
    shippingWithTax: number
    total: number
    totalWithTax: number
    customFields?: any | null
    shippingLines: Array<{
      __typename?: 'ShippingLine'
      id: string
      price: number
      priceWithTax: number
      discountedPrice: number
      discountedPriceWithTax: number
      customFields?: any | null
      shippingMethod: {
        __typename?: 'ShippingMethod'
        id: string
        createdAt: any
        updatedAt: any
        languageCode: LanguageCode
        code: string
        name: string
        description: string
        fulfillmentHandlerCode: string
        customFields?: any | null
      }
    }>
    customer?: {
      __typename?: 'Customer'
      id: string
      createdAt: any
      updatedAt: any
      title?: string | null
      firstName: string
      lastName: string
      phoneNumber?: string | null
      emailAddress: string
      customFields?: any | null
    } | null
    shippingAddress?: {
      __typename?: 'OrderAddress'
      fullName?: string | null
      company?: string | null
      streetLine1?: string | null
      streetLine2?: string | null
      city?: string | null
      province?: string | null
      postalCode?: string | null
      country?: string | null
      countryCode?: string | null
      phoneNumber?: string | null
      customFields?: any | null
    } | null
    billingAddress?: {
      __typename?: 'OrderAddress'
      fullName?: string | null
      company?: string | null
      streetLine1?: string | null
      streetLine2?: string | null
      city?: string | null
      province?: string | null
      postalCode?: string | null
      country?: string | null
      countryCode?: string | null
      phoneNumber?: string | null
      customFields?: any | null
    } | null
    lines: Array<{
      __typename?: 'OrderLine'
      id: string
      createdAt: any
      updatedAt: any
      unitPrice: number
      unitPriceWithTax: number
      unitPriceChangeSinceAdded: number
      unitPriceWithTaxChangeSinceAdded: number
      discountedUnitPrice: number
      discountedUnitPriceWithTax: number
      proratedUnitPrice: number
      proratedUnitPriceWithTax: number
      quantity: number
      orderPlacedQuantity: number
      taxRate: number
      linePrice: number
      linePriceWithTax: number
      discountedLinePrice: number
      discountedLinePriceWithTax: number
      proratedLinePrice: number
      proratedLinePriceWithTax: number
      lineTax: number
      customFields?: any | null
      fulfillmentLines?: Array<{
        __typename: 'FulfillmentLine'
        fulfillmentId: string
        quantity: number
      }> | null
      productVariant: {
        __typename?: 'ProductVariant'
        enabled: boolean
        trackInventory: GlobalFlag
        stockOnHand: number
        stockAllocated: number
        outOfStockThreshold: number
        useGlobalOutOfStockThreshold: boolean
        id: string
        productId: string
        createdAt: any
        updatedAt: any
        languageCode: LanguageCode
        sku: string
        name: string
        price: number
        currencyCode: CurrencyCode
        priceWithTax: number
        stockLevel: string
        stockLevels: Array<{
          __typename?: 'StockLevel'
          id: string
          createdAt: any
          updatedAt: any
          stockLocationId: string
          stockOnHand: number
          stockAllocated: number
          customFields?: any | null
        }>
      }
    }>
    taxSummary: Array<{
      __typename?: 'OrderTaxSummary'
      description: string
      taxRate: number
      taxBase: number
      taxTotal: number
    }>
    payments?: Array<{
      __typename?: 'Payment'
      nextStates: Array<string>
      id: string
      createdAt: any
      updatedAt: any
      method: string
      amount: number
      state: string
      transactionId?: string | null
      errorMessage?: string | null
      metadata?: any | null
      customFields?: any | null
      refunds: Array<{
        __typename?: 'Refund'
        id: string
        createdAt: any
        updatedAt: any
        items: number
        shipping: number
        adjustment: number
        total: number
        method?: string | null
        state: string
        transactionId?: string | null
        reason?: string | null
        paymentId: string
        metadata?: any | null
        customFields?: any | null
      }>
    }> | null
    fulfillments?: Array<{
      __typename?: 'Fulfillment'
      nextStates: Array<string>
      id: string
      createdAt: any
      updatedAt: any
      state: string
      method: string
      trackingCode?: string | null
      customFields?: any | null
      lines: Array<{
        __typename?: 'FulfillmentLine'
        orderLineId: string
        quantity: number
        fulfillmentId: string
        orderLine: {
          __typename?: 'OrderLine'
          id: string
          createdAt: any
          updatedAt: any
          unitPrice: number
          unitPriceWithTax: number
          unitPriceChangeSinceAdded: number
          unitPriceWithTaxChangeSinceAdded: number
          discountedUnitPrice: number
          discountedUnitPriceWithTax: number
          proratedUnitPrice: number
          proratedUnitPriceWithTax: number
          quantity: number
          orderPlacedQuantity: number
          taxRate: number
          linePrice: number
          linePriceWithTax: number
          discountedLinePrice: number
          discountedLinePriceWithTax: number
          proratedLinePrice: number
          proratedLinePriceWithTax: number
          lineTax: number
          customFields?: any | null
        }
      }>
    }> | null
    surcharges: Array<{
      __typename?: 'Surcharge'
      id: string
      createdAt: any
      updatedAt: any
      description: string
      sku?: string | null
      price: number
      priceWithTax: number
      taxRate: number
      taxLines: Array<{
        __typename?: 'TaxLine'
        description: string
        taxRate: number
      }>
    }>
    discounts: Array<{
      __typename?: 'Discount'
      adjustmentSource: string
      type: AdjustmentType
      description: string
      amount: number
      amountWithTax: number
    }>
    promotions: Array<{
      __typename?: 'Promotion'
      id: string
      createdAt: any
      updatedAt: any
      startsAt?: any | null
      endsAt?: any | null
      couponCode?: string | null
      perCustomerUsageLimit?: number | null
      usageLimit?: number | null
      name: string
      description: string
      enabled: boolean
      customFields?: any | null
    }>
    sellerOrders?: Array<{
      __typename?: 'Order'
      id: string
      code: string
      state: string
    }> | null
    modifications: Array<{
      __typename?: 'OrderModification'
      id: string
      createdAt: any
      updatedAt: any
      priceChange: number
      note: string
      isSettled: boolean
      lines: Array<{
        __typename?: 'OrderModificationLine'
        orderLineId: string
        quantity: number
        modificationId: string
      }>
      surcharges?: Array<{
        __typename?: 'Surcharge'
        id: string
        createdAt: any
        updatedAt: any
        description: string
        sku?: string | null
        price: number
        priceWithTax: number
        taxRate: number
      }> | null
      payment?: {
        __typename?: 'Payment'
        nextStates: Array<string>
        id: string
        createdAt: any
        updatedAt: any
        method: string
        amount: number
        state: string
        transactionId?: string | null
        errorMessage?: string | null
        metadata?: any | null
        customFields?: any | null
      } | null
      refund?: {
        __typename?: 'Refund'
        id: string
        createdAt: any
        updatedAt: any
        items: number
        shipping: number
        adjustment: number
        total: number
        method?: string | null
        state: string
        transactionId?: string | null
        reason?: string | null
        paymentId: string
        metadata?: any | null
        customFields?: any | null
      } | null
    }>
  } | null
}

export type CancelOrderMutationVariables = Exact<{
  input: CancelOrderInput
}>

export type CancelOrderMutation = {
  __typename?: 'Mutation'
  cancelOrder:
    | {
        __typename?: 'CancelActiveOrderError'
        errorCode: ErrorCode
        message: string
        orderState: string
      }
    | {
        __typename?: 'EmptyOrderLineSelectionError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'MultipleOrderError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'Order'
        nextStates: Array<string>
        aggregateOrderId?: string | null
        id: string
        createdAt: any
        updatedAt: any
        type: OrderType
        orderPlacedAt?: any | null
        code: string
        state: string
        active: boolean
        couponCodes: Array<string>
        totalQuantity: number
        subTotal: number
        subTotalWithTax: number
        currencyCode: CurrencyCode
        shipping: number
        shippingWithTax: number
        total: number
        totalWithTax: number
        customFields?: any | null
      }
    | {
        __typename?: 'OrderStateTransitionError'
        errorCode: ErrorCode
        message: string
        transitionError: string
        fromState: string
        toState: string
      }
    | {
        __typename?: 'QuantityTooGreatError'
        errorCode: ErrorCode
        message: string
      }
}

export type RefundOrderMutationVariables = Exact<{
  input: RefundOrderInput
}>

export type RefundOrderMutation = {
  __typename?: 'Mutation'
  refundOrder:
    | {
        __typename?: 'AlreadyRefundedError'
        errorCode: ErrorCode
        message: string
        refundId: string
      }
    | {
        __typename?: 'MultipleOrderError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'NothingToRefundError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'OrderStateTransitionError'
        errorCode: ErrorCode
        message: string
        transitionError: string
        fromState: string
        toState: string
      }
    | {
        __typename?: 'PaymentOrderMismatchError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'QuantityTooGreatError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'Refund'
        id: string
        createdAt: any
        updatedAt: any
        items: number
        shipping: number
        adjustment: number
        total: number
        method?: string | null
        state: string
        transactionId?: string | null
        reason?: string | null
        paymentId: string
        metadata?: any | null
        customFields?: any | null
      }
    | {
        __typename?: 'RefundAmountError'
        errorCode: ErrorCode
        message: string
        maximumRefundable: number
      }
    | {
        __typename?: 'RefundOrderStateError'
        errorCode: ErrorCode
        message: string
        orderState: string
      }
    | {
        __typename?: 'RefundStateTransitionError'
        errorCode: ErrorCode
        message: string
        transitionError: string
        fromState: string
        toState: string
      }
}

export type AddNoteToOrderMutationVariables = Exact<{
  input: AddNoteToOrderInput
}>

export type AddNoteToOrderMutation = {
  __typename?: 'Mutation'
  addNoteToOrder: {
    __typename?: 'Order'
    nextStates: Array<string>
    aggregateOrderId?: string | null
    id: string
    createdAt: any
    updatedAt: any
    type: OrderType
    orderPlacedAt?: any | null
    code: string
    state: string
    active: boolean
    couponCodes: Array<string>
    totalQuantity: number
    subTotal: number
    subTotalWithTax: number
    currencyCode: CurrencyCode
    shipping: number
    shippingWithTax: number
    total: number
    totalWithTax: number
    customFields?: any | null
  }
}

export type UpdateOrderNoteMutationVariables = Exact<{
  input: UpdateOrderNoteInput
}>

export type UpdateOrderNoteMutation = {
  __typename?: 'Mutation'
  updateOrderNote: {
    __typename?: 'HistoryEntry'
    isPublic: boolean
    id: string
    createdAt: any
    updatedAt: any
    type: HistoryEntryType
    data: any
    customFields?: any | null
  }
}

export type DeleteOrderNoteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteOrderNoteMutation = {
  __typename?: 'Mutation'
  deleteOrderNote: {
    __typename?: 'DeletionResponse'
    result: DeletionResult
    message?: string | null
  }
}

export type TransitionOrderToStateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  state: Scalars['String']['input']
}>

export type TransitionOrderToStateMutation = {
  __typename?: 'Mutation'
  transitionOrderToState?:
    | {
        __typename?: 'Order'
        nextStates: Array<string>
        aggregateOrderId?: string | null
        id: string
        createdAt: any
        updatedAt: any
        type: OrderType
        orderPlacedAt?: any | null
        code: string
        state: string
        active: boolean
        couponCodes: Array<string>
        totalQuantity: number
        subTotal: number
        subTotalWithTax: number
        currencyCode: CurrencyCode
        shipping: number
        shippingWithTax: number
        total: number
        totalWithTax: number
        customFields?: any | null
      }
    | {
        __typename?: 'OrderStateTransitionError'
        errorCode: ErrorCode
        message: string
        transitionError: string
        fromState: string
        toState: string
      }
    | null
}

export type SetOrderCustomerMutationVariables = Exact<{
  input: SetOrderCustomerInput
}>

export type SetOrderCustomerMutation = {
  __typename?: 'Mutation'
  setOrderCustomer?: {
    __typename?: 'Order'
    nextStates: Array<string>
    aggregateOrderId?: string | null
    id: string
    createdAt: any
    updatedAt: any
    type: OrderType
    orderPlacedAt?: any | null
    code: string
    state: string
    active: boolean
    couponCodes: Array<string>
    totalQuantity: number
    subTotal: number
    subTotalWithTax: number
    currencyCode: CurrencyCode
    shipping: number
    shippingWithTax: number
    total: number
    totalWithTax: number
    customFields?: any | null
  } | null
}

export type ModifyOrderMutationVariables = Exact<{
  input: ModifyOrderInput
}>

export type ModifyOrderMutation = {
  __typename?: 'Mutation'
  modifyOrder:
    | {
        __typename?: 'CouponCodeExpiredError'
        errorCode: ErrorCode
        message: string
        couponCode: string
      }
    | {
        __typename?: 'CouponCodeInvalidError'
        errorCode: ErrorCode
        message: string
        couponCode: string
      }
    | {
        __typename?: 'CouponCodeLimitError'
        errorCode: ErrorCode
        message: string
        couponCode: string
        limit: number
      }
    | {
        __typename?: 'IneligibleShippingMethodError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'InsufficientStockError'
        errorCode: ErrorCode
        message: string
        quantityAvailable: number
      }
    | {
        __typename?: 'NegativeQuantityError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'NoChangesSpecifiedError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'Order'
        nextStates: Array<string>
        aggregateOrderId?: string | null
        id: string
        createdAt: any
        updatedAt: any
        type: OrderType
        orderPlacedAt?: any | null
        code: string
        state: string
        active: boolean
        couponCodes: Array<string>
        totalQuantity: number
        subTotal: number
        subTotalWithTax: number
        currencyCode: CurrencyCode
        shipping: number
        shippingWithTax: number
        total: number
        totalWithTax: number
        customFields?: any | null
        lines: Array<{
          __typename?: 'OrderLine'
          id: string
          createdAt: any
          updatedAt: any
          unitPrice: number
          unitPriceWithTax: number
          unitPriceChangeSinceAdded: number
          unitPriceWithTaxChangeSinceAdded: number
          discountedUnitPrice: number
          discountedUnitPriceWithTax: number
          proratedUnitPrice: number
          proratedUnitPriceWithTax: number
          quantity: number
          orderPlacedQuantity: number
          taxRate: number
          linePrice: number
          linePriceWithTax: number
          discountedLinePrice: number
          discountedLinePriceWithTax: number
          proratedLinePrice: number
          proratedLinePriceWithTax: number
          lineTax: number
          customFields?: any | null
          productVariant: {
            __typename?: 'ProductVariant'
            enabled: boolean
            trackInventory: GlobalFlag
            stockOnHand: number
            stockAllocated: number
            outOfStockThreshold: number
            useGlobalOutOfStockThreshold: boolean
            id: string
            productId: string
            createdAt: any
            updatedAt: any
            languageCode: LanguageCode
            sku: string
            name: string
            price: number
            currencyCode: CurrencyCode
            priceWithTax: number
            stockLevel: string
          }
        }>
        surcharges: Array<{
          __typename?: 'Surcharge'
          id: string
          createdAt: any
          updatedAt: any
          description: string
          sku?: string | null
          price: number
          priceWithTax: number
          taxRate: number
        }>
        shippingLines: Array<{
          __typename?: 'ShippingLine'
          id: string
          price: number
          priceWithTax: number
          discountedPrice: number
          discountedPriceWithTax: number
          customFields?: any | null
          shippingMethod: {
            __typename?: 'ShippingMethod'
            id: string
            createdAt: any
            updatedAt: any
            languageCode: LanguageCode
            code: string
            name: string
            description: string
            fulfillmentHandlerCode: string
            customFields?: any | null
          }
        }>
      }
    | {
        __typename?: 'OrderLimitError'
        errorCode: ErrorCode
        message: string
        maxItems: number
      }
    | {
        __typename?: 'OrderModificationStateError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'PaymentMethodMissingError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'RefundPaymentIdMissingError'
        errorCode: ErrorCode
        message: string
      }
}

export type AddManualPaymentToOrderMutationVariables = Exact<{
  input: ManualPaymentInput
}>

export type AddManualPaymentToOrderMutation = {
  __typename?: 'Mutation'
  addManualPaymentToOrder:
    | {
        __typename?: 'ManualPaymentStateError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'Order'
        nextStates: Array<string>
        aggregateOrderId?: string | null
        id: string
        createdAt: any
        updatedAt: any
        type: OrderType
        orderPlacedAt?: any | null
        code: string
        state: string
        active: boolean
        couponCodes: Array<string>
        totalQuantity: number
        subTotal: number
        subTotalWithTax: number
        currencyCode: CurrencyCode
        shipping: number
        shippingWithTax: number
        total: number
        totalWithTax: number
        customFields?: any | null
      }
}

export type CreateDraftOrderMutationVariables = Exact<{ [key: string]: never }>

export type CreateDraftOrderMutation = {
  __typename?: 'Mutation'
  createDraftOrder: {
    __typename?: 'Order'
    nextStates: Array<string>
    aggregateOrderId?: string | null
    id: string
    createdAt: any
    updatedAt: any
    type: OrderType
    orderPlacedAt?: any | null
    code: string
    state: string
    active: boolean
    couponCodes: Array<string>
    totalQuantity: number
    subTotal: number
    subTotalWithTax: number
    currencyCode: CurrencyCode
    shipping: number
    shippingWithTax: number
    total: number
    totalWithTax: number
    customFields?: any | null
  }
}

export type DeleteDraftOrderMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteDraftOrderMutation = {
  __typename?: 'Mutation'
  deleteDraftOrder: {
    __typename?: 'DeletionResponse'
    result: DeletionResult
    message?: string | null
  }
}

export type AddItemToDraftOrderMutationVariables = Exact<{
  orderId: Scalars['ID']['input']
  input: AddItemToDraftOrderInput
}>

export type AddItemToDraftOrderMutation = {
  __typename?: 'Mutation'
  addItemToDraftOrder:
    | {
        __typename?: 'InsufficientStockError'
        errorCode: ErrorCode
        message: string
        quantityAvailable: number
      }
    | {
        __typename?: 'NegativeQuantityError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'Order'
        nextStates: Array<string>
        aggregateOrderId?: string | null
        id: string
        createdAt: any
        updatedAt: any
        type: OrderType
        orderPlacedAt?: any | null
        code: string
        state: string
        active: boolean
        couponCodes: Array<string>
        totalQuantity: number
        subTotal: number
        subTotalWithTax: number
        currencyCode: CurrencyCode
        shipping: number
        shippingWithTax: number
        total: number
        totalWithTax: number
        customFields?: any | null
      }
    | {
        __typename?: 'OrderInterceptorError'
        errorCode: ErrorCode
        message: string
        interceptorError: string
      }
    | {
        __typename?: 'OrderLimitError'
        errorCode: ErrorCode
        message: string
        maxItems: number
      }
    | {
        __typename?: 'OrderModificationError'
        errorCode: ErrorCode
        message: string
      }
}

export type AdjustDraftOrderLineMutationVariables = Exact<{
  orderId: Scalars['ID']['input']
  input: AdjustDraftOrderLineInput
}>

export type AdjustDraftOrderLineMutation = {
  __typename?: 'Mutation'
  adjustDraftOrderLine:
    | {
        __typename?: 'InsufficientStockError'
        errorCode: ErrorCode
        message: string
        quantityAvailable: number
      }
    | {
        __typename?: 'NegativeQuantityError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'Order'
        nextStates: Array<string>
        aggregateOrderId?: string | null
        id: string
        createdAt: any
        updatedAt: any
        type: OrderType
        orderPlacedAt?: any | null
        code: string
        state: string
        active: boolean
        couponCodes: Array<string>
        totalQuantity: number
        subTotal: number
        subTotalWithTax: number
        currencyCode: CurrencyCode
        shipping: number
        shippingWithTax: number
        total: number
        totalWithTax: number
        customFields?: any | null
      }
    | {
        __typename?: 'OrderInterceptorError'
        errorCode: ErrorCode
        message: string
        interceptorError: string
      }
    | {
        __typename?: 'OrderLimitError'
        errorCode: ErrorCode
        message: string
        maxItems: number
      }
    | {
        __typename?: 'OrderModificationError'
        errorCode: ErrorCode
        message: string
      }
}

export type RemoveDraftOrderLineMutationVariables = Exact<{
  orderId: Scalars['ID']['input']
  orderLineId: Scalars['ID']['input']
}>

export type RemoveDraftOrderLineMutation = {
  __typename?: 'Mutation'
  removeDraftOrderLine:
    | {
        __typename?: 'Order'
        nextStates: Array<string>
        aggregateOrderId?: string | null
        id: string
        createdAt: any
        updatedAt: any
        type: OrderType
        orderPlacedAt?: any | null
        code: string
        state: string
        active: boolean
        couponCodes: Array<string>
        totalQuantity: number
        subTotal: number
        subTotalWithTax: number
        currencyCode: CurrencyCode
        shipping: number
        shippingWithTax: number
        total: number
        totalWithTax: number
        customFields?: any | null
      }
    | {
        __typename?: 'OrderInterceptorError'
        errorCode: ErrorCode
        message: string
        interceptorError: string
      }
    | {
        __typename?: 'OrderModificationError'
        errorCode: ErrorCode
        message: string
      }
}

export type SetCustomerForDraftOrderMutationVariables = Exact<{
  orderId: Scalars['ID']['input']
  customerId: Scalars['ID']['input']
  input: CreateCustomerInput
}>

export type SetCustomerForDraftOrderMutation = {
  __typename?: 'Mutation'
  setCustomerForDraftOrder:
    | {
        __typename?: 'EmailAddressConflictError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'Order'
        nextStates: Array<string>
        aggregateOrderId?: string | null
        id: string
        createdAt: any
        updatedAt: any
        type: OrderType
        orderPlacedAt?: any | null
        code: string
        state: string
        active: boolean
        couponCodes: Array<string>
        totalQuantity: number
        subTotal: number
        subTotalWithTax: number
        currencyCode: CurrencyCode
        shipping: number
        shippingWithTax: number
        total: number
        totalWithTax: number
        customFields?: any | null
      }
}

export type SetDraftOrderShippingAddressMutationVariables = Exact<{
  orderId: Scalars['ID']['input']
  input: CreateAddressInput
}>

export type SetDraftOrderShippingAddressMutation = {
  __typename?: 'Mutation'
  setDraftOrderShippingAddress: {
    __typename?: 'Order'
    nextStates: Array<string>
    aggregateOrderId?: string | null
    id: string
    createdAt: any
    updatedAt: any
    type: OrderType
    orderPlacedAt?: any | null
    code: string
    state: string
    active: boolean
    couponCodes: Array<string>
    totalQuantity: number
    subTotal: number
    subTotalWithTax: number
    currencyCode: CurrencyCode
    shipping: number
    shippingWithTax: number
    total: number
    totalWithTax: number
    customFields?: any | null
  }
}

export type SetDraftOrderBillingAddressMutationVariables = Exact<{
  orderId: Scalars['ID']['input']
  input: CreateAddressInput
}>

export type SetDraftOrderBillingAddressMutation = {
  __typename?: 'Mutation'
  setDraftOrderBillingAddress: {
    __typename?: 'Order'
    nextStates: Array<string>
    aggregateOrderId?: string | null
    id: string
    createdAt: any
    updatedAt: any
    type: OrderType
    orderPlacedAt?: any | null
    code: string
    state: string
    active: boolean
    couponCodes: Array<string>
    totalQuantity: number
    subTotal: number
    subTotalWithTax: number
    currencyCode: CurrencyCode
    shipping: number
    shippingWithTax: number
    total: number
    totalWithTax: number
    customFields?: any | null
  }
}

export type UnsetDraftOrderShippingAddressMutationVariables = Exact<{
  orderId: Scalars['ID']['input']
}>

export type UnsetDraftOrderShippingAddressMutation = {
  __typename?: 'Mutation'
  unsetDraftOrderShippingAddress: {
    __typename?: 'Order'
    nextStates: Array<string>
    aggregateOrderId?: string | null
    id: string
    createdAt: any
    updatedAt: any
    type: OrderType
    orderPlacedAt?: any | null
    code: string
    state: string
    active: boolean
    couponCodes: Array<string>
    totalQuantity: number
    subTotal: number
    subTotalWithTax: number
    currencyCode: CurrencyCode
    shipping: number
    shippingWithTax: number
    total: number
    totalWithTax: number
    customFields?: any | null
  }
}

export type UnsetDraftOrderBillingAddressMutationVariables = Exact<{
  orderId: Scalars['ID']['input']
}>

export type UnsetDraftOrderBillingAddressMutation = {
  __typename?: 'Mutation'
  unsetDraftOrderBillingAddress: {
    __typename?: 'Order'
    nextStates: Array<string>
    aggregateOrderId?: string | null
    id: string
    createdAt: any
    updatedAt: any
    type: OrderType
    orderPlacedAt?: any | null
    code: string
    state: string
    active: boolean
    couponCodes: Array<string>
    totalQuantity: number
    subTotal: number
    subTotalWithTax: number
    currencyCode: CurrencyCode
    shipping: number
    shippingWithTax: number
    total: number
    totalWithTax: number
    customFields?: any | null
  }
}

export type ApplyCouponCodeToDraftOrderMutationVariables = Exact<{
  orderId: Scalars['ID']['input']
  couponCode: Scalars['String']['input']
}>

export type ApplyCouponCodeToDraftOrderMutation = {
  __typename?: 'Mutation'
  applyCouponCodeToDraftOrder:
    | {
        __typename?: 'CouponCodeExpiredError'
        errorCode: ErrorCode
        message: string
        couponCode: string
      }
    | {
        __typename?: 'CouponCodeInvalidError'
        errorCode: ErrorCode
        message: string
        couponCode: string
      }
    | {
        __typename?: 'CouponCodeLimitError'
        errorCode: ErrorCode
        message: string
        couponCode: string
        limit: number
      }
    | {
        __typename?: 'Order'
        nextStates: Array<string>
        aggregateOrderId?: string | null
        id: string
        createdAt: any
        updatedAt: any
        type: OrderType
        orderPlacedAt?: any | null
        code: string
        state: string
        active: boolean
        couponCodes: Array<string>
        totalQuantity: number
        subTotal: number
        subTotalWithTax: number
        currencyCode: CurrencyCode
        shipping: number
        shippingWithTax: number
        total: number
        totalWithTax: number
        customFields?: any | null
      }
}

export type RemoveCouponCodeFromDraftOrderMutationVariables = Exact<{
  orderId: Scalars['ID']['input']
  couponCode: Scalars['String']['input']
}>

export type RemoveCouponCodeFromDraftOrderMutation = {
  __typename?: 'Mutation'
  removeCouponCodeFromDraftOrder?: {
    __typename?: 'Order'
    nextStates: Array<string>
    aggregateOrderId?: string | null
    id: string
    createdAt: any
    updatedAt: any
    type: OrderType
    orderPlacedAt?: any | null
    code: string
    state: string
    active: boolean
    couponCodes: Array<string>
    totalQuantity: number
    subTotal: number
    subTotalWithTax: number
    currencyCode: CurrencyCode
    shipping: number
    shippingWithTax: number
    total: number
    totalWithTax: number
    customFields?: any | null
  } | null
}

export type SetDraftOrderShippingMethodMutationVariables = Exact<{
  orderId: Scalars['ID']['input']
  shippingMethodId: Scalars['ID']['input']
}>

export type SetDraftOrderShippingMethodMutation = {
  __typename?: 'Mutation'
  setDraftOrderShippingMethod:
    | {
        __typename?: 'IneligibleShippingMethodError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'NoActiveOrderError'
        errorCode: ErrorCode
        message: string
      }
    | {
        __typename?: 'Order'
        nextStates: Array<string>
        aggregateOrderId?: string | null
        id: string
        createdAt: any
        updatedAt: any
        type: OrderType
        orderPlacedAt?: any | null
        code: string
        state: string
        active: boolean
        couponCodes: Array<string>
        totalQuantity: number
        subTotal: number
        subTotalWithTax: number
        currencyCode: CurrencyCode
        shipping: number
        shippingWithTax: number
        total: number
        totalWithTax: number
        customFields?: any | null
      }
    | {
        __typename?: 'OrderModificationError'
        errorCode: ErrorCode
        message: string
      }
}

export type ProductSelectorSearchQueryVariables = Exact<{
  take: Scalars['Int']['input']
  term: Scalars['String']['input']
}>

export type ProductSelectorSearchQuery = {
  __typename?: 'Query'
  search: {
    __typename: 'SearchResponse'
    items: Array<{
      __typename: 'SearchResult'
      productVariantId: string
      productVariantName: string
      sku: string
      productAsset?: {
        __typename: 'SearchResultAsset'
        id: string
        preview: string
        focalPoint?: { __typename: 'Coordinate'; x: number; y: number } | null
      } | null
      price:
        | { __typename: 'PriceRange' }
        | { __typename: 'SinglePrice'; value: number }
      priceWithTax:
        | { __typename: 'PriceRange' }
        | { __typename: 'SinglePrice'; value: number }
    }>
  }
}

export type EligibleShippingMethodsForDraftOrderQueryVariables = Exact<{
  orderId: Scalars['ID']['input']
}>

export type EligibleShippingMethodsForDraftOrderQuery = {
  __typename?: 'Query'
  eligibleShippingMethodsForDraftOrder: Array<{
    __typename?: 'ShippingMethodQuote'
    id: string
    price: number
    priceWithTax: number
    code: string
    name: string
    description: string
    metadata?: any | null
    customFields?: any | null
  }>
}

export type TransitionFulfillmentToStateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  state: Scalars['String']['input']
}>

export type TransitionFulfillmentToStateMutation = {
  __typename?: 'Mutation'
  transitionFulfillmentToState:
    | {
        __typename?: 'Fulfillment'
        nextStates: Array<string>
        id: string
        createdAt: any
        updatedAt: any
        state: string
        method: string
        trackingCode?: string | null
        customFields?: any | null
      }
    | { __typename?: 'FulfillmentStateTransitionError' }
}

export type GetOrderHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input']
  options?: InputMaybe<HistoryEntryListOptions>
}>

export type GetOrderHistoryQuery = {
  __typename?: 'Query'
  order?: {
    __typename: 'Order'
    id: string
    history: {
      __typename: 'HistoryEntryList'
      totalItems: number
      items: Array<{
        __typename: 'HistoryEntry'
        id: string
        type: HistoryEntryType
        createdAt: any
        isPublic: boolean
        data: any
        administrator?: {
          __typename: 'Administrator'
          id: string
          firstName: string
          lastName: string
        } | null
      }>
    }
  } | null
}

export type AddFulfillmentToOrderMutationVariables = Exact<{
  input: FulfillOrderInput
}>

export type AddFulfillmentToOrderMutation = {
  __typename?: 'Mutation'
  addFulfillmentToOrder:
    | { __typename?: 'CreateFulfillmentError' }
    | { __typename?: 'EmptyOrderLineSelectionError' }
    | {
        __typename?: 'Fulfillment'
        nextStates: Array<string>
        id: string
        createdAt: any
        updatedAt: any
        state: string
        method: string
        trackingCode?: string | null
        customFields?: any | null
      }
    | { __typename?: 'FulfillmentStateTransitionError' }
    | { __typename?: 'InsufficientStockOnHandError' }
    | { __typename?: 'InvalidFulfillmentHandlerError' }
    | { __typename?: 'ItemsAlreadyFulfilledError' }
}

export type OrdersQueryVariables = Exact<{
  orderListOptions: OrderListOptions
}>

export type OrdersQuery = {
  __typename?: 'Query'
  orders: {
    __typename?: 'OrderList'
    totalItems: number
    items: Array<{
      __typename?: 'Order'
      nextStates: Array<string>
      aggregateOrderId?: string | null
      id: string
      createdAt: any
      updatedAt: any
      orderPlacedAt?: any | null
      code: string
      state: string
      active: boolean
      couponCodes: Array<string>
      totalQuantity: number
      subTotal: number
      subTotalWithTax: number
      currencyCode: CurrencyCode
      shipping: number
      shippingWithTax: number
      total: number
      totalWithTax: number
      customFields?: any | null
      shippingLines: Array<{
        __typename?: 'ShippingLine'
        customFields?: any | null
        discountedPriceWithTax: number
        discountedPrice: number
        priceWithTax: number
        price: number
        id: string
        discounts: Array<{
          __typename?: 'Discount'
          adjustmentSource: string
          type: AdjustmentType
          description: string
          amount: number
          amountWithTax: number
        }>
        shippingMethod: {
          __typename?: 'ShippingMethod'
          id: string
          createdAt: any
          updatedAt: any
          languageCode: LanguageCode
          code: string
          name: string
          description: string
          fulfillmentHandlerCode: string
          customFields?: any | null
          translations: Array<{
            __typename?: 'ShippingMethodTranslation'
            id: string
            createdAt: any
            updatedAt: any
            languageCode: LanguageCode
            name: string
            description: string
          }>
        }
      }>
      aggregateOrder?: {
        __typename?: 'Order'
        nextStates: Array<string>
        aggregateOrderId?: string | null
        id: string
        createdAt: any
        updatedAt: any
        orderPlacedAt?: any | null
        code: string
        state: string
        active: boolean
        couponCodes: Array<string>
        totalQuantity: number
        subTotal: number
        subTotalWithTax: number
        currencyCode: CurrencyCode
        shipping: number
        shippingWithTax: number
        total: number
        totalWithTax: number
        customFields?: any | null
      } | null
      customer?: {
        __typename?: 'Customer'
        id: string
        createdAt: any
        updatedAt: any
        title?: string | null
        firstName: string
        lastName: string
        phoneNumber?: string | null
        emailAddress: string
        customFields?: any | null
      } | null
      shippingAddress?: {
        __typename?: 'OrderAddress'
        fullName?: string | null
        company?: string | null
        streetLine1?: string | null
        streetLine2?: string | null
        city?: string | null
        province?: string | null
        postalCode?: string | null
        country?: string | null
        countryCode?: string | null
        phoneNumber?: string | null
        customFields?: any | null
      } | null
    }>
  }
}

export type GetAddManualPaymentMethodListQueryVariables = Exact<{
  options: PaymentMethodListOptions
}>

export type GetAddManualPaymentMethodListQuery = {
  __typename?: 'Query'
  paymentMethods: {
    __typename?: 'PaymentMethodList'
    totalItems: number
    items: Array<{
      __typename?: 'PaymentMethod'
      id: string
      createdAt: any
      updatedAt: any
      name: string
      code: string
      description: string
      enabled: boolean
    }>
  }
}

export type CancelPaymentMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CancelPaymentMutation = {
  __typename?: 'Mutation'
  cancelPayment:
    | { __typename?: 'CancelPaymentError' }
    | {
        __typename?: 'Payment'
        nextStates: Array<string>
        id: string
        createdAt: any
        updatedAt: any
        method: string
        amount: number
        state: string
        transactionId?: string | null
        errorMessage?: string | null
        metadata?: any | null
        customFields?: any | null
      }
    | { __typename?: 'PaymentStateTransitionError' }
}

export type SettleRefundMutationVariables = Exact<{
  input: SettleRefundInput
}>

export type SettleRefundMutation = {
  __typename?: 'Mutation'
  settleRefund:
    | {
        __typename?: 'Refund'
        id: string
        createdAt: any
        updatedAt: any
        items: number
        shipping: number
        adjustment: number
        total: number
        method?: string | null
        state: string
        transactionId?: string | null
        reason?: string | null
        paymentId: string
        metadata?: any | null
        customFields?: any | null
      }
    | { __typename?: 'RefundStateTransitionError' }
}

export type ProductQueryVariables = Exact<{
  productId?: InputMaybe<Scalars['ID']['input']>
}>

export type ProductQuery = {
  __typename?: 'Query'
  product?: {
    __typename?: 'Product'
    name: string
    description: string
    slug: string
    id: string
    languageCode: LanguageCode
    enabled: boolean
    assets: Array<{
      __typename?: 'Asset'
      height: number
      id: string
      mimeType: string
      name: string
      preview: string
      source: string
      width: number
      fileSize: number
      createdAt: any
      updatedAt: any
      type: AssetType
      focalPoint?: { __typename?: 'Coordinate'; x: number; y: number } | null
      tags: Array<{ __typename?: 'Tag'; value: string; id: string }>
    }>
    featuredAsset?: {
      __typename?: 'Asset'
      height: number
      id: string
      mimeType: string
      name: string
      preview: string
      source: string
      width: number
      fileSize: number
      createdAt: any
      updatedAt: any
      type: AssetType
      focalPoint?: { __typename?: 'Coordinate'; x: number; y: number } | null
      tags: Array<{ __typename?: 'Tag'; value: string; id: string }>
    } | null
    optionGroups: Array<{
      __typename?: 'ProductOptionGroup'
      code: string
      id: string
      languageCode: LanguageCode
      name: string
      options: Array<{
        __typename?: 'ProductOption'
        code: string
        groupId: string
        name: string
        languageCode: LanguageCode
        id: string
        translations: Array<{
          __typename?: 'ProductOptionTranslation'
          name: string
          id: string
          languageCode: LanguageCode
        }>
      }>
      translations: Array<{
        __typename?: 'ProductOptionGroupTranslation'
        name: string
        id: string
        languageCode: LanguageCode
      }>
    }>
    facetValues: Array<{
      __typename?: 'FacetValue'
      facetId: string
      name: string
      languageCode: LanguageCode
      id: string
      code: string
    }>
    variants: Array<{
      __typename?: 'ProductVariant'
      id: string
      name: string
      sku: string
      productId: string
      enabled: boolean
      languageCode: LanguageCode
      price: number
      priceWithTax: number
      stockLevel: string
      outOfStockThreshold: number
      featuredAsset?: {
        __typename?: 'Asset'
        height: number
        id: string
        mimeType: string
        name: string
        preview: string
        source: string
        width: number
        fileSize: number
        createdAt: any
        updatedAt: any
        type: AssetType
        focalPoint?: { __typename?: 'Coordinate'; x: number; y: number } | null
        tags: Array<{ __typename?: 'Tag'; value: string; id: string }>
      } | null
      options: Array<{
        __typename?: 'ProductOption'
        code: string
        name: string
        languageCode: LanguageCode
        id: string
        groupId: string
        createdAt: any
        translations: Array<{
          __typename?: 'ProductOptionTranslation'
          name: string
          id: string
          languageCode: LanguageCode
        }>
      }>
      stockLevels: Array<{
        __typename?: 'StockLevel'
        stockLocationId: string
        id: string
        stockOnHand: number
        stockAllocated: number
        stockLocation: {
          __typename?: 'StockLocation'
          name: string
          id: string
          description: string
        }
      }>
      translations: Array<{
        __typename?: 'ProductVariantTranslation'
        name: string
        id: string
        languageCode: LanguageCode
      }>
    }>
  } | null
}

export type CreateProductMutationVariables = Exact<{
  input: CreateProductInput
}>

export type CreateProductMutation = {
  __typename?: 'Mutation'
  createProduct: {
    __typename?: 'Product'
    name: string
    description: string
    slug: string
    id: string
    languageCode: LanguageCode
    enabled: boolean
    assets: Array<{
      __typename?: 'Asset'
      height: number
      id: string
      mimeType: string
      name: string
      preview: string
      source: string
      width: number
      fileSize: number
      createdAt: any
      updatedAt: any
      type: AssetType
      focalPoint?: { __typename?: 'Coordinate'; x: number; y: number } | null
      tags: Array<{ __typename?: 'Tag'; value: string; id: string }>
    }>
    featuredAsset?: {
      __typename?: 'Asset'
      height: number
      id: string
      mimeType: string
      name: string
      preview: string
      source: string
      width: number
      fileSize: number
      createdAt: any
      updatedAt: any
      type: AssetType
      focalPoint?: { __typename?: 'Coordinate'; x: number; y: number } | null
      tags: Array<{ __typename?: 'Tag'; value: string; id: string }>
    } | null
    optionGroups: Array<{
      __typename?: 'ProductOptionGroup'
      code: string
      id: string
      languageCode: LanguageCode
      name: string
      options: Array<{
        __typename?: 'ProductOption'
        code: string
        groupId: string
        name: string
        languageCode: LanguageCode
        id: string
      }>
    }>
    facetValues: Array<{
      __typename?: 'FacetValue'
      facetId: string
      name: string
      languageCode: LanguageCode
      id: string
      code: string
    }>
    variants: Array<{
      __typename?: 'ProductVariant'
      id: string
      name: string
      sku: string
      productId: string
      enabled: boolean
      languageCode: LanguageCode
      price: number
      priceWithTax: number
      stockLevel: string
      outOfStockThreshold: number
      featuredAsset?: {
        __typename?: 'Asset'
        height: number
        id: string
        mimeType: string
        name: string
        preview: string
        source: string
        width: number
        fileSize: number
        createdAt: any
        updatedAt: any
        type: AssetType
        focalPoint?: { __typename?: 'Coordinate'; x: number; y: number } | null
        tags: Array<{ __typename?: 'Tag'; value: string; id: string }>
      } | null
      options: Array<{
        __typename?: 'ProductOption'
        code: string
        name: string
        languageCode: LanguageCode
        id: string
        groupId: string
        createdAt: any
      }>
      stockLevels: Array<{
        __typename?: 'StockLevel'
        stockLocationId: string
        id: string
        stockOnHand: number
        stockAllocated: number
        stockLocation: {
          __typename?: 'StockLocation'
          name: string
          id: string
          description: string
        }
      }>
    }>
  }
}

export type ProductVariantQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ProductVariantQuery = {
  __typename?: 'Query'
  productVariant?: {
    __typename?: 'ProductVariant'
    enabled: boolean
    trackInventory: GlobalFlag
    stockOnHand: number
    stockAllocated: number
    outOfStockThreshold: number
    useGlobalOutOfStockThreshold: boolean
    id: string
    productId: string
    createdAt: any
    updatedAt: any
    languageCode: LanguageCode
    sku: string
    name: string
    price: number
    currencyCode: CurrencyCode
    priceWithTax: number
    stockLevel: string
    stockLevels: Array<{
      __typename?: 'StockLevel'
      id: string
      createdAt: any
      updatedAt: any
      stockLocationId: string
      stockOnHand: number
      stockAllocated: number
      customFields?: any | null
    }>
    assets: Array<{
      __typename?: 'Asset'
      id: string
      createdAt: any
      updatedAt: any
      name: string
      type: AssetType
      fileSize: number
      mimeType: string
      width: number
      height: number
      source: string
      preview: string
      thumbnail: string
      customFields?: any | null
    }>
  } | null
}

export type CreateProductVariantsMutationVariables = Exact<{
  input: Array<CreateProductVariantInput> | CreateProductVariantInput
}>

export type CreateProductVariantsMutation = {
  __typename?: 'Mutation'
  createProductVariants: Array<{
    __typename?: 'ProductVariant'
    name: string
    id: string
    createdAt: any
    currencyCode: CurrencyCode
    enabled: boolean
    languageCode: LanguageCode
    outOfStockThreshold: number
    price: number
    priceWithTax: number
    productId: string
    sku: string
    updatedAt: any
    assets: Array<{
      __typename?: 'Asset'
      height: number
      id: string
      mimeType: string
      name: string
      preview: string
      source: string
      width: number
      fileSize: number
      createdAt: any
      updatedAt: any
      type: AssetType
      focalPoint?: { __typename?: 'Coordinate'; x: number; y: number } | null
      tags: Array<{ __typename?: 'Tag'; value: string; id: string }>
    }>
    facetValues: Array<{
      __typename?: 'FacetValue'
      facetId: string
      name: string
      languageCode: LanguageCode
      id: string
      code: string
    }>
    featuredAsset?: {
      __typename?: 'Asset'
      height: number
      id: string
      mimeType: string
      name: string
      preview: string
      source: string
      width: number
      fileSize: number
      createdAt: any
      updatedAt: any
      type: AssetType
      focalPoint?: { __typename?: 'Coordinate'; x: number; y: number } | null
      tags: Array<{ __typename?: 'Tag'; value: string; id: string }>
    } | null
    options: Array<{
      __typename?: 'ProductOption'
      code: string
      groupId: string
      name: string
      languageCode: LanguageCode
      id: string
    }>
  } | null>
}

export type UpdateProductVariantsMutationVariables = Exact<{
  input: Array<UpdateProductVariantInput> | UpdateProductVariantInput
}>

export type UpdateProductVariantsMutation = {
  __typename?: 'Mutation'
  updateProductVariants: Array<{
    __typename?: 'ProductVariant'
    enabled: boolean
    trackInventory: GlobalFlag
    stockOnHand: number
    stockAllocated: number
    outOfStockThreshold: number
    useGlobalOutOfStockThreshold: boolean
    id: string
    productId: string
    createdAt: any
    updatedAt: any
    languageCode: LanguageCode
    sku: string
    name: string
    price: number
    currencyCode: CurrencyCode
    priceWithTax: number
    stockLevel: string
  } | null>
}

export type CreateProductOptionGroupMutationVariables = Exact<{
  input: CreateProductOptionGroupInput
}>

export type CreateProductOptionGroupMutation = {
  __typename?: 'Mutation'
  createProductOptionGroup: {
    __typename?: 'ProductOptionGroup'
    code: string
    id: string
    name: string
    languageCode: LanguageCode
    options: Array<{
      __typename?: 'ProductOption'
      code: string
      groupId: string
      name: string
      languageCode: LanguageCode
      id: string
    }>
  }
}

export type UpdateProductOptionGroupMutationVariables = Exact<{
  input: UpdateProductOptionGroupInput
}>

export type UpdateProductOptionGroupMutation = {
  __typename?: 'Mutation'
  updateProductOptionGroup: {
    __typename?: 'ProductOptionGroup'
    code: string
    id: string
    name: string
    languageCode: LanguageCode
  }
}

export type CreateProductOptionMutationVariables = Exact<{
  input: CreateProductOptionInput
}>

export type CreateProductOptionMutation = {
  __typename?: 'Mutation'
  createProductOption: {
    __typename?: 'ProductOption'
    code: string
    groupId: string
    id: string
    languageCode: LanguageCode
    name: string
  }
}

export type UpdateProductOptionMutationVariables = Exact<{
  input: UpdateProductOptionInput
}>

export type UpdateProductOptionMutation = {
  __typename?: 'Mutation'
  updateProductOption: {
    __typename?: 'ProductOption'
    code: string
    name: string
    id: string
    languageCode: LanguageCode
  }
}

export type DeleteProductOptionMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteProductOptionMutation = {
  __typename?: 'Mutation'
  deleteProductOption: {
    __typename?: 'DeletionResponse'
    result: DeletionResult
    message?: string | null
  }
}

export type RemoveOptionGroupFromProductMutationVariables = Exact<{
  productId: Scalars['ID']['input']
  optionGroupId: Scalars['ID']['input']
  force?: InputMaybe<Scalars['Boolean']['input']>
}>

export type RemoveOptionGroupFromProductMutation = {
  __typename?: 'Mutation'
  removeOptionGroupFromProduct:
    | {
        __typename?: 'Product'
        id: string
        createdAt: any
        updatedAt: any
        languageCode: LanguageCode
        name: string
        slug: string
        description: string
        enabled: boolean
      }
    | { __typename?: 'ProductOptionInUseError' }
}

export type DeleteProductVariantMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteProductVariantMutation = {
  __typename?: 'Mutation'
  deleteProductVariant: {
    __typename?: 'DeletionResponse'
    result: DeletionResult
    message?: string | null
  }
}

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteProductMutation = {
  __typename?: 'Mutation'
  deleteProduct: {
    __typename?: 'DeletionResponse'
    result: DeletionResult
    message?: string | null
  }
}

export type UpdateProductMutationVariables = Exact<{
  input: UpdateProductInput
}>

export type UpdateProductMutation = {
  __typename?: 'Mutation'
  updateProduct: {
    __typename?: 'Product'
    id: string
    name: string
    slug: string
  }
}

export type AddOptionGroupToProductMutationVariables = Exact<{
  productId: Scalars['ID']['input']
  optionGroupId: Scalars['ID']['input']
}>

export type AddOptionGroupToProductMutation = {
  __typename?: 'Mutation'
  addOptionGroupToProduct: {
    __typename?: 'Product'
    id: string
    name: string
    slug: string
  }
}

export type GetProductVariantDetailQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetProductVariantDetailQuery = {
  __typename?: 'Query'
  productVariant?: {
    __typename?: 'ProductVariant'
    id: string
    createdAt: any
    updatedAt: any
    enabled: boolean
    languageCode: LanguageCode
    name: string
    price: number
    currencyCode: CurrencyCode
    priceWithTax: number
    stockOnHand: number
    stockAllocated: number
    trackInventory: GlobalFlag
    outOfStockThreshold: number
    useGlobalOutOfStockThreshold: boolean
    sku: string
    prices: Array<{
      __typename?: 'ProductVariantPrice'
      price: number
      currencyCode: CurrencyCode
    }>
    taxRateApplied: {
      __typename?: 'TaxRate'
      id: string
      name: string
      value: number
    }
    taxCategory: { __typename?: 'TaxCategory'; id: string; name: string }
    options: Array<{
      __typename?: 'ProductOption'
      id: string
      createdAt: any
      updatedAt: any
      code: string
      languageCode: LanguageCode
      name: string
      groupId: string
      translations: Array<{
        __typename?: 'ProductOptionTranslation'
        id: string
        languageCode: LanguageCode
        name: string
      }>
    }>
    stockLevels: Array<{
      __typename?: 'StockLevel'
      id: string
      createdAt: any
      updatedAt: any
      stockOnHand: number
      stockAllocated: number
      stockLocationId: string
      stockLocation: {
        __typename?: 'StockLocation'
        id: string
        createdAt: any
        updatedAt: any
        name: string
      }
    }>
    facetValues: Array<{
      __typename?: 'FacetValue'
      id: string
      code: string
      name: string
      facet: { __typename?: 'Facet'; id: string; name: string }
    }>
    featuredAsset?: {
      __typename?: 'Asset'
      id: string
      createdAt: any
      updatedAt: any
      name: string
      fileSize: number
      mimeType: string
      type: AssetType
      preview: string
      source: string
      width: number
      height: number
      focalPoint?: { __typename?: 'Coordinate'; x: number; y: number } | null
    } | null
    assets: Array<{
      __typename?: 'Asset'
      id: string
      createdAt: any
      updatedAt: any
      name: string
      fileSize: number
      mimeType: string
      type: AssetType
      preview: string
      source: string
      width: number
      height: number
      focalPoint?: { __typename?: 'Coordinate'; x: number; y: number } | null
    }>
    translations: Array<{
      __typename?: 'ProductVariantTranslation'
      id: string
      languageCode: LanguageCode
      name: string
    }>
    channels: Array<{ __typename?: 'Channel'; id: string; code: string }>
    product: {
      __typename?: 'Product'
      id: string
      name: string
      optionGroups: Array<{
        __typename?: 'ProductOptionGroup'
        id: string
        name: string
        code: string
        translations: Array<{
          __typename?: 'ProductOptionGroupTranslation'
          id: string
          languageCode: LanguageCode
          name: string
        }>
      }>
    }
  } | null
  stockLocations: {
    __typename?: 'StockLocationList'
    items: Array<{
      __typename?: 'StockLocation'
      id: string
      createdAt: any
      updatedAt: any
      name: string
      description: string
    }>
  }
  taxCategories: {
    __typename?: 'TaxCategoryList'
    totalItems: number
    items: Array<{
      __typename?: 'TaxCategory'
      id: string
      createdAt: any
      updatedAt: any
      name: string
      isDefault: boolean
    }>
  }
  activeChannel: {
    __typename?: 'Channel'
    id: string
    availableCurrencyCodes: Array<CurrencyCode>
    defaultCurrencyCode: CurrencyCode
    pricesIncludeTax: boolean
  }
}

export type GetChannelAndTaxesQueryVariables = Exact<{ [key: string]: never }>

export type GetChannelAndTaxesQuery = {
  __typename?: 'Query'
  activeChannel: {
    __typename?: 'Channel'
    id: string
    createdAt: any
    updatedAt: any
    code: string
    token: string
    defaultLanguageCode: LanguageCode
    availableLanguageCodes?: Array<LanguageCode> | null
    currencyCode: CurrencyCode
    defaultCurrencyCode: CurrencyCode
    availableCurrencyCodes: Array<CurrencyCode>
    trackInventory?: boolean | null
    outOfStockThreshold?: number | null
    pricesIncludeTax: boolean
    defaultTaxZone?: {
      __typename?: 'Zone'
      id: string
      createdAt: any
      updatedAt: any
      name: string
      customFields?: any | null
    } | null
  }
  taxRates: {
    __typename?: 'TaxRateList'
    totalItems: number
    items: Array<{
      __typename?: 'TaxRate'
      id: string
      createdAt: any
      updatedAt: any
      name: string
      enabled: boolean
      value: number
      customFields?: any | null
      category: {
        __typename?: 'TaxCategory'
        id: string
        createdAt: any
        updatedAt: any
        name: string
        isDefault: boolean
        customFields?: any | null
      }
      zone: {
        __typename?: 'Zone'
        id: string
        createdAt: any
        updatedAt: any
        name: string
        customFields?: any | null
      }
    }>
  }
}

export type ProductVariantsQueryVariables = Exact<{ [key: string]: never }>

export type ProductVariantsQuery = {
  __typename?: 'Query'
  productVariants: {
    __typename?: 'ProductVariantList'
    totalItems: number
    items: Array<{
      __typename?: 'ProductVariant'
      enabled: boolean
      trackInventory: GlobalFlag
      stockOnHand: number
      stockAllocated: number
      outOfStockThreshold: number
      useGlobalOutOfStockThreshold: boolean
      id: string
      productId: string
      createdAt: any
      updatedAt: any
      languageCode: LanguageCode
      sku: string
      name: string
      price: number
      currencyCode: CurrencyCode
      priceWithTax: number
      stockLevel: string
      translations: Array<{
        __typename?: 'ProductVariantTranslation'
        id: string
        createdAt: any
        updatedAt: any
        languageCode: LanguageCode
        name: string
      }>
    }>
  }
}

export type ProductsQueryVariables = Exact<{
  options?: InputMaybe<ProductListOptions>
}>

export type ProductsQuery = {
  __typename?: 'Query'
  products: {
    __typename?: 'ProductList'
    totalItems: number
    items: Array<{
      __typename?: 'Product'
      id: string
      enabled: boolean
      description: string
      createdAt: any
      name: string
      languageCode: LanguageCode
      slug: string
      updatedAt: any
      channels: Array<{
        __typename?: 'Channel'
        code: string
        id: string
        token: string
        seller?: {
          __typename?: 'Seller'
          name: string
          id: string
          createdAt: any
        } | null
      }>
      featuredAsset?: {
        __typename?: 'Asset'
        id: string
        height: number
        mimeType: string
        name: string
        preview: string
        source: string
        fileSize: number
        type: AssetType
        width: number
        updatedAt: any
        focalPoint?: { __typename?: 'Coordinate'; y: number; x: number } | null
        tags: Array<{
          __typename?: 'Tag'
          value: string
          updatedAt: any
          id: string
          createdAt: any
        }>
      } | null
      variants: Array<{
        __typename?: 'ProductVariant'
        name: string
        stockLevel: string
        enabled: boolean
        stockLevels: Array<{
          __typename?: 'StockLevel'
          stockAllocated: number
          stockOnHand: number
          id: string
          createdAt: any
          updatedAt: any
        }>
      }>
      translations: Array<{
        __typename?: 'ProductTranslation'
        id: string
        description: string
        languageCode: LanguageCode
        name: string
        slug: string
      }>
    }>
  }
}

export type GetCouponCodeSelectorPromotionListQueryVariables = Exact<{
  options?: InputMaybe<PromotionListOptions>
}>

export type GetCouponCodeSelectorPromotionListQuery = {
  __typename?: 'Query'
  promotions: {
    __typename: 'PromotionList'
    totalItems: number
    items: Array<{
      __typename: 'Promotion'
      id: string
      name: string
      couponCode?: string | null
    }>
  }
}

export type RegisterMutationVariables = Exact<{
  authenticationRegisterInput: AuthenticationRegisterInput
}>

export type RegisterMutation = {
  __typename?: 'Mutation'
  register?: { __typename?: 'RegisterSellerResponse'; result: boolean } | null
}

export type VerifyMutationVariables = Exact<{
  token: Scalars['String']['input']
}>

export type VerifyMutation = {
  __typename?: 'Mutation'
  verify?: {
    __typename?: 'VerifySellerResponse'
    success: boolean
    message: string
  } | null
}

export type SearchQueryVariables = Exact<{
  input: SearchInput
}>

export type SearchQuery = {
  __typename?: 'Query'
  search: {
    __typename?: 'SearchResponse'
    facetValues: Array<{
      __typename?: 'FacetValueResult'
      count: number
      facetValue: {
        __typename?: 'FacetValue'
        id: string
        name: string
        code: string
        createdAt: any
        languageCode: LanguageCode
        updatedAt: any
        facet: {
          __typename?: 'Facet'
          name: string
          code: string
          id: string
          isPrivate: boolean
          languageCode: LanguageCode
          createdAt: any
        }
      }
    }>
  }
}

export type StockLocationsQueryVariables = Exact<{
  input?: InputMaybe<StockLocationListOptions>
}>

export type StockLocationsQuery = {
  __typename?: 'Query'
  stockLocations: {
    __typename?: 'StockLocationList'
    totalItems: number
    items: Array<{
      __typename?: 'StockLocation'
      id: string
      name: string
      description: string
    }>
  }
}

export const InsertGroupDocument = gql`
  mutation InsertGroup($input: InsertGroupInput!) {
    insertGroup(input: $input) {
      id
      fields {
        ... on StringFieldDefinition {
          id
          fieldName
          maxLength
          __typename
        }
        ... on ListRelationFieldDefinition {
          id
          fieldName
          relatedEntityName
          __typename
        }
        ... on ListFieldDefinition {
          id
          fieldName
          __typename
        }
        ... on DateFieldDefinition {
          id
          fieldName
          __typename
        }
        ... on BooleanFieldDefinition {
          id
          fieldName
          __typename
        }
        ... on GroupFieldDefinition {
          id
          fieldName
          title
          description
          sortOrder
          list
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class InsertGroupGQL extends Apollo.Mutation<
  InsertGroupMutation,
  InsertGroupMutationVariables
> {
  document = InsertGroupDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const UpdateGroupDocument = gql`
  mutation UpdateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      id
      fields {
        ... on StringFieldDefinition {
          id
          fieldName
          maxLength
          __typename
        }
        ... on ListRelationFieldDefinition {
          id
          fieldName
          relatedEntityName
          __typename
        }
        ... on ListFieldDefinition {
          id
          fieldName
          __typename
        }
        ... on DateFieldDefinition {
          id
          fieldName
          __typename
        }
        ... on BooleanFieldDefinition {
          id
          fieldName
          __typename
        }
        ... on GroupFieldDefinition {
          id
          fieldName
          title
          description
          sortOrder
          list
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class UpdateGroupGQL extends Apollo.Mutation<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
> {
  document = UpdateGroupDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetAllDocument = gql`
  query getAll($input: GetAllInput!) {
    getAll(input: $input) {
      __typename
      ... on GroupFieldDefinition {
        id
        fieldName
        title
        description
        sortOrder
        list
        fields {
          __typename
          ... on GroupFieldDefinition {
            id
            fieldName
            title
            description
            sortOrder
            list
            fields {
              __typename
              ... on GroupFieldDefinition {
                id
                fieldName
                title
                description
                sortOrder
                list
              }
              ... on StringFieldDefinition {
                id
                fieldName
                title
                description
                sortOrder
                maxLength
              }
              ... on BooleanFieldDefinition {
                id
                fieldName
                title
                description
                sortOrder
              }
              ... on DateFieldDefinition {
                id
                fieldName
                title
                description
                sortOrder
              }
              ... on ListFieldDefinition {
                id
                fieldName
                title
                description
                sortOrder
              }
              ... on ListRelationFieldDefinition {
                id
                fieldName
                title
                description
                sortOrder
                relatedEntityName
              }
            }
          }
          ... on StringFieldDefinition {
            id
            fieldName
            title
            description
            sortOrder
            maxLength
          }
          ... on BooleanFieldDefinition {
            id
            fieldName
            title
            description
            sortOrder
          }
          ... on DateFieldDefinition {
            id
            fieldName
            title
            description
            sortOrder
          }
          ... on ListFieldDefinition {
            id
            fieldName
            title
            description
            sortOrder
          }
          ... on ListRelationFieldDefinition {
            id
            fieldName
            title
            description
            sortOrder
            relatedEntityName
          }
        }
      }
      ... on StringFieldDefinition {
        id
        fieldName
        title
        description
        sortOrder
        maxLength
      }
      ... on BooleanFieldDefinition {
        id
        fieldName
        title
        description
        sortOrder
      }
      ... on DateFieldDefinition {
        id
        fieldName
        title
        description
        sortOrder
      }
      ... on ListFieldDefinition {
        id
        fieldName
        title
        description
        sortOrder
      }
      ... on ListRelationFieldDefinition {
        id
        fieldName
        title
        description
        sortOrder
        relatedEntityName
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetAllGQL extends Apollo.Query<GetAllQuery, GetAllQueryVariables> {
  document = GetAllDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const InsertStringValueDocument = gql`
  mutation InsertStringValue($input: InsertStringValueInput!) {
    insertStringValue(input: $input) {
      id
      value
      field {
        id
        fieldName
        maxLength
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class InsertStringValueGQL extends Apollo.Mutation<
  InsertStringValueMutation,
  InsertStringValueMutationVariables
> {
  document = InsertStringValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const InsertBooleanValueDocument = gql`
  mutation InsertBooleanValue($input: InsertBooleanValueInput!) {
    insertBooleanValue(input: $input) {
      id
      value
      field {
        id
        fieldName
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class InsertBooleanValueGQL extends Apollo.Mutation<
  InsertBooleanValueMutation,
  InsertBooleanValueMutationVariables
> {
  document = InsertBooleanValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const InsertDateValueDocument = gql`
  mutation InsertDateValue($input: InsertDateValueInput!) {
    insertDateValue(input: $input) {
      id
      field {
        id
        fieldName
      }
      value
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class InsertDateValueGQL extends Apollo.Mutation<
  InsertDateValueMutation,
  InsertDateValueMutationVariables
> {
  document = InsertDateValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const InsertListValueDocument = gql`
  mutation InsertListValue($input: InsertListValueInput!) {
    insertListValue(input: $input) {
      id
      field {
        id
        fieldName
      }
      value
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class InsertListValueGQL extends Apollo.Mutation<
  InsertListValueMutation,
  InsertListValueMutationVariables
> {
  document = InsertListValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const InsertListRelationValueDocument = gql`
  mutation InsertListRelationValue($input: InsertListRelationValueInput!) {
    insertListRelationValue(input: $input) {
      id
      value
      field {
        id
        fieldName
        relatedEntityName
      }
      entities
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class InsertListRelationValueGQL extends Apollo.Mutation<
  InsertListRelationValueMutation,
  InsertListRelationValueMutationVariables
> {
  document = InsertListRelationValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const InsertGroupValueDocument = gql`
  mutation InsertGroupValue($input: InsertGroupValueInput!) {
    insertGroupValue(input: $input) {
      id
      field {
        id
        fieldName
        title
        description
        sortOrder
        list
      }
      value
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class InsertGroupValueGQL extends Apollo.Mutation<
  InsertGroupValueMutation,
  InsertGroupValueMutationVariables
> {
  document = InsertGroupValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const InsertListGroupValueDocument = gql`
  mutation InsertListGroupValue($input: InsertListGroupValueInput!) {
    insertListGroupValue(input: $input) {
      id
      field {
        id
        fieldName
        title
        description
        sortOrder
        list
      }
      value
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class InsertListGroupValueGQL extends Apollo.Mutation<
  InsertListGroupValueMutation,
  InsertListGroupValueMutationVariables
> {
  document = InsertListGroupValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetStringValueDocument = gql`
  query GetStringValue($input: GetStringValueInput!) {
    getStringValue(input: $input) {
      ... on StringValue {
        id
        field {
          id
          fieldName
          maxLength
        }
        value
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetStringValueGQL extends Apollo.Query<
  GetStringValueQuery,
  GetStringValueQueryVariables
> {
  document = GetStringValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetBooleanValueDocument = gql`
  query GetBooleanValue($input: GetBooleanValueInput!) {
    getBooleanValue(input: $input) {
      ... on BooleanValue {
        id
        value
        field {
          id
          fieldName
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetBooleanValueGQL extends Apollo.Query<
  GetBooleanValueQuery,
  GetBooleanValueQueryVariables
> {
  document = GetBooleanValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetDateValueDocument = gql`
  query GetDateValue($input: GetDateValueInput!) {
    getDateValue(input: $input) {
      ... on DateValue {
        id
        field {
          id
          fieldName
        }
        value
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetDateValueGQL extends Apollo.Query<
  GetDateValueQuery,
  GetDateValueQueryVariables
> {
  document = GetDateValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetListValueDocument = gql`
  query GetListValue($input: GetListValueInput!) {
    getListValue(input: $input) {
      ... on ListValue {
        id
        field {
          id
          fieldName
        }
        value
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetListValueGQL extends Apollo.Query<
  GetListValueQuery,
  GetListValueQueryVariables
> {
  document = GetListValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetListRelationValueDocument = gql`
  query GetListRelationValue($input: GetListRelationValueInput!) {
    getListRelationValue(input: $input) {
      ... on ListRelationValue {
        id
        field {
          id
          fieldName
          relatedEntityName
        }
        value
        entities
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetListRelationValueGQL extends Apollo.Query<
  GetListRelationValueQuery,
  GetListRelationValueQueryVariables
> {
  document = GetListRelationValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetGroupValueDocument = gql`
  query GetGroupValue($input: GetGroupValueInput!) {
    getGroupValue(input: $input) {
      __typename
      ... on GroupValue {
        id
        value
        field {
          id
          fieldName
          title
          description
          sortOrder
          list
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetGroupValueGQL extends Apollo.Query<
  GetGroupValueQuery,
  GetGroupValueQueryVariables
> {
  document = GetGroupValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetListGroupValueDocument = gql`
  query GetListGroupValue($input: GetListGroupValueInput!) {
    getListGroupValue(input: $input) {
      __typename
      ... on ListGroupValue {
        id
        value
        field {
          id
          fieldName
          title
          description
          sortOrder
          list
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetListGroupValueGQL extends Apollo.Query<
  GetListGroupValueQuery,
  GetListGroupValueQueryVariables
> {
  document = GetListGroupValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const DeleteStringValueDocument = gql`
  mutation DeleteStringValue($input: DeleteStringValueInput!) {
    deleteStringValue(input: $input)
  }
`

@Injectable({
  providedIn: 'root',
})
export class DeleteStringValueGQL extends Apollo.Mutation<
  DeleteStringValueMutation,
  DeleteStringValueMutationVariables
> {
  document = DeleteStringValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const DeleteBooleanValueDocument = gql`
  mutation DeleteBooleanValue($input: DeleteBooleanValueInput!) {
    deleteBooleanValue(input: $input)
  }
`

@Injectable({
  providedIn: 'root',
})
export class DeleteBooleanValueGQL extends Apollo.Mutation<
  DeleteBooleanValueMutation,
  DeleteBooleanValueMutationVariables
> {
  document = DeleteBooleanValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const DeleteDateValueDocument = gql`
  mutation DeleteDateValue($input: DeleteDateValueInput!) {
    deleteDateValue(input: $input)
  }
`

@Injectable({
  providedIn: 'root',
})
export class DeleteDateValueGQL extends Apollo.Mutation<
  DeleteDateValueMutation,
  DeleteDateValueMutationVariables
> {
  document = DeleteDateValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const DeleteListValueDocument = gql`
  mutation DeleteListValue($input: DeleteListValueInput!) {
    deleteListValue(input: $input)
  }
`

@Injectable({
  providedIn: 'root',
})
export class DeleteListValueGQL extends Apollo.Mutation<
  DeleteListValueMutation,
  DeleteListValueMutationVariables
> {
  document = DeleteListValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const DeleteListRelationValueDocument = gql`
  mutation DeleteListRelationValue($input: DeleteListRelationInput!) {
    deleteListRelationValue(input: $input)
  }
`

@Injectable({
  providedIn: 'root',
})
export class DeleteListRelationValueGQL extends Apollo.Mutation<
  DeleteListRelationValueMutation,
  DeleteListRelationValueMutationVariables
> {
  document = DeleteListRelationValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const DeleteGroupValueDocument = gql`
  mutation DeleteGroupValue($input: DeleteGroupValueInput!) {
    deleteGroupValue(input: $input)
  }
`

@Injectable({
  providedIn: 'root',
})
export class DeleteGroupValueGQL extends Apollo.Mutation<
  DeleteGroupValueMutation,
  DeleteGroupValueMutationVariables
> {
  document = DeleteGroupValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const DeleteListGroupValueDocument = gql`
  mutation DeleteListGroupValue($input: DeleteListGroupValueInput!) {
    deleteListGroupValue(input: $input)
  }
`

@Injectable({
  providedIn: 'root',
})
export class DeleteListGroupValueGQL extends Apollo.Mutation<
  DeleteListGroupValueMutation,
  DeleteListGroupValueMutationVariables
> {
  document = DeleteListGroupValueDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const CreateAssetsDocument = gql`
  mutation CreateAssets($input: [CreateAssetInput!]!) {
    createAssets(input: $input) {
      ... on Asset {
        preview
        height
        name
        id
      }
      ... on MimeTypeError {
        errorCode
        message
        fileName
        mimeType
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class CreateAssetsGQL extends Apollo.Mutation<
  CreateAssetsMutation,
  CreateAssetsMutationVariables
> {
  document = CreateAssetsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const AssetDocument = gql`
  query Asset($id: ID!) {
    asset(id: $id) {
      id
      createdAt
      updatedAt
      name
      type
      fileSize
      mimeType
      width
      height
      source
      preview
      thumbnail
      customFields
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class AssetGQL extends Apollo.Query<AssetQuery, AssetQueryVariables> {
  document = AssetDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const AssetsDocument = gql`
  query Assets($options: AssetListOptions) {
    assets(options: $options) {
      items {
        id
        createdAt
        updatedAt
        name
        type
        fileSize
        mimeType
        width
        height
        source
        preview
        thumbnail
        customFields
      }
      totalItems
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class AssetsGQL extends Apollo.Query<AssetsQuery, AssetsQueryVariables> {
  document = AssetsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const CreateCustomerDocument = gql`
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      ... on Customer {
        id
        createdAt
        updatedAt
        title
        firstName
        lastName
        phoneNumber
        emailAddress
        customFields
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class CreateCustomerGQL extends Apollo.Mutation<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
> {
  document = CreateCustomerDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetCustomerAddressesDocument = gql`
  query GetCustomerAddresses($customerId: ID!) {
    customer(id: $customerId) {
      id
      addresses {
        id
        createdAt
        updatedAt
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country {
          id
          code
          name
        }
        phoneNumber
        defaultShippingAddress
        defaultBillingAddress
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetCustomerAddressesGQL extends Apollo.Query<
  GetCustomerAddressesQuery,
  GetCustomerAddressesQueryVariables
> {
  document = GetCustomerAddressesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const CustomersDocument = gql`
  query Customers($options: CustomerListOptions) {
    customers(options: $options) {
      totalItems
      items {
        id
        createdAt
        updatedAt
        title
        firstName
        lastName
        phoneNumber
        emailAddress
        customFields
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class CustomersGQL extends Apollo.Query<
  CustomersQuery,
  CustomersQueryVariables
> {
  document = CustomersDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const CreateFacetDocument = gql`
  mutation CreateFacet($input: CreateFacetInput!) {
    createFacet(input: $input) {
      code
      isPrivate
      name
      id
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class CreateFacetGQL extends Apollo.Mutation<
  CreateFacetMutation,
  CreateFacetMutationVariables
> {
  document = CreateFacetDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const CreateFacetValuesDocument = gql`
  mutation CreateFacetValues(
    $createFacetValuesInput: [CreateFacetValueInput!]!
  ) {
    createFacetValues(input: $createFacetValuesInput) {
      name
      code
      id
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class CreateFacetValuesGQL extends Apollo.Mutation<
  CreateFacetValuesMutation,
  CreateFacetValuesMutationVariables
> {
  document = CreateFacetValuesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const FacetValuesDocument = gql`
  query FacetValues($options: FacetValueListOptions) {
    facetValues(options: $options) {
      items {
        code
        id
        name
        facet {
          id
          name
          code
          languageCode
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class FacetValuesGQL extends Apollo.Query<
  FacetValuesQuery,
  FacetValuesQueryVariables
> {
  document = FacetValuesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const FacetDocument = gql`
  query Facet($facetId: ID!) {
    facet(id: $facetId) {
      createdAt
      updatedAt
      code
      name
      isPrivate
      valueList {
        items {
          createdAt
          updatedAt
          code
          name
          id
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class FacetGQL extends Apollo.Query<FacetQuery, FacetQueryVariables> {
  document = FacetDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const UpdateFacetDocument = gql`
  mutation UpdateFacet($updateFacetInput: UpdateFacetInput!) {
    updateFacet(input: $updateFacetInput) {
      code
      id
      name
      valueList {
        items {
          code
          id
          name
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class UpdateFacetGQL extends Apollo.Mutation<
  UpdateFacetMutation,
  UpdateFacetMutationVariables
> {
  document = UpdateFacetDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const UpdateFacetValuesDocument = gql`
  mutation UpdateFacetValues(
    $updateFacetValuesInput: [UpdateFacetValueInput!]!
  ) {
    updateFacetValues(input: $updateFacetValuesInput) {
      code
      name
      id
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class UpdateFacetValuesGQL extends Apollo.Mutation<
  UpdateFacetValuesMutation,
  UpdateFacetValuesMutationVariables
> {
  document = UpdateFacetValuesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const FacetsDocument = gql`
  query Facets($options: FacetListOptions) {
    facets(options: $options) {
      items {
        createdAt
        code
        id
        isPrivate
        languageCode
        name
        values {
          code
          facetId
          id
          name
          languageCode
        }
        translations {
          name
          languageCode
          id
        }
      }
      totalItems
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class FacetsGQL extends Apollo.Query<FacetsQuery, FacetsQueryVariables> {
  document = FacetsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GlobalSettingsDocument = gql`
  query GlobalSettings {
    globalSettings {
      id
      createdAt
      updatedAt
      availableLanguages
      trackInventory
      outOfStockThreshold
      customFields
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GlobalSettingsGQL extends Apollo.Query<
  GlobalSettingsQuery,
  GlobalSettingsQueryVariables
> {
  document = GlobalSettingsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const LoginDocument = gql`
  mutation Login($authenticationInput: AuthenticationInput!) {
    authenticate(input: $authenticationInput) {
      __typename
      ... on CurrentUser {
        id
        identifier
        channels {
          code
          token
          id
          permissions
        }
      }
      ... on InvalidCredentialsError {
        message
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class LoginGQL extends Apollo.Mutation<
  LoginMutation,
  LoginMutationVariables
> {
  document = LoginDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const AuthenticateGoogleDocument = gql`
  mutation AuthenticateGoogle($token: String!) {
    authenticate(input: { google: { token: $token } }) {
      __typename
      ... on CurrentUser {
        id
        identifier
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class AuthenticateGoogleGQL extends Apollo.Mutation<
  AuthenticateGoogleMutation,
  AuthenticateGoogleMutationVariables
> {
  document = AuthenticateGoogleDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ListManageablePluginsDocument = gql`
  query ListManageablePlugins {
    listManageablePlugins {
      id
      name
      code
      description
      imageUrl
      tags {
        id
        name
        code
      }
      configurationItems {
        id
        type
        translations {
          id
          languageCode
          name
          description
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ListManageablePluginsGQL extends Apollo.Query<
  ListManageablePluginsQuery,
  ListManageablePluginsQueryVariables
> {
  document = ListManageablePluginsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ListChannelPluginsDocument = gql`
  query ListChannelPlugins {
    listChannelPlugins {
      id
      status
      configuration
      manageablePlugin {
        id
        name
        code
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ListChannelPluginsGQL extends Apollo.Query<
  ListChannelPluginsQuery,
  ListChannelPluginsQueryVariables
> {
  document = ListChannelPluginsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const CreateChannelPluginDocument = gql`
  mutation CreateChannelPlugin($code: String!) {
    createChannelPlugin(code: $code) {
      id
      status
      configuration
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class CreateChannelPluginGQL extends Apollo.Mutation<
  CreateChannelPluginMutation,
  CreateChannelPluginMutationVariables
> {
  document = CreateChannelPluginDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const EnableChannelPluginDocument = gql`
  mutation EnableChannelPlugin($channelPluginId: ID!) {
    enableChannelPlugin(channelPluginId: $channelPluginId) {
      id
      status
      configuration
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class EnableChannelPluginGQL extends Apollo.Mutation<
  EnableChannelPluginMutation,
  EnableChannelPluginMutationVariables
> {
  document = EnableChannelPluginDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const DisableChannelPluginDocument = gql`
  mutation DisableChannelPlugin($channelPluginId: ID!) {
    disableChannelPlugin(channelPluginId: $channelPluginId) {
      id
      status
      configuration
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class DisableChannelPluginGQL extends Apollo.Mutation<
  DisableChannelPluginMutation,
  DisableChannelPluginMutationVariables
> {
  document = DisableChannelPluginDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const UpdateChannelPluginDocument = gql`
  mutation UpdateChannelPlugin($channelPluginId: ID!, $configuration: JSON!) {
    updateChannelPluginConfiguration(
      channelPluginId: $channelPluginId
      configuration: $configuration
    ) {
      id
      status
      configuration
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class UpdateChannelPluginGQL extends Apollo.Mutation<
  UpdateChannelPluginMutation,
  UpdateChannelPluginMutationVariables
> {
  document = UpdateChannelPluginDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const MeDocument = gql`
  query Me {
    me {
      id
      identifier
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class MeGQL extends Apollo.Query<MeQuery, MeQueryVariables> {
  document = MeDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const OrderDocument = gql`
  query Order($id: ID!) {
    order(id: $id) {
      nextStates
      aggregateOrderId
      id
      createdAt
      updatedAt
      type
      orderPlacedAt
      code
      state
      active
      couponCodes
      totalQuantity
      subTotal
      subTotalWithTax
      currencyCode
      shipping
      shippingWithTax
      shippingLines {
        id
        price
        priceWithTax
        discountedPrice
        discountedPriceWithTax
        customFields
        shippingMethod {
          id
          createdAt
          updatedAt
          languageCode
          code
          name
          description
          fulfillmentHandlerCode
          customFields
        }
      }
      customer {
        id
        createdAt
        updatedAt
        title
        firstName
        lastName
        phoneNumber
        emailAddress
        customFields
      }
      shippingAddress {
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country
        countryCode
        phoneNumber
        customFields
      }
      billingAddress {
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country
        countryCode
        phoneNumber
        customFields
      }
      total
      totalWithTax
      customFields
      lines {
        id
        createdAt
        updatedAt
        unitPrice
        unitPriceWithTax
        unitPriceChangeSinceAdded
        unitPriceWithTaxChangeSinceAdded
        discountedUnitPrice
        discountedUnitPriceWithTax
        proratedUnitPrice
        proratedUnitPriceWithTax
        quantity
        orderPlacedQuantity
        taxRate
        linePrice
        linePriceWithTax
        discountedLinePrice
        discountedLinePriceWithTax
        proratedLinePrice
        proratedLinePriceWithTax
        lineTax
        customFields
        fulfillmentLines {
          fulfillmentId
          quantity
          __typename
        }
        productVariant {
          enabled
          trackInventory
          stockOnHand
          stockAllocated
          outOfStockThreshold
          useGlobalOutOfStockThreshold
          id
          productId
          createdAt
          updatedAt
          languageCode
          sku
          name
          price
          currencyCode
          priceWithTax
          stockLevel
          stockLevels {
            id
            createdAt
            updatedAt
            stockLocationId
            stockOnHand
            stockAllocated
            customFields
          }
        }
      }
      taxSummary {
        description
        taxRate
        taxBase
        taxTotal
      }
      payments {
        nextStates
        id
        createdAt
        updatedAt
        method
        amount
        state
        transactionId
        errorMessage
        metadata
        customFields
        refunds {
          id
          createdAt
          updatedAt
          items
          shipping
          adjustment
          total
          method
          state
          transactionId
          reason
          paymentId
          metadata
          customFields
        }
      }
      fulfillments {
        nextStates
        id
        createdAt
        updatedAt
        state
        method
        trackingCode
        customFields
        lines {
          orderLineId
          quantity
          fulfillmentId
          orderLine {
            id
            createdAt
            updatedAt
            unitPrice
            unitPriceWithTax
            unitPriceChangeSinceAdded
            unitPriceWithTaxChangeSinceAdded
            discountedUnitPrice
            discountedUnitPriceWithTax
            proratedUnitPrice
            proratedUnitPriceWithTax
            quantity
            orderPlacedQuantity
            taxRate
            linePrice
            linePriceWithTax
            discountedLinePrice
            discountedLinePriceWithTax
            proratedLinePrice
            proratedLinePriceWithTax
            lineTax
            customFields
          }
        }
      }
      surcharges {
        id
        createdAt
        updatedAt
        description
        sku
        price
        priceWithTax
        taxRate
        taxLines {
          description
          taxRate
        }
      }
      discounts {
        adjustmentSource
        type
        description
        amount
        amountWithTax
      }
      promotions {
        id
        createdAt
        updatedAt
        startsAt
        endsAt
        couponCode
        perCustomerUsageLimit
        usageLimit
        name
        description
        enabled
        customFields
      }
      sellerOrders {
        id
        code
        state
      }
      modifications {
        id
        createdAt
        updatedAt
        priceChange
        note
        isSettled
        lines {
          orderLineId
          quantity
          modificationId
        }
        surcharges {
          id
          createdAt
          updatedAt
          description
          sku
          price
          priceWithTax
          taxRate
        }
        payment {
          nextStates
          id
          createdAt
          updatedAt
          method
          amount
          state
          transactionId
          errorMessage
          metadata
          customFields
        }
        refund {
          id
          createdAt
          updatedAt
          items
          shipping
          adjustment
          total
          method
          state
          transactionId
          reason
          paymentId
          metadata
          customFields
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class OrderGQL extends Apollo.Query<OrderQuery, OrderQueryVariables> {
  document = OrderDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const CancelOrderDocument = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    cancelOrder(input: $input) {
      ... on OrderStateTransitionError {
        errorCode
        message
        transitionError
        fromState
        toState
      }
      ... on CancelActiveOrderError {
        errorCode
        message
        orderState
      }
      ... on QuantityTooGreatError {
        errorCode
        message
      }
      ... on MultipleOrderError {
        errorCode
        message
      }
      ... on EmptyOrderLineSelectionError {
        errorCode
        message
      }
      ... on Order {
        nextStates
        aggregateOrderId
        id
        createdAt
        updatedAt
        type
        orderPlacedAt
        code
        state
        active
        couponCodes
        totalQuantity
        subTotal
        subTotalWithTax
        currencyCode
        shipping
        shippingWithTax
        total
        totalWithTax
        customFields
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class CancelOrderGQL extends Apollo.Mutation<
  CancelOrderMutation,
  CancelOrderMutationVariables
> {
  document = CancelOrderDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const RefundOrderDocument = gql`
  mutation RefundOrder($input: RefundOrderInput!) {
    refundOrder(input: $input) {
      ... on RefundAmountError {
        errorCode
        message
        maximumRefundable
      }
      ... on RefundStateTransitionError {
        errorCode
        message
        transitionError
        fromState
        toState
      }
      ... on AlreadyRefundedError {
        errorCode
        message
        refundId
      }
      ... on RefundOrderStateError {
        errorCode
        message
        orderState
      }
      ... on PaymentOrderMismatchError {
        errorCode
        message
      }
      ... on MultipleOrderError {
        errorCode
        message
      }
      ... on OrderStateTransitionError {
        errorCode
        message
        transitionError
        fromState
        toState
      }
      ... on NothingToRefundError {
        errorCode
        message
      }
      ... on QuantityTooGreatError {
        errorCode
        message
      }
      ... on Refund {
        id
        createdAt
        updatedAt
        items
        shipping
        adjustment
        total
        method
        state
        transactionId
        reason
        paymentId
        metadata
        customFields
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class RefundOrderGQL extends Apollo.Mutation<
  RefundOrderMutation,
  RefundOrderMutationVariables
> {
  document = RefundOrderDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const AddNoteToOrderDocument = gql`
  mutation AddNoteToOrder($input: AddNoteToOrderInput!) {
    addNoteToOrder(input: $input) {
      nextStates
      aggregateOrderId
      id
      createdAt
      updatedAt
      type
      orderPlacedAt
      code
      state
      active
      couponCodes
      totalQuantity
      subTotal
      subTotalWithTax
      currencyCode
      shipping
      shippingWithTax
      total
      totalWithTax
      customFields
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class AddNoteToOrderGQL extends Apollo.Mutation<
  AddNoteToOrderMutation,
  AddNoteToOrderMutationVariables
> {
  document = AddNoteToOrderDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const UpdateOrderNoteDocument = gql`
  mutation UpdateOrderNote($input: UpdateOrderNoteInput!) {
    updateOrderNote(input: $input) {
      isPublic
      id
      createdAt
      updatedAt
      type
      data
      customFields
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class UpdateOrderNoteGQL extends Apollo.Mutation<
  UpdateOrderNoteMutation,
  UpdateOrderNoteMutationVariables
> {
  document = UpdateOrderNoteDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const DeleteOrderNoteDocument = gql`
  mutation DeleteOrderNote($id: ID!) {
    deleteOrderNote(id: $id) {
      result
      message
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class DeleteOrderNoteGQL extends Apollo.Mutation<
  DeleteOrderNoteMutation,
  DeleteOrderNoteMutationVariables
> {
  document = DeleteOrderNoteDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const TransitionOrderToStateDocument = gql`
  mutation TransitionOrderToState($id: ID!, $state: String!) {
    transitionOrderToState(id: $id, state: $state) {
      ... on OrderStateTransitionError {
        errorCode
        message
        transitionError
        fromState
        toState
      }
      ... on Order {
        nextStates
        aggregateOrderId
        id
        createdAt
        updatedAt
        type
        orderPlacedAt
        code
        state
        active
        couponCodes
        totalQuantity
        subTotal
        subTotalWithTax
        currencyCode
        shipping
        shippingWithTax
        total
        totalWithTax
        customFields
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class TransitionOrderToStateGQL extends Apollo.Mutation<
  TransitionOrderToStateMutation,
  TransitionOrderToStateMutationVariables
> {
  document = TransitionOrderToStateDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const SetOrderCustomerDocument = gql`
  mutation SetOrderCustomer($input: SetOrderCustomerInput!) {
    setOrderCustomer(input: $input) {
      nextStates
      aggregateOrderId
      id
      createdAt
      updatedAt
      type
      orderPlacedAt
      code
      state
      active
      couponCodes
      totalQuantity
      subTotal
      subTotalWithTax
      currencyCode
      shipping
      shippingWithTax
      total
      totalWithTax
      customFields
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class SetOrderCustomerGQL extends Apollo.Mutation<
  SetOrderCustomerMutation,
  SetOrderCustomerMutationVariables
> {
  document = SetOrderCustomerDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ModifyOrderDocument = gql`
  mutation ModifyOrder($input: ModifyOrderInput!) {
    modifyOrder(input: $input) {
      ... on IneligibleShippingMethodError {
        errorCode
        message
      }
      ... on CouponCodeLimitError {
        errorCode
        message
        couponCode
        limit
      }
      ... on CouponCodeInvalidError {
        errorCode
        message
        couponCode
      }
      ... on CouponCodeExpiredError {
        errorCode
        message
        couponCode
      }
      ... on InsufficientStockError {
        errorCode
        message
        quantityAvailable
      }
      ... on OrderLimitError {
        errorCode
        message
        maxItems
      }
      ... on NegativeQuantityError {
        errorCode
        message
      }
      ... on RefundPaymentIdMissingError {
        errorCode
        message
      }
      ... on OrderModificationStateError {
        errorCode
        message
      }
      ... on PaymentMethodMissingError {
        errorCode
        message
      }
      ... on NoChangesSpecifiedError {
        errorCode
        message
      }
      ... on Order {
        nextStates
        aggregateOrderId
        id
        createdAt
        updatedAt
        type
        orderPlacedAt
        code
        state
        active
        couponCodes
        totalQuantity
        subTotal
        subTotalWithTax
        currencyCode
        shipping
        shippingWithTax
        total
        totalWithTax
        customFields
        lines {
          id
          createdAt
          updatedAt
          unitPrice
          unitPriceWithTax
          unitPriceChangeSinceAdded
          unitPriceWithTaxChangeSinceAdded
          discountedUnitPrice
          discountedUnitPriceWithTax
          proratedUnitPrice
          proratedUnitPriceWithTax
          quantity
          orderPlacedQuantity
          taxRate
          linePrice
          linePriceWithTax
          discountedLinePrice
          discountedLinePriceWithTax
          proratedLinePrice
          proratedLinePriceWithTax
          lineTax
          customFields
          productVariant {
            enabled
            trackInventory
            stockOnHand
            stockAllocated
            outOfStockThreshold
            useGlobalOutOfStockThreshold
            id
            productId
            createdAt
            updatedAt
            languageCode
            sku
            name
            price
            currencyCode
            priceWithTax
            stockLevel
          }
        }
        surcharges {
          id
          createdAt
          updatedAt
          description
          sku
          price
          priceWithTax
          taxRate
        }
        shippingLines {
          id
          price
          priceWithTax
          discountedPrice
          discountedPriceWithTax
          customFields
          shippingMethod {
            id
            createdAt
            updatedAt
            languageCode
            code
            name
            description
            fulfillmentHandlerCode
            customFields
          }
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ModifyOrderGQL extends Apollo.Mutation<
  ModifyOrderMutation,
  ModifyOrderMutationVariables
> {
  document = ModifyOrderDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const AddManualPaymentToOrderDocument = gql`
  mutation AddManualPaymentToOrder($input: ManualPaymentInput!) {
    addManualPaymentToOrder(input: $input) {
      ... on ManualPaymentStateError {
        errorCode
        message
      }
      ... on Order {
        nextStates
        aggregateOrderId
        id
        createdAt
        updatedAt
        type
        orderPlacedAt
        code
        state
        active
        couponCodes
        totalQuantity
        subTotal
        subTotalWithTax
        currencyCode
        shipping
        shippingWithTax
        total
        totalWithTax
        customFields
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class AddManualPaymentToOrderGQL extends Apollo.Mutation<
  AddManualPaymentToOrderMutation,
  AddManualPaymentToOrderMutationVariables
> {
  document = AddManualPaymentToOrderDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const CreateDraftOrderDocument = gql`
  mutation CreateDraftOrder {
    createDraftOrder {
      nextStates
      aggregateOrderId
      id
      createdAt
      updatedAt
      type
      orderPlacedAt
      code
      state
      active
      couponCodes
      totalQuantity
      subTotal
      subTotalWithTax
      currencyCode
      shipping
      shippingWithTax
      total
      totalWithTax
      customFields
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class CreateDraftOrderGQL extends Apollo.Mutation<
  CreateDraftOrderMutation,
  CreateDraftOrderMutationVariables
> {
  document = CreateDraftOrderDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const DeleteDraftOrderDocument = gql`
  mutation DeleteDraftOrder($id: ID!) {
    deleteDraftOrder(orderId: $id) {
      result
      message
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class DeleteDraftOrderGQL extends Apollo.Mutation<
  DeleteDraftOrderMutation,
  DeleteDraftOrderMutationVariables
> {
  document = DeleteDraftOrderDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const AddItemToDraftOrderDocument = gql`
  mutation AddItemToDraftOrder(
    $orderId: ID!
    $input: AddItemToDraftOrderInput!
  ) {
    addItemToDraftOrder(orderId: $orderId, input: $input) {
      ... on Order {
        nextStates
        aggregateOrderId
        id
        createdAt
        updatedAt
        type
        orderPlacedAt
        code
        state
        active
        couponCodes
        totalQuantity
        subTotal
        subTotalWithTax
        currencyCode
        shipping
        shippingWithTax
        total
        totalWithTax
        customFields
      }
      ... on OrderModificationError {
        errorCode
        message
      }
      ... on OrderLimitError {
        errorCode
        message
        maxItems
      }
      ... on NegativeQuantityError {
        errorCode
        message
      }
      ... on InsufficientStockError {
        errorCode
        message
        quantityAvailable
      }
      ... on OrderInterceptorError {
        errorCode
        message
        interceptorError
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class AddItemToDraftOrderGQL extends Apollo.Mutation<
  AddItemToDraftOrderMutation,
  AddItemToDraftOrderMutationVariables
> {
  document = AddItemToDraftOrderDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const AdjustDraftOrderLineDocument = gql`
  mutation AdjustDraftOrderLine(
    $orderId: ID!
    $input: AdjustDraftOrderLineInput!
  ) {
    adjustDraftOrderLine(orderId: $orderId, input: $input) {
      ... on OrderInterceptorError {
        errorCode
        message
        interceptorError
      }
      ... on InsufficientStockError {
        errorCode
        message
        quantityAvailable
      }
      ... on NegativeQuantityError {
        errorCode
        message
      }
      ... on OrderLimitError {
        errorCode
        message
        maxItems
      }
      ... on OrderModificationError {
        errorCode
        message
      }
      ... on Order {
        nextStates
        aggregateOrderId
        id
        createdAt
        updatedAt
        type
        orderPlacedAt
        code
        state
        active
        couponCodes
        totalQuantity
        subTotal
        subTotalWithTax
        currencyCode
        shipping
        shippingWithTax
        total
        totalWithTax
        customFields
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class AdjustDraftOrderLineGQL extends Apollo.Mutation<
  AdjustDraftOrderLineMutation,
  AdjustDraftOrderLineMutationVariables
> {
  document = AdjustDraftOrderLineDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const RemoveDraftOrderLineDocument = gql`
  mutation RemoveDraftOrderLine($orderId: ID!, $orderLineId: ID!) {
    removeDraftOrderLine(orderId: $orderId, orderLineId: $orderLineId) {
      ... on OrderInterceptorError {
        errorCode
        message
        interceptorError
      }
      ... on Order {
        nextStates
        aggregateOrderId
        id
        createdAt
        updatedAt
        type
        orderPlacedAt
        code
        state
        active
        couponCodes
        totalQuantity
        subTotal
        subTotalWithTax
        currencyCode
        shipping
        shippingWithTax
        total
        totalWithTax
        customFields
      }
      ... on OrderModificationError {
        errorCode
        message
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class RemoveDraftOrderLineGQL extends Apollo.Mutation<
  RemoveDraftOrderLineMutation,
  RemoveDraftOrderLineMutationVariables
> {
  document = RemoveDraftOrderLineDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const SetCustomerForDraftOrderDocument = gql`
  mutation SetCustomerForDraftOrder(
    $orderId: ID!
    $customerId: ID!
    $input: CreateCustomerInput!
  ) {
    setCustomerForDraftOrder(
      orderId: $orderId
      input: $input
      customerId: $customerId
    ) {
      ... on EmailAddressConflictError {
        errorCode
        message
      }
      ... on Order {
        nextStates
        aggregateOrderId
        id
        createdAt
        updatedAt
        type
        orderPlacedAt
        code
        state
        active
        couponCodes
        totalQuantity
        subTotal
        subTotalWithTax
        currencyCode
        shipping
        shippingWithTax
        total
        totalWithTax
        customFields
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class SetCustomerForDraftOrderGQL extends Apollo.Mutation<
  SetCustomerForDraftOrderMutation,
  SetCustomerForDraftOrderMutationVariables
> {
  document = SetCustomerForDraftOrderDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const SetDraftOrderShippingAddressDocument = gql`
  mutation SetDraftOrderShippingAddress(
    $orderId: ID!
    $input: CreateAddressInput!
  ) {
    setDraftOrderShippingAddress(orderId: $orderId, input: $input) {
      nextStates
      aggregateOrderId
      id
      createdAt
      updatedAt
      type
      orderPlacedAt
      code
      state
      active
      couponCodes
      totalQuantity
      subTotal
      subTotalWithTax
      currencyCode
      shipping
      shippingWithTax
      total
      totalWithTax
      customFields
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class SetDraftOrderShippingAddressGQL extends Apollo.Mutation<
  SetDraftOrderShippingAddressMutation,
  SetDraftOrderShippingAddressMutationVariables
> {
  document = SetDraftOrderShippingAddressDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const SetDraftOrderBillingAddressDocument = gql`
  mutation SetDraftOrderBillingAddress(
    $orderId: ID!
    $input: CreateAddressInput!
  ) {
    setDraftOrderBillingAddress(orderId: $orderId, input: $input) {
      nextStates
      aggregateOrderId
      id
      createdAt
      updatedAt
      type
      orderPlacedAt
      code
      state
      active
      couponCodes
      totalQuantity
      subTotal
      subTotalWithTax
      currencyCode
      shipping
      shippingWithTax
      total
      totalWithTax
      customFields
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class SetDraftOrderBillingAddressGQL extends Apollo.Mutation<
  SetDraftOrderBillingAddressMutation,
  SetDraftOrderBillingAddressMutationVariables
> {
  document = SetDraftOrderBillingAddressDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const UnsetDraftOrderShippingAddressDocument = gql`
  mutation UnsetDraftOrderShippingAddress($orderId: ID!) {
    unsetDraftOrderShippingAddress(orderId: $orderId) {
      nextStates
      aggregateOrderId
      id
      createdAt
      updatedAt
      type
      orderPlacedAt
      code
      state
      active
      couponCodes
      totalQuantity
      subTotal
      subTotalWithTax
      currencyCode
      shipping
      shippingWithTax
      total
      totalWithTax
      customFields
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class UnsetDraftOrderShippingAddressGQL extends Apollo.Mutation<
  UnsetDraftOrderShippingAddressMutation,
  UnsetDraftOrderShippingAddressMutationVariables
> {
  document = UnsetDraftOrderShippingAddressDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const UnsetDraftOrderBillingAddressDocument = gql`
  mutation UnsetDraftOrderBillingAddress($orderId: ID!) {
    unsetDraftOrderBillingAddress(orderId: $orderId) {
      nextStates
      aggregateOrderId
      id
      createdAt
      updatedAt
      type
      orderPlacedAt
      code
      state
      active
      couponCodes
      totalQuantity
      subTotal
      subTotalWithTax
      currencyCode
      shipping
      shippingWithTax
      total
      totalWithTax
      customFields
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class UnsetDraftOrderBillingAddressGQL extends Apollo.Mutation<
  UnsetDraftOrderBillingAddressMutation,
  UnsetDraftOrderBillingAddressMutationVariables
> {
  document = UnsetDraftOrderBillingAddressDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ApplyCouponCodeToDraftOrderDocument = gql`
  mutation ApplyCouponCodeToDraftOrder($orderId: ID!, $couponCode: String!) {
    applyCouponCodeToDraftOrder(orderId: $orderId, couponCode: $couponCode) {
      ... on CouponCodeLimitError {
        errorCode
        message
        couponCode
        limit
      }
      ... on CouponCodeInvalidError {
        errorCode
        message
        couponCode
      }
      ... on CouponCodeExpiredError {
        errorCode
        message
        couponCode
      }
      ... on Order {
        nextStates
        aggregateOrderId
        id
        createdAt
        updatedAt
        type
        orderPlacedAt
        code
        state
        active
        couponCodes
        totalQuantity
        subTotal
        subTotalWithTax
        currencyCode
        shipping
        shippingWithTax
        total
        totalWithTax
        customFields
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ApplyCouponCodeToDraftOrderGQL extends Apollo.Mutation<
  ApplyCouponCodeToDraftOrderMutation,
  ApplyCouponCodeToDraftOrderMutationVariables
> {
  document = ApplyCouponCodeToDraftOrderDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const RemoveCouponCodeFromDraftOrderDocument = gql`
  mutation RemoveCouponCodeFromDraftOrder($orderId: ID!, $couponCode: String!) {
    removeCouponCodeFromDraftOrder(orderId: $orderId, couponCode: $couponCode) {
      nextStates
      aggregateOrderId
      id
      createdAt
      updatedAt
      type
      orderPlacedAt
      code
      state
      active
      couponCodes
      totalQuantity
      subTotal
      subTotalWithTax
      currencyCode
      shipping
      shippingWithTax
      total
      totalWithTax
      customFields
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class RemoveCouponCodeFromDraftOrderGQL extends Apollo.Mutation<
  RemoveCouponCodeFromDraftOrderMutation,
  RemoveCouponCodeFromDraftOrderMutationVariables
> {
  document = RemoveCouponCodeFromDraftOrderDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const SetDraftOrderShippingMethodDocument = gql`
  mutation SetDraftOrderShippingMethod($orderId: ID!, $shippingMethodId: ID!) {
    setDraftOrderShippingMethod(
      orderId: $orderId
      shippingMethodId: $shippingMethodId
    ) {
      ... on NoActiveOrderError {
        errorCode
        message
      }
      ... on IneligibleShippingMethodError {
        errorCode
        message
      }
      ... on OrderModificationError {
        errorCode
        message
      }
      ... on Order {
        nextStates
        aggregateOrderId
        id
        createdAt
        updatedAt
        type
        orderPlacedAt
        code
        state
        active
        couponCodes
        totalQuantity
        subTotal
        subTotalWithTax
        currencyCode
        shipping
        shippingWithTax
        total
        totalWithTax
        customFields
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class SetDraftOrderShippingMethodGQL extends Apollo.Mutation<
  SetDraftOrderShippingMethodMutation,
  SetDraftOrderShippingMethodMutationVariables
> {
  document = SetDraftOrderShippingMethodDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ProductSelectorSearchDocument = gql`
  query ProductSelectorSearch($take: Int!, $term: String!) {
    search(input: { groupByProduct: false, term: $term, take: $take }) {
      items {
        productVariantId
        productVariantName
        productAsset {
          id
          preview
          focalPoint {
            x
            y
            __typename
          }
          __typename
        }
        price {
          ... on SinglePrice {
            value
            __typename
          }
          __typename
        }
        priceWithTax {
          ... on SinglePrice {
            value
            __typename
          }
          __typename
        }
        sku
        __typename
      }
      __typename
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ProductSelectorSearchGQL extends Apollo.Query<
  ProductSelectorSearchQuery,
  ProductSelectorSearchQueryVariables
> {
  document = ProductSelectorSearchDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const EligibleShippingMethodsForDraftOrderDocument = gql`
  query EligibleShippingMethodsForDraftOrder($orderId: ID!) {
    eligibleShippingMethodsForDraftOrder(orderId: $orderId) {
      id
      price
      priceWithTax
      code
      name
      description
      metadata
      customFields
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class EligibleShippingMethodsForDraftOrderGQL extends Apollo.Query<
  EligibleShippingMethodsForDraftOrderQuery,
  EligibleShippingMethodsForDraftOrderQueryVariables
> {
  document = EligibleShippingMethodsForDraftOrderDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const TransitionFulfillmentToStateDocument = gql`
  mutation TransitionFulfillmentToState($id: ID!, $state: String!) {
    transitionFulfillmentToState(id: $id, state: $state) {
      ... on Fulfillment {
        nextStates
        id
        createdAt
        updatedAt
        state
        method
        trackingCode
        customFields
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class TransitionFulfillmentToStateGQL extends Apollo.Mutation<
  TransitionFulfillmentToStateMutation,
  TransitionFulfillmentToStateMutationVariables
> {
  document = TransitionFulfillmentToStateDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetOrderHistoryDocument = gql`
  query GetOrderHistory($id: ID!, $options: HistoryEntryListOptions) {
    order(id: $id) {
      id
      history(options: $options) {
        totalItems
        items {
          id
          type
          createdAt
          isPublic
          administrator {
            id
            firstName
            lastName
            __typename
          }
          data
          __typename
        }
        __typename
      }
      __typename
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetOrderHistoryGQL extends Apollo.Query<
  GetOrderHistoryQuery,
  GetOrderHistoryQueryVariables
> {
  document = GetOrderHistoryDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const AddFulfillmentToOrderDocument = gql`
  mutation AddFulfillmentToOrder($input: FulfillOrderInput!) {
    addFulfillmentToOrder(input: $input) {
      ... on Fulfillment {
        nextStates
        id
        createdAt
        updatedAt
        state
        method
        trackingCode
        customFields
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class AddFulfillmentToOrderGQL extends Apollo.Mutation<
  AddFulfillmentToOrderMutation,
  AddFulfillmentToOrderMutationVariables
> {
  document = AddFulfillmentToOrderDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const OrdersDocument = gql`
  query Orders($orderListOptions: OrderListOptions!) {
    orders(options: $orderListOptions) {
      items {
        nextStates
        aggregateOrderId
        id
        createdAt
        updatedAt
        orderPlacedAt
        code
        state
        active
        couponCodes
        totalQuantity
        subTotal
        subTotalWithTax
        currencyCode
        shipping
        shippingWithTax
        total
        totalWithTax
        customFields
        shippingLines {
          customFields
          discounts {
            adjustmentSource
            type
            description
            amount
            amountWithTax
          }
          discountedPriceWithTax
          discountedPrice
          priceWithTax
          price
          shippingMethod {
            id
            createdAt
            updatedAt
            languageCode
            code
            name
            description
            fulfillmentHandlerCode
            customFields
            translations {
              id
              createdAt
              updatedAt
              languageCode
              name
              description
            }
          }
          id
        }
        aggregateOrder {
          nextStates
          aggregateOrderId
          id
          createdAt
          updatedAt
          orderPlacedAt
          code
          state
          active
          couponCodes
          totalQuantity
          subTotal
          subTotalWithTax
          currencyCode
          shipping
          shippingWithTax
          total
          totalWithTax
          customFields
        }
        customer {
          id
          createdAt
          updatedAt
          title
          firstName
          lastName
          phoneNumber
          emailAddress
          customFields
        }
        shippingAddress {
          fullName
          company
          streetLine1
          streetLine2
          city
          province
          postalCode
          country
          countryCode
          phoneNumber
          customFields
        }
      }
      totalItems
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class OrdersGQL extends Apollo.Query<OrdersQuery, OrdersQueryVariables> {
  document = OrdersDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetAddManualPaymentMethodListDocument = gql`
  query GetAddManualPaymentMethodList($options: PaymentMethodListOptions!) {
    paymentMethods(options: $options) {
      items {
        id
        createdAt
        updatedAt
        name
        code
        description
        enabled
      }
      totalItems
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetAddManualPaymentMethodListGQL extends Apollo.Query<
  GetAddManualPaymentMethodListQuery,
  GetAddManualPaymentMethodListQueryVariables
> {
  document = GetAddManualPaymentMethodListDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const CancelPaymentDocument = gql`
  mutation CancelPayment($id: ID!) {
    cancelPayment(id: $id) {
      ... on Payment {
        nextStates
        id
        createdAt
        updatedAt
        method
        amount
        state
        transactionId
        errorMessage
        metadata
        customFields
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class CancelPaymentGQL extends Apollo.Mutation<
  CancelPaymentMutation,
  CancelPaymentMutationVariables
> {
  document = CancelPaymentDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const SettleRefundDocument = gql`
  mutation SettleRefund($input: SettleRefundInput!) {
    settleRefund(input: $input) {
      ... on Refund {
        id
        createdAt
        updatedAt
        items
        shipping
        adjustment
        total
        method
        state
        transactionId
        reason
        paymentId
        metadata
        customFields
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class SettleRefundGQL extends Apollo.Mutation<
  SettleRefundMutation,
  SettleRefundMutationVariables
> {
  document = SettleRefundDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ProductDocument = gql`
  query Product($productId: ID) {
    product(id: $productId) {
      assets {
        height
        focalPoint {
          x
          y
        }
        id
        mimeType
        name
        preview
        source
        width
        fileSize
        createdAt
        updatedAt
        tags {
          value
          id
        }
        type
      }
      name
      description
      slug
      featuredAsset {
        height
        focalPoint {
          x
          y
        }
        id
        mimeType
        name
        preview
        source
        width
        fileSize
        createdAt
        updatedAt
        tags {
          value
          id
        }
        type
      }
      id
      languageCode
      optionGroups {
        code
        id
        languageCode
        name
        options {
          code
          groupId
          name
          languageCode
          id
          translations {
            name
            id
            languageCode
          }
        }
        translations {
          name
          id
          languageCode
        }
      }
      enabled
      facetValues {
        facetId
        name
        languageCode
        id
        code
      }
      variants {
        id
        name
        sku
        productId
        enabled
        languageCode
        price
        priceWithTax
        stockLevel
        featuredAsset {
          height
          focalPoint {
            x
            y
          }
          id
          mimeType
          name
          preview
          source
          width
          fileSize
          createdAt
          updatedAt
          tags {
            value
            id
          }
          type
        }
        options {
          code
          name
          languageCode
          id
          groupId
          createdAt
          translations {
            name
            id
            languageCode
          }
        }
        outOfStockThreshold
        stockLevels {
          stockLocationId
          id
          stockOnHand
          stockAllocated
          stockLocation {
            name
            id
            description
          }
        }
        translations {
          name
          id
          languageCode
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ProductGQL extends Apollo.Query<
  ProductQuery,
  ProductQueryVariables
> {
  document = ProductDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const CreateProductDocument = gql`
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      assets {
        height
        focalPoint {
          x
          y
        }
        id
        mimeType
        name
        preview
        source
        width
        fileSize
        createdAt
        updatedAt
        tags {
          value
          id
        }
        type
      }
      name
      description
      slug
      featuredAsset {
        height
        focalPoint {
          x
          y
        }
        id
        mimeType
        name
        preview
        source
        width
        fileSize
        createdAt
        updatedAt
        tags {
          value
          id
        }
        type
      }
      id
      languageCode
      optionGroups {
        code
        id
        languageCode
        name
        options {
          code
          groupId
          name
          languageCode
          id
        }
      }
      enabled
      facetValues {
        facetId
        name
        languageCode
        id
        code
      }
      variants {
        id
        name
        sku
        productId
        enabled
        languageCode
        price
        priceWithTax
        stockLevel
        featuredAsset {
          height
          focalPoint {
            x
            y
          }
          id
          mimeType
          name
          preview
          source
          width
          fileSize
          createdAt
          updatedAt
          tags {
            value
            id
          }
          type
        }
        options {
          code
          name
          languageCode
          id
          groupId
          createdAt
        }
        outOfStockThreshold
        stockLevels {
          stockLocationId
          id
          stockOnHand
          stockAllocated
          stockLocation {
            name
            id
            description
          }
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class CreateProductGQL extends Apollo.Mutation<
  CreateProductMutation,
  CreateProductMutationVariables
> {
  document = CreateProductDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ProductVariantDocument = gql`
  query ProductVariant($id: ID!) {
    productVariant(id: $id) {
      enabled
      trackInventory
      stockOnHand
      stockAllocated
      outOfStockThreshold
      useGlobalOutOfStockThreshold
      id
      productId
      createdAt
      updatedAt
      languageCode
      sku
      name
      price
      currencyCode
      priceWithTax
      stockLevel
      stockLevels {
        id
        createdAt
        updatedAt
        stockLocationId
        stockOnHand
        stockAllocated
        customFields
      }
      assets {
        id
        createdAt
        updatedAt
        name
        type
        fileSize
        mimeType
        width
        height
        source
        preview
        thumbnail
        customFields
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ProductVariantGQL extends Apollo.Query<
  ProductVariantQuery,
  ProductVariantQueryVariables
> {
  document = ProductVariantDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const CreateProductVariantsDocument = gql`
  mutation CreateProductVariants($input: [CreateProductVariantInput!]!) {
    createProductVariants(input: $input) {
      name
      id
      assets {
        height
        focalPoint {
          x
          y
        }
        id
        mimeType
        name
        preview
        source
        width
        fileSize
        createdAt
        updatedAt
        tags {
          value
          id
        }
        type
      }
      createdAt
      currencyCode
      enabled
      facetValues {
        facetId
        name
        languageCode
        id
        code
      }
      featuredAsset {
        height
        focalPoint {
          x
          y
        }
        id
        mimeType
        name
        preview
        source
        width
        fileSize
        createdAt
        updatedAt
        tags {
          value
          id
        }
        type
      }
      languageCode
      outOfStockThreshold
      price
      priceWithTax
      productId
      sku
      updatedAt
      options {
        code
        groupId
        name
        languageCode
        id
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class CreateProductVariantsGQL extends Apollo.Mutation<
  CreateProductVariantsMutation,
  CreateProductVariantsMutationVariables
> {
  document = CreateProductVariantsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const UpdateProductVariantsDocument = gql`
  mutation UpdateProductVariants($input: [UpdateProductVariantInput!]!) {
    updateProductVariants(input: $input) {
      enabled
      trackInventory
      stockOnHand
      stockAllocated
      outOfStockThreshold
      useGlobalOutOfStockThreshold
      id
      productId
      createdAt
      updatedAt
      languageCode
      sku
      name
      price
      currencyCode
      priceWithTax
      stockLevel
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class UpdateProductVariantsGQL extends Apollo.Mutation<
  UpdateProductVariantsMutation,
  UpdateProductVariantsMutationVariables
> {
  document = UpdateProductVariantsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const CreateProductOptionGroupDocument = gql`
  mutation CreateProductOptionGroup($input: CreateProductOptionGroupInput!) {
    createProductOptionGroup(input: $input) {
      code
      id
      name
      languageCode
      options {
        code
        groupId
        name
        languageCode
        id
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class CreateProductOptionGroupGQL extends Apollo.Mutation<
  CreateProductOptionGroupMutation,
  CreateProductOptionGroupMutationVariables
> {
  document = CreateProductOptionGroupDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const UpdateProductOptionGroupDocument = gql`
  mutation UpdateProductOptionGroup($input: UpdateProductOptionGroupInput!) {
    updateProductOptionGroup(input: $input) {
      code
      id
      name
      languageCode
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class UpdateProductOptionGroupGQL extends Apollo.Mutation<
  UpdateProductOptionGroupMutation,
  UpdateProductOptionGroupMutationVariables
> {
  document = UpdateProductOptionGroupDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const CreateProductOptionDocument = gql`
  mutation CreateProductOption($input: CreateProductOptionInput!) {
    createProductOption(input: $input) {
      code
      groupId
      id
      languageCode
      name
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class CreateProductOptionGQL extends Apollo.Mutation<
  CreateProductOptionMutation,
  CreateProductOptionMutationVariables
> {
  document = CreateProductOptionDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const UpdateProductOptionDocument = gql`
  mutation UpdateProductOption($input: UpdateProductOptionInput!) {
    updateProductOption(input: $input) {
      code
      name
      id
      languageCode
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class UpdateProductOptionGQL extends Apollo.Mutation<
  UpdateProductOptionMutation,
  UpdateProductOptionMutationVariables
> {
  document = UpdateProductOptionDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const DeleteProductOptionDocument = gql`
  mutation DeleteProductOption($id: ID!) {
    deleteProductOption(id: $id) {
      result
      message
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class DeleteProductOptionGQL extends Apollo.Mutation<
  DeleteProductOptionMutation,
  DeleteProductOptionMutationVariables
> {
  document = DeleteProductOptionDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const RemoveOptionGroupFromProductDocument = gql`
  mutation RemoveOptionGroupFromProduct(
    $productId: ID!
    $optionGroupId: ID!
    $force: Boolean
  ) {
    removeOptionGroupFromProduct(
      productId: $productId
      optionGroupId: $optionGroupId
      force: $force
    ) {
      ... on Product {
        id
        createdAt
        updatedAt
        languageCode
        name
        slug
        description
        enabled
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class RemoveOptionGroupFromProductGQL extends Apollo.Mutation<
  RemoveOptionGroupFromProductMutation,
  RemoveOptionGroupFromProductMutationVariables
> {
  document = RemoveOptionGroupFromProductDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const DeleteProductVariantDocument = gql`
  mutation DeleteProductVariant($id: ID!) {
    deleteProductVariant(id: $id) {
      result
      message
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class DeleteProductVariantGQL extends Apollo.Mutation<
  DeleteProductVariantMutation,
  DeleteProductVariantMutationVariables
> {
  document = DeleteProductVariantDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const DeleteProductDocument = gql`
  mutation DeleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      result
      message
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class DeleteProductGQL extends Apollo.Mutation<
  DeleteProductMutation,
  DeleteProductMutationVariables
> {
  document = DeleteProductDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const UpdateProductDocument = gql`
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      name
      slug
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class UpdateProductGQL extends Apollo.Mutation<
  UpdateProductMutation,
  UpdateProductMutationVariables
> {
  document = UpdateProductDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const AddOptionGroupToProductDocument = gql`
  mutation AddOptionGroupToProduct($productId: ID!, $optionGroupId: ID!) {
    addOptionGroupToProduct(
      productId: $productId
      optionGroupId: $optionGroupId
    ) {
      id
      name
      slug
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class AddOptionGroupToProductGQL extends Apollo.Mutation<
  AddOptionGroupToProductMutation,
  AddOptionGroupToProductMutationVariables
> {
  document = AddOptionGroupToProductDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetProductVariantDetailDocument = gql`
  query GetProductVariantDetail($id: ID!) {
    productVariant(id: $id) {
      id
      createdAt
      updatedAt
      enabled
      languageCode
      name
      price
      currencyCode
      prices {
        price
        currencyCode
      }
      priceWithTax
      stockOnHand
      stockAllocated
      trackInventory
      outOfStockThreshold
      useGlobalOutOfStockThreshold
      taxRateApplied {
        id
        name
        value
      }
      taxCategory {
        id
        name
      }
      sku
      options {
        id
        createdAt
        updatedAt
        code
        languageCode
        name
        groupId
        translations {
          id
          languageCode
          name
        }
      }
      stockLevels {
        id
        createdAt
        updatedAt
        stockOnHand
        stockAllocated
        stockLocationId
        stockLocation {
          id
          createdAt
          updatedAt
          name
        }
      }
      facetValues {
        id
        code
        name
        facet {
          id
          name
        }
      }
      featuredAsset {
        id
        createdAt
        updatedAt
        name
        fileSize
        mimeType
        type
        preview
        source
        width
        height
        focalPoint {
          x
          y
        }
      }
      assets {
        id
        createdAt
        updatedAt
        name
        fileSize
        mimeType
        type
        preview
        source
        width
        height
        focalPoint {
          x
          y
        }
      }
      translations {
        id
        languageCode
        name
      }
      channels {
        id
        code
      }
      product {
        id
        name
        optionGroups {
          id
          name
          code
          translations {
            id
            languageCode
            name
          }
        }
      }
    }
    stockLocations(options: { take: 100 }) {
      items {
        id
        createdAt
        updatedAt
        name
        description
      }
    }
    taxCategories(options: { take: 200 }) {
      items {
        id
        createdAt
        updatedAt
        name
        isDefault
      }
      totalItems
    }
    activeChannel {
      id
      availableCurrencyCodes
      defaultCurrencyCode
      pricesIncludeTax
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetProductVariantDetailGQL extends Apollo.Query<
  GetProductVariantDetailQuery,
  GetProductVariantDetailQueryVariables
> {
  document = GetProductVariantDetailDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetChannelAndTaxesDocument = gql`
  query GetChannelAndTaxes {
    activeChannel {
      id
      createdAt
      updatedAt
      code
      token
      defaultLanguageCode
      availableLanguageCodes
      currencyCode
      defaultCurrencyCode
      availableCurrencyCodes
      trackInventory
      outOfStockThreshold
      pricesIncludeTax
      defaultTaxZone {
        id
        createdAt
        updatedAt
        name
        customFields
      }
    }
    taxRates {
      totalItems
      items {
        id
        createdAt
        updatedAt
        name
        enabled
        value
        customFields
        category {
          id
          createdAt
          updatedAt
          name
          isDefault
          customFields
        }
        zone {
          id
          createdAt
          updatedAt
          name
          customFields
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetChannelAndTaxesGQL extends Apollo.Query<
  GetChannelAndTaxesQuery,
  GetChannelAndTaxesQueryVariables
> {
  document = GetChannelAndTaxesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ProductVariantsDocument = gql`
  query ProductVariants {
    productVariants {
      totalItems
      items {
        enabled
        trackInventory
        stockOnHand
        stockAllocated
        outOfStockThreshold
        useGlobalOutOfStockThreshold
        id
        productId
        createdAt
        updatedAt
        languageCode
        sku
        name
        price
        currencyCode
        priceWithTax
        stockLevel
        translations {
          id
          createdAt
          updatedAt
          languageCode
          name
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ProductVariantsGQL extends Apollo.Query<
  ProductVariantsQuery,
  ProductVariantsQueryVariables
> {
  document = ProductVariantsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ProductsDocument = gql`
  query Products($options: ProductListOptions) {
    products(options: $options) {
      totalItems
      items {
        id
        enabled
        description
        createdAt
        channels {
          code
          id
          seller {
            name
            id
            createdAt
          }
          token
        }
        featuredAsset {
          id
          height
          focalPoint {
            y
            x
          }
          mimeType
          name
          preview
          source
          tags {
            value
            updatedAt
            id
            createdAt
          }
          fileSize
          type
          width
          updatedAt
        }
        name
        languageCode
        slug
        updatedAt
        variants {
          name
          stockLevel
          stockLevels {
            stockAllocated
            stockOnHand
            id
            createdAt
            updatedAt
          }
          enabled
        }
        translations {
          id
          description
          languageCode
          name
          slug
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ProductsGQL extends Apollo.Query<
  ProductsQuery,
  ProductsQueryVariables
> {
  document = ProductsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetCouponCodeSelectorPromotionListDocument = gql`
  query GetCouponCodeSelectorPromotionList($options: PromotionListOptions) {
    promotions(options: $options) {
      items {
        id
        name
        couponCode
        __typename
      }
      totalItems
      __typename
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetCouponCodeSelectorPromotionListGQL extends Apollo.Query<
  GetCouponCodeSelectorPromotionListQuery,
  GetCouponCodeSelectorPromotionListQueryVariables
> {
  document = GetCouponCodeSelectorPromotionListDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const RegisterDocument = gql`
  mutation Register(
    $authenticationRegisterInput: AuthenticationRegisterInput!
  ) {
    register(input: $authenticationRegisterInput) {
      result
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class RegisterGQL extends Apollo.Mutation<
  RegisterMutation,
  RegisterMutationVariables
> {
  document = RegisterDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const VerifyDocument = gql`
  mutation Verify($token: String!) {
    verify(token: $token) {
      success
      message
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class VerifyGQL extends Apollo.Mutation<
  VerifyMutation,
  VerifyMutationVariables
> {
  document = VerifyDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const SearchDocument = gql`
  query Search($input: SearchInput!) {
    search(input: $input) {
      facetValues {
        count
        facetValue {
          id
          name
          code
          createdAt
          languageCode
          updatedAt
          facet {
            name
            code
            id
            isPrivate
            languageCode
            createdAt
          }
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class SearchGQL extends Apollo.Query<SearchQuery, SearchQueryVariables> {
  document = SearchDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const StockLocationsDocument = gql`
  query StockLocations($input: StockLocationListOptions) {
    stockLocations(options: $input) {
      items {
        id
        name
        description
      }
      totalItems
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class StockLocationsGQL extends Apollo.Query<
  StockLocationsQuery,
  StockLocationsQueryVariables
> {
  document = StockLocationsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
