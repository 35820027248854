import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import {
  FormArray,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { cloneDeep, flatMap, result } from 'lodash'
import { NzFormLayoutType } from 'ng-zorro-antd/form'
import { NzMessageService } from 'ng-zorro-antd/message'
import {
  combineLatest,
  concatMap,
  defaultIfEmpty,
  delay,
  filter,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
  tap,
  toArray,
} from 'rxjs'
import { v4 } from 'uuid'
import {
  Asset,
  CreateProductVariantInput,
  FacetValue,
  LanguageCode,
  Product,
  ProductOption,
  ProductOptionGroup,
  ProductVariant,
  SortOrder,
  StockLevel,
  StockLocation,
  UpdateProductVariantInput,
} from '../../gql/shop/generated'
import { AssetService } from '../../service/asset.service'
import { FacetService } from '../../service/facet.service'
import { ProductService } from '../../service/product.service'
import { StockService } from '../../service/stock-location.service'
import { AdvancedCustomFieldsComponent } from '../advanced-custom-fields/advanced-custom-fields.component'

interface FacetValueForm {
  id: FormControl<string>
  code: FormControl<string>
  name: FormControl<string>
}

interface StockLocationForm {
  id: FormControl<string>
  description: FormControl<string>
  name: FormControl<string>
  variants: FormArray<FormGroup<VariantForm>>
}

interface TranslationForm {
  id: FormControl<string>
  name: FormControl<string>
  languageCode: FormControl<string>
}

interface VariantForm {
  id: FormControl<string>
  stockLocationId: FormControl<string>
  name: FormControl<string>
  price: FormControl<number>
  sku: FormControl<string>
  enabled: FormControl<boolean>
  stock: FormControl<number>
  options: FormArray<FormGroup<VariantOptionForm>>
  translations: FormArray<FormGroup<TranslationForm>>
}

interface VariantOptionForm {
  id: FormControl<string>
  code: FormControl<string>
  name: FormControl<string>
  translations: FormArray<FormGroup<TranslationForm>>
}

interface VariantOptionGroupForm {
  id: FormControl<string>
  code: FormControl<string>
  name: FormControl<string>
  options: FormArray<FormGroup<VariantOptionForm>>
  newItem: FormControl<string>
  selectedItem: FormControl<VariantOptionForm | null>
  translations: FormArray<FormGroup<TranslationForm>>
}

interface ProductForm {
  id: FormControl<string>
  description: FormControl<string>
  name: FormControl<string>
  slug: FormControl<string>
  enabled: FormControl<boolean>
  facetValues: FormArray<FormGroup<FacetValueForm>>
  stockLocations: FormArray<FormGroup<StockLocationForm>>
  optionGroups: FormArray<FormGroup<VariantOptionGroupForm>>
  assets: FormArray<FormGroup<AssetForm>>
  variant: FormGroup<VariantForm>
}

interface AssetForm {
  name: FormControl<string>
  file: FormControl<File>
  type: FormControl<string>
  binary: FormControl<string>
  id: FormControl<string>
}

interface VariantModalForm {
  stockLocation: FormGroup<StockLocationForm>
  variant: FormGroup<VariantForm>
}

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrl: './product-detail.component.less',
})
export class ProductDetailComponent implements OnInit, OnDestroy {
  validateForm: FormGroup<{
    formLayout: FormControl<NzFormLayoutType>
    product: FormGroup<ProductForm>
    selectedFacetValue: FormControl<FacetValueForm | null>
    selectedStockLocation: FormControl<StockLocationForm | null>
    stockLocations: FormArray<FormGroup<StockLocationForm>>
    variantModal: FormGroup<VariantModalForm>
  }> = this.formBuilder.group({
    formLayout: 'horizontal' as NzFormLayoutType,
    product: this.formBuilder.group<ProductForm>({
      id: this.formBuilder.control(''),
      description: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(15),
      ]),
      name: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      slug: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      // set `Visibility` to `true` by default
      enabled: this.formBuilder.control(true, [Validators.required]),
      facetValues: this.formBuilder.array<FormGroup<FacetValueForm>>([]),
      stockLocations: this.formBuilder.array<FormGroup<StockLocationForm>>(
        []
        // allow empty stock locations
        // [ArrayValidators.minLength(1)]
      ),
      optionGroups: this.formBuilder.array<FormGroup<VariantOptionGroupForm>>(
        []
        // allow empty option groups
        // [ArrayValidators.minLength(1)]
      ),
      assets: this.formBuilder.array<FormGroup<AssetForm>>([]),
      variant: this.formBuilder.group<VariantForm>({
        stockLocationId: this.formBuilder.control(''),
        name: this.formBuilder.control(''),
        price: this.formBuilder.control(0),
        sku: this.formBuilder.control(''),
        enabled: this.formBuilder.control(true),
        stock: this.formBuilder.control(0),
        id: this.formBuilder.control(''),
        options: this.formBuilder.array<FormGroup<VariantOptionForm>>([]),
        translations: this.formBuilder.array<FormGroup<TranslationForm>>([]),
      }),
    }),
    selectedFacetValue: this.formBuilder.control<FacetValueForm | null>(null),
    stockLocations: this.formBuilder.array<FormGroup<StockLocationForm>>([]),
    selectedStockLocation: this.formBuilder.control<StockLocationForm | null>(
      null
    ),
    variantModal: this.formBuilder.group({
      stockLocation: this.formBuilder.group<StockLocationForm>({
        id: this.formBuilder.control(''),
        description: this.formBuilder.control(''),
        name: this.formBuilder.control(''),
        variants: this.formBuilder.array<FormGroup<VariantForm>>([]),
      }),
      variant: this.formBuilder.group<VariantForm>({
        stockLocationId: this.formBuilder.control(''),
        name: this.formBuilder.control(''),
        price: this.formBuilder.control(0),
        sku: this.formBuilder.control(''),
        enabled: this.formBuilder.control(true),
        stock: this.formBuilder.control(0),
        id: this.formBuilder.control(''),
        options: this.formBuilder.array<FormGroup<VariantOptionForm>>([]),
        translations: this.formBuilder.array<FormGroup<TranslationForm>>([]),
      }),
    }),
  })

  @ViewChild(AdvancedCustomFieldsComponent) acfComponent!: AdvancedCustomFieldsComponent

  isCreation = true
  finishSubmit = false
  id: string | null = null
  hasVariants = true
  showVariantModal = false
  showRemoveProductModal = false
  variantModalEditing = false
  isDropZoneDragging = false
  mediaUploadingMessage = ''
  // if product has only one variant with default stock location, store the variant id
  singleVariantId: string | null = null
  // store assets
  assetSources: { [key: string]: string } = {}
  // store removed option groups
  removedOptionGroups: FormGroup<VariantOptionGroupForm>[] = []
  // store removed options
  removedOptions: FormGroup<VariantOptionForm>[] = []
  // store removed variants
  removedVariants: FormGroup<VariantForm>[] = []
  // store product variant and option combination
  productVariantOptionCombination: { [key: string]: string } = {}
  // default stock location in this channel
  defaultStockLocation: StockLocation | null = null
  // tax category id
  taxCategoryId: string | null = null

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private productService: ProductService,
    public facetService: FacetService,
    private router: Router,
    private messageService: NzMessageService,
    private stockService: StockService,
    private assetService: AssetService
  ) {}

  ngOnInit(): void {
    this.setupListeners()
  }

  ngOnDestroy() {
    this.productService.clearProduct()
    this.stockService.clearStockLocations()
  }

  setupListeners() {
    // Setup StockLocation listener
    this.stockService.stockLocations$.subscribe((response) => {
      if (!response.items.length) {
        return
      }

      if (this.isCreation) {
        this.validateForm.controls.stockLocations.clear()
        const [first, ...remaining] = response.items as StockLocation[]
        remaining.forEach((stockLocation: StockLocation) => {
          this.validateForm.controls.stockLocations.push(
            this.formBuilder.group({
              name: this.formBuilder.control(stockLocation.name),
              description: this.formBuilder.control(stockLocation.description),
              id: this.formBuilder.control(stockLocation.id),
              variants: this.formBuilder.array<FormGroup<VariantForm>>(
                [],
                [Validators.minLength(1)]
              ),
            })
          )
        })

        const stockLocation = first
        this.defaultStockLocation = first
        const stockLocations =
          this.validateForm.controls.product.controls.stockLocations
        stockLocations.clear()
        stockLocations.push(
          this.formBuilder.group({
            name: this.formBuilder.control(stockLocation.name),
            description: this.formBuilder.control(stockLocation.description),
            id: this.formBuilder.control(stockLocation.id),
            variants: this.formBuilder.array<FormGroup<VariantForm>>(
              [],
              [Validators.minLength(1)]
            ),
          })
        )
      } else {
        const existStockLocations =
          this.validateForm.controls.product.controls.stockLocations.controls

        const [first] = response.items as StockLocation[]
        this.defaultStockLocation = first

        response.items.forEach((stockLocation: StockLocation) => {
          if (
            existStockLocations.find(
              (sl) => sl.getRawValue().id === stockLocation.id
            )
          ) {
            return
          }

          this.validateForm.controls.stockLocations.push(
            this.formBuilder.group({
              name: this.formBuilder.control(stockLocation.name, [
                Validators.required,
              ]),
              description: this.formBuilder.control(stockLocation.description),
              id: this.formBuilder.control(stockLocation.id, [
                Validators.required,
              ]),
              variants: this.formBuilder.array<FormGroup<VariantForm>>(
                [],
                [Validators.minLength(1)]
              ),
            })
          )
        })
      }
    })

    // Setup Parameter Listener
    combineLatest([this.route.paramMap, this.route.data]).subscribe(
      ([params, data]) => {
        this.id = params.get('id')
        this.isCreation = data['isCreation']

        if (!this.isCreation && this.id) {
          this.productService.fetchProduct(this.id).subscribe()
        } else {
          this.stockService
            .fetchStockLocations({
              sort: {
                createdAt: SortOrder.ASC,
              },
              take: 20,
            })
            .subscribe()
          // if it's creation, set hasVariants to `false`
          this.onHasVariantsChange(false)
        }
        // set tax category id
        this.productService.fetchChannelAndTaxes().subscribe((response) => {
          this.taxCategoryId =
            response.data.taxRates.items.find(
              (taxRate) =>
                taxRate.zone.id ===
                response.data.activeChannel.defaultTaxZone?.id
            )?.category.id ?? response.data.taxRates.items[0].category.id
        })
      }
    )

    // Setup Product Listener on Store
    this.productService.product$
      .pipe(
        tap((product) => {
          if (!product) {
            return
          }

          const uniqueStockLocations: string[] = []
          const stockLocations =
            this.validateForm.controls.product.controls.stockLocations

          const assets = this.validateForm.controls.product.controls.assets
          assets.clear()
          product.assets.forEach(async (asset: Asset) => {
            const file = await this.assetService.fetchFile(
              asset.source,
              asset.name
            )
            const binary = await this.assetService.fileToBase64(file)

            assets.push(
              this.formBuilder.group<AssetForm>({
                id: this.formBuilder.control(asset.id),
                file: this.formBuilder.control(file),
                binary: this.formBuilder.control(binary),
                name: this.formBuilder.control(asset.name),
                type: this.formBuilder.control(asset.mimeType),
              })
            )
          })

          // render single variant,if length of variants is 1 and option group is empty
          if (
            product.variants.length === 1 &&
            product.optionGroups.length === 0
          ) {
            product.variants.forEach((productVariant: ProductVariant) => {
              this.hasVariants = false
              const formVariant =
                this.validateForm.controls.product.controls.variant
              this.singleVariantId = productVariant.id
              // convert price to cents
              formVariant.controls.price.setValue(productVariant.price / 100)
              formVariant.controls.sku.setValue(productVariant.sku)
              formVariant.controls.stock.setValue(
                productVariant.stockLevels[0].stockOnHand -
                  productVariant.stockLevels[0].stockAllocated -
                  productVariant.outOfStockThreshold
              )
            })
          }

          // render variants
          product.variants.forEach((productVariant: ProductVariant) => {
            const optionsCombination = productVariant.options
              .map((option) => option.id)
              .sort()
              .join(',')
            this.productVariantOptionCombination[productVariant.id] =
              optionsCombination
          })

          stockLocations.clear()
          product.variants.forEach((productVariant: ProductVariant) => {
            return productVariant.stockLevels.forEach(
              (stockLevel: StockLevel) => {
                // https://docs.vendure.io/guides/core-concepts/stock-control/#stock-control-concepts
                const saleable =
                  stockLevel.stockOnHand -
                  stockLevel.stockAllocated -
                  productVariant.outOfStockThreshold

                const hasStockLocationFound = uniqueStockLocations.find(
                  (s) => s === stockLevel.stockLocation.id
                )

                if (!hasStockLocationFound) {
                  stockLocations.push(
                    this.formBuilder.group({
                      name: this.formBuilder.control(
                        stockLevel.stockLocation.name,
                        [Validators.required]
                      ),
                      description: this.formBuilder.control(
                        stockLevel.stockLocation.description
                      ),
                      id: this.formBuilder.control(
                        stockLevel.stockLocation.id,
                        [Validators.required]
                      ),
                      variants: this.formBuilder.array<FormGroup<VariantForm>>(
                        [],
                        [Validators.minLength(1)]
                      ),
                    })
                  )
                  uniqueStockLocations.push(stockLevel.stockLocation.id)
                }

                const stockLocation = stockLocations.controls.find(
                  (stockLocation) => {
                    return (
                      stockLocation.controls.id.getRawValue() ===
                      stockLevel.stockLocation.id
                    )
                  }
                )

                stockLocation?.controls.variants.push(
                  this.formBuilder.group<VariantForm>({
                    stockLocationId: this.formBuilder.control(
                      stockLevel.stockLocationId,
                      [Validators.required]
                    ),
                    name: this.formBuilder.control(productVariant.name, [
                      Validators.required,
                    ]),
                    price: this.formBuilder.control(
                      productVariant.price / 100,
                      [Validators.required]
                    ),
                    sku: this.formBuilder.control(productVariant.sku, [
                      Validators.required,
                    ]),
                    enabled: this.formBuilder.control(productVariant.enabled, [
                      Validators.required,
                    ]),
                    stock: this.formBuilder.control(saleable, [
                      Validators.required,
                    ]),
                    id: this.formBuilder.control(productVariant.id, [
                      Validators.required,
                    ]),
                    options: this.formBuilder.array(
                      productVariant.options.map((option) => {
                        return this.formBuilder.group({
                          name: this.formBuilder.control(option.name, [
                            Validators.required,
                          ]),
                          id: this.formBuilder.control(option.id, [
                            Validators.required,
                          ]),
                          code: this.formBuilder.control(option.code, [
                            Validators.required,
                          ]),
                          translations: this.formBuilder.array<
                            FormGroup<TranslationForm>
                          >(
                            option.translations.map((t) => {
                              return this.formBuilder.group({
                                id: this.formBuilder.control(t.id),
                                name: this.formBuilder.control(t.name),
                                languageCode: this.formBuilder.control(
                                  t.languageCode
                                ),
                              } as TranslationForm)
                            })
                          ),
                        })
                      })
                    ),
                    translations: this.formBuilder.array(
                      productVariant.translations.map((t) => {
                        return this.formBuilder.group({
                          id: this.formBuilder.control(t.id),
                          name: this.formBuilder.control(t.name),
                          languageCode: this.formBuilder.control(
                            t.languageCode
                          ),
                        } as TranslationForm)
                      })
                    ),
                  })
                )
              }
            )
          })

          const optionGroups =
            this.validateForm.controls.product.controls.optionGroups
          optionGroups.clear()

          product.optionGroups.forEach((optionGroup) => {
            const newGroup = this.formBuilder.group<VariantOptionGroupForm>({
              id: this.formBuilder.control(optionGroup.id, [
                Validators.required,
              ]),
              name: this.formBuilder.control(optionGroup.name, [
                Validators.required,
              ]),
              code: this.formBuilder.control(optionGroup.code, [
                Validators.required,
              ]),
              options: this.formBuilder.array(
                optionGroup.options.map((option) => {
                  return this.formBuilder.group({
                    id: this.formBuilder.control(option.id, [
                      Validators.required,
                    ]),
                    name: this.formBuilder.control(option.name, [
                      Validators.required,
                    ]),
                    code: this.formBuilder.control(option.code, [
                      Validators.required,
                    ]),
                    translations: this.formBuilder.array<
                      FormGroup<TranslationForm>
                    >(
                      option.translations.map((t) => {
                        return this.formBuilder.group({
                          id: this.formBuilder.control(t.id),
                          name: this.formBuilder.control(t.name),
                          languageCode: this.formBuilder.control(
                            t.languageCode
                          ),
                        } as TranslationForm)
                      })
                    ),
                  })
                })
              ),
              newItem: this.formBuilder.control(''),
              selectedItem: this.formBuilder.control(null),
              translations: this.formBuilder.array<FormGroup<TranslationForm>>(
                optionGroup.translations.map((t) => {
                  return this.formBuilder.group({
                    id: this.formBuilder.control(t.id),
                    name: this.formBuilder.control(t.name),
                    languageCode: this.formBuilder.control(t.languageCode),
                  } as TranslationForm)
                })
              ),
            })
            optionGroups.push(newGroup)
          })

          const facetValues =
            this.validateForm.controls.product.controls.facetValues
          facetValues.clear()
          product.facetValues.forEach((item: FacetValue) => {
            const value = {
              name: this.formBuilder.control(item.name, [Validators.required]),
              code: this.formBuilder.control(item.code, [Validators.required]),
              id: this.formBuilder.control(item.id),
            }
            const newGroup = this.formBuilder.group<FacetValueForm>(value)
            facetValues.push(newGroup)
          })

          this.validateForm.patchValue({
            product: this.formBuilder
              .group({
                id: this.formBuilder.control(product.id),
                name: this.formBuilder.control(product.name),
                slug: this.formBuilder.control(product.slug),
                description: this.formBuilder.control(product.description),
                enabled: this.formBuilder.control(product.enabled),
                facetValues,
                optionGroups,
                stockLocations,
                assets,
              })
              .getRawValue(),
          })

          this.stockService
            .fetchStockLocations({
              sort: {
                createdAt: SortOrder.ASC,
              },
              take: 20,
            })
            .subscribe()
        })
      )
      .subscribe()

    // Setup SelectedFacetValueControl Listener
    this.validateForm.controls.selectedFacetValue.valueChanges.subscribe(
      (item) => {
        if (item) {
          const value = {
            name: this.formBuilder.control(item.name, [Validators.required]),
            code: this.formBuilder.control(item.code, [Validators.required]),
            id: this.formBuilder.control(item.id, [Validators.required]),
          }
          const newGroup = this.formBuilder.group<FacetValueForm>(value)
          // if the facet value is already in the form, do not add it again
          if (
            this.validateForm.controls.product.controls.facetValues.controls.find(
              (fv) => fv.controls.id.value === item.id.toString()
            )
          ) {
            return
          }
          this.validateForm.controls.product.controls.facetValues.push(newGroup)
        }
      }
    )
  }

  submitForm() {
    const product = this.validateForm.controls.product.getRawValue()

    // check if the product has at least one product variant
    if (this.hasVariants) {
      // check stock locations and variants
      if (product.stockLocations.length === 0) {
        this.messageService.error('Please create at least one stock location.')
        return
      }
      let variantsCount = 0
      product.stockLocations.forEach((stockLocation) => {
        variantsCount += stockLocation.variants.length
      })
      if (variantsCount === 0) {
        this.messageService.error('Please create at least one product variant.')
        return
      }
    } else {
      // check single variant
      if (product.variant === null) {
        this.messageService.error('Please create at least one product variant.')
        return
      }
      // check SKU
      if (product.variant.sku === '') {
        this.messageService.error(
          'Please fill in all the required fields for the product variant. SKU cannot be empty.'
        )
        return
      }
    }

    // Ensure conversion to number for price and stock
    product.stockLocations.forEach((stockLocation) => {
      stockLocation.variants.forEach((variant) => {
        variant.price = +variant.price // Convert string to number
        variant.stock = +variant.stock // Convert string to number
      })
    })

    this.validateForm.markAllAsTouched()
    this.validateForm.updateValueAndValidity()
    this.validateForm.validate()

    if (this.validateForm.valid) {
      this.finishSubmit = true
      if (this.isCreation) {
        this.assetService
          .createAssets(product.assets.map((asset) => ({ file: asset.file })))
          .pipe(
            map(
              (assetsResponse) =>
                assetsResponse.data?.createAssets
                  .filter((asset) => asset.__typename === 'Asset')
                  .map((asset) => (asset as Asset).id) || []
            ),
            mergeMap((assetIds) =>
              this.productService.createProduct({
                assetIds,
                translations: [
                  {
                    languageCode: LanguageCode.en,
                    name: product.name,
                    description: product.description,
                    slug: product.slug,
                  },
                ],
                facetValueIds: product.facetValues.map(
                  (facetValue) => facetValue.id
                ),
              })
            ),
            mergeMap((productResponse) => {
              const _product = productResponse.data?.createProduct as Product
              if (!this.hasVariants) {
                const formVariant =
                  this.validateForm.controls.product.controls.variant
                const variant = {
                  // convert price to cents
                  price: Number(
                    (formVariant.controls.price.value * 100).toFixed(0)
                  ),
                  productId: _product.id,
                  sku: formVariant.controls.sku.value,
                  stockOnHand: formVariant.controls.stock.value,
                  taxCategoryId: this.taxCategoryId,
                  stockLevels: [
                    {
                      stockOnHand: formVariant.controls.stock.value,
                      stockLocationId: this.defaultStockLocation?.id as string,
                    },
                  ],
                  translations: [
                    {
                      languageCode: LanguageCode.en,
                      name: this.validateForm.controls.product.controls.name
                        .value,
                    },
                  ],
                }
                return this.productService
                  .createProductVariants([variant])
                  .pipe(map(() => productResponse))
              }
              return of(productResponse)
            }),
            mergeMap((productResponse) => {
              const productOptionGroups$ = product.optionGroups.map(
                (optionGroup) =>
                  this.productService
                    .createProductOptionGroup({
                      code: optionGroup.code,
                      translations: [
                        {
                          languageCode: LanguageCode.en,
                          name: optionGroup.name,
                        },
                      ],
                      options: [],
                    })
                    .pipe(
                      tap((response) => {
                        const createdGroup = response.data
                          ?.createProductOptionGroup as ProductOptionGroup
                        optionGroup.id = createdGroup.id
                      })
                    )
              )
              return productOptionGroups$.length == 0
                ? of(productResponse)
                : forkJoin(productOptionGroups$).pipe(
                    map(() => productResponse)
                  )
            }),
            mergeMap((productResponse) => {
              const productOptions$ = flatMap(
                product.optionGroups.map((optionGroup) =>
                  optionGroup.options.map((option) =>
                    this.productService
                      .createProductOption({
                        productOptionGroupId: optionGroup.id,
                        code: option.code,
                        translations: [
                          {
                            languageCode: LanguageCode.en,
                            name: option.name,
                          },
                        ],
                      })
                      .pipe(
                        tap((optionResponse) => {
                          const productOption = optionResponse.data
                            ?.createProductOption as ProductOption
                          const oldOptionId = option.id
                          option.id = productOption.id

                          product.stockLocations.forEach((stockLocation) => {
                            stockLocation.variants.forEach((variant) => {
                              variant.options.forEach((variantOption) => {
                                // Compare with the old ID before updating
                                if (variantOption.id === oldOptionId) {
                                  variantOption.id = productOption.id
                                }
                              })
                            })
                          })

                          // Update form controls
                          this.validateForm.controls.product.controls.stockLocations.controls.forEach(
                            (stockLocationControl) => {
                              stockLocationControl.controls.variants.controls.forEach(
                                (variantControl) => {
                                  variantControl.controls.options.controls.forEach(
                                    (optionControl) => {
                                      if (
                                        optionControl.value.id === oldOptionId
                                      ) {
                                        optionControl.patchValue({
                                          id: productOption.id,
                                        })
                                      }
                                    }
                                  )
                                }
                              )
                            }
                          )
                        })
                      )
                  )
                )
              )

              return productOptions$.length == 0
                ? of(productResponse)
                : forkJoin(productOptions$).pipe(map(() => productResponse))
            }),
            mergeMap((productResponse) => {
              const _product = productResponse.data?.createProduct as Product
              const optionGroupAssociations$ = product.optionGroups.map(
                (optionGroup) =>
                  this.productService.addOptionGroupToProduct(
                    optionGroup.id,
                    _product.id
                  )
              )

              // Ensure all option groups are associated with the product before creating variants
              return optionGroupAssociations$.length == 0
                ? of(_product)
                : forkJoin(optionGroupAssociations$).pipe(map(() => _product))
            }),
            mergeMap((_product) => {
              const allVariants = flatMap(
                product.stockLocations.map((stockLocation) =>
                  stockLocation.variants.map(
                    (variant) =>
                      ({
                        productId: _product.id,
                        sku: variant.sku,
                        // convert price to cents
                        price: Number((variant.price * 100).toFixed(0)),
                        stockOnHand: variant.stock,
                        optionIds: variant.options.map((option) => option.id),
                        taxCategoryId: this.taxCategoryId,
                        stockLevels: [
                          {
                            stockOnHand: variant.stock,
                            stockLocationId: variant.stockLocationId,
                          },
                        ],
                        translations: [
                          {
                            languageCode: LanguageCode.en,
                            name: variant.name,
                          },
                        ],
                      }) as CreateProductVariantInput
                  )
                )
              )
              // Create all variants in one request
              return allVariants.length == 0
                ? of(_product)
                : this.productService
                    .createProductVariants(allVariants)
                    .pipe(map(() => _product))
            }),
            mergeMap((_product) => {
              return this.acfComponent
                .insertOrUpdate(_product.id)
                .pipe(map(() => _product))
            })
          )
          .subscribe({
            next: (product) => {
              this.router.navigate(['/admin', 'products', product.id])
              console.log(
                'Product, its option groups, options, and variants created successfully'
              )
              this.finishSubmit = false
            },
            error: (err) => {
              console.log(err)

              console.error('Error during product creation:', err)
            },
          })
      }

      if (this.id && !this.isCreation) {
        const product = this.validateForm.controls.product.getRawValue()

        const newAssetsFile = product.assets
          .filter((asset) => asset.id.indexOf('local') > -1)
          .map((asset) => ({ file: asset.file }))
        const oldAssets = product.assets.filter(
          (asset) => asset.id.indexOf('local') === -1
        )

        this.assetService
          .createAssets(newAssetsFile)
          .pipe(
            map((assetsResponse) => {
              if (!assetsResponse.data?.createAssets) {
                return []
              }

              const newAssets = assetsResponse.data.createAssets
                .filter((asset) => asset.__typename === 'Asset')
                .map((asset) => asset as Asset)

              // Update form control values for each new asset
              newAssets.forEach((newAsset) => {
                const assetControls =
                  this.validateForm.controls.product.controls.assets.controls
                const localAssetControl = assetControls.find((control) =>
                  control.getRawValue().id.includes('local')
                )

                if (localAssetControl) {
                  localAssetControl.patchValue({ id: newAsset.id })
                }
              })

              return newAssets.map((asset) => asset.id)
            }),
            mergeMap((newAssetIds: string[]) => {
              const oldAssetsIds = oldAssets.map((oldAsset) => oldAsset.id)
              return this.productService.updateProduct({
                id: this.id as string,
                translations: [
                  {
                    languageCode: LanguageCode.en,
                    description: product.description,
                    name: product.name,
                    slug: product.slug,
                  },
                ],
                enabled: product.enabled,
                facetValueIds: product.facetValues.map((f) => f.id),
                assetIds: newAssetIds.concat(oldAssetsIds),
              })
            }),
            mergeMap((productResponse) => {
              const _product = productResponse.data?.updateProduct as Product

              const createGroups$ = from(product.optionGroups).pipe(
                filter((optionGroup) => optionGroup.id.indexOf('local') > -1),
                mergeMap((optionGroup) => {
                  return this.productService
                    .createProductOptionGroup({
                      code: optionGroup.code,
                      translations: [
                        {
                          languageCode: LanguageCode.en,
                          name: optionGroup.name,
                        },
                      ],
                      options: [],
                    })
                    .pipe(
                      tap((response) => {
                        const createdGroup = response.data
                          ?.createProductOptionGroup as ProductOptionGroup
                        // Find and update the form control
                        const optionGroupControl =
                          this.validateForm.controls.product.controls.optionGroups.controls.find(
                            (group) => group.value.id === optionGroup.id
                          )

                        if (optionGroupControl) {
                          optionGroupControl.patchValue({ id: createdGroup.id })
                        }

                        // Keep the raw value update for immediate use
                        optionGroup.id = createdGroup.id
                        this.productService
                          .addOptionGroupToProduct(createdGroup.id, _product.id)
                          .subscribe()
                      })
                    )
                }),
                defaultIfEmpty([])
              )

              const updateGroups$ = from(product.optionGroups).pipe(
                filter((optionGroup) => optionGroup.id.indexOf('local') === -1),
                mergeMap((optionGroup) => {
                  return this.productService.updateProductOptionGroup({
                    id: optionGroup.id,
                    code: optionGroup.code,
                    translations: optionGroup.translations.map((t) => {
                      return {
                        id: t.id,
                        languageCode: t.languageCode as LanguageCode,
                        name: optionGroup.name,
                      }
                    }),
                  })
                }),
                defaultIfEmpty([])
              )

              return forkJoin([createGroups$, updateGroups$]).pipe(
                map((response) => {
                  return productResponse
                })
              )
            }),
            mergeMap((productResponse) => {
              // Process each option group
              const productOptions$ = flatMap(
                product.optionGroups.map((optionGroup) => {
                  // Create observable stream for createOptions
                  const createOptions$ = from(optionGroup.options).pipe(
                    filter((option) => option.id.indexOf('local') > -1),
                    mergeMap((option) => {
                      return this.productService
                        .createProductOption({
                          productOptionGroupId: optionGroup.id,
                          code: option.code,
                          translations: [
                            {
                              languageCode: LanguageCode.en,
                              name: option.name,
                            },
                          ],
                        })
                        .pipe(
                          tap((optionResponse) => {
                            const productOption = optionResponse.data
                              ?.createProductOption as ProductOption
                            const oldOptionId = option.id
                            // Update the form control value
                            const optionGroupControl =
                              this.validateForm.controls.product.controls.optionGroups.controls.find(
                                (group) => group.value.id === optionGroup.id
                              )

                            if (optionGroupControl) {
                              const optionControl =
                                optionGroupControl.controls.options.controls.find(
                                  (opt) => opt.value.id === oldOptionId
                                )

                              if (optionControl) {
                                optionControl.patchValue({
                                  id: productOption.id,
                                })
                              }
                            }

                            // Update stock locations with the new option ID
                            product.stockLocations.forEach((stockLocation) => {
                              stockLocation.variants.forEach((variant) => {
                                variant.options.forEach((variantOption) => {
                                  if (variantOption.id === oldOptionId) {
                                    variantOption.id = productOption.id
                                  }
                                })
                              })
                            })

                            // Update form controls
                            this.validateForm.controls.product.controls.stockLocations.controls.forEach(
                              (stockLocationControl) => {
                                stockLocationControl.controls.variants.controls.forEach(
                                  (variantControl) => {
                                    variantControl.controls.options.controls.forEach(
                                      (optionControl) => {
                                        if (
                                          optionControl.value.id === oldOptionId
                                        ) {
                                          optionControl.patchValue({
                                            id: productOption.id,
                                          })
                                        }
                                      }
                                    )
                                  }
                                )
                              }
                            )
                          })
                        )
                    }),
                    defaultIfEmpty([])
                  )

                  // Create observable stream for updateOptions
                  const updateOptions$ = from(optionGroup.options).pipe(
                    filter((option) => option.id.indexOf('local') === -1),
                    mergeMap((option) => {
                      return this.productService.updateProductOption({
                        id: option.id,
                        code: option.code,
                        translations: [
                          {
                            languageCode: LanguageCode.en,
                            name: option.name,
                          },
                        ],
                      })
                    }),
                    defaultIfEmpty([])
                  )

                  // Combine the results for this option group
                  return forkJoin([createOptions$, updateOptions$])
                })
              )

              // Combine all option groups into a single observable
              return productOptions$.length == 0
                ? of(productResponse)
                : forkJoin(productOptions$).pipe(map(() => productResponse))
            }),
            mergeMap((productResponse) => {
              // start with variants deletion if any exist
              const deleteVariants$ =
                this.removedVariants.length > 0
                  ? from(this.removedVariants)
                      .pipe(
                        concatMap((variant) =>
                          of(variant).pipe(
                            delay(500),
                            mergeMap((v) =>
                              this.productService.deleteProductVariant(
                                v.getRawValue().id
                              )
                            )
                          )
                        ),
                        toArray()
                      )
                      .pipe(
                        tap(() => {
                          console.log('All variants deleted successfully')
                          this.removedVariants = []
                        })
                      )
                  : of(null)

              // handle options deletion
              const deleteOptions$ =
                this.removedOptions.length > 0
                  ? from(this.removedOptions)
                      .pipe(
                        concatMap((option) =>
                          of(option).pipe(
                            delay(500),
                            mergeMap((o) =>
                              this.productService.deleteProductOption(
                                o.getRawValue().id
                              )
                            )
                          )
                        ),
                        toArray()
                      )
                      .pipe(
                        tap(() => {
                          console.log('All options deleted successfully')
                          this.removedOptions = []
                        })
                      )
                  : of(null)

              // handle option groups deletion
              const deleteOptionGroups$ =
                this.removedOptionGroups.length > 0
                  ? from(this.removedOptionGroups)
                      .pipe(
                        concatMap((optionGroup) =>
                          of(optionGroup).pipe(
                            delay(500),
                            mergeMap((og) =>
                              this.productService.removeOptionGroupFromProduct(
                                product.id,
                                og.getRawValue().id,
                                true
                              )
                            )
                          )
                        ),
                        toArray()
                      )
                      .pipe(
                        tap(() => {
                          console.log('All option groups deleted successfully')
                          this.removedOptionGroups = []
                        })
                      )
                  : of(null)

              // Chain all operations
              return forkJoin([
                deleteVariants$,
                deleteOptions$,
                deleteOptionGroups$,
              ]).pipe(map(() => productResponse))
            }),
            mergeMap((productResponse) => {
              const _product = productResponse.data?.updateProduct as Product
              if (!this.hasVariants) {
                // single variant exist
                const formVariant =
                  this.validateForm.controls.product.controls.variant
                if (this.singleVariantId) {
                  const variant = {
                    id: this.singleVariantId as string,
                    // convert price to cents
                    price: Number(
                      (formVariant.controls.price.value * 100).toFixed(0)
                    ),
                    sku: formVariant.controls.sku.value,
                    stockOnHand: formVariant.controls.stock.value,
                    stockLevels: [
                      {
                        stockOnHand: formVariant.controls.stock.value,
                        stockLocationId: this.defaultStockLocation
                          ?.id as string,
                      },
                    ],
                    translations: [
                      {
                        languageCode: LanguageCode.en,
                        name: this.validateForm.controls.product.controls.name
                          .value,
                      },
                    ],
                  }
                  return this.productService
                    .updateProductVariants([variant])
                    .pipe(map(() => _product))
                } else {
                  // create new variant
                  const variant = {
                    // convert price to cents
                    price: Number(
                      (formVariant.controls.price.value * 100).toFixed(0)
                    ),
                    productId: this.id as string,
                    sku: formVariant.controls.sku.value,
                    stockOnHand: formVariant.controls.stock.value,
                    taxCategoryId: this.taxCategoryId,
                    stockLevels: [
                      {
                        stockOnHand: formVariant.controls.stock.value,
                        stockLocationId: this.defaultStockLocation
                          ?.id as string,
                      },
                    ],
                    translations: [
                      {
                        languageCode: LanguageCode.en,
                        name: this.validateForm.controls.product.controls.name
                          .value,
                      },
                    ],
                  }
                  return this.productService
                    .createProductVariants([variant])
                    .pipe(
                      tap((response) => {
                        const productVariantResponse = response.data
                          ?.createProductVariants as ProductVariant[]
                        this.singleVariantId = productVariantResponse[0].id
                      }),
                      map(() => _product)
                    )
                }
              } else {
                // Flatten and map all variants from stock locations
                const allVariants = flatMap(
                  product.stockLocations.map((stockLocation) =>
                    stockLocation.variants.map((variant) => {
                      variant.stock = +variant.stock

                      return {
                        id: variant.id,
                        productId: _product.id,
                        enabled: variant.enabled,
                        sku: variant.sku,
                        // convert price to cents
                        price: Number((variant.price * 100).toFixed(0)),
                        stockOnHand: variant.stock,
                        optionIds: variant.options.map((option) => option.id),
                        taxCategoryId: this.taxCategoryId,
                        stockLevels: [
                          {
                            stockOnHand: variant.stock,
                            stockLocationId: variant.stockLocationId,
                          },
                        ],
                        translations: [
                          {
                            languageCode: LanguageCode.en,
                            name: variant.name,
                          },
                        ],
                      }
                    })
                  )
                )

                // Filter new and existing variants
                const newVariantsArray = allVariants.filter(
                  (variant) => variant.id.indexOf('local') > -1
                )
                const existingVariantsArray = allVariants.filter(
                  (variant) => variant.id.indexOf('local') === -1
                )

                // Create observable stream for newVariants
                const createNewVariants$ = from(newVariantsArray).pipe(
                  mergeMap(({ enabled, ...variant }) => {
                    // execlude id
                    const { id: _, ...variantCopy } = variant
                    return this.productService
                      .createProductVariants(
                        variantCopy as CreateProductVariantInput
                      )
                      .pipe(
                        tap((response) => {
                          const productVariantResponse = response.data
                            ?.createProductVariants as ProductVariant[]
                          const newVariantId = productVariantResponse[0].id
                          const oldVariantId = variant.id

                          // Update in form controls
                          this.validateForm.controls.product.controls.stockLocations.controls.forEach(
                            (stockLocationControl) => {
                              stockLocationControl.controls.variants.controls.forEach(
                                (variantControl) => {
                                  if (
                                    variantControl.value.id === oldVariantId
                                  ) {
                                    variantControl.patchValue({
                                      id: newVariantId,
                                    })
                                  }
                                }
                              )
                            }
                          )

                          // Keep the raw value update for immediate use
                          variant.id = newVariantId

                          this.productVariantOptionCombination[newVariantId] = [
                            ...variant.optionIds,
                          ]
                            .sort()
                            .join(',')
                        })
                      )
                  }),
                  defaultIfEmpty([]) // Skip if there are no new variants
                )

                // Create observable stream for existingVariants
                const updateExistingVariants$ = from(
                  existingVariantsArray
                ).pipe(
                  mergeMap((variant) => {
                    // Check if the option combination has changed
                    const currentOptionsCombination = [...variant.optionIds]
                      .sort()
                      .join(',')
                    const originalOptionsCombination =
                      this.productVariantOptionCombination[variant.id]

                    if (
                      currentOptionsCombination !== originalOptionsCombination
                    ) {
                      // If options changed, exclude optionIds from the update
                      const { productId, taxCategoryId, ...variantUpdate } =
                        variant
                      return this.productService
                        .updateProductVariants(
                          variantUpdate as UpdateProductVariantInput
                        )
                        .pipe(
                          tap((response) => {
                            // if options changed, update the productVariantOptionCombination
                            this.productVariantOptionCombination[variant.id] = [
                              ...variant.optionIds,
                            ]
                              .sort()
                              .join(',')
                          })
                        )
                    } else {
                      // If options haven't changed, include optionIds from the update
                      const { productId, optionIds, ...variantUpdate } = variant
                      return this.productService
                        .updateProductVariants(
                          variantUpdate as UpdateProductVariantInput
                        )
                        .pipe(
                          tap((response) => {
                            // do nothing because the options haven't changed
                          })
                        )
                    }
                  }),
                  defaultIfEmpty([]) // Skip if there are no existing variants to update
                )

                // Combine all variant creation and update operations
                return forkJoin([
                  createNewVariants$,
                  updateExistingVariants$,
                ]).pipe(map(() => _product))
              }
            }),
            mergeMap((_product) => {
              return this.acfComponent
                .insertOrUpdate(this.id as string)
                .pipe(map(() => _product))
            })
          )
          .subscribe({
            next: () => {
              console.log(
                'Product, its option groups, options, and variants created successfully'
              )
              this.finishSubmit = false
            },
            error: (err) => {
              console.log(err)
              console.error('Error during product creation:', err)
            },
          })
      }
    }
  }

  addNewFacetValue() {
    const facetValues = this.validateForm.controls.product.controls.facetValues
    const newGroup = this.formBuilder.group({
      name: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      code: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      id: this.formBuilder.control(''),
    })

    facetValues.push(newGroup)
  }

  searchFacet(term: string) {
    this.facetService
      .fetchFacetValues({
        filter: {
          name: {
            contains: term,
          },
        },
      })
      .subscribe()
  }

  // getProductVariantsByStockLocationId(id: string) {
  //   const variants = this.validateForm.controls.product.controls.variants;

  //   const filteredProductvariants = variants.controls.filter(variantControl => {
  //     return variantControl.getRawValue().stockLocationId === id;
  //   });

  //   return filteredProductvariants;
  // }

  currencyFormat = (value: number) => {
    return `$ ${value}`
  }

  addVariantOptionGroup() {
    const newGroup = this.formBuilder.group<VariantOptionGroupForm>({
      name: this.formBuilder.control('', [Validators.required]),
      code: this.formBuilder.control('', [Validators.required]),
      id: this.formBuilder.control(`local-${v4()}`),
      options: this.formBuilder.array<FormGroup<VariantOptionForm>>([]),
      newItem: this.formBuilder.control(''),
      selectedItem: this.formBuilder.control<VariantOptionForm>({
        name: this.formBuilder.control('', [
          Validators.required,
          Validators.minLength(3),
        ]),
        code: this.formBuilder.control('', [
          Validators.required,
          Validators.minLength(3),
        ]),
        id: this.formBuilder.control(''),
        translations: this.formBuilder.array<FormGroup<TranslationForm>>([]),
      }),
      translations: this.formBuilder.array<FormGroup<TranslationForm>>([
        this.formBuilder.group({
          id: this.formBuilder.control(`local-${v4()}`),
          name: this.formBuilder.control(''),
          languageCode: this.formBuilder.control(LanguageCode.en),
        } as TranslationForm),
      ]),
    })
    // Subscribe to name changes and update code accordingly
    newGroup.controls.name.valueChanges.subscribe((value) => {
      newGroup.controls.code.setValue(value)
    })
    this.validateForm.controls.product.controls.optionGroups.push(newGroup)
  }

  addVariantOptionGroupValue(
    newItem: FormControl<string>,
    options: FormArray<FormGroup<VariantOptionForm>>,
    $event?: Event
  ) {
    if ($event) {
      $event?.preventDefault()
    }
    const value = newItem.getRawValue()
    if (!value) {
      this.messageService.create('error', 'option value is required')
      return
    }

    // Check for duplicate name in current option group
    const isDuplicateName = options.controls.some(
      (option) =>
        option.getRawValue().name.toLowerCase() === value.toLowerCase()
    )

    if (isDuplicateName) {
      this.messageService.create(
        'error',
        `Option with name "${value}" already exists`
      )
      return
    }

    newItem.patchValue('')

    options.push(
      this.formBuilder.group({
        name: this.formBuilder.control(value),
        id: this.formBuilder.control(`local-${v4()}`),
        code: this.formBuilder.control(value),
        translations: this.formBuilder.array<FormGroup<TranslationForm>>([
          this.formBuilder.group({
            id: this.formBuilder.control(`local-${v4()}`),
            name: this.formBuilder.control(value),
            languageCode: this.formBuilder.control(LanguageCode.en),
          } as TranslationForm),
        ]),
      })
    )
  }

  removeVariantOptionGroupValue(
    optionGroup: FormGroup<VariantOptionGroupForm>
  ) {
    // Check if any variant uses any option from this option group
    let isOptionInUse = false
    let variantName = ''

    // Get all option IDs from this option group
    const optionIds = optionGroup.controls.options.controls.map(
      (option) => option.getRawValue().id
    )

    // Check each variant's options against this option group's options
    for (const stockLocation of this.validateForm.controls.product.controls
      .stockLocations.controls) {
      for (const variant of stockLocation.controls.variants.controls) {
        const variantOptionIds = variant.controls.options.controls.map(
          (o) => o.getRawValue().id
        )

        // Check if any of the variant's options match any of the option group's options
        if (variantOptionIds.some((id) => optionIds.includes(id))) {
          isOptionInUse = true
          variantName = variant.getRawValue().name
          break
        }
      }
      if (isOptionInUse) break
    }

    if (isOptionInUse) {
      this.messageService.create(
        'error',
        `This option group contains options used by variant "${variantName}". Please remove the variants first.`
      )
      return
    }

    // If we get here, it's safe to remove the option group
    this.validateForm.controls.product.controls.optionGroups.removeAt(
      this.validateForm.controls.product.controls.optionGroups.controls.indexOf(
        optionGroup
      )
    )
    // only remove option group if it is not a local option group
    if (optionGroup.getRawValue().id.indexOf('local') === -1) {
      this.removedOptionGroups.push(optionGroup)
    }
  }

  closeOption(
    e: Event,
    optionGroup: FormGroup<VariantOptionGroupForm>,
    option: FormGroup<VariantOptionForm>
  ) {
    e.preventDefault()
    e.stopPropagation()

    // Check if any variant uses this option
    let isOptionInUse = false
    let variantName = ''

    // Check all stock locations and their variants
    for (const stockLocation of this.validateForm.controls.product.controls
      .stockLocations.controls) {
      for (const variant of stockLocation.controls.variants.controls) {
        for (const variantOption of variant.controls.options.controls) {
          if (variantOption.getRawValue().id === option.getRawValue().id) {
            isOptionInUse = true
            variantName = variant.getRawValue().name
            break
          }
        }
        if (isOptionInUse) break
      }
      if (isOptionInUse) break
    }

    // If option is in use, show error message and don't remove
    if (isOptionInUse) {
      this.messageService.create(
        'error',
        `The option is related to variant "${variantName}", please remove the variants first`
      )
      return
    }

    // remove the option
    optionGroup.controls.options.removeAt(
      optionGroup.controls.options.controls.indexOf(option)
    )
    // only remove option if it is not a local option
    if (option.getRawValue().id.indexOf('local') === -1) {
      this.removedOptions.push(option)
    }
  }

  showVariantCreation(stockLocation: FormGroup<StockLocationForm>) {
    if (this.validateForm.controls.product.controls.optionGroups.length === 0) {
      return this.messageService.create(
        'error',
        `You need to create an Variant Option Group and Variant Options in it before creating variants.`
      )
    }

    // Assign selected stock location to variantModal stock location
    this.validateForm.controls.variantModal.controls.stockLocation =
      cloneDeep(stockLocation)
    this.validateForm.controls.variantModal.controls.variant =
      this.formBuilder.group<VariantForm>({
        stockLocationId: this.formBuilder.control(''),
        name: this.formBuilder.control(''),
        price: this.formBuilder.control(0),
        sku: this.formBuilder.control(''),
        enabled: this.formBuilder.control(true),
        stock: this.formBuilder.control(0),
        id: this.formBuilder.control(''),
        options: this.formBuilder.array<FormGroup<VariantOptionForm>>([]),
        translations: this.formBuilder.array<FormGroup<TranslationForm>>([]),
      })

    return (this.showVariantModal = true)
  }

  cancelVariantCreation() {
    this.showVariantModal = false
    this.resetModal()
  }

  resetModal() {
    this.validateForm.controls.variantModal.controls.variant =
      this.formBuilder.group<VariantForm>({
        stockLocationId: this.formBuilder.control(''),
        name: this.formBuilder.control(''),
        price: this.formBuilder.control(0),
        sku: this.formBuilder.control(''),
        enabled: this.formBuilder.control(true),
        stock: this.formBuilder.control(0),
        id: this.formBuilder.control(''),
        options: this.formBuilder.array<FormGroup<VariantOptionForm>>([]),
        translations: this.formBuilder.array<FormGroup<TranslationForm>>([]),
      })
    this.validateForm.controls.variantModal.controls.stockLocation =
      this.formBuilder.group<StockLocationForm>({
        id: this.formBuilder.control(''),
        description: this.formBuilder.control(''),
        name: this.formBuilder.control(''),
        variants: this.formBuilder.array<FormGroup<VariantForm>>([]),
      })
    this.validateForm.controls.product.controls.optionGroups.controls.forEach(
      (optionGroup) => {
        optionGroup.controls.selectedItem.setValue(null)
      }
    )
  }

  completeVariantCreation() {
    const variantModal = this.validateForm.controls.variantModal
    const optionGroups =
      this.validateForm.controls.product.controls.optionGroups
    const stockLocations =
      this.validateForm.controls.product.controls.stockLocations

    if (!this.variantModalEditing) {
      // Create a new variant
      const variant = this.formBuilder.group<VariantForm>({
        ...cloneDeep(variantModal.controls.variant.controls),
        id: this.formBuilder.control(`local-${v4()}`, [Validators.required]),
        stockLocationId: cloneDeep(
          variantModal.controls.stockLocation.controls.id
        ),
      })

      // Clear variant options

      // Assign each selected variant options
      optionGroups.controls.forEach((optionGroup) => {
        const selectedItem = optionGroup.controls.selectedItem.getRawValue()
        if (selectedItem) {
          variant.controls.options.push(this.formBuilder.group(selectedItem))
        }
      })

      // check if the options combination is unique
      const optionIds = variant.controls.options.controls.map(
        (option) => option.getRawValue().id
      )
      const sortedOptionsCombination = [...optionIds].sort().join(',')
      const currentVariantId = variant.getRawValue().id
      let isDuplicate = false
      let duplicateVariantName = ''
      let duplicateOptionNames = ''

      // Check for duplicates across all stock locations
      for (const _stockLocation of stockLocations.controls) {
        for (const _variant of _stockLocation.controls.variants.controls) {
          // Skip comparing with itself
          if (_variant.getRawValue().id === currentVariantId) {
            continue
          }

          const variantOptionIds = _variant.controls.options.controls.map(
            (option) => option.getRawValue().id
          )
          const sortedVariantOptionsCombination = [...variantOptionIds]
            .sort()
            .join(',')

          if (sortedVariantOptionsCombination === sortedOptionsCombination) {
            isDuplicate = true
            duplicateVariantName = _variant.getRawValue().name
            // Sort the option names in the same order as the IDs for consistent display
            duplicateOptionNames = _variant.controls.options.controls
              .map((option) => option.getRawValue().name)
              .sort()
              .join('-')
            break
          }
        }
        if (isDuplicate) break
      }

      if (isDuplicate) {
        this.messageService.create(
          'error',
          `The option combination "${duplicateOptionNames}" is already in use by product variant "${duplicateVariantName}"`
        )
        return
      }
      // Add new variant into modal's stockLocation
      variantModal.controls.stockLocation.controls.variants.push(variant)

      stockLocations.controls.forEach((_stockLocation) => {
        if (
          _stockLocation.getRawValue().id ===
          variantModal.controls.stockLocation.getRawValue().id
        ) {
          // Add new variant to the product state's stockLocation
          _stockLocation.controls.variants.push(variant)
          _stockLocation.setValue(
            variantModal.controls.stockLocation.getRawValue()
          )
        }
      })
    } else {
      const variant = variantModal.controls.variant
      const variants = variantModal.controls.stockLocation.controls.variants
      // Clear options
      variant.controls.options.clear()

      // Assign each selected variant options
      optionGroups.controls.forEach((optionGroup) => {
        const selectedItem = optionGroup.controls.selectedItem.getRawValue()
        if (selectedItem) {
          variant.controls.options.push(this.formBuilder.group(selectedItem))
        }
      })

      // check if the options combination is unique
      const optionIds = variant.controls.options.controls.map(
        (option) => option.getRawValue().id
      )
      const sortedOptionsCombination = [...optionIds].sort().join(',')
      const currentVariantId = variant.getRawValue().id
      let isDuplicate = false
      let duplicateVariantName = ''
      let duplicateOptionNames = ''

      // Check for duplicates across all stock locations
      for (const _stockLocation of stockLocations.controls) {
        for (const _variant of _stockLocation.controls.variants.controls) {
          // Skip comparing with itself
          if (_variant.getRawValue().id === currentVariantId) {
            continue
          }

          const variantOptionIds = _variant.controls.options.controls.map(
            (option) => option.getRawValue().id
          )
          const sortedVariantOptionsCombination = [...variantOptionIds]
            .sort()
            .join(',')

          if (sortedVariantOptionsCombination === sortedOptionsCombination) {
            isDuplicate = true
            duplicateVariantName = _variant.getRawValue().name
            // Sort the option names in the same order as the IDs for consistent display
            duplicateOptionNames = _variant.controls.options.controls
              .map((option) => option.getRawValue().name)
              .sort()
              .join('-')
            break
          }
        }
        if (isDuplicate) break
      }

      if (isDuplicate) {
        this.messageService.create(
          'error',
          `The option combination "${duplicateOptionNames}" is already in use by product variant "${duplicateVariantName}"`
        )
        return
      }

      // Assign variantModal state to product state
      variants.controls.forEach((_variant) => {
        if (_variant.getRawValue().id === variant.getRawValue().id) {
          _variant.controls.options = cloneDeep(variant.controls.options)
          _variant.patchValue(variant.getRawValue())
        }
      })

      stockLocations.controls.forEach((_stockLocation, index) => {
        if (
          _stockLocation.getRawValue().id ===
          variantModal.controls.stockLocation.getRawValue().id
        ) {
          stockLocations.controls[index] = cloneDeep(
            variantModal.controls.stockLocation
          )
          _stockLocation.patchValue(
            variantModal.controls.stockLocation.getRawValue()
          )
        }
      })
    }

    this.showVariantModal = false
    this.resetModal()
    if (this.variantModalEditing) {
      this.variantModalEditing = false
    }
  }

  removeVariant(
    variant: FormGroup<VariantForm>,
    stockLocation: FormGroup<StockLocationForm>
  ) {
    stockLocation.controls.variants.controls.forEach((_variant, index) => {
      if (_variant.getRawValue().id === variant.getRawValue().id) {
        stockLocation.controls.variants.removeAt(index)
      }
    })
    // only remove variant if it is not a local variant
    if (variant.getRawValue().id.indexOf('local') === -1) {
      this.removedVariants.push(variant)
    }
  }

  editVariantCreation(
    variant: FormGroup<VariantForm>,
    stockLocation: FormGroup<StockLocationForm>
  ) {
    this.validateForm.controls.variantModal.controls.variant =
      cloneDeep(variant)
    this.validateForm.controls.variantModal.controls.stockLocation =
      cloneDeep(stockLocation)
    variant.controls.options.controls.forEach((option) => {
      const optionGroup =
        this.validateForm.controls.product.controls.optionGroups.controls.find(
          (optionGroup) => {
            return optionGroup.getRawValue().options.some((_option) => {
              return _option.id === option.getRawValue().id
            })
          }
        )
      if (optionGroup) {
        optionGroup.controls.selectedItem = this.formBuilder.control({
          id: option.controls.id,
          code: option.controls.code,
          name: option.controls.name,
          translations: option.controls.translations,
        })
      }
    })
    this.showVariantModal = true
    this.variantModalEditing = true
  }

  createStockLocation() {
    const selectedStockLocation =
      this.validateForm.controls.selectedStockLocation
    const stockLocation = selectedStockLocation.getRawValue()

    if (!stockLocation) {
      // don't pop up error message because we will initialize via `selectedStockLocation.setValue(null)`,just return
      return
    }

    this.validateForm.controls.stockLocations.controls.forEach(
      (_stockLocation, index) => {
        const identical =
          _stockLocation.controls.id.getRawValue() ===
          stockLocation.id.getRawValue()

        if (identical) {
          this.validateForm.controls.stockLocations.removeAt(index)
        }
      }
    )

    // this will trigger `ngModelChange` event again
    selectedStockLocation.setValue(null)
    return this.validateForm.controls.product.controls.stockLocations.push(
      this.formBuilder.group(stockLocation)
    )
  }

  removeStockLocation(stockLocation: FormGroup<StockLocationForm>) {
    const stockLocations =
      this.validateForm.controls.product.controls.stockLocations
    stockLocations.controls.forEach((stockLocationForm, index) => {
      if (
        stockLocationForm.controls.id.getRawValue() ===
        stockLocation.controls.id.getRawValue()
      ) {
        stockLocations.removeAt(index)
        this.validateForm.controls.stockLocations.push(stockLocationForm)
      }
    })

    stockLocation.controls.variants.controls.forEach((variant) => {
      this.removedVariants.push(variant)
    })
  }

  compareId = (o1: any, o2: any) => {
    return o1 && o2 ? o1.id.getRawValue() === o2.id.getRawValue() : o1 === o2
  }

  onDropZoneDrop(event: DragEvent) {
    event.preventDefault()
    if (event.dataTransfer?.files) {
      const files = Array.from(event.dataTransfer.files)
      this.handleFiles(files)
    }
    this.isDropZoneDragging = false
  }

  onDropZoneDragOver(event: DragEvent) {
    event.preventDefault()
    this.isDropZoneDragging = true
  }

  handleFiles(files: File[]) {
    if (!files.length) {
      this.mediaUploadingMessage = 'Please select an image'
      return
    }

    const id = `local-${v4()}`

    // Check file type
    const allowedTypes = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/gif',
      'image/svg+xml',
    ]
    if (!allowedTypes.includes(files[0].type)) {
      this.mediaUploadingMessage =
        'Only PNG, JPG, JPEG, GIF, and SVG files are allowed'
      return
    }

    files.forEach((file) => {
      const reader = new FileReader()
      reader.onload = (e: any) => {
        const img = new Image()
        img.src = e.target.result
        img.onload = () => {
          this.validateForm.controls.product.controls.assets.push(
            this.formBuilder.group({
              id,
              name: file.name,
              type: file.type,
              file,
              binary: e.target.result as string,
            })
          )
        }
      }
      reader.readAsDataURL(file)
    })
  }

  onDropZoneDragLeave(event: DragEvent) {
    event.preventDefault()
    this.isDropZoneDragging = false
  }

  // check image size
  onFileSelected(event: any) {
    const file: File = event.target.files[0]
    if (!file) {
      this.mediaUploadingMessage = 'Please select an image'
      return
    }

    // Check file type
    const allowedTypes = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/gif',
      'image/svg+xml',
    ]
    if (!allowedTypes.includes(file.type)) {
      this.mediaUploadingMessage =
        'Only PNG, JPG, JPEG, GIF, and SVG files are allowed'
      event.target.value = '' // Clear the file input
      return
    }

    // define file information
    const id = `local-${v4()}`
    const fileName: string = file.name
    const fileType: string = file.type
    this.mediaUploadingMessage = ''

    // Convert to base64
    const reader = new FileReader()
    reader.onload = (e: any) => {
      const img = new Image()
      img.src = e.target.result

      img.onload = () => {
        this.validateForm.controls.product.controls.assets.push(
          this.formBuilder.group({
            id,
            name: fileName,
            type: fileType,
            file,
            binary: e.target.result as string,
          })
        )
      }
    }

    reader.readAsDataURL(file)
  }

  displayImage(asset: FormGroup<AssetForm>) {
    if (asset.getRawValue().id.indexOf('local-') !== -1) {
      return asset.getRawValue().binary
    }

    const assetId = asset.getRawValue().id
    if (!this.assetSources[assetId]) {
      this.assetService
        .getAsset(assetId)
        .pipe(map((response) => response.data?.asset?.source || ''))
        .subscribe((source) => {
          this.assetSources[assetId] = source
        })
    }

    return this.assetSources[assetId] || ''
  }

  checkVariantId(id: string) {
    // check if user has submitted the form
    if (id.includes('local')) {
      return false
    }
    return true
  }

  goToProductVariant(id: string) {
    this.router.navigate([`/admin/product-variant/${id}`])
  }

  // update validators when `hasVariants` changes
  onHasVariantsChange(event: boolean) {
    // if change `hasVariants` to false, check if optionGroups is empty
    if (!event) {
      const optionGroups =
        this.validateForm.controls.product.controls.optionGroups
      if (optionGroups.controls.length > 0) {
        this.hasVariants = true
        this.messageService.error(
          'Please remove all option groups before before changing this value'
        )
        return
      }
    }

    this.hasVariants = event

    const variantForm = this.validateForm.controls.product.controls.variant
    const validators = this.hasVariants ? [] : [Validators.required]

    // update validators
    variantForm.controls.price.setValidators(validators)
    variantForm.controls.sku.setValidators(validators)
    variantForm.controls.stock.setValidators(validators)

    this.validateForm.updateValueAndValidity()
  }

  onPriceInput(event: any, control: FormControl) {
    const input = event.target
    const value = parseFloat(input.value)

    if (!isNaN(value)) {
      if (value > 999999) {
        // If exceeds maximum, set to maximum
        control.setValue(999999)
        return
      }

      const formattedValue = Math.floor(value * 100) / 100

      // Only update if the value has changed
      if (formattedValue !== value) {
        control.setValue(formattedValue)
      }
    }
  }

  deleteProduct() {
    this.productService
      .deleteProduct(this.id as string)
      .pipe(
        mergeMap((response) => {
          return this.acfComponent
            .deleteValues(this.id as string)
            .pipe(map(() => response))
        })
      )
      .subscribe({
        next: (response) => {
          this.messageService.success('Product deleted successfully')
          this.router.navigate(['/admin/products'])
        },
        error: (error) => {
          this.messageService.error('Failed to delete product')
          this.showRemoveProductModal = false
        },
      })
  }

  goBack() {
    this.router.navigate(['/admin/products/']).then()
  }
}
