import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Observable, tap } from 'rxjs';
import { FacetList } from '../../gql/shop/generated';
import { AppService } from '../../service/app.service';
import { FacetService } from '../../service/facet.service';

@Component({
  selector: 'app-facets',
  templateUrl: './facets.component.html',
  styleUrl: './facets.component.less',
})
export class FacetsComponent implements OnInit {
  activeTab = 'All';
  $isLoading: Observable<boolean>;

  facets: FacetList = {
    items: [],
    totalItems: 0,
  };

  pageSize = 10;
  pageIndex = 0;

  setOfCheckedId = new Set<string>();
  checked = false;
  indeterminate = false;

  constructor(
    private appService: AppService,
    private facetService: FacetService,
    private router: Router,
  ) {
    this.$isLoading = this.appService.isLoading$;
  }

  ngOnInit(): void {
    this.setupListeners();

    this.facetService.fetchFacets().subscribe();
  }

  setupListeners() {
    this.facetService.facets$
      .pipe(
        tap((facets) => {
          console.log({ facets });

          this.facets = facets;
        }),
      )
      .subscribe();
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  onAllChecked(value: boolean): void {
    this.facets.items.forEach((item) => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    this.checked = this.facets.items.every((item) =>
      this.setOfCheckedId.has(item.id),
    );
    this.indeterminate =
      this.facets.items.some((item) => this.setOfCheckedId.has(item.id)) &&
      !this.checked;
  }

  onItemChecked(id, $event) {
    console.log(id, $event);
  }

  goToFacet(id: string) {
    this.router.navigate([`/admin/facets/${id}`]);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    console.log(params);
    const { pageSize, pageIndex, sort, filter } = params;

    const currentSort = sort.find((item) => item.value !== null);
    const sortField = currentSort && currentSort.key;
    const sortOrder = currentSort && currentSort.value;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    this.facetService.updatePagination(pageIndex, pageSize);

    if (sortField && sortOrder) {
      this.facetService.updateSort(sortField, sortOrder);
    }

    this.facetService.goToPage(pageIndex);
  }
}
