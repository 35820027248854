import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { AdminGuard } from '../guards/admin.guard'
import { LayoutAlphaComponent } from '../layout/layout-alpha/layout-alpha.component'
import { LayoutBetaComponent } from '../layout/layout-beta/layout-beta.component'
import { BlogsDetailComponent } from '../organism/blogs-detail/blogs-detail.component'
import { BlogsComponent } from '../organism/blogs/blogs.component'
import { CustomerGroupsComponent } from '../organism/customer-groups/customer-groups.component'
import { CustomersComponent } from '../organism/customers/customers.component'
import { DesignSystemComponent } from '../organism/design-system/design-system.component'
import { EmptyComponent } from '../organism/empty/empty.component'
import { FacetDetailComponent } from '../organism/facet-detail/facet-detail.component'
import { FacetsComponent } from '../organism/facets/facets.component'
import { LoginComponent } from '../organism/login/login.component'
import { NotFoundAlphaComponent } from '../organism/not-found-alpha/not-found-alpha.component'
import { PluginsDetailComponent } from '../organism/plugins-detail/plugins-detail.component'
import { PluginsComponent } from '../organism/plugins/plugins.component'
import { PreferencesComponent } from '../organism/preferences/preferences.component'
import { ProductDetailComponent } from '../organism/product-detail/product-detail.component'
import { ProductVariantComponent } from '../organism/product-variant/product-variant.component'
import { ProductsComponent } from '../organism/products/products.component'
import { RegisterComponent } from '../organism/register/register.component'
import { OrderDetailComponent } from '../organism/order-detail/order-detail.component'
import { ThemesComponent } from '../organism/themes/themes.component'
import { VerifyComponent } from '../organism/verify/verify.component'
import { OrdersComponent } from '../organism/orders/orders.component'
import { CustomerDetailComponent } from '../organism/customer-detail/customer-detail.component'
import { CustomerGroupDetailComponent } from '../organism/customer-group-detail/customer-group-detail.component'

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: 'admin',
        component: LayoutAlphaComponent,
        canActivate: [AdminGuard],
        children: [
          {
            path: 'products',
            component: ProductsComponent,
          },
          {
            path: 'products/new',
            component: ProductDetailComponent,
            data: {
              isCreation: true,
            },
          },
          {
            path: 'products/:id',
            component: ProductDetailComponent,
            data: {
              isCreation: false,
            },
          },
          {
            path: 'product-variant/:id',
            component: ProductVariantComponent,
          },
          {
            path: 'facets',
            component: FacetsComponent,
          },
          {
            path: 'facets/new',
            component: FacetDetailComponent,
            data: {
              isCreation: true,
            },
          },
          {
            path: 'facets/:id',
            component: FacetDetailComponent,
            data: {
              isCreation: false,
            },
          },
          {
            path: 'plugins',
            component: PluginsComponent,
          },
          {
            path: 'plugins/:id',
            component: PluginsDetailComponent,
          },
          {
            path: 'blogs',
            component: BlogsComponent,
          },
          {
            path: 'blogs/:id',
            component: BlogsDetailComponent,
          },
          {
            path: 'preferences',
            component: PreferencesComponent,
          },
          {
            path: 'themes',
            component: ThemesComponent,
          },
          {
            path: 'orders',
            component: OrdersComponent,
          },
          {
            path: 'orders',
            component: OrdersComponent,
          },
          {
            path: 'orders/:id',
            component: OrderDetailComponent,
          },
          {
            path: 'customers',
            component: CustomersComponent,
          },
          {
            path: 'customers/:id',
            component: CustomerDetailComponent,
          },
          {
            path: 'customer-groups',
            component: CustomerGroupsComponent,
          },
          {
            path: 'customer-groups/:id',
            component: CustomerGroupDetailComponent,
          },
          {
            path: 'empty',
            component: EmptyComponent,
          },
        ],
      },
      {
        path: '',
        component: LayoutBetaComponent,
        children: [
          {
            path: 'login',
            component: LoginComponent,
          },
          {
            path: 'register',
            component: RegisterComponent,
          },
          {
            path: 'verify',
            component: VerifyComponent,
          },
        ],
      },
      { path: 'not-found', component: NotFoundAlphaComponent },
      { path: 'design-system', component: DesignSystemComponent },
    ]),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
