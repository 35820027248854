import { Injectable } from "@angular/core";
import { GetCouponCodeSelectorPromotionListGQL } from "../gql/shop/generated";
import { AuthService } from "./auth.service";
import { AppService } from "./app.service";
import { tap } from "rxjs";


@Injectable({ providedIn: 'root' })
export class PromotionService {

    constructor(
        private appService: AppService, 
        private authService: AuthService,
        private promotionsGQL: GetCouponCodeSelectorPromotionListGQL
    ) {}

    getCouponCodeSelectorPromotionList(code: string) {
        return this.appService.withLoader(() =>
            this.promotionsGQL
                .fetch(
                    { options: { filter: { couponCode: { contains: code } } } },
                    { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } },
                        fetchPolicy: 'network-only',
                    }
                ).pipe(
                    tap({
                        next: (response) => {
                            console.log(response)
                        }
                    })
                )
        );
    }
    
    
}