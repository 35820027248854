<form
  nz-form
  [nzLayout]="validateForm.get('formLayout')?.value"
  [formGroup]="validateForm"
  *ngIf="manageablePlugin"
>
  <div class="detail-page-container">
    <div nz-row class="detail-page-head">
      <div nz-col nzSpan="12">
        <button (click)="goBack()" nz-button nzType="link">
          <span nz-icon nzType="ng-zorro:arrow-left"></span>
        </button>
      </div>
      <div nz-col nzSpan="12" class="detail-page-toolbar">
        <div class="flex-end">
          <button
            (click)="submitForm()"
            nz-button
            nzType="primary"
            (keydown.enter)="$event.preventDefault()"
          >
            <span nz-icon nzType="ng-zorro:add"></span>
            Save
          </button>
        </div>
      </div>
    </div>
    <div nz-row class="detail-page-info">
      <div nz-col nzSpan="24">
        <div class="logo">
          <div class="logo-container">
            <img src="{{ manageablePlugin?.imageUrl }}" alt="Logo" />
          </div>
          <div class="logo-info">
            <h2>{{ manageablePlugin?.name }}</h2>
            <p>
              {{ manageablePlugin?.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="detail-page-container">
    <h2>Configuration</h2>

    <div *ngFor="let item of manageablePlugin?.configurationItems">
      <div nz-row>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label [nzSpan]="24">{{
              getTranslation(item.translations)?.name
            }}</nz-form-label>
            <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
              <!-- Render input based on item type -->
              <ng-container [ngSwitch]="item.type">
                <input
                  [formControl]="asFormControl(validateForm.controls[item.id])"
                  *ngSwitchCase="'string'"
                  nz-input
                  placeholder="{{
                    getTranslation(item.translations)?.description
                  }}"
                />
                <nz-switch *ngSwitchCase="'boolean'"></nz-switch>
              </ng-container>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label [nzSpan]="24">Enabled</nz-form-label>
          <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
            <nz-switch
              [formControl]="asFormControl(validateForm.controls['enabled'])"
            ></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </div>
</form>
