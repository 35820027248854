<form
  nz-form
  [nzLayout]="validateForm.controls.formLayout.value"
  [formGroup]="validateForm"
>
  <div class="page-container" style="padding-bottom: 0px;">
    <div nz-row style="margin-bottom: 16px;">
      <div nz-col nzSpan="12">
        <span nz-icon nzType="ng-zorro:arrow-left" (click)="goBack()" style="cursor: pointer;"></span>
      </div>
      <div nz-col nzSpan="12" class="page-container-toolbar">
        <div class="flex-end">
          <button nz-button nzDanger ngClass="trash" (click)="showRemoveProductVariantModal = true">
            <span nz-icon nzType="ng-zorro:trash"></span>
            Remove
          </button>
          <button
            (click)="submitForm()"
            nz-button
            nzType="primary"
            (keydown.enter)="$event.preventDefault()"
            [disabled]="finishSubmit"
          >
            <span nz-icon nzType="ng-zorro:add"></span>
            Update
          </button>
          <nz-modal [(nzVisible)]="showRemoveProductVariantModal" nzTitle="Remove Product Variant" (nzOnCancel)="showRemoveProductVariantModal = false" (nzOnOk)="deleteProductVariant()">
            <div *nzModalContent>Please confirm you want to remove this product variant.</div>
          </nz-modal>
        </div>
      </div>
    </div>
    <div nz-row class="page-container-head" style="border: none">
        <div nz-col nzSpan="12">
            <h2>{{name}}</h2>
            <p style="color: #828E98; margin-top: 44px;">Populated the following required fields to add new product</p>
        </div>
    </div>
  </div>

  <div class="page-container">
    <div class="page-container-inner">
      <div nz-row>
        <div nz-col nzSpan="24">
          <h2>General Info</h2>
        </div>
        <div nz-col nzSpan="24">
          <div nz-row>
            <div nz-col nzSpan="8" class="form-item-left">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">Name</nz-form-label>
                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                  <input
                    nz-input
                    [formControl]="validateForm.controls.variant.controls.name"
                    placeholder="Name"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8" class="form-item-left form-item-right">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">SKU</nz-form-label>
                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                  <input
                    nz-input
                    [formControl]="validateForm.controls.variant.controls.sku"
                    placeholder="SKU"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8" class="form-item-right">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">Visibility</nz-form-label>
                <nz-form-control [nzSpan]="24">
                  <nz-switch
                    [formControl]="
                      validateForm.controls.variant.controls.enabled
                    "
                  ></nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-container">
    <div class="page-container-inner">
      <div nz-row>
        <div nz-col nzSpan="24">
          <h2>Media</h2>
        </div>
        <div nz-col nzSpan="24">
          <div
            class="drop-zone"
            (drop)="onDropZoneDrop($event)"
            (dragover)="onDropZoneDragOver($event)"
            (dragleave)="onDropZoneDragLeave($event)"
            [ngClass]="{ dragover: isDropZoneDragging }"
          >
            <input class="drop-zone-input" type="file" (change)="onFileSelected($event)"
             accept=".svg,.png,.jpg,.jpeg,.gif" #fileInput/>
            <div class="drop-zone-inner">
              <span nz-icon nzType="ng-zorro:upload"></span>
              <span> <b>Click to upload</b> or drag and drop </span>
              <p>SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
              <p *ngIf="mediaUploadingMessage" style="color: red;">{{ mediaUploadingMessage }}</p>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="24">
          <!-- Loop images -->
          <div class="product-images">
            <div
              *ngFor="
                let item of validateForm.controls.variant.controls.assets.controls;
                let index = index
              "
              class="product-images-item"
            >
              <div
                (click)="
                  this.validateForm.controls.variant.controls.assets.removeAt(
                    index
                  )
                "
                class="close"
              >
                <span nz-icon nzType="close-circle" nzTheme="fill"></span>
              </div>
              <div class="image-info">
                <img
                  nz-image
                  width="200px"
                  [nzSrc]="displayImage(item)"
                  [alt]="item.getRawValue().name"
                />
                <p>{{ item.getRawValue().name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-container">
    <div class="page-container-inner">
      <div nz-row>
        <div nz-col nzSpan="24">
          <h2>Facets</h2>
        </div>
        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="24">Facets</nz-form-label>
            <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
              <div class="selectbox-group">
                <nz-select
                  nzShowSearch
                  nzServerSearch
                  nzPlaceHolder="input search text"
                  (nzOnSearch)="searchFacet($event)"
                  [formControl]="validateForm.controls.selectedFacetValue"
                >
                  <ng-container
                    *ngIf="facetService.facetValues$ | async as facetValues"
                  >
                    <nz-option
                      *ngFor="let item of facetValues?.items"
                      [nzLabel]="item.name + ': ' + item.code"
                      [nzValue]="item"
                    >
                    </nz-option>
                  </ng-container>
                </nz-select>
              </div>
              <div class="selectbox-items">
                <nz-tag
                  *ngFor="
                    let facetValue of validateForm.controls.variant.controls
                      .facetValues.controls;
                    let index = index
                  "
                  nzMode="closeable"
                  ngClass="alpha"
                  class="facet-tag"
                  (nzOnClose)="
                    validateForm.controls.variant.controls.facetValues.removeAt(
                      index
                    )
                  "
                  ><b>{{ facetValue.getRawValue().name }}:</b>&nbsp;{{ facetValue.getRawValue().code }}</nz-tag
                >
              </div>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>

  <div class="page-container">
    <div class="page-container-inner">
      <div nz-row>
        <div nz-col nzSpan="24">
          <h2>Price & Tax</h2>
        </div>
      </div>
      <div nz-row>
          <div nz-col nzSpan="12" class="form-item-left">
            <nz-form-item>
              <nz-form-label [nzSpan]="24">Tax Category</nz-form-label>
              <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                <nz-select
                  nzShowSearch
                  nzServerSearch
                  nzPlaceHolder="input search text"
                  [formControl]="validateForm.controls.variant.controls.taxCategoryId"
                >
                  <nz-option *ngFor="let item of validateForm.controls.taxCategories.controls" [nzLabel]="item.getRawValue().name" [nzValue]="item.getRawValue().id">{{item.getRawValue().name}}</nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="12" class="form-item-right">
            <nz-form-item>
              <nz-form-label [nzSpan]="24">Price</nz-form-label>
              <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                <input 
                  nz-input
                  type="number" 
                  min="0" 
                  step="0.01" 
                  placeholder="Price"  
                  [formControl]="validateForm.controls.variant.controls.pricesForm.controls.price"
                  (input)="onPriceInput($event, validateForm.controls.variant.controls.pricesForm.controls.price)"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
      </div>
      <div nz-row style="margin-bottom: 0px;">
        <div nz-col nzSpan="12" class="form-item-left">
          <nz-form-item>
            <!-- TODO -->
            <nz-form-label [nzSpan]="24"><b>Tax Rate at &nbsp;</b> {{validateForm.controls.taxRate.getRawValue()}}% <b>&nbsp;Price VAT Inc: &nbsp; </b> {{currencySymbol}} {{priceWithTax | number:'1.2-2'}}</nz-form-label>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>


  <div class="page-container">
    <div class="page-container-inner">
      <div nz-row>
        <div nz-col nzSpan="24">
          <h2>Stock Levels</h2>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-control [nzHasFeedback]="true" [nzSpan]="3">
              <nz-switch
                  [formControl]="
                    validateForm.controls.variant.controls.useGlobalOutOfStockThreshold
                  "
                ></nz-switch>
            </nz-form-control>
            <div style="padding-top: 10px;">Use gloval value({{globalOutOfStockThreshold}})</div>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="12" class="form-item-left">
          <nz-form-item>
            <nz-form-label [nzSpan]="24"><b>Track Inventory</b></nz-form-label>
            <nz-form-label [nzSpan]="24" style="min-height: 45px;">When tracked, product variant stock levels will be adjusted when sold</nz-form-label>
            <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
              <nz-select
                nzShowSearch
                nzServerSearch
                nzPlaceHolder="Inherent from global settings"
                [formControl]="validateForm.controls.variant.controls.trackInventory"
              >
                <nz-option *ngFor="let item of validateForm.controls.trackInventoryOptions.controls" [nzLabel]="item.getRawValue().name === 'TRUE' ? 'Track' : item.getRawValue().name === 'FALSE' ? 'Do not track' : 'Inherit from global settings'" [nzValue]="item.getRawValue().name">{{item.getRawValue().name === 'TRUE' ? 'Track' : item.getRawValue().name === 'FALSE' ? 'Do not track' : 'Inherit from global settings'}}</nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12" class="form-item-right">
          <nz-form-item>
            <nz-form-label [nzSpan]="24"><b>Out of stock threshold</b></nz-form-label>
            <nz-form-label [nzSpan]="24" style="min-height: 45px;">Sets the stock levels at which this variant is considered to be out of stock. a negative value enables backorder support.</nz-form-label>
            <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
              <input 
                nz-input
                type="number" 
                min="0" 
                step="1" 
                placeholder="0"  
                [formControl]="validateForm.controls.variant.controls.outOfStockThreshold"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row>
        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="24"
              ><h3>Add Stock to Location</h3></nz-form-label
            >
            <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
              <nz-select
                [formControl]="validateForm.controls.selectedStockLocation"
                (ngModelChange)="createStockLocation()"
              >
                <nz-option
                  *ngFor="
                    let item of validateForm.controls.stockLocations
                      .controls
                  "
                  [nzValue]="item.controls"
                  [nzLabel]="item.getRawValue().name"
                >
                  {{ item.getRawValue().name }}
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div *ngFor="let item of validateForm.controls.variant.controls.stockLocations.controls">
        <div nz-row>
          <div nz-col nzSpan="24" class="stock-location-name">{{item.getRawValue().name}}</div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="10" class="form-item-left">
            <nz-form-item>
              <nz-form-label [nzSpan]="24">{{item.getRawValue().name}}: stock</nz-form-label>
              <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                <input nz-input [formControl]="item.controls.stock" placeholder="0" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="10" class="form-item-right">
            <nz-form-item>
              <nz-form-label [nzSpan]="24">{{item.getRawValue().name}}: Allocated</nz-form-label>
              <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                <h2>{{item.getRawValue().allocated}}</h2>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="2" class="form-item-right form-item-top">
            <button nz-button nzDanger ngClass="trash" (click)="removeStockLocation(item)">
              <span nz-icon nzType="ng-zorro:trash"></span>
              Remove
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</form> 
