import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { AppService } from "./app.service";
import { GetAddManualPaymentMethodListGQL, PaymentMethodListOptions, SettleRefundInput } from "../gql/shop/generated";
import { pipe, tap } from "rxjs";
import { CancelPaymentGQL, SettleRefundGQL } from "../gql/shop/generated";
@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(
    private appService: AppService, 
    private authService: AuthService, 
    private getAddManualPaymentMethodListGQL: GetAddManualPaymentMethodListGQL,
    private cancelPaymentGQL: CancelPaymentGQL,
    private settleRefundGQL: SettleRefundGQL
  ) {}

  getPaymentMethods(options: PaymentMethodListOptions) {
    return this.appService.withLoader(() => 
      this.getAddManualPaymentMethodListGQL.fetch(
        { options },
        { 
          context: { 
            headers: { 
              'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token 
            } 
          },
          fetchPolicy: 'network-only',
        }
      ).pipe(
        tap({ 
          next: (response) => console.log(response) 
        })
      )
    );
  }

  cancelPayment(paymentId: string) {
    return this.appService.withLoader(() => 
      this.cancelPaymentGQL.mutate(
        { id: paymentId },
        { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => console.log(response)
        })
      ) 
    )
  }

  settleRefund(input: SettleRefundInput) {
    return this.appService.withLoader(() => 
      this.settleRefundGQL.mutate(
        { input },
        { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => console.log(response)
        })
      )
    )
  }
}