import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { MoleculeModule } from '../molecule/molecule.module';
import { DesignSystemComponent } from './design-system/design-system.component';
import { FacetDetailComponent } from './facet-detail/facet-detail.component';
import { FacetsComponent } from './facets/facets.component';
import { LoginComponent } from './login/login.component';
import { NotFoundAlphaComponent } from './not-found-alpha/not-found-alpha.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductsComponent } from './products/products.component';
import { RegisterComponent } from './register/register.component';
import { ProductVariantComponent } from './product-variant/product-variant.component';
import { PluginsComponent } from './plugins/plugins.component';
import { PluginsDetailComponent } from './plugins-detail/plugins-detail.component';
import { EmptyComponent } from './empty/empty.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { CustomerGroupsComponent } from './customer-groups/customer-groups.component';
import { CustomersComponent } from './customers/customers.component';
import { OrdersComponent } from './orders/orders.component';
import { AdvancedCustomFieldsComponent } from './advanced-custom-fields/advanced-custom-fields.component';

@NgModule({
  declarations: [
    EmptyComponent,
    NotFoundAlphaComponent,
    DesignSystemComponent,
    LoginComponent,
    RegisterComponent,
    ProductsComponent,
    ProductDetailComponent,
    ProductVariantComponent,
    AdvancedCustomFieldsComponent,
    FacetsComponent,
    FacetDetailComponent,
    PluginsComponent,
    PluginsDetailComponent,
    OrdersComponent,
    OrderDetailComponent,
    CustomersComponent,
    CustomerGroupsComponent,
  ],
  imports: [
    MoleculeModule,
    QuillModule.forRoot({
      theme: 'bubble',
    }),
  ],
  providers: [],
  exports: [MoleculeModule],
})
export class OrganismModule {}
