<div class="page-container">
  <div nz-row class="page-container-head">
    <div nz-col nzSpan="12">
      <h2>Orders</h2>
    </div>
    <div nz-col nzSpan="12" class="page-container-toolbar">
      <button nz-button nzType="primary" (click)="createDraftOrder()">
        <span nz-icon nzType="ng-zorro:add"></span>
        Draft Order
      </button>
    </div>
  </div>
  <div class="page-container-inner tab-container">
    <div nz-row>
      <div nz-col nzSpan="24">
          <nz-table
            #rowSelectionTable
            #sortTable
            nzShowSizeChanger
            [nzData]="orders.items"
            [nzFrontPagination]="false"
            [nzLoading]="appService.isLoading$ | async"
            [nzTotal]="orders.totalItems"
            (nzQueryParams)="onQueryParamsChange($event)"
            class="order-table"
          >
            <thead>
              <tr>
                <th
                  [(nzChecked)]="checked"
                  [nzIndeterminate]="indeterminate"
                  (nzCheckedChange)="onAllChecked($event)"
                ></th>
                <th>CODE</th>
                <th>CUSTOMER</th>
                <th>STATE</th>
                <th>TOTAL</th>
                <th>UPDATED AT</th>
                <th>PLACED AT</th>
                <th>SHIPPING</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="order-table-row"
                *ngFor="let data of orders.items || []"
                (click)="goToOrder(data.id)"
              >
                <td
                  [nzChecked]="setOfCheckedId.has(data.id)"
                  (nzCheckedChange)="onItemChecked(data.id, $event)"
                ></td>
                <td>
                  {{ data.code }}
                </td>
                <td>{{ data.customer ? (data.customer.firstName + ' ' + data.customer.lastName) : 'Guest' }}</td>
                <td>
                  <nz-tag [nzColor]="pickOrderStateColor(data.state)">
                    {{ data.state }}
                  </nz-tag>
                </td>
                <td>{{ data.total / 100 | currency:data.currencyCode }}</td>
                <td>{{ data.updatedAt }}</td>
                <td>{{ data.orderPlacedAt }}</td>
                <td>
                  <nz-tag
                    *ngFor="
                      let shippingLine of data.shippingLines;
                      let i = index
                    "
                    [nzColor]="pickShippingColor(i)"
                  >
                    {{ shippingLine.shippingMethod.name }}
                  </nz-tag>
                </td>
              </tr>
            </tbody>
          </nz-table>
          <div class="pagination-info">
            <p>
              Showing {{ pageSize * (pageIndex - 1) }} -
              {{ pageSize * pageIndex }} of {{ orders.totalItems }}
            </p>
          </div>
      </div>
    </div>
  </div>
</div>