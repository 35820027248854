import { Component } from '@angular/core';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-profile-button-alpha',
  templateUrl: './profile-button-alpha.component.html',
  styleUrl: './profile-button-alpha.component.less',
})
export class ProfileButtonAlphaComponent {
  isProfileVisible = false;

  constructor(private authService: AuthService) {}

  closeProfile() {
    this.isProfileVisible = false;
  }

  openProfile() {
    this.isProfileVisible = true;
  }

  logout() {
    this.authService.logout();
  }
}
