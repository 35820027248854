import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep, flatMap } from 'lodash';
import { NzFormLayoutType } from 'ng-zorro-antd/form';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  combineLatest,
  defaultIfEmpty,
  filter,
  forkJoin,
  from,
  map,
  mergeMap,
  tap,
} from 'rxjs';
import { v4 } from 'uuid';
import {
  Asset,
  CreateProductVariantInput,
  FacetValue,
  LanguageCode,
  Product,
  ProductOption,
  ProductOptionGroup,
  ProductVariant,
  StockLevel,
  StockLocation,
  UpdateProductVariantInput,
} from '../../gql/shop/generated';
import { AssetService } from '../../service/asset.service';
import { FacetService } from '../../service/facet.service';
import { ProductService } from '../../service/product.service';
import { StockService } from '../../service/stock-location.service';
import { ArrayValidators } from '../../utils/angular-patch/form-validation';

interface FacetValueForm {
  id: FormControl<string>;
  code: FormControl<string>;
  name: FormControl<string>;
}

interface StockLocationForm {
  id: FormControl<string>;
  description: FormControl<string>;
  name: FormControl<string>;
  variants: FormArray<FormGroup<VariantForm>>;
}

interface VariantOptionForm {
  id: FormControl<string>;
  code: FormControl<string>;
  name: FormControl<string>;
}

interface TranslationForm {
  id: FormControl<string>;
  name: FormControl<string>;
  languageCode: FormControl<string>;
}

interface VariantForm {
  id: FormControl<string>;
  stockLocationId: FormControl<string>;
  name: FormControl<string>;
  price: FormControl<number>;
  sku: FormControl<string>;
  enabled: FormControl<boolean>;
  stock: FormControl<number>;
  options: FormArray<FormGroup<VariantOptionForm>>;
  translations: FormArray<FormGroup<TranslationForm>>;
}

interface VariantOptionForm {
  id: FormControl<string>;
  code: FormControl<string>;
  name: FormControl<string>;
  translations: FormArray<FormGroup<TranslationForm>>;
}

interface VariantOptionGroupForm {
  id: FormControl<string>;
  code: FormControl<string>;
  name: FormControl<string>;
  options: FormArray<FormGroup<VariantOptionForm>>;
  newItem: FormControl<string>;
  selectedItem: FormControl<VariantOptionForm | null>;
  translations: FormArray<FormGroup<TranslationForm>>;
}

interface ProductForm {
  id: FormControl<string>;
  description: FormControl<string>;
  name: FormControl<string>;
  slug: FormControl<string>;
  enabled: FormControl<boolean>;
  facetValues: FormArray<FormGroup<FacetValueForm>>;
  stockLocations: FormArray<FormGroup<StockLocationForm>>;
  optionGroups: FormArray<FormGroup<VariantOptionGroupForm>>;
  assets: FormArray<FormGroup<AssetForm>>;
}

interface AssetForm {
  name: FormControl<string>;
  file: FormControl<File>;
  type: FormControl<string>;
  binary: FormControl<string>;
  id: FormControl<string>;
}

interface VariantModalForm {
  stockLocation: FormGroup<StockLocationForm>;
  variant: FormGroup<VariantForm>;
}

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrl: './product-detail.component.less',
})
export class ProductDetailComponent implements OnInit, OnDestroy {
  validateForm: FormGroup<{
    formLayout: FormControl<NzFormLayoutType>;
    product: FormGroup<ProductForm>;
    selectedFacetValue: FormControl<FacetValueForm | null>;
    selectedStockLocation: FormControl<StockLocationForm | null>;
    stockLocations: FormArray<FormGroup<StockLocationForm>>;
    variantModal: FormGroup<VariantModalForm>;
  }> = this.formBuilder.group({
    formLayout: 'horizontal' as NzFormLayoutType,
    product: this.formBuilder.group<ProductForm>({
      id: this.formBuilder.control(''),
      description: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(15),
      ]),
      name: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      slug: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      enabled: this.formBuilder.control(false, [Validators.required]),
      facetValues: this.formBuilder.array<FormGroup<FacetValueForm>>([]),
      stockLocations: this.formBuilder.array<FormGroup<StockLocationForm>>(
        [],
        [ArrayValidators.minLength(1)],
      ),
      optionGroups: this.formBuilder.array<FormGroup<VariantOptionGroupForm>>(
        [],
        [ArrayValidators.minLength(1)],
      ),
      assets: this.formBuilder.array<FormGroup<AssetForm>>([]),
    }),
    selectedFacetValue: this.formBuilder.control<FacetValueForm | null>(null),
    stockLocations: this.formBuilder.array<FormGroup<StockLocationForm>>([]),
    selectedStockLocation: this.formBuilder.control<StockLocationForm | null>(
      null,
    ),
    variantModal: this.formBuilder.group({
      stockLocation: this.formBuilder.group<StockLocationForm>({
        id: this.formBuilder.control(''),
        description: this.formBuilder.control(''),
        name: this.formBuilder.control(''),
        variants: this.formBuilder.array<FormGroup<VariantForm>>([]),
      }),
      variant: this.formBuilder.group<VariantForm>({
        stockLocationId: this.formBuilder.control(''),
        name: this.formBuilder.control(''),
        price: this.formBuilder.control(0),
        sku: this.formBuilder.control(''),
        enabled: this.formBuilder.control(true),
        stock: this.formBuilder.control(0),
        id: this.formBuilder.control(''),
        options: this.formBuilder.array<FormGroup<VariantOptionForm>>([]),
        translations: this.formBuilder.array<FormGroup<TranslationForm>>([]),
      }),
    }),
  });

  isCreation = true;
  id: string | null = null;
  hasVariants = true;
  showVariantModal = false;
  variantModalEditing = false;
  isDropZoneDragging = false;

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private productService: ProductService,
    public facetService: FacetService,
    private router: Router,
    private messageService: NzMessageService,
    private stockService: StockService,
    private assetService: AssetService,
  ) {}

  ngOnInit(): void {
    this.setupListeners();
  }

  ngOnDestroy() {
    this.productService.clearProduct();
    this.stockService.clearStockLocations();
  }

  setupListeners() {
    // Setup StockLocation listener
    this.stockService.stockLocations$.subscribe((response) => {
      if (!response.items.length) {
        return;
      }

      if (this.isCreation) {
        this.validateForm.controls.stockLocations.clear();
        const [first, ...remaining] = response.items as StockLocation[];
        remaining.forEach((stockLocation: StockLocation) => {
          this.validateForm.controls.stockLocations.push(
            this.formBuilder.group({
              name: this.formBuilder.control(stockLocation.name),
              description: this.formBuilder.control(stockLocation.description),
              id: this.formBuilder.control(stockLocation.id),
              variants: this.formBuilder.array<FormGroup<VariantForm>>(
                [],
                [Validators.minLength(1)],
              ),
            }),
          );
        });

        const stockLocation = first;
        const stockLocations =
          this.validateForm.controls.product.controls.stockLocations;
        stockLocations.clear();
        stockLocations.push(
          this.formBuilder.group({
            name: this.formBuilder.control(stockLocation.name),
            description: this.formBuilder.control(stockLocation.description),
            id: this.formBuilder.control(stockLocation.id),
            variants: this.formBuilder.array<FormGroup<VariantForm>>(
              [],
              [Validators.minLength(1)],
            ),
          }),
        );
      } else {
        const existStockLocations =
          this.validateForm.controls.product.controls.stockLocations.controls;
        console.log({ existStockLocations });

        response.items.forEach((stockLocation: StockLocation) => {
          if (
            existStockLocations.find(
              (sl) => sl.getRawValue().id === stockLocation.id,
            )
          ) {
            return;
          }

          this.validateForm.controls.stockLocations.push(
            this.formBuilder.group({
              name: this.formBuilder.control(stockLocation.name, [
                Validators.required,
              ]),
              description: this.formBuilder.control(stockLocation.description),
              id: this.formBuilder.control(stockLocation.id, [
                Validators.required,
              ]),
              variants: this.formBuilder.array<FormGroup<VariantForm>>(
                [],
                [Validators.minLength(1)],
              ),
            }),
          );
        });
      }
    });

    // Setup Parameter Listener
    combineLatest([this.route.paramMap, this.route.data]).subscribe(
      ([params, data]) => {
        this.id = params.get('id');
        this.isCreation = data['isCreation'];

        if (!this.isCreation && this.id) {
          this.productService.fetchProduct(this.id).subscribe();
        } else {
          this.stockService
            .fetchStockLocations({
              take: 20,
            })
            .subscribe();
        }
      },
    );

    // Setup Product Listener on Store
    this.productService.product$
      .pipe(
        tap((product) => {
          if (!product) {
            return;
          }

          const uniqueStockLocations: string[] = [];
          const stockLocations =
            this.validateForm.controls.product.controls.stockLocations;

          const assets = this.validateForm.controls.product.controls.assets;
          assets.clear();
          product.assets.forEach(async (asset: Asset) => {
            const file = await this.assetService.fetchFile(
              asset.source,
              asset.name,
            );
            const binary = await this.assetService.fileToBase64(file);

            assets.push(
              this.formBuilder.group<AssetForm>({
                id: this.formBuilder.control(asset.id),
                file: this.formBuilder.control(file),
                binary: this.formBuilder.control(binary),
                name: this.formBuilder.control(asset.name),
                type: this.formBuilder.control(asset.mimeType),
              }),
            );
          });

          product.variants.forEach((productVariant: ProductVariant) => {
            return productVariant.stockLevels.forEach(
              (stockLevel: StockLevel) => {
                // https://docs.vendure.io/guides/core-concepts/stock-control/#stock-control-concepts
                const saleable =
                  stockLevel.stockOnHand -
                  stockLevel.stockAllocated -
                  productVariant.outOfStockThreshold;

                const hasStockLocationFound = uniqueStockLocations.find(
                  (s) => s === stockLevel.stockLocation.id,
                );

                if (!hasStockLocationFound) {
                  stockLocations.push(
                    this.formBuilder.group({
                      name: this.formBuilder.control(
                        stockLevel.stockLocation.name,
                        [Validators.required],
                      ),
                      description: this.formBuilder.control(
                        stockLevel.stockLocation.description,
                      ),
                      id: this.formBuilder.control(
                        stockLevel.stockLocation.id,
                        [Validators.required],
                      ),
                      variants: this.formBuilder.array<FormGroup<VariantForm>>(
                        [],
                        [Validators.minLength(1)],
                      ),
                    }),
                  );
                  uniqueStockLocations.push(stockLevel.stockLocation.id);
                }

                const stockLocation = stockLocations.controls.find(
                  (stockLocation) => {
                    return (
                      stockLocation.controls.id.getRawValue() ===
                      stockLevel.stockLocation.id
                    );
                  },
                );

                stockLocation?.controls.variants.push(
                  this.formBuilder.group<VariantForm>({
                    stockLocationId: this.formBuilder.control(
                      stockLevel.stockLocationId,
                      [Validators.required],
                    ),
                    name: this.formBuilder.control(productVariant.name, [
                      Validators.required,
                    ]),
                    price: this.formBuilder.control(productVariant.price / 10, [
                      Validators.required,
                    ]),
                    sku: this.formBuilder.control(productVariant.sku, [
                      Validators.required,
                    ]),
                    enabled: this.formBuilder.control(productVariant.enabled, [
                      Validators.required,
                    ]),
                    stock: this.formBuilder.control(saleable, [
                      Validators.required,
                    ]),
                    id: this.formBuilder.control(productVariant.id, [
                      Validators.required,
                    ]),
                    options: this.formBuilder.array(
                      productVariant.options.map((option) => {
                        return this.formBuilder.group({
                          name: this.formBuilder.control(option.name, [
                            Validators.required,
                          ]),
                          id: this.formBuilder.control(option.id, [
                            Validators.required,
                          ]),
                          code: this.formBuilder.control(option.code, [
                            Validators.required,
                          ]),
                          translations: this.formBuilder.array<
                            FormGroup<TranslationForm>
                          >(
                            option.translations.map((t) => {
                              return this.formBuilder.group({
                                id: this.formBuilder.control(t.id),
                                name: this.formBuilder.control(t.name),
                                languageCode: this.formBuilder.control(
                                  t.languageCode,
                                ),
                              } as TranslationForm);
                            }),
                          ),
                        });
                      }),
                    ),
                    translations: this.formBuilder.array(
                      productVariant.translations.map((t) => {
                        return this.formBuilder.group({
                          id: this.formBuilder.control(t.id),
                          name: this.formBuilder.control(t.name),
                          languageCode: this.formBuilder.control(
                            t.languageCode,
                          ),
                        } as TranslationForm);
                      }),
                    ),
                  }),
                );
              },
            );
          });

          const optionGroups =
            this.validateForm.controls.product.controls.optionGroups;
          optionGroups.clear();

          product.optionGroups.forEach((optionGroup) => {
            const newGroup = this.formBuilder.group<VariantOptionGroupForm>({
              id: this.formBuilder.control(optionGroup.id, [
                Validators.required,
              ]),
              name: this.formBuilder.control(optionGroup.name, [
                Validators.required,
              ]),
              code: this.formBuilder.control(optionGroup.code, [
                Validators.required,
              ]),
              options: this.formBuilder.array(
                optionGroup.options.map((option) => {
                  return this.formBuilder.group({
                    id: this.formBuilder.control(option.id, [
                      Validators.required,
                    ]),
                    name: this.formBuilder.control(option.name, [
                      Validators.required,
                    ]),
                    code: this.formBuilder.control(option.code, [
                      Validators.required,
                    ]),
                    translations: this.formBuilder.array<
                      FormGroup<TranslationForm>
                    >(
                      option.translations.map((t) => {
                        return this.formBuilder.group({
                          id: this.formBuilder.control(t.id),
                          name: this.formBuilder.control(t.name),
                          languageCode: this.formBuilder.control(
                            t.languageCode,
                          ),
                        } as TranslationForm);
                      }),
                    ),
                  });
                }),
              ),
              newItem: this.formBuilder.control(''),
              selectedItem: this.formBuilder.control(null),
              translations: this.formBuilder.array<FormGroup<TranslationForm>>(
                optionGroup.translations.map((t) => {
                  return this.formBuilder.group({
                    id: this.formBuilder.control(t.id),
                    name: this.formBuilder.control(t.name),
                    languageCode: this.formBuilder.control(t.languageCode),
                  } as TranslationForm);
                }),
              ),
            });
            optionGroups.push(newGroup);
          });

          const facetValues =
            this.validateForm.controls.product.controls.facetValues;
          facetValues.clear();
          product.facetValues.forEach((item: FacetValue) => {
            const value = {
              name: this.formBuilder.control(item.name, [Validators.required]),
              code: this.formBuilder.control(item.code, [Validators.required]),
              id: this.formBuilder.control(item.id),
            };
            const newGroup = this.formBuilder.group<FacetValueForm>(value);
            facetValues.push(newGroup);
          });

          this.validateForm.patchValue({
            product: this.formBuilder
              .group({
                id: this.formBuilder.control(product.id),
                name: this.formBuilder.control(product.name),
                slug: this.formBuilder.control(product.slug),
                description: this.formBuilder.control(product.description),
                enabled: this.formBuilder.control(product.enabled),
                facetValues,
                optionGroups,
                stockLocations,
                assets,
              })
              .getRawValue(),
          });

          this.stockService
            .fetchStockLocations({
              take: 20,
            })
            .subscribe();
        }),
      )
      .subscribe();

    // Setup SelectedFacetValueControl Listener
    this.validateForm.controls.selectedFacetValue.valueChanges.subscribe(
      (item) => {
        if (item) {
          console.log(`New FacetValue selected.`);
          const value = {
            name: this.formBuilder.control(item.name, [Validators.required]),
            code: this.formBuilder.control(item.code, [Validators.required]),
            id: this.formBuilder.control(item.id, [Validators.required]),
          };
          const newGroup = this.formBuilder.group<FacetValueForm>(value);
          this.validateForm.controls.product.controls.facetValues.push(
            newGroup,
          );
        }
      },
    );
  }

  submitForm() {
    const product = this.validateForm.controls.product.getRawValue();

    // Ensure conversion to number for price and stock
    product.stockLocations.forEach((stockLocation) => {
      stockLocation.variants.forEach((variant) => {
        variant.price = +variant.price; // Convert string to number
        variant.stock = +variant.stock; // Convert string to number
      });
    });

    this.validateForm.markAllAsTouched();
    this.validateForm.updateValueAndValidity();
    this.validateForm.validate();

    if (this.validateForm.valid) {
      if (this.isCreation) {
        this.assetService
          .createAssets(product.assets.map((asset) => ({ file: asset.file })))
          .pipe(
            map(
              (assetsResponse) =>
                assetsResponse.data?.createAssets
                  .filter((asset) => asset.__typename === 'Asset')
                  .map((asset) => (asset as Asset).id) || [],
            ),
            mergeMap((assetIds) =>
              this.productService.createProduct({
                assetIds,
                translations: [
                  {
                    languageCode: LanguageCode.en,
                    name: product.name,
                    description: product.description,
                    slug: product.slug,
                  },
                ],
                facetValueIds: product.facetValues.map(
                  (facetValue) => facetValue.id,
                ),
              }),
            ),
            mergeMap((productResponse) => {
              const productOptionGroups$ = product.optionGroups.map(
                (optionGroup) =>
                  this.productService
                    .createProductOptionGroup({
                      code: optionGroup.code,
                      translations: [
                        {
                          languageCode: LanguageCode.en,
                          name: optionGroup.name,
                        },
                      ],
                      options: [],
                    })
                    .pipe(
                      tap((response) => {
                        const createdGroup = response.data
                          ?.createProductOptionGroup as ProductOptionGroup;
                        optionGroup.id = createdGroup.id;
                      }),
                    ),
              );
              return forkJoin(productOptionGroups$).pipe(
                map(() => productResponse),
              );
            }),
            mergeMap((productResponse) => {
              const productOptions$ = flatMap(
                product.optionGroups.map((optionGroup) =>
                  optionGroup.options.map((option) =>
                    this.productService
                      .createProductOption({
                        productOptionGroupId: optionGroup.id,
                        code: option.code,
                        translations: [
                          {
                            languageCode: LanguageCode.en,
                            name: option.name,
                          },
                        ],
                      })
                      .pipe(
                        tap((optionResponse) => {
                          const productOption = optionResponse.data
                            ?.createProductOption as ProductOption;
                          const oldOptionId = option.id;
                          option.id = productOption.id;

                          product.stockLocations.forEach((stockLocation) => {
                            stockLocation.variants.forEach((variant) => {
                              variant.options.forEach((variantOption) => {
                                // Compare with the old ID before updating
                                if (variantOption.id === oldOptionId) {
                                  variantOption.id = productOption.id;
                                }
                              });
                            });
                          });
                        }),
                      ),
                  ),
                ),
              );

              return forkJoin(productOptions$).pipe(map(() => productResponse));
            }),
            mergeMap((productResponse) => {
              const _product = productResponse.data?.createProduct as Product;
              const optionGroupAssociations$ = product.optionGroups.map(
                (optionGroup) =>
                  this.productService.addOptionGroupToProduct(
                    optionGroup.id,
                    _product.id,
                  ),
              );

              // Ensure all option groups are associated with the product before creating variants
              return forkJoin(optionGroupAssociations$).pipe(
                map(() => _product),
              );
            }),
            mergeMap((_product) => {
              const allVariants = flatMap(
                product.stockLocations.map((stockLocation) =>
                  stockLocation.variants.map(
                    (variant) =>
                      ({
                        productId: _product.id,
                        sku: variant.sku,
                        price: variant.price,
                        stockOnHand: variant.stock,
                        optionIds: variant.options.map((option) => option.id),
                        translations: [
                          {
                            languageCode: LanguageCode.en,
                            name: variant.name,
                          },
                        ],
                      }) as CreateProductVariantInput,
                  ),
                ),
              );

              // Create all variants in one request
              return this.productService.createProductVariants(allVariants);
            }),
          )
          .subscribe({
            next: () => {
              console.log(
                'Product, its option groups, options, and variants created successfully',
              );
            },
            error: (err) => {
              console.log(err);

              console.error('Error during product creation:', err);
            },
          });
      }

      if (this.id && !this.isCreation) {
        const product = this.validateForm.controls.product.getRawValue();

        const newAssetsFile = product.assets
          .filter((asset) => asset.id.indexOf('local') > -1)
          .map((asset) => ({ file: asset.file }));
        const oldAssets = product.assets.filter(
          (asset) => asset.id.indexOf('local') === -1,
        );

        this.assetService
          .createAssets(newAssetsFile)
          .pipe(
            map(
              (assetsResponse) =>
                assetsResponse.data?.createAssets
                  .filter((asset) => asset.__typename === 'Asset')
                  .map((asset) => (asset as Asset).id) || [],
            ),
            mergeMap((newAssetIds: string[]) => {
              const oldAssetsIds = oldAssets.map((oldAsset) => oldAsset.id);
              return this.productService.updateProduct({
                id: this.id as string,
                translations: [
                  {
                    languageCode: LanguageCode.en,
                    description: product.description,
                    name: product.name,
                    slug: product.slug,
                  },
                ],
                enabled: product.enabled,
                facetValueIds: product.facetValues.map((f) => f.id),
                assetIds: newAssetIds.concat(oldAssetsIds),
              });
            }),
            mergeMap((productResponse) => {
              const _product = productResponse.data?.updateProduct as Product;

              const createGroups$ = from(product.optionGroups).pipe(
                filter((optionGroup) => optionGroup.id.indexOf('local') > -1),
                mergeMap((optionGroup) => {
                  return this.productService
                    .createProductOptionGroup({
                      code: optionGroup.code,
                      translations: [
                        {
                          languageCode: LanguageCode.en,
                          name: optionGroup.name,
                        },
                      ],
                      options: [],
                    })
                    .pipe(
                      tap((response) => {
                        const createdGroup = response.data
                          ?.createProductOptionGroup as ProductOptionGroup;
                        optionGroup.id = createdGroup.id;
                        this.productService
                          .addOptionGroupToProduct(createdGroup.id, _product.id)
                          .subscribe();
                      }),
                    );
                }),
                defaultIfEmpty([]),
              );

              const updateGroups$ = from(product.optionGroups).pipe(
                filter((optionGroup) => optionGroup.id.indexOf('local') === -1),
                mergeMap((optionGroup) => {
                  return this.productService.updateProductOptionGroup({
                    id: optionGroup.id,
                    code: optionGroup.code,
                    translations: optionGroup.translations.map((t) => {
                      return {
                        id: t.id,
                        languageCode: t.languageCode as LanguageCode,
                        name: t.name,
                      };
                    }),
                  });
                }),
                defaultIfEmpty([]),
              );

              return forkJoin([createGroups$, updateGroups$]).pipe(
                map((response) => {
                  return productResponse;
                }),
              );
            }),
            mergeMap((productResponse) => {
              // Process each option group
              const productOptions$ = flatMap(
                product.optionGroups.map((optionGroup) => {
                  // Create observable stream for createOptions
                  const createOptions$ = from(optionGroup.options).pipe(
                    filter((option) => option.id.indexOf('local') > -1),
                    mergeMap((option) => {
                      return this.productService
                        .createProductOption({
                          productOptionGroupId: optionGroup.id,
                          code: option.code,
                          translations: [
                            {
                              languageCode: LanguageCode.en,
                              name: option.name,
                            },
                          ],
                        })
                        .pipe(
                          tap((optionResponse) => {
                            const productOption = optionResponse.data
                              ?.createProductOption as ProductOption;
                            const oldOptionId = option.id;
                            option.id = productOption.id;

                            // Update stock locations with the new option ID
                            product.stockLocations.forEach((stockLocation) => {
                              stockLocation.variants.forEach((variant) => {
                                variant.options.forEach((variantOption) => {
                                  if (variantOption.id === oldOptionId) {
                                    variantOption.id = productOption.id;
                                  }
                                });
                              });
                            });
                          }),
                        );
                    }),
                    defaultIfEmpty([]),
                  );

                  // Create observable stream for updateOptions
                  const updateOptions$ = from(optionGroup.options).pipe(
                    filter((option) => option.id.indexOf('local') === -1),
                    mergeMap((option) =>
                      this.productService.updateProductOption({
                        id: option.id,
                        code: option.code,
                        translations: [
                          {
                            languageCode: LanguageCode.en,
                            name: option.name,
                          },
                        ],
                      }),
                    ),
                    defaultIfEmpty([]),
                  );

                  // Combine the results for this option group
                  return forkJoin([createOptions$, updateOptions$]);
                }),
              );

              // Combine all option groups into a single observable
              return forkJoin(productOptions$).pipe(map(() => productResponse));
            }),
            mergeMap((productResponse) => {
              const _product = productResponse.data?.updateProduct as Product;
              // Flatten and map all variants from stock locations
              const allVariants = flatMap(
                product.stockLocations.map((stockLocation) =>
                  stockLocation.variants.map((variant) => {
                    variant.price = Math.floor(variant.price * 10);
                    variant.stock = +variant.stock;

                    return {
                      id: variant.id,
                      productId: _product.id,
                      sku: variant.sku,
                      price: variant.price,
                      stockOnHand: variant.stock,
                      optionIds: variant.options.map((option) => option.id),
                      translations: variant.translations.map((t) => {
                        return {
                          ...t,
                          name: variant.name,
                        };
                      }),
                    };
                  }),
                ),
              );

              // Filter new and existing variants
              const newVariantsArray = allVariants.filter(
                (variant) => variant.id.indexOf('local') > -1,
              );
              const existingVariantsArray = allVariants.filter(
                (variant) => variant.id.indexOf('local') === -1,
              );

              // Create observable stream for newVariants
              const createNewVariants$ = from(newVariantsArray).pipe(
                mergeMap(({ id, ...variant }) => {
                  return this.productService.createProductVariants(
                    variant as CreateProductVariantInput,
                  );
                }),
                defaultIfEmpty([]), // Skip if there are no new variants
              );

              // Create observable stream for existingVariants
              const updateExistingVariants$ = from(existingVariantsArray).pipe(
                mergeMap(({ productId, optionIds, ...variant }) => {
                  // Eleminate the properties
                  return this.productService.updateProductVariants(
                    variant as UpdateProductVariantInput,
                  );
                }),
                defaultIfEmpty([]), // Skip if there are no existing variants to update
              );

              // Combine all variant creation and update operations
              return forkJoin([
                createNewVariants$,
                updateExistingVariants$,
              ]).pipe(map(() => _product));
            }),
          )
          .subscribe({
            next: () => {
              console.log(
                'Product, its option groups, options, and variants created successfully',
              );
            },
            error: (err) => {
              console.log(err);

              console.error('Error during product creation:', err);
            },
          });
      }
    }
  }

  addNewFacetValue() {
    const facetValues = this.validateForm.controls.product.controls.facetValues;
    const newGroup = this.formBuilder.group({
      name: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      code: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      id: this.formBuilder.control(''),
    });

    facetValues.push(newGroup);
  }

  searchFacet(term: string) {
    this.facetService
      .fetchFacetValues({
        filter: {
          name: {
            contains: term,
          },
        },
      })
      .subscribe();
  }

  // getProductVariantsByStockLocationId(id: string) {
  //   const variants = this.validateForm.controls.product.controls.variants;

  //   const filteredProductvariants = variants.controls.filter(variantControl => {
  //     return variantControl.getRawValue().stockLocationId === id;
  //   });

  //   return filteredProductvariants;
  // }

  currencyFormat = (value: number) => {
    return `$ ${value}`;
  };

  addVariantOptionGroup() {
    const newGroup = this.formBuilder.group<VariantOptionGroupForm>({
      name: this.formBuilder.control('', [Validators.required]),
      code: this.formBuilder.control(''),
      id: this.formBuilder.control(`local-${v4()}`),
      options: this.formBuilder.array<FormGroup<VariantOptionForm>>([]),
      newItem: this.formBuilder.control(''),
      selectedItem: this.formBuilder.control<VariantOptionForm>({
        name: this.formBuilder.control('', [
          Validators.required,
          Validators.minLength(3),
        ]),
        code: this.formBuilder.control('', [
          Validators.required,
          Validators.minLength(3),
        ]),
        id: this.formBuilder.control(''),
        translations: this.formBuilder.array<FormGroup<TranslationForm>>([]),
      }),
      translations: this.formBuilder.array<FormGroup<TranslationForm>>([
        this.formBuilder.group({
          id: this.formBuilder.control(`local-${v4()}`),
          name: this.formBuilder.control(''),
          languageCode: this.formBuilder.control(LanguageCode.en),
        } as TranslationForm),
      ]),
    });
    this.validateForm.controls.product.controls.optionGroups.push(newGroup);
  }

  addVariantOptionGroupValue(
    newItem: FormControl<string>,
    options: FormArray<FormGroup<VariantOptionForm>>,
    $event?: Event,
  ) {
    if ($event) {
      $event?.preventDefault();
    }
    const value = newItem.getRawValue();
    newItem.patchValue('');

    options.push(
      this.formBuilder.group({
        name: this.formBuilder.control(value),
        id: this.formBuilder.control(`local-${v4()}`),
        code: this.formBuilder.control(''),
        translations: this.formBuilder.array<FormGroup<TranslationForm>>([
          this.formBuilder.group({
            id: this.formBuilder.control(`local-${v4()}`),
            name: this.formBuilder.control(value),
            languageCode: this.formBuilder.control(LanguageCode.en),
          } as TranslationForm),
        ]),
      }),
    );
  }

  showVariantCreation(stockLocation: FormGroup<StockLocationForm>) {
    if (this.validateForm.controls.product.controls.optionGroups.length === 0) {
      return this.messageService.create(
        'error',
        `You need to create an Variant Option Group and Variant Options in it before creating variants.`,
      );
    }

    // Assign selected stock location to variantModal stock location
    this.validateForm.controls.variantModal.controls.stockLocation =
      cloneDeep(stockLocation);
    this.validateForm.controls.variantModal.controls.variant =
      this.formBuilder.group<VariantForm>({
        stockLocationId: this.formBuilder.control(''),
        name: this.formBuilder.control(''),
        price: this.formBuilder.control(0),
        sku: this.formBuilder.control(''),
        enabled: this.formBuilder.control(true),
        stock: this.formBuilder.control(0),
        id: this.formBuilder.control(''),
        options: this.formBuilder.array<FormGroup<VariantOptionForm>>([]),
        translations: this.formBuilder.array<FormGroup<TranslationForm>>([]),
      });

    return (this.showVariantModal = true);
  }

  cancelVariantCreation() {
    this.showVariantModal = false;
    this.resetModal();
  }

  resetModal() {
    this.validateForm.controls.variantModal.controls.variant =
      this.formBuilder.group<VariantForm>({
        stockLocationId: this.formBuilder.control(''),
        name: this.formBuilder.control(''),
        price: this.formBuilder.control(0),
        sku: this.formBuilder.control(''),
        enabled: this.formBuilder.control(true),
        stock: this.formBuilder.control(0),
        id: this.formBuilder.control(''),
        options: this.formBuilder.array<FormGroup<VariantOptionForm>>([]),
        translations: this.formBuilder.array<FormGroup<TranslationForm>>([]),
      });
    this.validateForm.controls.variantModal.controls.stockLocation =
      this.formBuilder.group<StockLocationForm>({
        id: this.formBuilder.control(''),
        description: this.formBuilder.control(''),
        name: this.formBuilder.control(''),
        variants: this.formBuilder.array<FormGroup<VariantForm>>([]),
      });
    this.validateForm.controls.product.controls.optionGroups.controls.forEach(
      (optionGroup) => {
        optionGroup.controls.selectedItem.setValue(null);
      },
    );
  }

  completeVariantCreation() {
    console.log(`completeVariantCreation triggered`);

    const variantModal = this.validateForm.controls.variantModal;
    const optionGroups =
      this.validateForm.controls.product.controls.optionGroups;
    const stockLocations =
      this.validateForm.controls.product.controls.stockLocations;

    if (!this.variantModalEditing) {
      // Create a new variant
      const variant = this.formBuilder.group<VariantForm>({
        ...cloneDeep(variantModal.controls.variant.controls),
        id: this.formBuilder.control(`local-${v4()}`, [Validators.required]),
        stockLocationId: cloneDeep(
          variantModal.controls.stockLocation.controls.id,
        ),
      });

      // Clear variant options

      // Assign each selected variant options
      optionGroups.controls.forEach((optionGroup) => {
        const selectedItem = optionGroup.controls.selectedItem.getRawValue();
        if (selectedItem) {
          console.log({ selectedItem });

          variant.controls.options.push(this.formBuilder.group(selectedItem));
        }
      });

      // Add new variant into modal's stockLocation
      variantModal.controls.stockLocation.controls.variants.push(variant);

      stockLocations.controls.forEach((_stockLocation) => {
        if (
          _stockLocation.getRawValue().id ===
          variantModal.controls.stockLocation.getRawValue().id
        ) {
          // Add new variant to the product state's stockLocation
          _stockLocation.controls.variants.push(variant);
          _stockLocation.setValue(
            variantModal.controls.stockLocation.getRawValue(),
          );
        }
      });
    } else {
      const variant = variantModal.controls.variant;
      const variants = variantModal.controls.stockLocation.controls.variants;
      // Clear options
      variant.controls.options.clear();

      // Assign each selected variant options
      optionGroups.controls.forEach((optionGroup) => {
        const selectedItem = optionGroup.controls.selectedItem.getRawValue();
        if (selectedItem) {
          variant.controls.options.push(this.formBuilder.group(selectedItem));
        }
      });

      // Assign variantModal state to product state
      variants.controls.forEach((_variant) => {
        if (_variant.getRawValue().id === variant.getRawValue().id) {
          _variant.controls.options = cloneDeep(variant.controls.options);
          _variant.patchValue(variant.getRawValue());
        }
      });

      stockLocations.controls.forEach((_stockLocation, index) => {
        if (
          _stockLocation.getRawValue().id ===
          variantModal.controls.stockLocation.getRawValue().id
        ) {
          stockLocations.controls[index] = cloneDeep(
            variantModal.controls.stockLocation,
          );
          _stockLocation.patchValue(
            variantModal.controls.stockLocation.getRawValue(),
          );
        }
      });
    }

    this.showVariantModal = false;
    this.resetModal();
    if (this.variantModalEditing) {
      this.variantModalEditing = false;
    }
  }

  removeVariant(
    variant: FormGroup<VariantForm>,
    stockLocation: FormGroup<StockLocationForm>,
  ) {
    stockLocation.controls.variants.controls.forEach((_variant, index) => {
      if (_variant.getRawValue().id === variant.getRawValue().id) {
        stockLocation.controls.variants.removeAt(index);
      }
    });
  }

  editVariantCreation(
    variant: FormGroup<VariantForm>,
    stockLocation: FormGroup<StockLocationForm>,
  ) {
    this.validateForm.controls.variantModal.controls.variant =
      cloneDeep(variant);
    this.validateForm.controls.variantModal.controls.stockLocation =
      cloneDeep(stockLocation);
    variant.controls.options.controls.forEach((option) => {
      const optionGroup =
        this.validateForm.controls.product.controls.optionGroups.controls.find(
          (optionGroup) => {
            return optionGroup.getRawValue().options.some((_option) => {
              return _option.id === option.getRawValue().id;
            });
          },
        );
      if (optionGroup) {
        optionGroup.controls.selectedItem = this.formBuilder.control({
          id: option.controls.id,
          code: option.controls.code,
          name: option.controls.name,
          translations: option.controls.translations,
        });
      }
    });
    this.showVariantModal = true;
    this.variantModalEditing = true;
  }

  createStockLocation() {
    const selectedStockLocation =
      this.validateForm.controls.selectedStockLocation;
    const stockLocation = selectedStockLocation.getRawValue();

    if (!stockLocation) {
      return this.messageService.error(`You did not choose any stock location`);
    }

    this.validateForm.controls.stockLocations.controls.forEach(
      (_stockLocation, index) => {
        const identical =
          _stockLocation.controls.id.getRawValue() ===
          stockLocation.id.getRawValue();

        if (identical) {
          this.validateForm.controls.stockLocations.removeAt(index);
        }
      },
    );

    selectedStockLocation.setValue(null);
    return this.validateForm.controls.product.controls.stockLocations.push(
      this.formBuilder.group(stockLocation),
    );
  }

  removeStockLocation(stockLocation: FormGroup<StockLocationForm>) {
    const stockLocations =
      this.validateForm.controls.product.controls.stockLocations;
    stockLocations.controls.forEach((stockLocationForm, index) => {
      if (
        stockLocationForm.controls.id.getRawValue() ===
        stockLocation.controls.id.getRawValue()
      ) {
        stockLocations.removeAt(index);
        this.validateForm.controls.stockLocations.push(stockLocationForm);
      }
    });
  }

  compareId = (o1: any, o2: any) => {
    return o1 && o2 ? o1.id.getRawValue() === o2.id.getRawValue() : o1 === o2;
  };

  onDropZoneDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer?.files) {
      const files = Array.from(event.dataTransfer.files);
      this.handleFiles(files);
    }
    this.isDropZoneDragging = false;
  }

  onDropZoneDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDropZoneDragging = true;
  }

  handleFiles(files: File[]) {
    const id = `local-${v4()}`;
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.validateForm.controls.product.controls.assets.push(
          this.formBuilder.group({
            id,
            name: file.name,
            type: file.type,
            file,
            binary: e.target.result as string,
          }),
        );
      };
      reader.readAsDataURL(file);
    });
  }

  onDropZoneDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDropZoneDragging = false;
  }

  removeAsset(index: number) {}
}
