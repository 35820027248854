<div class="page-container">
  <div nz-row class="page-container-head">
    <div nz-col nzSpan="12">
      <h2>Empty Page</h2>
    </div>
    <div nz-col nzSpan="12" class="page-container-toolbar">
      <button [routerLink]="['/admin/empty']" nz-button nzType="primary">
        <span nz-icon nzType="ng-zorro:add"></span>
        Empty Button
      </button>
    </div>
  </div>
  <div class="page-container-inner tab-container">
    <p>Use this page as a template for new pages.</p>
  </div>
</div>
