<div class="page-container">
  <div nz-row class="page-container-head">
    <div nz-col nzSpan="12">
      <h2>Products</h2>
    </div>
    <div nz-col nzSpan="12" class="page-container-toolbar">
      <button [routerLink]="['/admin/products/new']" nz-button nzType="primary">
        <span nz-icon nzType="ng-zorro:add"></span>
        Create Product
      </button>
    </div>
  </div>
  <div class="page-container-inner tab-container">
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-tabset [nzTabBarExtraContent]="extraTemplate">
          <nz-tab nzTitle="All">
            <nz-table
              #rowSelectionTable
              #sortTable
              nzShowSizeChanger
              [nzData]="products.items"
              [nzFrontPagination]="false"
              [nzLoading]="appService.isLoading$ | async"
              [nzTotal]="products.totalItems"
              (nzQueryParams)="onQueryParamsChange($event)"
              class="product-table"
            >
              <thead>
                <tr>
                  <th
                    [(nzChecked)]="checked"
                    [nzIndeterminate]="indeterminate"
                    (nzCheckedChange)="onAllChecked($event)"
                  ></th>
                  <th>PRODUCT</th>
                  <th>ENABLED</th>
                  <th>SLUG</th>
                  <th>VARIENTS</th>
                  <th>TOTAL STOCK</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="product-table-row"
                  *ngFor="let data of products.items || []"
                  (click)="goToProduct(data.id)"
                >
                  <td
                    [nzChecked]="setOfCheckedId.has(data.id)"
                    (nzCheckedChange)="onItemChecked(data.id, $event)"
                  ></td>
                  <td>
                    {{ data.name }}
                  </td>
                  <td>{{ data.enabled }}</td>
                  <td>{{ data.slug }}</td>
                  <td>{{ getVariantSize(data.variants) }}</td>
                  <td>{{ getTotalStock(data.variants) }}</td>
                </tr>
              </tbody>
            </nz-table>
            <div class="pagination-info">
              <p>
                Showing {{ pageSize * (pageIndex - 1) }} -
                {{ pageSize * pageIndex }} of {{ products.totalItems }}
              </p>
            </div>
          </nz-tab>
          <nz-tab nzTitle="Active"> </nz-tab>
          <nz-tab nzTitle="Draft"> </nz-tab>
          <nz-tab nzTitle="Archived"> </nz-tab>
        </nz-tabset>
      </div>
      <ng-template #extraTemplate>
        <div class="tab-tools">
          <button nz-button nzType="primary" ngClass="gamma">
            <span nz-icon nzType="search"></span>
            Search & Filter
          </button>

          <button nz-button nzType="primary" ngClass="gamma">
            <span nz-icon nzType="ng-zorro:sort"></span>
            Sort
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
