<form
  nz-form
  [formGroup]="validateForm"
>

    <!-- Group definitions-->
    <div class="page-container" *ngFor="let group of groups">
        <div class="page-container-inner">
            <div nz-row>
                <div nz-col nzSpan="24">
                    <h2>{{ group.fieldName }}</h2>
                    <h4 *ngIf="group.description" class="description-text" style="margin-top: 10px;">{{ group.description }}</h4>
                </div>
                <div nz-col nzSpan="24">
                    <ng-container *ngTemplateOutlet="recursiveFields; context: { $implicit: group.fields }">
                    </ng-container>
                </div>
            </div>
            <div nz-row>
                <div nz-col nzSpan="24" class="add-group-button">
                    <button *ngIf="group.list" nzDanger nz-button nzType="primary" (click)="removeObjectFromListGroup(group)">
                        <span nz-icon nzType="ng-zorro:trash"></span>
                        Remove From Group: {{group.fieldName}}
                    </button>
                    
                    <button *ngIf="group.list" nz-button nzType="primary" (click)="addObjectToListGroup(group)">
                        <span nz-icon nzType="ng-zorro:add"></span>
                        Add To Group: {{group.fieldName}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Field definitions don't belong to any group-->
    <div class="page-container">
        <div class="page-container-inner">
            <div nz-row>
                <div nz-col nzSpan="24" *ngFor="let field of fieldDefinitions">
                    <ng-container *ngTemplateOutlet="fieldTemplate; context: { $implicit: field }">
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

</form>


<!-- Recursive template for fields -->
<ng-template #recursiveFields let-fields>
    <div nz-col nzSpan="24" *ngFor="let field of fields; let last = last; let i = index">
        <ng-container *ngIf="field.fields?.length">
            <!-- Recursive call for nested fields -->
            <ng-container *ngTemplateOutlet="recursiveFields; context: { $implicit: field.fields }">
            </ng-container>
        </ng-container>
        
        <ng-container *ngIf="!field.fields?.length">
            <!-- Regular field template -->
            <ng-container *ngTemplateOutlet="fieldTemplate; context: { $implicit: field }">
            </ng-container>
        </ng-container>

        <div nz-row *ngIf="field.fields?.length && field.list">
            <div nz-col nzSpan="24" class="add-group-button">
                <button nzDanger nz-button nzType="primary" (click)="removeObjectFromListGroup(field)">
                    <span nz-icon nzType="ng-zorro:trash"></span>
                    Remove From Group: {{field.fieldName.substring(0, field.fieldName.indexOf('_index') === -1 ? 
                    field.fieldName.length : 
                    field.fieldName.lastIndexOf('_index'))}}
                </button>
                
                <button nz-button nzType="primary" (click)="addObjectToListGroup(field)">
                    <span nz-icon nzType="ng-zorro:add"></span>
                    Add To Group: {{field.fieldName.substring(0, field.fieldName.indexOf('_index') === -1 ? 
                    field.fieldName.length : 
                    field.fieldName.lastIndexOf('_index'))}}
                </button>
            </div>
        </div>
        <!-- <nz-divider 
            *ngIf="group.list && !last && 
                (!field.fieldName.includes('_index') || 
                (field.fieldName.substring(field.fieldName.lastIndexOf('_index') + 6) !== 
                fields[i + 1]?.fieldName.substring(fields[i + 1]?.fieldName.lastIndexOf('_index') + 6)))"
            [nzText]="''"
            nzOrientation="left"
            style="border-width: 3px;">
        </nz-divider> -->
    </div>
</ng-template>

<!-- Define field template -->
 <ng-template #fieldTemplate let-field>
    <nz-form-item>
        <!-- {{field.fieldName}} -->
        <nz-form-label [nzSpan]="24">{{ field.title }}</nz-form-label>
        <nz-form-label [nzSpan]="24" *ngIf="field.description"><p class="description-text">{{ field.description }}</p></nz-form-label>
        <nz-form-label *ngIf="field.__typename === 'ListRelationFieldDefinition'" [nzSpan]="24">RelatedEntityName: <b>{{ field.relatedEntityName }}</b></nz-form-label>
        <!-- String-->
        <nz-form-control [nzHasFeedback]="true" [nzSpan]="24" *ngIf="field.__typename === 'StringFieldDefinition'">
            <input
                style="width: 50%;"
                *ngIf="validateForm.get(field.fieldName)"
                nz-input
                [formControl]="validateForm.controls[field.fieldName]"
                placeholder="Please enter {{ field.title }}"
            />
        </nz-form-control>
        <!-- Boolean-->
        <nz-form-control [nzHasFeedback]="true" [nzSpan]="24" *ngIf="field.__typename === 'BooleanFieldDefinition'">
            <nz-switch
            style="width: 50%;"
            *ngIf="validateForm.get(field.fieldName)"
            [formControl]="validateForm.controls[field.fieldName]" />
        </nz-form-control>
        <!-- Date-->
        <nz-form-control [nzHasFeedback]="true" [nzSpan]="24" *ngIf="field.__typename === 'DateFieldDefinition'">
            <nz-date-picker
            style="width: 50%;"
            *ngIf="validateForm.get(field.fieldName)"
            [formControl]="validateForm.controls[field.fieldName]" />
        </nz-form-control>
        <!-- List-->
        <nz-form-control [nzHasFeedback]="false" [nzSpan]="24" *ngIf="field.__typename === 'ListFieldDefinition'">
            <nz-select 
                *ngIf="validateForm.get(field.fieldName)"
                style="width: 50%;"
                [formControl]="validateForm.controls[field.fieldName]"
                [nzMode]="'multiple'" 
                [nzDropdownRender]="renderTemplate"
                [nzShowSearch]="true"
                [nzShowArrow]="true" 
                [nzDropdownMatchSelectWidth]="true" >
                    <nz-option *ngFor="let item of listValues[field.fieldName]; let index = index" [nzValue]="item" [nzLabel]="item"></nz-option>
                    <ng-template #renderTemplate>
                        <nz-divider class="add-item-divider"></nz-divider>
                        <div nz-row class="add-item-container">
                            <input nz-col nzSpan="18" type="text" nz-input #inputElement 
                            (keydown.enter)="addItem(inputElement, field.fieldName);$event.preventDefault(); $event.stopPropagation();"
                            (keydown.tab)="addItem(inputElement, field.fieldName);$event.preventDefault(); $event.stopPropagation();"/>
                            <a nz-col nzSpan="6" class="add-item-container-button" 
                                (click)="addItem(inputElement, field.fieldName)">
                                <span nz-icon nzType="plus"></span>
                                Add item
                            </a>
                        </div>
                    </ng-template>
            </nz-select>
        </nz-form-control>
        <!-- ListRelation-->
        <nz-form-control [nzHasFeedback]="true" [nzSpan]="24" *ngIf="field.__typename === 'ListRelationFieldDefinition'">
            <!-- if relatedEntity is `Asset`, render assets picker -->
            <div
                *ngIf="field.relatedEntityName === 'Asset' && validateForm.get(field.fieldName)"
            >
                <button nz-col nzSpan="8" (click)="openAssetModal(field.fieldName)">Choose Exsiting Assets</button>
                <nz-modal
                    [(nzVisible)]="showAssetModal"
                    [nzTitle]="modalTitle"
                    [nzWidth]="'80%'"
                    [nzClosable]="false"
                    [nzFooter]="selectAssetFooter"
                    (nzOnCancel)="cancelAssetSelection()"
                >
                    <input type="file" (change)="onFileSelected(currentFieldName, $event)"
                    accept=".svg,.png,.jpg,.jpeg,.gif" display="none" #fileInput/>
                    <ng-template #modalTitle>
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span>Select Assets</span>
                            <button nz-button nzType="primary" (click)="fileInput.click()">Choose Local Assets</button>
                        </div>
                    </ng-template>
                    <div *nzModalContent>
                        <div>
                            <nz-input-group [nzSuffix]="suffixTemplateSearch">
                                <input [formControl]="searchAssetControl"  nz-input placeholder="Search by asset name" class="search-select" (keydown.enter)="searchAssetsByName()" />
                                <ng-template #suffixTemplateSearch>
                                    <span nz-icon nzType="search"></span>
                                </ng-template>
                            </nz-input-group>
                        </div>
                        <div class="assets-grid">
                            <div *ngFor="let item of paginatedAssets" class="asset-item">
                                <div class="preview-image-container" 
                                     (click)="selectAsset(item)"
                                     [class.selected]="isAssetSelected(item)">
                                    <span *ngIf="isAssetSelected(item)" class="selected-icon">
                                        <span nz-icon nzType="check-circle" nzTheme="fill"></span>
                                    </span>
                                    <div>
                                        <img class="preview-image" [src]="item.source" [alt]="item.name" />
                                        <p class="asset-name">{{ item.name }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: flex-end;">
                            <nz-pagination 
                                [nzPageIndex]="pageNum" 
                                [nzTotal]="totalAssets" 
                                [nzPageSize]="pageSize" 
                                [nzShowTotal]="totalTemplate"
                                [nzShowSizeChanger]="true"
                                [nzPageSizeOptions]="[8, 10, 20, 25, 40]"
                                (nzPageIndexChange)="onPageIndexChange($event)"
                                (nzPageSizeChange)="onPageSizeChange($event)"
                                ></nz-pagination>
                            <ng-template #totalTemplate let-total>Total {{ total }} items</ng-template>
                        </div>
                    </div>
                    <ng-template #selectAssetFooter>
                        <button nz-button nzType="default" (click)="cancelAssetSelection()">Cancel</button>
                        <button nz-button nzType="primary" (click)="completeAssetSelection(currentFieldName)">Create</button>
                    </ng-template>
                </nz-modal>
            </div>
            <!-- if relatedEntity is not `Asset`, render multiple select with dropdown -->
            <nz-select 
                *ngIf="field.relatedEntityName !== 'Asset' && validateForm.get(field.fieldName)"
                style="width: 50%;"
                [formControl]="validateForm.controls[field.fieldName]"
                [nzMode]="'multiple'" 
                [nzShowSearch]="true"
                [nzShowArrow]="true" 
                [nzDropdownMatchSelectWidth]="true" 
                [nzDropdownStyle]="{ maxHeight: '200px', overflow: 'auto' }">
                <!-- display all entities in the same type related to this channel -->
                <nz-option *ngFor="let item of entities[field.fieldName]" [nzValue]="item.id" [nzLabel]="item.label"></nz-option>
            </nz-select>
        </nz-form-control>
        <!-- listRelation with assets -->
        <nz-form-control [nzSpan]="24" *ngIf="field.__typename === 'ListRelationFieldDefinition' && field.relatedEntityName === 'Asset' && validateForm.get(field.fieldName)">
            <!-- if relatedEntity is `Asset`, loop assets -->
            <div class="product-images">
                <div
                *ngFor="
                    let item of validateForm.controls[field.fieldName].controls;
                    let index = index
                "
                class="product-images-item"
                >
                <div
                    (click)="
                    this.validateForm.controls[field.fieldName].removeAt(
                        index
                    )
                    "
                    class="close"
                >
                    <span nz-icon nzType="close-circle" nzTheme="fill"></span>
                </div>
                <div class="image-info">
                    <img
                    nz-image
                    width="200px"
                    [nzSrc]="displayImage(item)"
                    [alt]="item.getRawValue().name"
                    />
                    <p>{{ item.getRawValue().name }}</p>
                </div>
                </div>
            </div>
        </nz-form-control>
    </nz-form-item>
 </ng-template>