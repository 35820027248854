import { Injectable } from '@angular/core'
import { createStore, select, withProps } from '@ngneat/elf'
import { tap } from 'rxjs/operators'
import {
  CreateDraftOrderGQL,
  EligibleShippingMethodsForDraftOrderGQL,
  LogicalOperator,
  Order,
  OrderGQL,
  OrderList,
  OrderListOptions,
  OrdersGQL,
  ProductSelectorSearchGQL,
  SortOrder,
  SetCustomerForDraftOrderGQL,
  CreateCustomerInput,
  CreateAddressInput,
  SetDraftOrderShippingAddressGQL,
  SetDraftOrderBillingAddressGQL,
  SetDraftOrderShippingMethodGQL,
  ApplyCouponCodeToDraftOrderGQL,
  TransitionOrderToStateGQL,
  CancelOrderInput,
  CancelOrderGQL,
  DeleteDraftOrderGQL,
  AddManualPaymentToOrderGQL,
  ManualPaymentInput,
  TransitionFulfillmentToStateGQL,
  RefundOrderGQL,
  GetOrderHistoryGQL,
  ModifyOrderInput,
  ModifyOrderGQL,
  FulfillOrderInput,
  AddFulfillmentToOrderGQL,
  RemoveCouponCodeFromDraftOrderGQL,
  AddItemToDraftOrderInput,
  AddItemToDraftOrderGQL,
  AdjustDraftOrderLineInput,
  AdjustDraftOrderLineGQL,
  RemoveDraftOrderLineGQL
} from '../gql/shop/generated'
import { AppService } from './app.service'
import { AuthService } from './auth.service'
  
interface OrdersState {
  orders: OrderList
  params: OrderListOptions
  currentPage: number
  pageSize: number
  order: Order | undefined
}

const initialState = withProps<OrdersState>({
  order: undefined,
  orders: {
    items: [],
    totalItems: 0,
  },
  params: {
    filter: undefined,
    filterOperator: LogicalOperator.AND,
    skip: 0,
    take: 10,
    sort: undefined,
  },
  currentPage: 1,
  pageSize: 10,
})

@Injectable({ providedIn: 'root' })
export class OrderService {
  private store = createStore({ name: 'order' }, initialState)

  orders$ = this.store.pipe(select((state) => state.orders))
  order$ = this.store.pipe(select((state) => state.order))
  currentPage$ = this.store.pipe(select((state) => state.currentPage))
  pageSize$ = this.store.pipe(select((state) => state.pageSize))

  constructor(
    private authService: AuthService,
    private appService: AppService,
    private ordersGQL: OrdersGQL,
    private orderGQL: OrderGQL,
    private productSelectorSearchGQL: ProductSelectorSearchGQL,
    private createDraftOrderGQL: CreateDraftOrderGQL,
    private eligibleShippingMethodsForDraftOrderGQL: EligibleShippingMethodsForDraftOrderGQL,
    private setCustomerForDraftOrderGQL: SetCustomerForDraftOrderGQL,
    private setDraftOrderShippingAddressGQL: SetDraftOrderShippingAddressGQL,
    private setDraftOrderBillingAddressGQL: SetDraftOrderBillingAddressGQL,
    private setDraftOrderShippingMethodGQL: SetDraftOrderShippingMethodGQL,
    private applyCouponCodeToDraftOrderGQL: ApplyCouponCodeToDraftOrderGQL,
    private transitionOrderToStateGQL: TransitionOrderToStateGQL,
    private cancelOrderGQL: CancelOrderGQL,
    private deleteDraftOrderGQL: DeleteDraftOrderGQL,
    private addManualPaymentToOrderGQL: AddManualPaymentToOrderGQL,
    private transitionFulfillmentToStateGQL: TransitionFulfillmentToStateGQL,
    private refundOrderGQL: RefundOrderGQL,
    private getOrderHistoryGQL: GetOrderHistoryGQL,
    private modifyOrderGQL: ModifyOrderGQL,
    private addFulfillmentToOrderGQL: AddFulfillmentToOrderGQL,
    private removeCouponCodeFromDraftOrderGQL: RemoveCouponCodeFromDraftOrderGQL,
    private addItemToDraftOrderGQL: AddItemToDraftOrderGQL,
    private adjustDraftOrderLineGQL: AdjustDraftOrderLineGQL,
    private removeDraftOrderLineGQL: RemoveDraftOrderLineGQL
  ) {}

  updatePagination(page: number, pageSize: number) {
    this.store.update((state) => ({
      ...state,
      currentPage: page,
      pageSize: pageSize,
      params: {
        ...state.params,
        skip: (page - 1) * pageSize,
      },
    }))
  }

  updateSort(sortField: string, sortOrder: string) {
    this.store.update((state) => ({
      ...state,
      params: {
        sort: {
          [sortField]: SortOrder.ASC,
        },
      },
    }))
  }

  goToPage(page: number) {
    this.updatePagination(page, this.store.getValue().pageSize)
    return this.fetchOrders()
  }

  nextPage() {
    const currentPage = this.store.getValue().currentPage
    this.goToPage(currentPage + 1)
  }

  previousPage() {
    const currentPage = this.store.getValue().currentPage
    this.goToPage(Math.max(1, currentPage - 1))
  }

  clearOrder() {
    this.store.update((state) => ({
      ...state,
      order: undefined,
    }))
  }

  fetchOrders() {
    const params = this.store.getValue().params

    return this.appService.withLoader(() =>
      this.ordersGQL
        .fetch(
          { orderListOptions: params },
          {
            context: {
              headers: {
                'vendure-token':
                  this.authService.authStore$.getValue().currentUserChannel
                    ?.token,
              },
            },
            fetchPolicy: 'network-only',
          }
        )
        .pipe(
          tap({
            next: (response) => {
              this.store.update((state) => ({
                ...state,
                orders: response.data.orders as OrderList,
                loading: false,
              }))
            },
            error: (error) => {
              console.error('Failed to fetch orders:', error)
              this.store.update((state) => ({
                ...state,
                loading: false,
              }))
            },
          })
        )
    )
  }

  fetchOrder(id: string) {
    return this.appService.withLoader(() =>
      this.orderGQL.fetch({ id },
        {
          context: {
            headers: {
              'vendure-token':
                this.authService.authStore$.getValue().currentUserChannel
                  ?.token,
            },
          },
          fetchPolicy: 'network-only',
        }
      ).pipe(
        tap({
          next: (response) => {
            this.store.update((state) => ({
              ...state,
              order: response.data.order as Order,
            }))
          },
          error: (error) => {
            console.error(`Failed to fetch order ${id}:`, error);
            this.store.update((state) => ({
              ...state,
              loading: false,
            }))
          },
        })
      )
    )
  }

  searchProduct(term: string) {
    return this.appService.withLoader(() =>
        this.productSelectorSearchGQL.fetch({
          term,
          take: 10,
        },
        {
          context: {
            headers: {
              'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
            },
          },
          fetchPolicy: 'network-only',
        }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  eligibleShippingMethodsForDraftOrder(orderId: string){
    return this.appService.withLoader(() =>
      this.eligibleShippingMethodsForDraftOrderGQL.fetch({ orderId },
        { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } }, fetchPolicy: 'network-only' }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response) 
          },
        })
      )
    )
  }

  createDraftOrder() {
    return this.appService.withLoader(() =>
      this.createDraftOrderGQL.mutate(
        {},
        { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  setCustomerForDraftOrder(customerId: string, orderId: string, input: CreateCustomerInput) {
    return this.appService.withLoader(() =>
      this.setCustomerForDraftOrderGQL.mutate({ customerId, orderId, input }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  setDraftOrderBillingAddress(orderId: string, input: CreateAddressInput) {
    return this.appService.withLoader(() =>
      this.setDraftOrderBillingAddressGQL.mutate({ orderId, input }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  setDraftOrderShippingAddress(orderId: string, input: CreateAddressInput) {
    return this.appService.withLoader(() =>
      this.setDraftOrderShippingAddressGQL.mutate({ orderId, input }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  setDraftOrderShippingMethod(orderId: string, shippingMethodId: string) {
    return this.appService.withLoader(() =>
      this.setDraftOrderShippingMethodGQL.mutate({ orderId, shippingMethodId }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  applyCouponCodeToDraftOrder(orderId: string, couponCode: string) {
    return this.appService.withLoader(() =>
      this.applyCouponCodeToDraftOrderGQL.mutate({ orderId, couponCode }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  transitionOrderToState(orderId: string, state: string) {
    return this.appService.withLoader(() =>
      this.transitionOrderToStateGQL.mutate({ id: orderId, state }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  cancelOrder(input: CancelOrderInput) {
    return this.appService.withLoader(() =>
      this.cancelOrderGQL.mutate(
        { input }, 
        { context: 
          { 
            headers: 
              { 
                'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token 
              } 
          } 
        }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  deleteDraftOrder(orderId: string) {
    return this.appService.withLoader(() =>
      this.deleteDraftOrderGQL.mutate({ id: orderId }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response) 
          },
        })
      )
    )
  }

  addManualPaymentToOrder(input: ManualPaymentInput) {
    return this.appService.withLoader(() =>
      this.addManualPaymentToOrderGQL.mutate({ input }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response) 
          },
        })
      )
    )
  }

  transitionFulfillmentToState(fulfillmentId: string, state: string) {
    return this.appService.withLoader(() =>
      this.transitionFulfillmentToStateGQL.mutate({ id: fulfillmentId, state }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  refundOrder(input: any) {
    return this.appService.withLoader(() =>
      this.refundOrderGQL.mutate({ input }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  getOrderHistory(id: string) {
    return this.appService.withLoader(() =>
      this.getOrderHistoryGQL.fetch(
        {
          id,
          options: {
            take: 99,
            sort: { createdAt: SortOrder.DESC },
            filter: { type: { eq: "ORDER_STATE_TRANSITION" } }
          }
        },
        {
          context: {
            headers: {
              'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token
            }
          },
          fetchPolicy: 'network-only',
        }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  modifyOrder(input: ModifyOrderInput) {
    return this.appService.withLoader(() =>
      this.modifyOrderGQL.mutate({ input }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  addFulfillmentToOrder(input: FulfillOrderInput) {
    return this.appService.withLoader(() =>
      this.addFulfillmentToOrderGQL.mutate({ input }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  removeCouponCodeFromDraftOrder(orderId: string, couponCode: string) {
    return this.appService.withLoader(() =>
      this.removeCouponCodeFromDraftOrderGQL.mutate({ orderId, couponCode }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  addItemToDraftOrder(orderId: string, input: AddItemToDraftOrderInput) {
    return this.appService.withLoader(() =>
      this.addItemToDraftOrderGQL.mutate({ orderId, input }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  adjustDraftOrderLine(orderId: string, input: AdjustDraftOrderLineInput) {
    return this.appService.withLoader(() =>
      this.adjustDraftOrderLineGQL.mutate({ orderId, input }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }

  removeDraftOrderLine(orderId: string, orderLineId: string) {
    return this.appService.withLoader(() =>
      this.removeDraftOrderLineGQL.mutate({ orderId, orderLineId }, { context: { headers: { 'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token } } }
      ).pipe(
        tap({
          next: (response) => {
            console.log(response)
          },
        })
      )
    )
  }
}

