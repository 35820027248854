<div class="page-container">
  <div nz-row class="page-container-head">
    <div nz-col nzSpan="12">
      <h2>Plugins</h2>
    </div>
    <div nz-col nzSpan="12" class="page-container-toolbar"></div>
  </div>

  <div class="page-container">
    <div class="page-container-inner">
      <div nz-row>
        <div nz-col nzSpan="24">
          <h3>Seamlessly Connected</h3>
          <p>Your Gateway to Unlimited Integrations!</p>
        </div>
      </div>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzXs="24" nzMd="12">
      <div class="category-tags">
        <nz-tag
          nzMode="checkable"
          [nzChecked]="checkedTag === 'all'"
          (nzCheckedChange)="onTagChecked('all')"
          >All</nz-tag
        >
        <nz-tag
          nzMode="checkable"
          [nzChecked]="checkedTag === 'payment'"
          (nzCheckedChange)="onTagChecked('payment')"
          >Payment</nz-tag
        >
        <nz-tag
          nzMode="checkable"
          [nzChecked]="checkedTag === 'crm'"
          (nzCheckedChange)="onTagChecked('crm')"
          >CRM</nz-tag
        >
        <nz-tag
          nzMode="checkable"
          [nzChecked]="checkedTag === 'security'"
          (nzCheckedChange)="onTagChecked('security')"
          >Security</nz-tag
        >
        <nz-tag
          nzMode="checkable"
          [nzChecked]="checkedTag === 'utility'"
          (nzCheckedChange)="onTagChecked('utility')"
          >Utility</nz-tag
        >
        <nz-tag
          nzMode="checkable"
          [nzChecked]="checkedTag === 'fulfillment'"
          (nzCheckedChange)="onTagChecked('fulfillment')"
          >Fulfillment</nz-tag
        >
      </div>
    </div>
    <div nz-col nzXs="24" nzMd="12">
      <div class="search-bar">
        <input nz-input placeholder="Quick search..." />
      </div>
    </div>
  </div>

  <nz-row [nzGutter]="[16, 16]">
    <div
      nz-col
      nzXs="24"
      nzMd="12"
      nzLg="6"
      nzXl="4"
      *ngFor="let plugin of plugins$ | async"
    >
      <div routerLink="/admin/plugins/{{ plugin.id }}" class="plugin-card">
        <nz-tag
          *ngIf="checkIsActive(plugin.id) | async"
          class="plugin-card-status"
          >Active</nz-tag
        >
        <div class="plugin-card-content">
          <div class="plugin-card-header">
            <img [src]="plugin.imageUrl" alt="Plugin Logo" />
          </div>
          <div class="plugin-card-body">
            <h3>{{ plugin.name }}</h3>
            <p>{{ plugin.description }}</p>
          </div>
        </div>

        <div class="plugin-card-footer">
          <nz-tag *ngFor="let tag of plugin.tags">{{ tag.name }}</nz-tag>
        </div>
      </div>
    </div>
  </nz-row>
</div>
