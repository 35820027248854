import { Component, OnDestroy, OnInit } from '@angular/core'
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { NzFormLayoutType } from 'ng-zorro-antd/form'
import { forkJoin } from 'rxjs'
import {
  ChannelPlugin,
  ManageablePlugin,
  ManageablePluginConfigItemTranslation,
} from '../../gql/shop/generated'
import { PluginService } from '../../service/manageable-plugin.service'

@Component({
  selector: 'app-plugins-detail',
  templateUrl: './plugins-detail.component.html',
  styleUrl: './plugins-detail.component.less',
})
export class PluginsDetailComponent implements OnInit, OnDestroy {
  validateForm: FormGroup = this.formBuilder.group({
    formLayout: 'horizontal' as NzFormLayoutType,
    enabled: new FormControl(false) as FormControl<boolean>,
  })

  channelPlugin: ChannelPlugin | null = null
  manageablePlugin: ManageablePlugin | null = null
  constructor(
    private formBuilder: FormBuilder,
    private pluginService: PluginService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      console.log(params)
      const id = params['id']
      if (id) {
        forkJoin([
          this.pluginService.getPlugins(),
          this.pluginService.getChannelPlugins(),
        ]).subscribe(([plugins, channelPlugins]) => {
          const channelPlugin = channelPlugins.data.listChannelPlugins.find(
            (channelPlugin) => channelPlugin.manageablePlugin.id === id
          )
          const manageablePlugin = plugins.data.listManageablePlugins.find(
            (manageablePlugin) => manageablePlugin.id === id
          ) as ManageablePlugin

          this.manageablePlugin = manageablePlugin as ManageablePlugin
          this.channelPlugin = channelPlugin as ChannelPlugin

          manageablePlugin.configurationItems.forEach((item) => {
            this.validateForm.addControl(item.id, new FormControl(''))
          })

          if (channelPlugin) {
            this.validateForm.patchValue(channelPlugin.configuration)
          } else {
            this.pluginService
              .createChannelPlugin(manageablePlugin.code)
              .subscribe((response) => {
                this.channelPlugin = response.data
                  ?.createChannelPlugin as ChannelPlugin
              })
          }
        })
      }
    })
  }
  ngOnDestroy(): void {
    console.log('ngOnDestroy')
  }

  async submitForm() {
    console.log('submitForm')

    if (this.validateForm.value.enabled) {
      this.pluginService
        .enablePlugin(this.channelPlugin!.id)
        .subscribe((response) => {
          console.log(response)
        })
    } else {
      this.pluginService
        .disablePlugin(this.channelPlugin!.id)
        .subscribe((response) => {
          console.log(response)
        })
    }

    this.pluginService
      .updatePlugin(this.channelPlugin!.id, this.validateForm.value)
      .subscribe()
  }

  getTranslation(translations: ManageablePluginConfigItemTranslation[]) {
    return translations.find((translation) => translation.languageCode === 'en')
  }

  public asFormControl = (formControl: AbstractControl): FormControl =>
    formControl as FormControl

  goBack() {
    this.router.navigate(['/admin/plugins'])
  }
}
