import { Injectable } from '@angular/core'
import { createStore, withProps } from '@ngneat/elf'
import { tap } from 'rxjs'
import {
  ChannelPlugin,
  CreateChannelPluginGQL,
  DisableChannelPluginGQL,
  EnableChannelPluginGQL,
  ListChannelPluginsGQL,
  ListManageablePluginsGQL,
  ManageablePlugin,
  UpdateChannelPluginGQL,
} from '../gql/shop/generated'
import { AppService } from './app.service'
import { AuthService } from './auth.service'

interface AppState {
  channelPlugins: ChannelPlugin[]
  plugins: ManageablePlugin[]
}

@Injectable({
  providedIn: 'root',
})
export class PluginService {
  pluginsStore$ = createStore(
    { name: 'plugins' },
    withProps<AppState>({
      channelPlugins: [],
      plugins: [],
    })
  )

  constructor(
    private listManageablePluginsGQL: ListManageablePluginsGQL,
    private listChannelPluginsGQL: ListChannelPluginsGQL,
    private updateChannelPluginGQL: UpdateChannelPluginGQL,
    private enableChannelPluginGQL: EnableChannelPluginGQL,
    private disableChannelPluginGQL: DisableChannelPluginGQL,
    private createChannelPluginGQL: CreateChannelPluginGQL,
    private appService: AppService,
    private authService: AuthService
  ) {}

  getPlugins() {
    return this.appService.withLoader(() =>
      this.listManageablePluginsGQL
        .fetch(
          {},
          {
            context: {
              headers: {
                'vendure-token':
                  this.authService.authStore$.getValue().currentUserChannel
                    ?.token,
              },
            },
          }
        )
        .pipe(
          tap({
            next: (response) => {
              this.pluginsStore$.update((state) => ({
                ...state,
                plugins: response.data
                  .listManageablePlugins as ManageablePlugin[],
              }))
            },
          })
        )
    )
  }

  getChannelPlugins() {
    return this.appService.withLoader(() =>
      this.listChannelPluginsGQL
        .fetch(
          {},
          {
            context: {
              headers: {
                'vendure-token':
                  this.authService.authStore$.getValue().currentUserChannel
                    ?.token,
              },
            },
            fetchPolicy: 'network-only',
          }
        )
        .pipe(
          tap({
            next: (response) => {
              this.pluginsStore$.update((state) => ({
                ...state,
                channelPlugins: response.data
                  .listChannelPlugins as ChannelPlugin[],
              }))
            },
          })
        )
    )
  }

  updatePlugin(id: string, configuration: any) {
    return this.appService.withLoader(() =>
      this.updateChannelPluginGQL
        .mutate(
          { channelPluginId: id, configuration },
          {
            context: {
              headers: {
                'vendure-token':
                  this.authService.authStore$.getValue().currentUserChannel
                    ?.token,
              },
            },
          }
        )
        .pipe(
          tap({
            next: (response) => {
              console.log(response)
            },
          })
        )
    )
  }

  enablePlugin(id: string) {
    return this.enableChannelPluginGQL.mutate(
      { channelPluginId: id },
      {
        context: {
          headers: {
            'vendure-token':
              this.authService.authStore$.getValue().currentUserChannel?.token,
          },
        },
      }
    )
  }

  disablePlugin(id: string) {
    return this.disableChannelPluginGQL.mutate(
      { channelPluginId: id },
      {
        context: {
          headers: {
            'vendure-token':
              this.authService.authStore$.getValue().currentUserChannel?.token,
          },
        },
      }
    )
  }

  createChannelPlugin(code: string) {
    return this.createChannelPluginGQL.mutate(
      { code },
      {
        context: {
          headers: {
            'vendure-token':
              this.authService.authStore$.getValue().currentUserChannel?.token,
          },
        },
      }
    )
  }
}
