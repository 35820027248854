import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormArray, FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms'
import { of, tap, mergeMap, concatMap, from, takeLast, finalize, filter, firstValueFrom } from 'rxjs'
import { OrderService } from '../../service/order.service'
import { ActivatedRoute, Router } from '@angular/router'
import { NzFormLayoutType } from 'ng-zorro-antd/form'
import { FulfillOrderInput, Order, OrderLineInput } from '../../gql/shop/generated'
import { AppService } from '../../service/app.service'
import { CustomerService } from '../../service/customer.service'
import { NzMessageService } from 'ng-zorro-antd/message'
import { v4 } from 'uuid'
import { PromotionService } from '../../service/promotion.service'
import { PaymentService } from '../../service/payment.service'
import { ProductService } from '../../service/product.service'
import { summate } from '@vendure/common/lib/shared-utils';

interface VariantForm {
  id: FormControl<string>;
  productVariantName: FormControl<string>;
  sku: FormControl<string>;
  price: FormControl<number>;
  priceWithTax: FormControl<number>;
  total: FormControl<number>;
  totalWithTax: FormControl<number>;
  stock: FormControl<number>;
  quantity: FormControl<number>;
  refund: FormControl<number>;
  returnToStock: FormControl<boolean>;
  unfulfilledQuantity: FormControl<number>;
  quantityToFulfill: FormControl<number>;
}

interface CustomerForm {
  id: FormControl<string>;
  title: FormControl<string>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
  phone: FormControl<string>;
}

interface AddressForm {
  fullName: FormControl<string>;
  company: FormControl<string>;
  streetLine1: FormControl<string>;
  streetLine2: FormControl<string>;
  city: FormControl<string>;
  province: FormControl<string>;
  postalCode: FormControl<string>;
  country: FormControl<string>;
  phoneNumber: FormControl<string>;
}

interface CancelOrderForm {
  amount: FormControl<number>;
  reason: FormControl<string>;
  cancelShipping: FormControl<boolean>;
}

interface CancelLineForm {
  orderLineId: FormControl<string>;
  quantity: FormControl<number>;
}

interface CancelDeliveredOrderForm {
  reason: FormControl<string>;
  lines: FormArray<FormGroup<CancelLineForm>>;
}

interface TransitionOrderStateForm {
  state: FormControl<string>;
}

interface AddManualPaymentToOrderForm {
  paymentMethodCode: FormControl<string>;
  transactionId: FormControl<string>;
}

interface RefundAndCancelOrderForm {
  adjustment: FormControl<number>;
  lines: FormArray<FormGroup<CancelLineForm>>;
  paymentId: FormControl<string>;
  paymentMethod: FormControl<string>;
  transactionId: FormControl<string>;
  paymentAmount: FormControl<number>;
  cancelShipping: FormControl<boolean>;
  refundAmount: FormControl<number>;
  refundableAmount: FormControl<number>;
  selected: FormControl<boolean>;
}

interface SettleRefundForm {
  id: FormControl<string>;
  transactionId: FormControl<string>;
  paymentMethod: FormControl<string>;
}

interface CancelPaymentForm {
  id: FormControl<string>;
}

interface TempSurchargeForm {
  description: FormControl<string>;
  sku: FormControl<string>;
  price: FormControl<number>;
  includesTax: FormControl<boolean>;
  taxRate: FormControl<number>;
  taxDescription: FormControl<string>;
}

interface SurchargeForm {
  description: FormControl<string>;
  sku: FormControl<string>;
  price: FormControl<number>;
  includesTax: FormControl<boolean>;
  taxRate: FormControl<number>;
  taxDescription: FormControl<string>;
}

interface ConfirmModificationForm {
  recalculateShipping: FormControl<boolean>;
  note: FormControl<string>;
}

interface FulfillmentDetailsForm {
  method: FormControl<string>;
  trackingCode: FormControl<string>;
}

interface FulfillmentLineForm {
  id: FormControl<string>;
}


interface ProductObject {
  productVariantId: string;
  productVariantName: string;
  sku: string;
  image: string;
  unitPrice: number;
  unitPriceWithTax: number;
  quantity: number;
  total: number;
  totalWithTax: number;
}

interface CustomerObject {
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

interface AddressObject {
  fullName: string;
  company: string;
  streetLine1: string;
  streetLine2: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  phoneNumber: string;
}

interface ShippingMethodObject {
  id: string;
  price: number;
  priceWithTax: number;
  code: string;
  name: string;
  description: string;
}

interface PromotionObject {
  id: string;
  name: string;
  couponCode: string;
}

interface PaymentObject {
  id: string;
  paymentMethod: string;
  amount: number;
  transactionId: string;
  status: string;
  paymentMetadata: string;
  refunds: {
    paymentMethod: string;
    paymentTransactionId: string;
    id: string;
    createdAt: string;
    refundTotal: number;
    transactionId: string;
    reason: string;
    state: string;
  }[]
}

interface FulfillmentObject {
  id: string;
  state: string;
  createdAt: string;
  method: string;
  trackingCode: string;
  contents: string;
}

interface SellerOrderObject {
  id: string;
  code: string;
  state: string;
}

interface RefundObject {
  reason: string;
  paymentId: string;
  amount: number;
}

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrl: './order-detail.component.less',
})
export class OrderDetailComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public appService: AppService,
    private orderService: OrderService,
    private customerService: CustomerService,
    private messageService: NzMessageService,
    private promotionService: PromotionService,
    private paymentService: PaymentService,
    private productService: ProductService
  ) {}

  validateForm: FormGroup<{
    formLayout: FormControl<NzFormLayoutType>,
    variants: FormArray<FormGroup<VariantForm>>,
    customer: FormGroup<CustomerForm>,
    address: FormGroup<AddressForm>,
    sameAddress: FormControl<boolean>,
    customers: FormArray<FormControl<CustomerObject>>,
    addresses: FormArray<FormControl<AddressObject>>,
    eligibleShippingMethods: FormArray<FormControl<ShippingMethodObject>>,
    promotions: FormArray<FormControl<PromotionObject>>,
    selectedProduct: FormControl<ProductObject | null>,
    selectedCustomer: FormControl<CustomerObject | null>,
    selectedBillingAddress: FormControl<AddressObject | null>,
    selectedShippingAddress: FormControl<AddressObject | null>,
    selectedShippingMethod: FormControl<ShippingMethodObject | null>,
    selectedPromotions: FormControl<PromotionObject[]>,
    cancelOrder: FormGroup<CancelOrderForm>,
    cancelDeliveredOrder: FormGroup<CancelDeliveredOrderForm>,
    transitionOrderState: FormGroup<TransitionOrderStateForm>,
    addManualPaymentToOrder: FormGroup<AddManualPaymentToOrderForm>,
    payments: FormArray<FormControl<PaymentObject>>,
    fulfillments: FormArray<FormControl<FulfillmentObject>>,
    fulfillmentMarkLine: FormGroup<FulfillmentLineForm>,
    cancelEntireOrder: FormControl<boolean>,
    cancelPayment: FormGroup<CancelPaymentForm>,
    refundAndCancelOrders: FormArray<FormGroup<RefundAndCancelOrderForm>>,
    sellerOrders: FormArray<FormControl<SellerOrderObject>>,
    settleRefund: FormGroup<SettleRefundForm>,
    surcharges: FormArray<FormGroup<SurchargeForm>>,
    tempSurcharge: FormGroup<TempSurchargeForm>,
    confirmModification: FormGroup<ConfirmModificationForm>,
    orderEditResult: FormControl<string>,
    fulfillmentDetails: FormGroup<FulfillmentDetailsForm>
  }> = this.formBuilder.group({
    formLayout: 'horizontal' as NzFormLayoutType,
    variants: this.formBuilder.array<FormGroup<VariantForm>>([]),
    customer: this.formBuilder.group<CustomerForm>({
      id: this.formBuilder.control(''),
      title: this.formBuilder.control('', {validators: [Validators.required]}),
      firstName: this.formBuilder.control('', {validators: [Validators.required]}),
      lastName: this.formBuilder.control('', {validators: [Validators.required]}),
      email: this.formBuilder.control('', {validators: [Validators.required, Validators.email]}),
      phone: this.formBuilder.control('', {validators: [Validators.required]}),
    }),
    address: this.formBuilder.group<AddressForm>({
      fullName: this.formBuilder.control('', {validators: [Validators.required]}),
      company: this.formBuilder.control('', {validators: [Validators.required]}),
      streetLine1: this.formBuilder.control('', {validators: [Validators.required]}),
      streetLine2: this.formBuilder.control('', {validators: [Validators.required]}),
      city: this.formBuilder.control('', {validators: [Validators.required]}),
      province: this.formBuilder.control('', {validators: [Validators.required]}),
      postalCode: this.formBuilder.control('', {validators: [Validators.required]}),
      country: this.formBuilder.control('', {validators: [Validators.required]}),
      phoneNumber: this.formBuilder.control('', {validators: [Validators.required]}),
    }),
    sameAddress: this.formBuilder.control(false),
    customers: this.formBuilder.array<FormControl<CustomerObject>>([]),
    addresses: this.formBuilder.array<FormControl<AddressObject>>([]),
    eligibleShippingMethods: this.formBuilder.array<FormControl<ShippingMethodObject>>([]),
    promotions: this.formBuilder.array<FormControl<PromotionObject>>([]),
    selectedProduct: this.formBuilder.control<ProductObject | null>(null),
    selectedCustomer: this.formBuilder.control<CustomerObject | null>(null),
    selectedBillingAddress: this.formBuilder.control<AddressObject | null>(null),
    selectedShippingAddress: this.formBuilder.control<AddressObject | null>(null),
    selectedShippingMethod: this.formBuilder.control<ShippingMethodObject | null>(null),
    selectedPromotions: this.formBuilder.control<PromotionObject[]>([]),
    cancelOrder: this.formBuilder.group<CancelOrderForm>({
      amount: this.formBuilder.control<number>(0, {validators: [Validators.required]}),
      reason: this.formBuilder.control<string>('', {validators: [Validators.required]}),
      cancelShipping: this.formBuilder.control<boolean>(false)
    }),
    cancelDeliveredOrder: this.formBuilder.group<CancelDeliveredOrderForm>({
      reason: this.formBuilder.control<string>('', {validators: [Validators.required]}),
      lines: this.formBuilder.array<FormGroup<CancelLineForm>>([])
    }),
    transitionOrderState: this.formBuilder.group<TransitionOrderStateForm>({
      state: this.formBuilder.control<string>('', {validators: [Validators.required]})
    }),
    addManualPaymentToOrder: this.formBuilder.group<AddManualPaymentToOrderForm>({
      paymentMethodCode: this.formBuilder.control<string>('', {validators: [Validators.required]}),
      transactionId: this.formBuilder.control<string>('', {validators: [Validators.required]})
    }),
    payments: this.formBuilder.array<FormControl<PaymentObject>>([]),
    fulfillments: this.formBuilder.array<FormControl<FulfillmentObject>>([]),
    fulfillmentMarkLine: this.formBuilder.group<FulfillmentLineForm>({
      id: this.formBuilder.control<string>('')
    }),
    cancelEntireOrder: this.formBuilder.control<boolean>(true),
    cancelPayment: this.formBuilder.group<CancelPaymentForm>({
      id: this.formBuilder.control<string>('')
    }),
    refundAndCancelOrders: this.formBuilder.array<FormGroup<RefundAndCancelOrderForm>>([]),
    sellerOrders: this.formBuilder.array<FormControl<SellerOrderObject>>([]),
    settleRefund: this.formBuilder.group<SettleRefundForm>({
      id: this.formBuilder.control<string>(''),
      transactionId: this.formBuilder.control<string>(''),
      paymentMethod: this.formBuilder.control<string>('')
    }),
    surcharges: this.formBuilder.array<FormGroup<SurchargeForm>>([]),
    tempSurcharge: this.formBuilder.group<TempSurchargeForm>({
      description: this.formBuilder.control<string>(''),
      sku: this.formBuilder.control<string>(''),
      price: this.formBuilder.control<number>(0, {validators: [
        Validators.required,
        Validators.min(0.01) 
      ]}),
      includesTax: this.formBuilder.control<boolean>(false),
      taxRate: this.formBuilder.control<number>(0, {validators: [
        Validators.min(0),
        Validators.max(100)
      ]}),
      taxDescription: this.formBuilder.control<string>('')
    }),
    confirmModification: this.formBuilder.group<ConfirmModificationForm>({
      recalculateShipping: this.formBuilder.control<boolean>(false),
      note: this.formBuilder.control<string>('', {validators: [Validators.required]})
    }),
    orderEditResult: this.formBuilder.control<string>(''),
    fulfillmentDetails: this.formBuilder.group<FulfillmentDetailsForm>({
      method: this.formBuilder.control<string>('', {validators: [Validators.required]}),
      trackingCode: this.formBuilder.control<string>('', {validators: [Validators.required]})
    })
  })

  // order id
  orderId: string | null = null

  // order code
  orderCode: string | null = null

  // search term
  searchTerm = ''

  // subTotal
  subTotal = 0

  // subTotalWithTax
  subTotalWithTax = 0

  // shipping
  shipping = 0

  // shippingWithTax
  shippingWithTax = 0

  // total
  total = 0

  // totalWithTax
  totalWithTax = 0

  // finish save state
  finishSave = false

  // complete draft state
  completeDraft = false

  // created at
  createdAt: string | null = null

  // updated at
  updatedAt: string | null = null

  // order state
  orderState: string | null = null

  // search products
  searchProducts: ProductObject[] = []

  // tax summary
  taxSummary: {
    description: string
    taxRate: number
    taxBase: number
    taxTotal: number
  }[] = []

  // payments
  payments: any[] = []

  // next states
  nextStates: string[] = []

  // payment method codes
  paymentMethodCodes: string[] = []

  // show remove variant modal
  showRemoveVariantModal = false

  // show shipping method select modal
  showShippingMethodSelectModal = false

  // show add customer modal
  showAddCustomerModal = false
  
  // show add address modal
  showAddAddressModal = false

  // show complete draft modal
  showCompleteDraftModal = false

  // show cancel order modal
  showCancelOrderModal = false

  // show transition state modal
  showTransitionStateModal = false

  // show remove draft order modal
  showRemoveDraftOrderModal = false

  // show add payment modal
  showAddPaymentModal = false

  // show cancel payment modal
  showCancelPaymentModal = false

  // show arrange additional payment modal
  showArrangeAdditionalPaymentModal = false

  // show transition fulfillment state modal
  showTransitionFulfillmentStateModal = false

  // show transition fulfillment state to shipped modal
  showTransitionFulfillmentStateToShippedModal = false

  // show transition fulfillment state to delivered modal
  showTransitionFulfillmentStateToDeliveredModal = false

  // show transition fulfillment state to cancelled modal
  showTransitionFulfillmentStateToCancelledModal = false

  // show cancel delivered order modal
  showCancelDeliveredOrderModal = false

  // show refund and cancel order modal
  showRefundAndCancelOrderModal = false

  // show settle refund modal
  showSettleRefundModal = false

  // show cancel modification modal
  showCancelModificationModal = false

  // show preview changes modal
  showPreviewChangesModal = false 

  // show modify order modal
  showModifyOrderModal = false

  // show fulfill order modal
  showFulfillOrderModal = false

  // currency code
  currencyCode = 'USD'

  // the flag of show arrange additional payment while order state is `PaymentSettled`,`ArrangingAdditionalPayment`,`Shipped` or `Delivered`
  showArrangeAdditionalPaymentFlag = false

  // the array of orderLineId and productVariantId 
  orderLineIdAndProductVariantId: {orderLineId: string, productVariantId: string}[] = []

  // store original order information (variants, billingAddress, shippingAddress, shippingMethod, promotion)
  originalOrderInformation: {
    variants: { id: string, productVariantName: string, quantity: number }[],
    billingAddress: { fullName: string, company: string, streetLine1: string, streetLine2: string, city: string, province: string, postalCode: string, country: string, phoneNumber: string},
    shippingAddress: { fullName: string, company: string, streetLine1: string, streetLine2: string, city: string, province: string, postalCode: string, country: string, phoneNumber: string},
    shippingMethod: { name: string},
    promotions: { couponCode: string}[]
  } = {
    variants: [{id: '', productVariantName: '', quantity: 0}],
    billingAddress: {fullName: '', company: '', streetLine1: '', streetLine2: '', city: '', province: '', postalCode: '', country: '', phoneNumber: ''},
    shippingAddress: {fullName: '', company: '', streetLine1: '', streetLine2: '', city: '', province: '', postalCode: '', country: '', phoneNumber: ''},
    shippingMethod: { name: ''},
    promotions: [{couponCode: ''}]
  }

  // modification order information
  modificationOrderInformation: {
    variants: { id: string, productVariantName: string, modificationQuantity: number }[],
    billingAddress: { fullName: { fromName: string, toName: string }, company: { fromName: string, toName: string }, streetLine1: { fromName: string, toName: string }, streetLine2: { fromName: string, toName: string }, city: { fromName: string, toName: string }, province: { fromName: string, toName: string }, postalCode: { fromName: string, toName: string }, country: { fromName: string, toName: string }, phoneNumber: { fromName: string, toName: string }},
    shippingAddress: { fullName: { fromName: string, toName: string }, company: { fromName: string, toName: string }, streetLine1: { fromName: string, toName: string }, streetLine2: { fromName: string, toName: string }, city: { fromName: string, toName: string }, province: { fromName: string, toName: string }, postalCode: { fromName: string, toName: string }, country: { fromName: string, toName: string }, phoneNumber: { fromName: string, toName: string }},
    shippingMethod: { fromName: string, toName: string},
    promotions: { couponCode: string, removeOrAdd: string}[] 
  } = {
    variants: [],
    billingAddress: {fullName: { fromName: '', toName: ''}, company: { fromName: '', toName: ''}, streetLine1: { fromName: '', toName: ''}, streetLine2: { fromName: '', toName: ''}, city: { fromName: '', toName: ''}, province: { fromName: '', toName: ''}, postalCode: { fromName: '', toName: ''}, country: { fromName: '', toName: ''}, phoneNumber: { fromName: '', toName: ''}},
    shippingAddress: {fullName: { fromName: '', toName: ''}, company: { fromName: '', toName: ''}, streetLine1: { fromName: '', toName: ''}, streetLine2: { fromName: '', toName: ''}, city: { fromName: '', toName: ''}, province: { fromName: '', toName: ''}, postalCode: { fromName: '', toName: ''}, country: { fromName: '', toName: ''}, phoneNumber: { fromName: '', toName: ''}},
    shippingMethod: {fromName: '', toName: ''},
    promotions: []
  }

  // modify checker timer
  modifyCheckerTimer: any = null

  // original totalWithTax
  originalTotalWithTax = 0

  // variants response in modifying state
  variantsResponse: {
    image: string,
    name: string,
    sku: string,
    unitPrice: number,
    quantity: number,
    total: number
  }[] = []

  // surcharges response in modifying state
  surchargesResponse: {
    description: string,
    sku: string,
    price: number,
    priceWithTax: number,
    taxRate: number,
    taxDescription: string
  }[] = []

  // discounts response
  discountsResponse: {
    adjustmentSource: string,
    couponCode: string,
    description: string,
    amount: number,
    amountWithTax: number
  }[] = []

  // promotions response
  promotionsResponse: {
    id: string,
    couponCode: string
  }[] = []

  // shipping methods response in modifying state
  shippingMethodsResponse: {
    name: string
  }[] = []

  // modify order request payload
  modifyOrderRequestPayload: any = {}

  // modification response
  modificationResponse: {
    createdAt: string,
    id: string,
    isSettled: boolean,
    lines: { orderLineId: string, quantity: number }[],
    note: string,
    payment: any,
    priceChange: number,
    refund: any,
    surcharges: any[]
  }[] = []

  // refunds in modifying state
  refunds: RefundObject[] = []
  // caculate `Add Payment To Order`
  addPaymentToOrder = 0
  
  ngOnInit(): void {
    // get customers
    this.getCustomers()

    this.setupListeners()
  }

  setupListeners() {
    // get order id from route and fetch order
    this.route.paramMap.subscribe((params) => {
      this.orderId = params.get('id')
      this.orderService.fetchOrder(this.orderId as string).subscribe()
    })

    // set up order listener
    this.orderService.order$
      .pipe(
        tap((order) => {
          if(!order){
            return
          }
          if(this.orderId !== order.id){
            return
          }
          // set order code
          this.orderCode = order.code

          // set created at
          this.createdAt = order.createdAt

          // set updated at
          this.updatedAt = order.updatedAt

          // set order state
          this.orderState = order.state

          // set subTotal
          this.subTotal = order.subTotal
        
          // set subTotalWithTax
          this.subTotalWithTax = order.subTotalWithTax
        
          // set shipping
          this.shipping = order.shipping
        
          // set shippingWithTax
          this.shippingWithTax = order.shippingWithTax
        
          // set total
          this.total = order.total
        
          // set totalWithTax
          this.totalWithTax = order.totalWithTax

          // set original totalWithTax
          this.originalTotalWithTax = order.totalWithTax

          // set currency code
          this.currencyCode = order.currencyCode

          if(this.orderState === 'PaymentSettled' || this.orderState === 'ArrangingAdditionalPayment'
            || this.orderState === 'Shipped' || this.orderState === 'Delivered'
          ){
            // check if there is any payment with status `Settled`
            const settledPayment = order.payments?.some((payment) => payment.state === 'Settled')
            if(settledPayment){
              this.showArrangeAdditionalPaymentFlag = false
            }else{
              this.showArrangeAdditionalPaymentFlag = true
            }
          }

          // set variants
          this.validateForm.controls.variants.clear()
          this.orderLineIdAndProductVariantId = []
          order.lines.forEach((line) => {
            this.orderLineIdAndProductVariantId.push({
              orderLineId: line.id,
              productVariantId: line.productVariant.id
            })
            this.validateForm.controls.variants.push(this.formBuilder.group({
              id: line.productVariant.id as string,
              productVariantName: line.productVariant.name,
              sku: line.productVariant.sku,
              price: line.unitPrice,
              priceWithTax: line.unitPriceWithTax,
              total: line.linePrice,
              totalWithTax: line.linePriceWithTax,
              stock: line.productVariant.stockLevels[0].stockOnHand - line.productVariant.stockLevels[0].stockAllocated,
              quantity: line.quantity,
              refund: this.formBuilder.control<number>(0),
              returnToStock: this.formBuilder.control<boolean>(false),
              unfulfilledQuantity: this.formBuilder.control<number>(
                line.fulfillmentLines?.length === 0 ? line.quantity : (line.quantity - (line.fulfillmentLines?.reduce((acc, fulfillmentLine) => acc + fulfillmentLine.quantity, 0) ?? 0))
              ),
              quantityToFulfill: this.formBuilder.control<number>(0)
            }))
          })

          // set payments
          this.validateForm.controls.payments.clear()
          order.payments?.forEach((payment) => {
            this.validateForm.controls.payments.push(this.formBuilder.control({
              id: payment.id,
              paymentMethod: payment.method,
              amount: payment.amount,
              transactionId: payment.transactionId ?? '',
              status: payment.state,
              paymentMetadata: JSON.stringify(payment.metadata),
              refunds: payment.refunds ? payment.refunds.map((refund) => ({
                paymentMethod: payment.method,
                paymentTransactionId: payment.transactionId ?? '',
                id: refund.id,
                createdAt: refund.createdAt,
                refundTotal: refund.total,
                transactionId: refund.transactionId ?? '',
                reason: refund.reason ?? '',
                state: refund.state ?? ''
              })) : []
            }))
          })
          this.payments = order.payments ?? []

          // set seller orders
          this.validateForm.controls.sellerOrders.clear()
          order.sellerOrders?.forEach((sellerOrder) => {
            this.validateForm.controls.sellerOrders.push(this.formBuilder.control({
              id: sellerOrder.id,
              code: sellerOrder.code,
              state: sellerOrder.state
            }))
          })
          
          // set refund and cancel order
          this.validateForm.controls.refundAndCancelOrders.clear()
          order.payments?.filter(payment => payment.state === 'Settled').forEach(payment => {
            this.validateForm.controls.refundAndCancelOrders.push(this.formBuilder.group({
              adjustment: this.formBuilder.control<number>(0),
              lines: this.formBuilder.array<FormGroup<CancelLineForm>>([]),
              paymentId: this.formBuilder.control<string>(payment.id),
              paymentMethod: this.formBuilder.control<string>(payment.method),
              transactionId: this.formBuilder.control<string>(payment.transactionId ?? ''),
              paymentAmount: this.formBuilder.control<number>(payment.amount),
              cancelShipping: this.formBuilder.control<boolean>(false),
              refundAmount: this.formBuilder.control<number>(0),
              refundableAmount: this.formBuilder.control<number>(payment.amount - (payment.refunds?.reduce((acc, refund) => acc + refund.total, 0) ?? 0)),
              selected: this.formBuilder.control<boolean>(false)
            }))
          })
          // set fulfillments
          this.validateForm.controls.fulfillments.clear()
          order.fulfillments?.forEach((fulfillment) => {
            // 1. get fulfillment lines
            // 2. iterate lines, get quantity and product variant name
            const contents = fulfillment.lines.map((line) => {
              const quantity = line.quantity
              const productVariantName = order.lines.find(
                (l) => l.id === line.orderLineId)?.productVariant.name
              return `${quantity} x ${productVariantName}`
            }).join(',')
            this.validateForm.controls.fulfillments.push(this.formBuilder.control({
              id: fulfillment.id,
              state: fulfillment.state,
              createdAt: fulfillment.createdAt,
              method: fulfillment.method,
              trackingCode: fulfillment.trackingCode ?? '',
              contents: contents
            }))
          })

          // set modification response
          this.modificationResponse = order.modifications.map((modification) => ({
            createdAt: modification.createdAt,
            id: modification.id,
            isSettled: modification.isSettled,
            lines: modification.lines,
            note: modification.note,
            payment: modification.payment,
            priceChange: modification.priceChange,
            refund: modification.refund,
            surcharges: modification.surcharges as any[]
          }))

          // set tax summary
          this.taxSummary = order.taxSummary.map((tax) => ({
            description: tax.description,
            taxRate: tax.taxRate,
            taxBase: tax.taxBase,
            taxTotal: tax.taxTotal
          }))
          
          // set customer
          const customerControl = this.validateForm.controls.customers.controls.find(control => 
            control.getRawValue().id === order.customer?.id
          );

          if (customerControl) {
            this.validateForm.controls.selectedCustomer.setValue(customerControl.getRawValue());
          }

          // set addresses
          if(order.customer?.id){
            this.customerService.getCustomerAddresses(order.customer?.id as string).subscribe(
              (response) => {
                this.validateForm.controls.addresses.clear()
      
                if(!response.data.customer?.addresses){
                  return
                }
      
                // init addresses
                response.data.customer?.addresses.forEach((address) => {
                  this.validateForm.controls.addresses.push(this.formBuilder.control({
                    fullName: address.fullName ?? '',
                    company: address.company ?? '',
                    streetLine1: address.streetLine1 ?? '',
                    streetLine2: address.streetLine2 ?? '',
                    city: address.city ?? '',
                    province: address.province ?? '',
                    postalCode: address.postalCode ?? '',
                    country: address.country.code ?? '',
                    phoneNumber: address.phoneNumber ?? ''
                  }))
                })

                // set billing address by order 
                const existingBillingAddress = this.validateForm.controls.addresses.controls.find(
                    control => control.value.fullName === order.billingAddress?.fullName
                );
                
                if (!existingBillingAddress) {
                    this.validateForm.controls.addresses.push(this.formBuilder.control({
                        fullName: order.billingAddress?.fullName ?? '',
                        company: order.billingAddress?.company ?? '',
                        streetLine1: order.billingAddress?.streetLine1 ?? '',
                        streetLine2: order.billingAddress?.streetLine2 ?? '',
                        city: order.billingAddress?.city ?? '',
                        province: order.billingAddress?.province ?? '',
                        postalCode: order.billingAddress?.postalCode ?? '',
                        country: order.billingAddress?.country ?? '',
                        phoneNumber: order.billingAddress?.phoneNumber ?? ''
                    }));
                }

                // set shipping address by order 
                const existingShippingAddress = this.validateForm.controls.addresses.controls.find(
                  control => control.value.fullName === order.shippingAddress?.fullName
                );
              
                if (!existingShippingAddress) {
                    this.validateForm.controls.addresses.push(this.formBuilder.control({
                        fullName: order.shippingAddress?.fullName ?? '',
                        company: order.shippingAddress?.company ?? '',
                        streetLine1: order.shippingAddress?.streetLine1 ?? '',
                        streetLine2: order.shippingAddress?.streetLine2 ?? '',
                        city: order.shippingAddress?.city ?? '',
                        province: order.shippingAddress?.province ?? '',
                        postalCode: order.shippingAddress?.postalCode ?? '',
                        country: order.shippingAddress?.country ?? '',
                        phoneNumber: order.shippingAddress?.phoneNumber ?? ''
                    }));
                }

  
                // set selected billing address
                const billingAddressControl = this.validateForm.controls.addresses.controls.find(control => 
                  control.getRawValue().fullName === order.billingAddress?.fullName
                );
  
                if (billingAddressControl) {
                  this.validateForm.controls.selectedBillingAddress.setValue(billingAddressControl.getRawValue());
                }
  
                // set selected shipping address
                const shippingAddressControl = this.validateForm.controls.addresses.controls.find(control => 
                  control.getRawValue().fullName === order.shippingAddress?.fullName
                );
  
                if (shippingAddressControl) {
                  this.validateForm.controls.selectedShippingAddress.setValue(shippingAddressControl.getRawValue());
                }
              }
            )
          }
          // set eligible shipping methods
          this.orderService.eligibleShippingMethodsForDraftOrder(this.orderId as string).subscribe(
            (response) => {
              this.validateForm.controls.eligibleShippingMethods.clear()
              response.data.eligibleShippingMethodsForDraftOrder.forEach((shippingMethod) => {
                this.validateForm.controls.eligibleShippingMethods.push(this.formBuilder.control({
                  id: shippingMethod.id,
                  price: shippingMethod.price,
                  priceWithTax: shippingMethod.priceWithTax,
                  code: shippingMethod.code,
                  name: shippingMethod.name,
                  description: shippingMethod.description
                }))
              })
              const selectedMethod = this.validateForm.controls.eligibleShippingMethods.controls.find(
                method => method.getRawValue().id === order.shippingLines[0]?.shippingMethod?.id
              );
              if (selectedMethod) {
                this.validateForm.controls.selectedShippingMethod.setValue(selectedMethod.getRawValue());
              }
              if(this.orderState === 'Modifying' || this.orderState === 'Draft'){
                this.originalOrderInformation.shippingMethod.name = selectedMethod?.getRawValue()?.name ?? '';
              }
            }
          )
          // set coupon code
          this.promotionService.getCouponCodeSelectorPromotionList('').subscribe(
            (response) => {
              this.validateForm.controls.promotions.clear()
              this.validateForm.controls.selectedPromotions.reset()
              response.data.promotions.items
              .forEach((promotion) => {
                this.validateForm.controls.promotions.push(this.formBuilder.control({
                  id: promotion.id,
                  name: promotion.name,
                  couponCode: promotion.couponCode ?? ''
                }))
              })

              for(const couponCode of order.couponCodes){
                const couponCodeControl = this.validateForm.controls.promotions.controls.find(control => 
                  control.getRawValue().couponCode === couponCode
                );
                if (couponCodeControl) {
                  this.validateForm.controls.selectedPromotions.setValue([...this.validateForm.controls.selectedPromotions.value, couponCodeControl.getRawValue()]);
                }
                if(this.orderState === 'Modifying' || this.orderState === 'Draft'){
                  if(couponCode && couponCode.trim() !== ''){
                    this.originalOrderInformation.promotions.push({
                      couponCode: couponCode
                    });
                  }
                 }
              }
              this.originalOrderInformation.promotions = this.originalOrderInformation.promotions.filter((promotion) => 
                promotion.couponCode && promotion.couponCode.trim() !== ''
              );
            }
          )
          // set next states
          this.nextStates = order.nextStates
          // set surcharges
          this.surchargesResponse = []
          order.surcharges?.forEach((surcharge) => {
            this.surchargesResponse.push({
              description: surcharge.description,
              sku: surcharge.sku ?? '',
              price: surcharge.price,
              priceWithTax: surcharge.priceWithTax,
              taxRate: surcharge.taxRate,
              taxDescription: surcharge.description
            })
          })
          // set promotions
          this.promotionsResponse = []
          order.promotions?.forEach((promotion) => {
            this.promotionsResponse.push({
              id: promotion.id ?? '',
              couponCode: promotion.couponCode ?? ''
            })
          })

          // set discounts
          this.discountsResponse = []
          order.discounts?.forEach((discount) => {
            this.discountsResponse.push({
              couponCode: this.promotionsResponse.find((promotion) => discount.adjustmentSource.indexOf(promotion.id) !== -1)?.couponCode ?? '',
              description: discount.description ?? '',
              amount: discount.amount ?? 0,
              amountWithTax: discount.amountWithTax ?? 0,
              adjustmentSource: discount.adjustmentSource ?? ''
            })
          })
          // if it's a modification, set the original order information
          if(this.orderState === 'Modifying' || this.orderState === 'Draft'){
            this.originalOrderInformation = {
              variants: order.lines.map((line) => ({
                id: line.productVariant.id as string,
                productVariantName: line.productVariant.name,
                quantity: line.quantity,
              })),
              billingAddress: order.billingAddress ? {
                fullName: order.billingAddress.fullName ?? '',
                company: order.billingAddress.company ?? '',
                streetLine1: order.billingAddress.streetLine1 ?? '',
                streetLine2: order.billingAddress.streetLine2 ?? '',
                city: order.billingAddress.city ?? '',
                province: order.billingAddress.province ?? '',
                postalCode: order.billingAddress.postalCode ?? '',
                country: order.billingAddress.country ?? '',
                phoneNumber: order.billingAddress.phoneNumber ?? ''
              } : {
                fullName: '',
                company: '',
                streetLine1: '',
                streetLine2: '',
                city: '',
                province: '',
                postalCode: '',
                country: '',
                phoneNumber: ''
              },
              shippingAddress: order.shippingAddress ? {
                fullName: order.shippingAddress.fullName ?? '',
                company: order.shippingAddress.company ?? '',
                streetLine1: order.shippingAddress.streetLine1 ?? '',
                streetLine2: order.shippingAddress.streetLine2 ?? '',
                city: order.shippingAddress.city ?? '',
                province: order.shippingAddress.province ?? '',
                postalCode: order.shippingAddress.postalCode ?? '',
                country: order.shippingAddress.country ?? '',
                phoneNumber: order.shippingAddress.phoneNumber ?? ''
              } : {
                fullName: '',
                company: '',
                streetLine1: '',
                streetLine2: '',
                city: '',
                province: '',
                postalCode: '',
                country: '',
                phoneNumber: ''
              },
              // async get shipping method
              shippingMethod: {
                name: ''
              },
              // async get promotions
              promotions: [{
                couponCode: ''
              }]
            }
            this.modifyChecker();
          }
          // caculate `Add Payment To Order`
          // 1. total with tax - payments amount + refunds amount
          this.addPaymentToOrder = 
            order.totalWithTax - 
            (order.payments?.filter((payment) => payment.state === 'Settled').reduce((acc, payment) => acc + payment.amount, 0) ?? 0) + 
            (order.payments?.filter((payment) => payment.state === 'Settled').reduce((acc, payment) => acc + payment.refunds?.reduce((acc, refund) => acc + refund.total, 0), 0) ??  0)
        })
      ).subscribe()
  }

  searchProduct(term: string) {
    if(!term){
      return 
    }

    this.orderService.searchProduct(term).subscribe(
      (response) => {
        this.searchProducts = response.data.search.items.map((item) => ({
          productVariantId: item.productVariantId,
          productVariantName: item.productVariantName,
          sku: item.sku,
          image: item.productAsset?.preview ?? '',
          unitPrice: item.price?.__typename === 'SinglePrice' ? (item.price as { value: number }).value : 0,
          unitPriceWithTax: item.priceWithTax?.__typename === 'SinglePrice' ? (item.priceWithTax as { value: number }).value : 0,
          quantity: 1,
          total: item.price?.__typename === 'SinglePrice' ? (item.price as { value: number }).value : 0,
          totalWithTax: item.priceWithTax?.__typename === 'SinglePrice' ? (item.priceWithTax as { value: number }).value : 0
        }))
      }
    )
  }

  checkStock(stockOnHand: number | undefined, quantity: number | undefined) {
    if(!stockOnHand || !quantity){
      return false
    }
    if(!Number.isInteger(Number(quantity))){
      return false
    }
    return quantity <= stockOnHand;
  }

  openRemoveVariantModal(variantId: string) {
    this.showRemoveVariantModal = true
    this.validateForm.controls.selectedProduct.setValue({
      productVariantId: variantId,
      productVariantName: '',
      sku: '',
      image: '',
      unitPrice: 0,
      unitPriceWithTax: 0,
      quantity: 0,
      total: 0,
      totalWithTax: 0,
    })
  }

  removeVariant() {
    if(this.orderState !== 'Draft'){
      return
    }
    // remove variant from variants
    const orderLineId = this.orderLineIdAndProductVariantId.find((item) => item.productVariantId === this.validateForm.controls.selectedProduct?.getRawValue()?.productVariantId)?.orderLineId
    if(!orderLineId){
      return
    }
    this.orderService.removeDraftOrderLine(this.orderId as string, orderLineId).subscribe(
      (response) => {
        const error = response.data?.removeDraftOrderLine as { errorCode?: string; message?: string; transitionError?: string };
        if(error?.errorCode){
          this.messageService.error(error.message ?? 'An error occurred while removing the variant')
        }else{
          this.messageService.success('Variant removed successfully')
        }
        this.showRemoveVariantModal = false
        this.orderService.fetchOrder(this.orderId as string).subscribe()
      }
    )
  }
  
  addVariantToOrder(event: any) {
    if(this.orderState !== 'Draft'){
      this.messageService.error('You can only add items to a draft order')
      return
    }
    if(!event){
      return
    }
    if(this.validateForm.controls.variants.controls.findIndex((variant) => variant.getRawValue().id === event.productVariantId) === -1){
      // add item to draft order
      this.orderService.addItemToDraftOrder(this.orderId as string, {
        productVariantId: event.productVariantId,
        quantity: 1
      }).subscribe(
        (response) => {
          const error = response.data?.addItemToDraftOrder as { errorCode?: string; message?: string; transitionError?: string };
          if(error?.errorCode){
            this.messageService.error(error.message ?? 'An error occurred while adding the variant')
          }else{
            this.messageService.success('Variant added successfully')
          }
          this.orderService.fetchOrder(this.orderId as string).subscribe()
        }
      )
    }
  }

  adjustDraftOrderLine(productVariantId: string, quantity: number) {
    if(this.orderState !== 'Draft'){
      return
    }
    if(!quantity){
      return
    }
    const orderLineId = this.orderLineIdAndProductVariantId.find((item) => item.productVariantId === productVariantId)?.orderLineId
    if(!orderLineId){
      return
    }
    this.orderService.adjustDraftOrderLine(this.orderId as string, {
      orderLineId: orderLineId,
      quantity: +quantity
    }).subscribe(
      (response) => {
        const error = response.data?.adjustDraftOrderLine as { errorCode?: string; message?: string; transitionError?: string };
        if(error?.errorCode){
          this.messageService.error(error.message ?? 'An error occurred while adjusting the variant')
        }
        this.orderService.fetchOrder(this.orderId as string).subscribe()
      }
    )
  }

  getCustomers(){
    this.customerService.fetchCustomers().subscribe(
      (response) => {
        // push data to this.customers
        response.data.customers.items.forEach((customer) => {
          const existingIndex = this.validateForm.controls.customers.controls.findIndex(existingCustomer => existingCustomer.getRawValue().id === customer.id);
          if (existingIndex === -1) {
            // Add new customer
            this.validateForm.controls.customers.push(this.formBuilder.control({
              id: customer.id,
              title: customer.title ?? '',
              firstName: customer.firstName ?? '',
              lastName: customer.lastName ?? '',
              email: customer.emailAddress ?? '',
              phone: customer.phoneNumber ?? ''
            }));
          }
        })
      })
  }

  openShippingMethodSelectModal(){
    if(this.validateForm.controls.eligibleShippingMethods.length > 0){
      this.showShippingMethodSelectModal = true
    }else{
      this.messageService.error("No eligible shipping methods")
    }
  }

  selectShippingMethod(shippingMethodValue: any) {
    this.validateForm.controls.selectedShippingMethod.setValue(shippingMethodValue.getRawValue());
  }

  searchCustomers(){
    const searchCustomers = this.validateForm.controls.customers.controls.filter((customer) => customer.getRawValue().firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) || customer.getRawValue().lastName.toLowerCase().includes(this.searchTerm.toLowerCase()))
    return searchCustomers
  }

  addCustomer() {
    this.validateForm.controls.customer.markAllAsTouched()
    this.validateForm.controls.customer.updateValueAndValidity()
    this.validateForm.controls.customer.validate()

    if(this.validateForm.controls.customer.valid){
      // add to customers list
      this.validateForm.controls.customers.push(this.formBuilder.control({
        id: `local-${v4()}`,
        title: this.validateForm.controls.customer.getRawValue().title ?? '',
        firstName: this.validateForm.controls.customer.getRawValue().firstName ?? '',
        lastName: this.validateForm.controls.customer.getRawValue().lastName ?? '',
        email: this.validateForm.controls.customer.getRawValue().email ?? '',
        phone: this.validateForm.controls.customer.getRawValue().phone ?? ''
      }))
      this.showAddCustomerModal = false;
      this.validateForm.controls.customer.reset()
    }
  }

  onCustomerSelect(customer: any){
    if(customer?.id.indexOf('local-') > -1){
      this.validateForm.controls.addresses.clear()
      return
    }else{
      this.customerService.getCustomerAddresses(customer?.id).subscribe(
        (response) => {
          this.validateForm.controls.addresses.clear()

          if(!response.data.customer?.addresses){
            return
          }

          // init addresses
          response.data.customer?.addresses.forEach((address) => {
            this.validateForm.controls.addresses.push(this.formBuilder.control({
              fullName: address.fullName ?? '',
              company: address.company ?? '',
              streetLine1: address.streetLine1 ?? '',
              streetLine2: address.streetLine2 ?? '',
              city: address.city ?? '',
              province: address.province ?? '',
              postalCode: address.postalCode ?? '',
              country: address.country.code ?? '',
              phoneNumber: address.phoneNumber ?? ''
            }))
          })
        }
      )
    }
  }

  addAddress() {
    console.log(this.validateForm.controls.address.value)
    this.validateForm.controls.address.markAllAsTouched()
    this.validateForm.controls.address.updateValueAndValidity()
    this.validateForm.controls.address.validate()
    
    if(this.validateForm.controls.address.valid){
      // add to addresses list
      this.validateForm.controls.addresses.push(this.formBuilder.control({
        fullName: this.validateForm.controls.address.getRawValue().fullName ?? '',
        company: this.validateForm.controls.address.getRawValue().company ?? '',
        streetLine1: this.validateForm.controls.address.getRawValue().streetLine1 ?? '',
        streetLine2: this.validateForm.controls.address.getRawValue().streetLine2 ?? '',
        city: this.validateForm.controls.address.getRawValue().city ?? '',
        province: this.validateForm.controls.address.getRawValue().province ?? '',
        postalCode: this.validateForm.controls.address.getRawValue().postalCode ?? '',
        country: this.validateForm.controls.address.getRawValue().country ?? '',
        phoneNumber: this.validateForm.controls.address.getRawValue().phoneNumber ?? ''
      }))
      this.showAddAddressModal = false;
      this.validateForm.controls.address.reset()
    }
  }

  getAddresses() {
    const searchAddresses = this.validateForm.controls.addresses.controls.filter((address) => address.getRawValue().fullName.toLowerCase().includes(this.searchTerm.toLowerCase()))
    return searchAddresses
  }

  onSameAddressChange() {
    const sameAddress = this.validateForm.controls.sameAddress.getRawValue()
    if(!this.validateForm.controls.selectedBillingAddress.getRawValue() && sameAddress){
      this.messageService.error('Please select a billing address first')
      this.validateForm.controls.sameAddress.setValue(false)
      return
    }else if(!sameAddress){
      return
    }
    this.validateForm.controls.selectedShippingAddress.setValue(this.validateForm.controls.selectedBillingAddress.getRawValue())
  }
  
  onBillingAddressSelect() {
    const sameAddress = this.validateForm.controls.sameAddress.getRawValue()
    if(sameAddress){
      this.validateForm.controls.selectedShippingAddress.setValue(this.validateForm.controls.selectedBillingAddress.getRawValue())
    }
  }

  getEligibleShippingMethodsForDraftOrder(){
    this.orderService.eligibleShippingMethodsForDraftOrder(this.orderId as string).subscribe(
      (response) => {
        this.validateForm.controls.eligibleShippingMethods.clear()
        response.data.eligibleShippingMethodsForDraftOrder
        .forEach((shippingMethod) => {
          this.validateForm.controls.eligibleShippingMethods.push(this.formBuilder.control({
            id: shippingMethod.id,
            price: shippingMethod.price,
            priceWithTax: shippingMethod.priceWithTax,
            code: shippingMethod.code,
            name: shippingMethod.name,
            description: shippingMethod.description
          }))
        })
      }
    )
  }

  searchShippingMethodsByName() {
    const searchShippingMethods = this.validateForm.controls.eligibleShippingMethods.controls.filter((shippingMethod) => shippingMethod.getRawValue().name.toLowerCase().includes(this.searchTerm.toLowerCase()))
    return searchShippingMethods
  }

  getPromotions() {
    this.promotionService.getCouponCodeSelectorPromotionList('').subscribe(
      (response) => {
        this.validateForm.controls.promotions.clear()
        response.data.promotions.items
        .forEach((promotion) => {
          this.validateForm.controls.promotions.push(this.formBuilder.control({
            id: promotion.id,
            name: promotion.name,
            couponCode: promotion.couponCode ?? ''
          }))
        })
      }
    )
  }

  searchPromotions() {
    const searchPromotions = this.validateForm.controls.promotions.controls.filter((promotion) => promotion.getRawValue().couponCode.toLowerCase().includes(this.searchTerm.toLowerCase()))
    return searchPromotions
  }

  saveDraftOrder() {
    console.log(`save draft order`)
    this.finishSave = true
    const customerId = this.validateForm.controls.selectedCustomer.value?.id;
    
    let setCustomer$;
    if(customerId){
      console.log(`set customer for draft order`)
      setCustomer$ = this.orderService.setCustomerForDraftOrder(
        customerId.indexOf("local-") > -1 ? "" : customerId,
        this.orderId as string,
        {
          title: this.validateForm.controls.selectedCustomer.getRawValue()?.title ?? '',
          firstName: this.validateForm.controls.selectedCustomer.getRawValue()?.firstName ?? '',
          lastName: this.validateForm.controls.selectedCustomer.getRawValue()?.lastName ?? '',
          emailAddress: this.validateForm.controls.selectedCustomer.getRawValue()?.email ?? '',
          phoneNumber: this.validateForm.controls.selectedCustomer.getRawValue()?.phone ?? ''
        }
      )
    }else{
      setCustomer$ = of(null)
    }

    setCustomer$.pipe(
      mergeMap(() => {
        console.log(`set customer for draft order begin`)
        if(this.validateForm.controls.selectedBillingAddress.getRawValue()){
          console.log(`add billing address to draft order`)
          return this.orderService.setDraftOrderBillingAddress(this.orderId as string, {
            fullName: this.validateForm.controls.selectedBillingAddress.getRawValue()?.fullName ?? '',
            company: this.validateForm.controls.selectedBillingAddress.getRawValue()?.company ?? '',
            streetLine1: this.validateForm.controls.selectedBillingAddress.getRawValue()?.streetLine1 ?? '',
            streetLine2: this.validateForm.controls.selectedBillingAddress.getRawValue()?.streetLine2 ?? '',
            city: this.validateForm.controls.selectedBillingAddress.getRawValue()?.city ?? '',
            province: this.validateForm.controls.selectedBillingAddress.getRawValue()?.province ?? '',
            postalCode: this.validateForm.controls.selectedBillingAddress.getRawValue()?.postalCode ?? '',
            countryCode: this.validateForm.controls.selectedBillingAddress.getRawValue()?.country ?? '',
            phoneNumber: this.validateForm.controls.selectedBillingAddress.getRawValue()?.phoneNumber ?? ''
          })
        }else{
          return of(null) 
        }
      }),
      mergeMap(() => {
        console.log(`set shipping address for draft order begin`)
        if(this.validateForm.controls.selectedShippingAddress.getRawValue()){
          console.log(`add shipping address to draft order`)
          return this.orderService.setDraftOrderShippingAddress(this.orderId as string, {
            fullName: this.validateForm.controls.selectedShippingAddress.getRawValue()?.fullName ?? '',
            company: this.validateForm.controls.selectedShippingAddress.getRawValue()?.company ?? '',
            streetLine1: this.validateForm.controls.selectedShippingAddress.getRawValue()?.streetLine1 ?? '',
            streetLine2: this.validateForm.controls.selectedShippingAddress.getRawValue()?.streetLine2 ?? '',
            city: this.validateForm.controls.selectedShippingAddress.getRawValue()?.city ?? '',
            province: this.validateForm.controls.selectedShippingAddress.getRawValue()?.province ?? '',
            postalCode: this.validateForm.controls.selectedShippingAddress.getRawValue()?.postalCode ?? '',
            countryCode: this.validateForm.controls.selectedShippingAddress.getRawValue()?.country ?? '',
            phoneNumber: this.validateForm.controls.selectedShippingAddress.getRawValue()?.phoneNumber ?? ''
          })
        }else{
          return of(null)
        }
      }),
      mergeMap(() => {
        console.log(`set shipping method for draft order begin`)
        if(this.validateForm.controls.selectedShippingMethod.getRawValue()){
          console.log(`add shipping method to draft order`)
          return this.orderService.setDraftOrderShippingMethod(this.orderId as string, this.validateForm.controls.selectedShippingMethod.getRawValue()?.id ?? '')
        }else{
          return of(null)
        }
      }),
      mergeMap(()=> {
        console.log(`remove coupon code from draft order begin`)
        if(this.originalOrderInformation.promotions && this.originalOrderInformation.promotions.length > 0){
          console.log(`remove coupon code from draft order`)
          return from(this.originalOrderInformation.promotions).pipe(
            concatMap(promotion => 
              this.orderService.removeCouponCodeFromDraftOrder(this.orderId as string, promotion.couponCode ?? '')
            ),
            takeLast(1)
          )
        }else{
          return of(null)
        }
      }),
      mergeMap(() => {
        console.log(`set promotions for draft order begin`)
        if(this.validateForm.controls.selectedPromotions.getRawValue() && this.validateForm.controls.selectedPromotions.getRawValue().length > 0){
          console.log(`apply promotions to draft order`)
          return from(this.validateForm.controls.selectedPromotions.getRawValue()).pipe(
            concatMap(promotion => 
              this.orderService.applyCouponCodeToDraftOrder(this.orderId as string, promotion.couponCode ?? '')
            ),
            takeLast(1)
          )
        }else{
          return of(null)
        }
      }),
      finalize(() => {
        console.log(`finish saving draft order`);
        this.finishSave = false;
        this.orderService.fetchOrder(this.orderId as string).subscribe()
      })
    ).subscribe(() => {
      console.log(`subscribe to save draft order`)
    })
  }

  completeDraftOrder() {
    this.completeDraft = true
    this.orderService.transitionOrderToState(this.orderId as string, 'ArrangingPayment').subscribe(
      (response) => {
        const error = response.data?.transitionOrderToState as { errorCode?: string; message?: string; transitionError?: string };
        if(error?.errorCode) {
          this.messageService.error(error.message || 'An error occurred');
        }else{
          this.messageService.success('Order completed successfully')
          this.orderService.fetchOrder(this.orderId as string).subscribe()
        }
        this.showCompleteDraftModal = false
        this.completeDraft = false
      }
    )
  }

  cancelOrder(){
    console.log(`cancel order`)
    this.validateForm.controls.cancelOrder.markAllAsTouched()
    this.validateForm.controls.cancelOrder.updateValueAndValidity()
    this.validateForm.controls.cancelOrder.validate()

    if(this.validateForm.controls.cancelOrder.valid){
      this.orderService.cancelOrder({
        // Specify whether the shipping charges should also be cancelled. Defaults to false
        cancelShipping: true,
        orderId: this.orderId as string,
        reason: this.validateForm.controls.cancelOrder.getRawValue()?.reason ?? ''
      }).subscribe((response) => {
        const error = response.data?.cancelOrder as { errorCode?: string; message?: string; transitionError?: string };
        if(error?.errorCode) {
          this.messageService.error(error.message || 'An error occurred');
        }else{
          this.messageService.success('Order cancelled successfully')
        }
        this.showCancelOrderModal = false
        this.orderService.fetchOrder(this.orderId as string).subscribe()
      })
    }
  }

  manuallyTransitionOrderState(){
    console.log(`manually transition order state`)
    this.validateForm.controls.transitionOrderState.markAllAsTouched()
    this.validateForm.controls.transitionOrderState.updateValueAndValidity()
    this.validateForm.controls.transitionOrderState.validate()

    if(this.validateForm.controls.transitionOrderState.valid){
      this.orderService.transitionOrderToState(
        this.orderId as string, this.validateForm.controls.transitionOrderState.getRawValue()?.state ?? ''
      ).subscribe((response) => {
          const error = response.data?.transitionOrderToState as { errorCode?: string; message?: string; transitionError?: string };
          if(error?.errorCode) {
            this.messageService.error(error.message || 'An error occurred');
          }else{
            this.messageService.success('Order state transitioned successfully')
          }
          this.showTransitionStateModal = false
          this.orderService.fetchOrder(this.orderId as string).subscribe()
        }
      )
    }
  }

  pickOrderStateColor(state: string) {
    if (state === 'Draft' || state === 'Cancelled') {
      return 'red'
    }
    if (state === "PaymentSettled" || state === "ArrangingAdditionalPayment" || state == "Modifying"){
      return 'yellow'
    }
    if (state === "Delivered"){
      return 'green'
    }
    return 'orange'
  }

  pickPaymentOrFulfillmentStateColor(state: string) {
    if (state === 'Settled' || state === 'Delivered') {
      return 'green'
    }
    if (state === 'Pending' || state === "Shipped") {
      return 'yellow'
    }
    if (state === 'Cancelled') {
      return 'red'
    }
    return 'default'
  }

  deleteDraftOrder(){
    this.orderService.deleteDraftOrder(this.orderId as string).subscribe({
      next: (response) => {
        this.messageService.success('Draft order deleted successfully')
        this.showRemoveDraftOrderModal = false
        this.router.navigate(['/admin/orders/']);
      },
      error: (error) => {
        this.messageService.error('Failed to delete draft order')
        this.showRemoveDraftOrderModal = false
      }
    })
  }

  openAddPaymentModal(){
    this.showAddPaymentModal = true
    console.log('open add payment modal')
    this.paymentService.getPaymentMethods({take: 999}).subscribe(
      (response) => {
        this.paymentMethodCodes = response.data.paymentMethods.items.map((paymentMethod) => paymentMethod.code)
      }
    )
  }

  addManualPaymentToOrder(){
    this.validateForm.controls.addManualPaymentToOrder.markAllAsTouched()
    this.validateForm.controls.addManualPaymentToOrder.updateValueAndValidity()
    this.validateForm.controls.addManualPaymentToOrder.validate()

    if(this.validateForm.controls.addManualPaymentToOrder.valid){
      this.orderService.addManualPaymentToOrder({
        orderId: this.orderId as string,
        metadata: {},
        method: this.validateForm.controls.addManualPaymentToOrder.getRawValue()?.paymentMethodCode ?? '',
        transactionId: this.validateForm.controls.addManualPaymentToOrder.getRawValue()?.transactionId ?? ''
      }).subscribe(
        (response) => {
          const error = response.data?.addManualPaymentToOrder as { errorCode?: string; message?: string; transitionError?: string };
          if(error?.errorCode) {
            this.messageService.error(error.message || 'An error occurred');
          }else{
            this.messageService.success('Manual payment added successfully, loading order...', {nzDuration: 3000})
          }
          this.showAddPaymentModal = false
          this.validateForm.controls.addManualPaymentToOrder.reset()
          // delay 3 seconds to fetch order because we need to wait for the fulfillment to be created if we enable `Marketplace Plugin`
          setTimeout(() => {
            this.orderService.fetchOrder(this.orderId as string).subscribe()
          }, 3000)
        }
      )
    }
  }

  cancelPayment(){
    this.validateForm.controls.cancelPayment.markAllAsTouched()
    this.validateForm.controls.cancelPayment.updateValueAndValidity()
    this.validateForm.controls.cancelPayment.validate()

    if(this.validateForm.controls.cancelPayment.valid){
      this.paymentService.cancelPayment(this.validateForm.controls.cancelPayment.getRawValue()?.id ?? '').subscribe(
        (response) => {
        const error = response.data?.cancelPayment as { errorCode?: string; message?: string; transitionError?: string };
        if(error?.errorCode) {
          this.messageService.error(error.message || 'An error occurred');
        }else{
          this.messageService.success('Payment cancelled successfully')
          }
          this.showCancelPaymentModal = false
          this.orderService.fetchOrder(this.orderId as string).subscribe()
        }
      )
    }
  }

  transitionOrderStateToArrangingAdditionalPayment(){
    this.orderService.transitionOrderToState(this.orderId as string, 'ArrangingAdditionalPayment').subscribe(
      (response) => {
        const error = response.data?.transitionOrderToState as { errorCode?: string; message?: string; transitionError?: string };
        if(error?.errorCode) {
          this.messageService.error(error.message || 'An error occurred');
        }else{
          this.messageService.success('Order state transitioned to ArrangingAdditionalPayment successfully')
        }
        this.showArrangeAdditionalPaymentModal = false
        this.orderService.fetchOrder(this.orderId as string).subscribe()
      }
    )
  }

  transitionFulfillmentToState(state: string){
    this.orderService.transitionFulfillmentToState(this.validateForm.controls.fulfillmentMarkLine.getRawValue()?.id ?? '', state).subscribe(
      (response) => {
        const error = response.data?.transitionFulfillmentToState as { errorCode?: string; message?: string; transitionError?: string };
        if(error?.errorCode) {
          this.messageService.error(error.message || 'An error occurred');
        }else{
          this.messageService.success('Fulfillment state transitioned successfully')
        }
        this.showTransitionFulfillmentStateModal = false
        this.orderService.fetchOrder(this.orderId as string).subscribe()
      }
    )
  }

  cancelDeliveredOrder(){
    console.log(`cancel delivered order`)
    this.validateForm.controls.cancelDeliveredOrder.markAllAsTouched()
    this.validateForm.controls.cancelDeliveredOrder.updateValueAndValidity()
    this.validateForm.controls.cancelDeliveredOrder.validate()
    
    if(this.validateForm.controls.cancelDeliveredOrder.valid){
      if(this.validateForm.controls.cancelEntireOrder.value){
        this.orderService.cancelOrder({
          cancelShipping: true,
          orderId: this.orderId as string,
          reason: this.validateForm.controls.cancelDeliveredOrder.getRawValue()?.reason ?? ''
        }).subscribe(
          (response) => {
            const error = response.data?.cancelOrder as { errorCode?: string; message?: string; transitionError?: string };
            if(error?.errorCode) {
              this.messageService.error(error.message || 'An error occurred');
            }else{
              this.messageService.success('Order cancelled successfully')
            }
            this.showCancelDeliveredOrderModal = false
            this.orderService.fetchOrder(this.orderId as string).subscribe()
          }
        )
      }else{
        // contact lines
        // 1. iterate variants 
        // 2. get order line id
        // 3. get order line quantity
        const lines: OrderLineInput[] = []
        this.validateForm.controls.variants.controls.forEach((variant) => {
          const quantity = variant.getRawValue().quantity
          const orderLineId = this.orderLineIdAndProductVariantId.find((item) => item.productVariantId === variant.getRawValue().id)?.orderLineId
          lines.push({
            orderLineId: orderLineId as string,
            quantity: +quantity
          })
        })
        this.orderService.cancelOrder({
          cancelShipping: true,
          orderId: this.orderId as string,
          reason: this.validateForm.controls.cancelDeliveredOrder.getRawValue()?.reason ?? '',
          lines: lines
        }).subscribe(
          (response) => {
            const error = response.data?.cancelOrder as { errorCode?: string; message?: string; transitionError?: string };
            if(error?.errorCode) {
              this.messageService.error(error.message || 'An error occurred');
            }else{
              this.messageService.success('Order items cancelled successfully')
            }
            this.showCancelDeliveredOrderModal = false
            this.orderService.fetchOrder(this.orderId as string).subscribe()
          }
        )
      }
    }
  }

  refundAndCancelOrder(){
    // get all refund amounts
    const refundAmounts = this.validateForm.controls.refundAndCancelOrders.controls.filter((control) => control.getRawValue().selected).map((control) => control.getRawValue().refundAmount)
    const paymentsWithRefunds = refundAmounts.reduce((total, amount) => total + (Number(amount) ?? 0), 0)
    // get cancel order amount
    const totalRefundAmount = this.validateForm.controls.cancelOrder.controls.amount.value
    // compare total refund amount and cancel order amount
    if(totalRefundAmount !== paymentsWithRefunds){
      this.messageService.error('Please specify refund amounts that equal the refund total.')
      return
    }

    const linesInCancelOrder: OrderLineInput[] = []
    const linesInRefundOrder: OrderLineInput[] = []
    this.validateForm.controls.variants.controls.forEach((variant) => {
      const refund = variant.getRawValue().refund
      const orderLineId = this.orderLineIdAndProductVariantId.find((item) => item.productVariantId === variant.getRawValue().id)?.orderLineId
      if(variant.getRawValue().returnToStock){
        linesInCancelOrder.push({
          orderLineId: orderLineId as string,
          quantity: +refund
        })
      }
      
      linesInRefundOrder.push({
        orderLineId: orderLineId as string,
        quantity: +refund
      })
    })
    
    this.orderService.cancelOrder({
      cancelShipping: this.validateForm.controls.cancelOrder.controls.cancelShipping.value ?? false,
      orderId: this.orderId as string,
      reason: this.validateForm.controls.cancelOrder.controls.reason.value ?? '',
      lines: linesInCancelOrder
    }).pipe(
      mergeMap(() => {
          return from(this.validateForm.controls.refundAndCancelOrders.controls).pipe(
            filter((control) => control.getRawValue().selected),
            concatMap((control) => {
            const payment = control.getRawValue()
            return this.orderService.refundOrder({
            adjustment: 0,
            amount: (payment.refundAmount ?? 0 ) * 100,
            lines: linesInRefundOrder,
            paymentId: payment.paymentId ?? '',
              reason:this.validateForm.controls.cancelOrder.controls.reason.value ?? '',
              shipping: 0
            })
          })
        )
      })
    ).subscribe(
      (response) => {
        const error = response.data?.refundOrder as { errorCode?: string; message?: string; transitionError?: string };
        if(error?.errorCode) {
          this.messageService.error(error.message || 'An error occurred');
        }else{
          this.messageService.success('Refunded and cancelled order successfully')
        }
        this.showRefundAndCancelOrderModal = false
        this.orderService.fetchOrder(this.orderId as string).subscribe()
      }
    )
  }

  updateRefundAmount() {
    const totalRefundAmount = this.validateForm.controls.variants.controls.reduce((total, variant) => {
      const refundQuantity = +variant.getRawValue().refund || 0;
      const price = variant.getRawValue().price / 100;
      return total + (refundQuantity * price);
    }, 0);
    
    this.validateForm.controls.cancelOrder.controls.amount.setValue(totalRefundAmount);
  }

  hasRefunds(): boolean {
    return this.validateForm.controls.payments.value.some(payment => payment.refunds?.length > 0);
  }

  paymentsWithRefunds() {
    return this.validateForm.controls.payments.value.filter(payment => payment.refunds?.length > 0);
  }

  allRefunds() {
    return ([] as any[]).concat(...this.paymentsWithRefunds().map(payment => payment.refunds || []));
  }

  settleRefund(){
    this.validateForm.controls.settleRefund.markAllAsTouched()
    this.validateForm.controls.settleRefund.updateValueAndValidity()
    this.validateForm.controls.settleRefund.validate()

    if(this.validateForm.controls.settleRefund.valid){
      this.paymentService.settleRefund({
        id: this.validateForm.controls.settleRefund.getRawValue()?.id ?? '',
        transactionId: this.validateForm.controls.settleRefund.getRawValue()?.transactionId ?? ''
    }).subscribe(
      (response) => {
          const error = response.data?.settleRefund as { errorCode?: string; message?: string; transitionError?: string };
          if(error?.errorCode) {
            this.messageService.error(error.message || 'An error occurred');
          }else{
            this.messageService.success('Refund settled successfully')
          }
          this.showSettleRefundModal = false
          this.validateForm.controls.settleRefund.reset()
          this.orderService.fetchOrder(this.orderId as string).subscribe()
        }
      )
    }
  }

  addSurcharge(){
    this.validateForm.controls.tempSurcharge.markAllAsTouched()
    this.validateForm.controls.tempSurcharge.updateValueAndValidity()
    this.validateForm.controls.tempSurcharge.validate()

    if(this.validateForm.controls.tempSurcharge.valid){
      this.validateForm.controls.surcharges.push(this.formBuilder.group<SurchargeForm>({
        description: this.formBuilder.control(this.validateForm.controls.tempSurcharge.getRawValue()?.description ?? ''),
        sku: this.formBuilder.control(this.validateForm.controls.tempSurcharge.getRawValue()?.sku ?? ''),
        price: this.formBuilder.control(this.validateForm.controls.tempSurcharge.getRawValue()?.price ?? 0),
        includesTax: this.formBuilder.control(this.validateForm.controls.tempSurcharge.getRawValue()?.includesTax ?? false),
        taxRate: this.formBuilder.control(this.validateForm.controls.tempSurcharge.getRawValue()?.taxRate ?? 0),
        taxDescription: this.formBuilder.control(this.validateForm.controls.tempSurcharge.getRawValue()?.taxDescription ?? '')
      }))
      this.validateForm.controls.tempSurcharge.reset()
    }
  }

  modifyChecker() {
    if (this.orderState === 'Modifying') {
      this.modifyCheckerTimer = setInterval(() => {
        // check if the order is modifying
        // check variants
        this.validateForm.controls.variants.controls.forEach((variant) => {
          const originalVariant = this.originalOrderInformation.variants.find(
            (item) => item.id === variant.getRawValue().id
          );
          if (variant.getRawValue().quantity !== originalVariant?.quantity) {
            // check if the variant is already in the modification order information
            const existingVariantIndex = this.modificationOrderInformation.variants.findIndex(
              item => item.id === variant.getRawValue().id
            );
  
            const modificationQuantity = originalVariant?.quantity 
              ? variant.getRawValue().quantity - originalVariant.quantity
              : variant.getRawValue().quantity;
  
            // if the variant is already in the modification order information, update the quantity; otherwise, add the variant
            if (existingVariantIndex !== -1) {
              console.log(`variant already in modification order information`);
              // Update existing variant
              this.modificationOrderInformation.variants[existingVariantIndex] = {
                id: variant.getRawValue().id,
                productVariantName: variant.getRawValue().productVariantName,
                modificationQuantity: modificationQuantity
              };
            } else {
              console.log(`adding variant to modification order information`);
              // Add new variant
              this.modificationOrderInformation.variants.push({
                id: variant.getRawValue().id,
                productVariantName: variant.getRawValue().productVariantName,
                modificationQuantity: modificationQuantity
              });
            }
          }
        });

        // check billing address
        const addressFields = [
          'fullName',
          'city', 
          'company',
          'streetLine1',
          'streetLine2',
          'postalCode',
          'province',
          'country',
          'phoneNumber'
        ] as const;

        addressFields.forEach(field => {
          if (this.validateForm.controls.selectedBillingAddress.getRawValue()?.[field] !== this.originalOrderInformation.billingAddress[field]) {
            console.log(`billing address changed`);
            this.modificationOrderInformation.billingAddress[field] = {
              fromName: this.originalOrderInformation.billingAddress[field],
              toName: this.validateForm.controls.selectedBillingAddress.getRawValue()?.[field] ?? ''
            };
          }
        });

        // check shipping address
        const shippingAddressFields = [
          'fullName',
          'city',
          'company', 
          'streetLine1',
          'streetLine2',
          'postalCode',
          'province',
          'country',
          'phoneNumber'
        ] as const;

        shippingAddressFields.forEach(field => {
          if (this.validateForm.controls.selectedShippingAddress.getRawValue()?.[field] !== this.originalOrderInformation.shippingAddress[field]) {
            console.log(`shipping address changed`);
            this.modificationOrderInformation.shippingAddress[field] = {
              fromName: this.originalOrderInformation.shippingAddress[field],
              toName: this.validateForm.controls.selectedShippingAddress.getRawValue()?.[field] ?? ''
            };
          }
        });

        // check shipping method
        if (this.validateForm.controls.selectedShippingMethod.getRawValue()?.name !== this.originalOrderInformation.shippingMethod.name) {
          console.log(`shipping method changed`);
          this.modificationOrderInformation.shippingMethod.fromName = this.originalOrderInformation.shippingMethod.name;
          this.modificationOrderInformation.shippingMethod.toName = this.validateForm.controls.selectedShippingMethod.getRawValue()?.name ?? '';
        }

        // check promotions
        // Get selected promotions and original promotions
        const selectedPromotions = this.validateForm.controls.selectedPromotions.getRawValue() || [];
        const originalPromotions = this.originalOrderInformation.promotions || [];
        // Check for promotions to add (exist in selected but not in original)
        selectedPromotions.forEach(selected => {
          const existsInOriginal = originalPromotions.some(original => 
            original.couponCode === selected.couponCode
          );
          
          if (!existsInOriginal) {
            if (!this.modificationOrderInformation.promotions.some(
              promotion => promotion.couponCode === selected.couponCode
            )) {
              this.modificationOrderInformation.promotions.push({
                couponCode: selected.couponCode,
                removeOrAdd: 'add'
              });
            }
          }else{
            // Check for promotions to remove from modification order information(both exist in original and selected)
            this.modificationOrderInformation.promotions = this.modificationOrderInformation.promotions.filter(promotion => promotion.couponCode !== selected.couponCode);
          }
        });

        // Check for promotions to remove (exist in original but not in selected) 
        originalPromotions.forEach(original => {
          const existsInSelected = selectedPromotions.some(selected =>
            selected.couponCode === original.couponCode
          );

          if (!existsInSelected) {
            if (!this.modificationOrderInformation.promotions.some(
              promotion => promotion.couponCode === original.couponCode
            )) {
              this.modificationOrderInformation.promotions.push({
                couponCode: original.couponCode,
                removeOrAdd: 'remove'
              });
            }
          }else{
            // Check for promotions to remove from modification order information(both exist in original and selected)
            this.modificationOrderInformation.promotions = this.modificationOrderInformation.promotions.filter(promotion => promotion.couponCode !== original.couponCode);
          }

          this.modificationOrderInformation.promotions = this.modificationOrderInformation.promotions.filter(promotion => 
            this.validateForm.controls.selectedPromotions.getRawValue().some(selected => selected.couponCode === promotion.couponCode) ||
            this.originalOrderInformation.promotions.some(original => original.couponCode === promotion.couponCode)
          );
        });
      }, 1000);
    }
  }

  displayModificationBillingAddress(){
    return Object.keys(this.modificationOrderInformation.billingAddress).some(key => this.modificationOrderInformation.billingAddress[key].fromName !== this.modificationOrderInformation.billingAddress[key].toName);
  }

  displayModificationShippingAddress(){
    return Object.keys(this.modificationOrderInformation.shippingAddress).some(key => this.modificationOrderInformation.shippingAddress[key].fromName !== this.modificationOrderInformation.shippingAddress[key].toName);
  }

  displayModificationShippingMethod(){
    return this.modificationOrderInformation.shippingMethod.fromName !== this.modificationOrderInformation.shippingMethod.toName;
  }

  modifyOrder(){
   this.orderService.transitionOrderToState(this.orderId as string,'Modifying').subscribe(
      (response) => {
        const error = response.data?.transitionOrderToState as { errorCode?: string; message?: string; transitionError?: string };
        if(error?.errorCode) {
          this.messageService.error(error.message || 'An error occurred');
        }else{
          this.messageService.success('Order modified successfully')
        }
        this.showModifyOrderModal = false
        this.orderService.fetchOrder(this.orderId as string).subscribe()
      }
    )
  }

  cancelModification (){
    // 1. get order history
    // 2. find the first item
    // 3. get `from` field in the history item
    // 4. transition order to the `from` field state
    this.orderService.getOrderHistory(this.orderId as string).subscribe(
      (response) => {
        const history = response.data?.order?.history?.items
        const fromState = history?.[0]?.data?.from
        this.orderService.transitionOrderToState(this.orderId as string, fromState as string).subscribe(
          (response) => {
            const error = response.data?.transitionOrderToState as { errorCode?: string; message?: string; transitionError?: string };
            if(error?.errorCode) {
              this.messageService.error(error.message || 'An error occurred');
            }else{
              this.messageService.success('Modification cancelled successfully')
            }
            // reset modification order information
            this.modifyCheckerTimer = {
                variants: [],
                billingAddress: {fullName: { fromName: '', toName: ''}, company: { fromName: '', toName: ''}, streetLine1: { fromName: '', toName: ''}, streetLine2: { fromName: '', toName: ''}, city: { fromName: '', toName: ''}, province: { fromName: '', toName: ''}, postalCode: { fromName: '', toName: ''}, country: { fromName: '', toName: ''}, phoneNumber: { fromName: '', toName: ''}},
                shippingAddress: {fullName: { fromName: '', toName: ''}, company: { fromName: '', toName: ''}, streetLine1: { fromName: '', toName: ''}, streetLine2: { fromName: '', toName: ''}, city: { fromName: '', toName: ''}, province: { fromName: '', toName: ''}, postalCode: { fromName: '', toName: ''}, country: { fromName: '', toName: ''}, phoneNumber: { fromName: '', toName: ''}},
                shippingMethod: {fromName: '', toName: ''},
                promotions: []
            }
            this.showCancelModificationModal = false
            this.orderService.fetchOrder(this.orderId as string).subscribe()
          }
        )
      }
    )
  }

  updateRecalculateShipping(){
    this.validateForm.controls.confirmModification.controls.recalculateShipping.setValue(!this.validateForm.controls.confirmModification.controls.recalculateShipping.value)
  }


  openPreviewChangesModal(){
    this.validateForm.controls.confirmModification.markAllAsTouched()
    this.validateForm.controls.confirmModification.updateValueAndValidity()
    this.validateForm.controls.confirmModification.validate()
    console.log(`orderLineIdAndProductVariantId: ${JSON.stringify(this.orderLineIdAndProductVariantId)}`)
    if(this.validateForm.controls.confirmModification.valid){
      const lines: OrderLineInput[] = []
      this.validateForm.controls.variants.controls.forEach((variant) => {
        const quantity = variant.getRawValue().quantity
        const orderLineId = this.orderLineIdAndProductVariantId.find((item) => item.productVariantId === variant.getRawValue().id)?.orderLineId
        lines.push({
          orderLineId: orderLineId as string,
          quantity: +quantity
        })
      })
      console.log(`openPreviewChangesModal this.validateForm.controls.confirmModification.getRawValue()?.recalculateShipping: ${this.validateForm.controls.confirmModification.getRawValue()?.recalculateShipping}`)
      const couponCodes = this.validateForm.controls.selectedPromotions.getRawValue().map((promotion) => promotion.couponCode)
      this.modifyOrderRequestPayload = {
        addItems: [],
        adjustOrderLines: lines,
        couponCodes: couponCodes.length > 0 ? couponCodes : [],
        // Setting the `dryRun` input property to `true` will apply all changes, including updating the price of the Order, except history entry and additional payment actions.
        dryRun: true,
        note: this.validateForm.controls.confirmModification.getRawValue()?.note ?? '',
        options: { recalculateShipping: this.validateForm.controls.confirmModification.getRawValue()?.recalculateShipping ?? false },
        orderId: this.orderId as string,
        refunds: [],
        surcharges: this.validateForm.controls.surcharges.controls.map((surcharge) => ({
          description: surcharge.getRawValue().description,
          sku: surcharge.getRawValue().sku,
          price: +surcharge.getRawValue().price * 100,
          priceIncludesTax: surcharge.getRawValue().includesTax,
          taxRate: +surcharge.getRawValue().taxRate,
          taxDescription: surcharge.getRawValue().taxDescription
        })),
        updateBillingAddress: {
          fullName: this.validateForm.controls.selectedBillingAddress.getRawValue()?.fullName ?? '',
          company: this.validateForm.controls.selectedBillingAddress.getRawValue()?.company ?? '',
          streetLine1: this.validateForm.controls.selectedBillingAddress.getRawValue()?.streetLine1 ?? '',
          streetLine2: this.validateForm.controls.selectedBillingAddress.getRawValue()?.streetLine2 ?? '',
          city: this.validateForm.controls.selectedBillingAddress.getRawValue()?.city ?? '',
          province: this.validateForm.controls.selectedBillingAddress.getRawValue()?.province ?? '',
          postalCode: this.validateForm.controls.selectedBillingAddress.getRawValue()?.postalCode ?? '',
          countryCode: this.validateForm.controls.selectedBillingAddress.getRawValue()?.country ?? '',
          phoneNumber: this.validateForm.controls.selectedBillingAddress.getRawValue()?.phoneNumber ?? ''
        },
        updateShippingAddress: {
          fullName: this.validateForm.controls.selectedShippingAddress.getRawValue()?.fullName ?? '',
          company: this.validateForm.controls.selectedShippingAddress.getRawValue()?.company ?? '',
          streetLine1: this.validateForm.controls.selectedShippingAddress.getRawValue()?.streetLine1 ?? '',
          streetLine2: this.validateForm.controls.selectedShippingAddress.getRawValue()?.streetLine2 ?? '',
          city: this.validateForm.controls.selectedShippingAddress.getRawValue()?.city ?? '',
          province: this.validateForm.controls.selectedShippingAddress.getRawValue()?.province ?? '',
          postalCode: this.validateForm.controls.selectedShippingAddress.getRawValue()?.postalCode ?? '',
          countryCode: this.validateForm.controls.selectedShippingAddress.getRawValue()?.country ?? '',
          phoneNumber: this.validateForm.controls.selectedShippingAddress.getRawValue()?.phoneNumber ?? ''
        }
      }
      this.orderService.modifyOrder(this.modifyOrderRequestPayload).subscribe(
        async (response) => {
          const error = response.data?.modifyOrder as { errorCode?: string; message?: string; transitionError?: string };
          if(error?.errorCode) {
            this.messageService.error(error.message || 'An error occurred');
            return
          }else{
            const order = response.data?.modifyOrder as Order
            this.variantsResponse = []
            this.surchargesResponse = []
            this.shippingMethodsResponse = []
            order.lines?.forEach((line) => {
              this.orderLineIdAndProductVariantId.push({
                orderLineId: line.id,
                productVariantId: line.productVariant.id
              })
              this.variantsResponse.push({
                // TODO: image
                image: '',
                name: line.productVariant.name,
                sku: line.productVariant.sku,
                unitPrice: line.unitPrice,
                quantity: line.quantity,
                total: line.linePrice
              })
            })
            order.surcharges?.forEach((surcharge) => {
              this.surchargesResponse.push({
                description: surcharge.description,
                sku: surcharge.sku ?? '',
                price: surcharge.price,
                priceWithTax: surcharge.priceWithTax,
                taxRate: surcharge.taxRate,
                taxDescription: surcharge.description
              })
            })
            order.shippingLines?.forEach((shippingLine) => {
              this.shippingMethodsResponse.push({
                name: shippingLine.shippingMethod.name
              })
            })
            this.modificationResponse = order.modifications?.map((modification) => ({
              createdAt: modification.createdAt,
              id: modification.id,
              isSettled: modification.isSettled,
              lines: modification.lines,
              note: modification.note,
              payment: modification.payment,
              priceChange: modification.priceChange,
              refund: modification.refund,
              surcharges: modification.surcharges as any[]
            }))
            this.subTotal = order.subTotal
            this.subTotalWithTax = order.subTotalWithTax
            this.total = order.total
            this.totalWithTax = order.totalWithTax
            this.shipping = order.shipping
            this.shippingWithTax = order.shippingWithTax
            await this.checkOrderEditResult();
          }
          this.showPreviewChangesModal = true
        }
      )
    }
  }

  async checkOrderEditResult(){
    const priceDifference = this.totalWithTax - this.originalTotalWithTax;
    if (0 < priceDifference) {
      this.validateForm.controls.orderEditResult.setValue('Payment')
    } else if (priceDifference < 0) {
      this.validateForm.controls.orderEditResult.setValue('Refund')
      const refundablePayments = await this.getRefundablePayments()
      console.log(`refundablePayments: ${JSON.stringify(refundablePayments)}`)
      if (refundablePayments.length) {
        this.onPaymentSelected(refundablePayments, true);
      }
      this.refunds = refundablePayments.filter(rp => rp.selected && 0 < rp.amountToRefund)
      .map(payment => {
          return {
              reason: '',
              paymentId: payment.id,
              amount: payment.amountToRefund,
          }
      })
    } else {
      this.validateForm.controls.orderEditResult.setValue('PriceUnchanged')
    }
  }

  async getRefundablePayments(): Promise<{ id: string, refundableAmount: number, amountToRefund: number, selected: boolean }[]> {
    const settledPayments = (this.payments || []).filter(p => p.state === 'Settled');
    return settledPayments.map((payment, index) => {
            const refundableAmount =
                payment.amount -
                summate(
                    payment.refunds.filter(r => r.state !== 'Failed'),
                    'total',
                );
            return {
                id: payment.id,
                refundableAmount,
                amountToRefund: 0,
                selected: index === 0,
            };
        });
  }

  onPaymentSelected(refundablePayments: { id: string, refundableAmount: number, amountToRefund: number, selected: boolean }[], selected: boolean) {
    const priceDifference = this.totalWithTax - this.originalTotalWithTax;
    if (selected) {
        const outstandingRefundAmount =
            priceDifference * -1 -
            refundablePayments
                .filter(p => p.id !== refundablePayments[0].id)
                .reduce((total, p) => total + p.amountToRefund, 0);
        if (0 < outstandingRefundAmount) {
            refundablePayments[0].amountToRefund = Math.min(outstandingRefundAmount, refundablePayments[0].refundableAmount);
        }
    } else {
        refundablePayments[0].amountToRefund = 0;
    }
}

  cancelPreviewChanges(){
    // re-fetch so that the preview values get overwritten in the cache.
    return this.orderService.fetchOrder(this.orderId as string).subscribe((response) => {
      this.showPreviewChangesModal = false
      this.modifyOrderRequestPayload = {}
    })
  }

  previewChanges() {
    this.modifyOrderRequestPayload.dryRun = false
    if (this.validateForm.controls.orderEditResult.getRawValue() === 'Refund') {
      this.modifyOrderRequestPayload.refunds = this.refunds;
    }
    
    this.orderService.modifyOrder(this.modifyOrderRequestPayload).pipe(
      mergeMap((response) => {
        const error = response.data?.modifyOrder as { errorCode?: string; message?: string; transitionError?: string };
        if (error?.errorCode) {
          this.messageService.error(error.message || 'An error occurred');
          this.showPreviewChangesModal = false;
          this.modifyOrderRequestPayload = {};
          return of(null)
        }
        
        this.messageService.success('Order modified successfully');
        const order = response.data?.modifyOrder as Order;
        const priceDelta = order.totalWithTax - this.originalTotalWithTax;
        
        return this.orderService.getOrderHistory(this.orderId as string).pipe(
          mergeMap((historyResponse) => {
            const fromState = historyResponse.data?.order?.history?.items?.[0]?.data?.from;
            const nextState = 0 < priceDelta ? 'ArrangingAdditionalPayment' : fromState;
            return this.orderService.transitionOrderToState(this.orderId as string, nextState)
          }),
          mergeMap(() => {
            return this.orderService.fetchOrder(this.orderId as string)
          })
        );
      })
    ).subscribe(() => {
      this.showPreviewChangesModal = false;
      this.modifyOrderRequestPayload = {};
    });
  }

  checkUnfulfillQuantity(){
    // 1. iterate through variants
    // 2. check if any variant has unfulfilled quantity
    // 3. if so, set returnToStock to true
    for(const variant of this.validateForm.controls.variants.controls){
      if(variant.getRawValue().unfulfilledQuantity > 0){
        return true
      }
    }
    return false
  }

  fulfillOrder(){
    this.validateForm.controls.fulfillmentDetails.markAllAsTouched()
    this.validateForm.controls.fulfillmentDetails.updateValueAndValidity()
    this.validateForm.controls.fulfillmentDetails.validate()

    if(!this.validateForm.controls.fulfillmentDetails.valid){
      return
    }

    if(!this.checkUnfulfillQuantity()){
      this.messageService.error('All items are fulfilled')
      return
    }

    const fulfillOrderRequestPayload: FulfillOrderInput = {
      handler: {
        arguments: [],
        code: 'manual-fulfillment'
      },
      lines: [],
    }

    const lines: OrderLineInput[] = []
    this.validateForm.controls.variants.controls.forEach((variant) => {
      const quantityToFulfill = variant.getRawValue().quantityToFulfill
      const orderLineId = this.orderLineIdAndProductVariantId.find((item) => item.productVariantId === variant.getRawValue().id)?.orderLineId
      lines.push({
        orderLineId: orderLineId as string,
        quantity: +quantityToFulfill
      })
    })
    fulfillOrderRequestPayload.lines = lines


    const fulfillmentDetails = this.validateForm.controls.fulfillmentDetails.getRawValue()
    fulfillOrderRequestPayload.handler.arguments.push({
      name: 'method',
      value: fulfillmentDetails.method
    })
    fulfillOrderRequestPayload.handler.arguments.push({
      name: 'trackingCode',
      value: fulfillmentDetails.trackingCode
    })

    this.orderService.addFulfillmentToOrder(fulfillOrderRequestPayload).subscribe(
      (response) => {
        const error = response.data?.addFulfillmentToOrder as { errorCode?: string; message?: string; transitionError?: string };
        if(error?.errorCode) {
          this.messageService.error(error.message || 'An error occurred');
        }else{
          this.messageService.success('Fulfillment added successfully')
        }
        this.showFulfillOrderModal = false
        this.orderService.fetchOrder(this.orderId as string).subscribe()
      }
    )
  }

  goToSellerOrder(sellerOrderId: string){
    this.router.navigate([`/admin/orders/${sellerOrderId}`])
  }

  ngOnDestroy(): void {
    console.log('OrderDetailComponent ngOnDestroy')
    // reset form
    this.validateForm.controls.variants.clear()
    this.validateForm.controls.surcharges.clear()
    this.validateForm.controls.payments.clear()
    this.validateForm.controls.fulfillments.clear()
    this.validateForm.controls.customers.clear()
    this.validateForm.controls.addresses.clear()
    this.validateForm.controls.eligibleShippingMethods.clear()
    this.validateForm.controls.promotions.clear()
    this.validateForm.controls.selectedCustomer.reset()
    this.validateForm.controls.selectedBillingAddress.reset()
    this.validateForm.controls.selectedShippingAddress.reset()
    this.validateForm.controls.selectedShippingMethod.reset()
    this.validateForm.controls.selectedPromotions.reset()
    this.validateForm.controls.cancelOrder.reset()
    this.validateForm.controls.cancelDeliveredOrder.reset()
    this.validateForm.controls.transitionOrderState.reset()
    this.validateForm.controls.addManualPaymentToOrder.reset()
    this.validateForm.controls.refundAndCancelOrders.reset()
    this.validateForm.controls.settleRefund.reset()
    this.validateForm.controls.tempSurcharge.reset()
    this.validateForm.controls.selectedCustomer.reset()
    this.validateForm.controls.selectedBillingAddress.reset()
    this.validateForm.controls.selectedShippingAddress.reset()
    this.validateForm.controls.selectedShippingMethod.reset()
    this.validateForm.controls.fulfillmentDetails.reset()
    // reset array
    this.orderLineIdAndProductVariantId = []
    // reset object
    this.modificationOrderInformation = {
      variants: [],
      billingAddress: {fullName: { fromName: '', toName: ''}, company: { fromName: '', toName: ''}, streetLine1: { fromName: '', toName: ''}, streetLine2: { fromName: '', toName: ''}, city: { fromName: '', toName: ''}, province: { fromName: '', toName: ''}, postalCode: { fromName: '', toName: ''}, country: { fromName: '', toName: ''}, phoneNumber: { fromName: '', toName: ''}},
      shippingAddress: {fullName: { fromName: '', toName: ''}, company: { fromName: '', toName: ''}, streetLine1: { fromName: '', toName: ''}, streetLine2: { fromName: '', toName: ''}, city: { fromName: '', toName: ''}, province: { fromName: '', toName: ''}, postalCode: { fromName: '', toName: ''}, country: { fromName: '', toName: ''}, phoneNumber: { fromName: '', toName: ''}},
      shippingMethod: {fromName: '', toName: ''},
      promotions: []
    }
    // clear timer
    if(this.modifyCheckerTimer){
      clearInterval(this.modifyCheckerTimer);
    }
  }

  goBack(){
    this.router.navigate(['/admin/orders/']).then();
  }
}
