import { Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AssetGQL, AssetsGQL, CreateAssetInput, CreateAssetsGQL, LogicalOperator, SortOrder } from '../gql/shop/generated';
import { AppService } from './app.service';
import { AuthService } from './auth.service';

interface AssetState {
  loading: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  assetStore$ = createStore(
    { name: 'asset' },
    withProps<AssetState>({ loading: false }),
  );

  constructor(
    private notification: NzNotificationService,
    private createAssetsGQL: CreateAssetsGQL,
    private assetGQL: AssetGQL,
    private assetsGQL: AssetsGQL,
    private appService: AppService,
    private authService: AuthService,
  ) {}

  createAssets(input: CreateAssetInput | CreateAssetInput[]) {
    return this.appService.withLoader(() =>
      this.createAssetsGQL.mutate(
        {
          input,
        },
        {
          context: {
            headers: {
              'vendure-token':
                this.authService.authStore$.getValue().currentUserChannel
                  ?.token,
            },
          },
        },
      ),
    );
  }

  async fetchFile(source: string, name: string) {
    const response = await fetch(source, { mode: 'no-cors' });
    const blob = await response.blob();
    const file = new File([blob], name, { type: blob.type });
    return file;
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  getAsset(id: string) {
    return this.appService.withLoader(() => this.assetGQL.fetch({ id }, {
      context: {
        headers: {
          'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
        },
      },
    }))
  }

  getPaginatedAssets(pageSize: number, pageNum: number, searchValue: string) {
    return this.appService.withLoader(() => this.assetsGQL.fetch(
      {options: {skip: (pageNum - 1) * pageSize, take: pageSize, filter: {name: {contains: searchValue}}, sort: {createdAt: SortOrder.DESC }, tagsOperator: LogicalOperator.AND}},
      {
        context: {
          headers: {
            'vendure-token': this.authService.authStore$.getValue().currentUserChannel?.token,
          },
        },
      }
    ))
  }
}
