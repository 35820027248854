<form
  nz-form
  [nzLayout]="validateForm.controls.formLayout.value"
  [formGroup]="validateForm"
>

    <div class="page-container">
        <div nz-row style="margin-bottom: 16px;">
            <div nz-col nzSpan="24">
                <span nz-icon nzType="ng-zorro:arrow-left" (click)="goBack()" style="cursor: pointer;"></span>
            </div>
        </div>

        <div nz-row class="page-container-head">
            <div nz-col nzSpan="8">
                <div style="display: flex; align-items: center; gap: 8px;">
                    <h2 style="margin: 0;">Create Order</h2>
                    <nz-tag *ngIf="orderState" [nzColor]="pickOrderStateColor(orderState)" style="margin-top: 5px;">{{ orderState }}</nz-tag>
                </div>
            </div>
            <div nz-col nzSpan="16" class="page-container-toolbar">
                <!-- Draft -->
                <div class="flex-end" *ngIf="orderState === 'Draft'">
                    <button
                    nz-button
                    nzType="primary"
                    (keydown.enter)="$event.preventDefault()"
                    (click)="saveDraftOrder()"
                    [disabled]="finishSave"
                    >
                        <span nz-icon nzType="ng-zorro:add"></span>
                        Save Draft
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    nz-button
                    nzType="primary"
                    (click)="showCompleteDraftModal = true"
                    [disabled]="completeDraft"
                    >
                        Complete Draft
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    nz-button
                    nzDanger
                    nzType="primary"
                    (click)="showRemoveDraftOrderModal = true"
                    >
                        <span nz-icon nzType="ng-zorro:trash"></span>
                        Delete Draft
                    </button>
                    <nz-modal [(nzVisible)]="showRemoveDraftOrderModal" nzTitle="Remove Draft Order" (nzOnCancel)="showRemoveDraftOrderModal = false" (nzOnOk)="deleteDraftOrder()">
                        <div *nzModalContent>Please confirm you want to delete this draft order.</div>
                    </nz-modal>
                </div>
                <!-- ArrangingPayment or AddingItems -->
                <div class="flex-end" *ngIf="orderState === 'ArrangingPayment' || orderState === 'AddingItems'">
                    <button
                    *ngIf="orderState === 'ArrangingPayment' && addPaymentToOrder !== 0"
                    nz-button
                    nzType="primary"
                    (keydown.enter)="$event.preventDefault()"
                    (click)="openAddPaymentModal()"
                    >
                        Add payment to order ({{ addPaymentToOrder / 100 | currency:currencyCode }})
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    nz-button
                    nzType="primary"
                    (click)="showCancelOrderModal = true"
                    >
                        Cancel order or items
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    nz-button
                    nzType="primary"
                    (click)="showTransitionStateModal = true"
                    >
                        Manually transition to state...
                    </button>
                </div>
                <!-- PaymentSettled -->
                <div class="flex-end" *ngIf="orderState === 'PaymentSettled'">
                    <button
                    nz-button
                    nzType="primary"
                    *ngIf="showArrangeAdditionalPaymentFlag && validateForm.controls.sellerOrders.value.length === 0"
                    (click)="showArrangeAdditionalPaymentModal = true"
                    >
                        Arrange Additional Payment
                    </button>
                    <nz-modal
                        [(nzVisible)]="showArrangeAdditionalPaymentModal"
                        nzTitle="Arrange Additional Payment"
                        (nzOnCancel)="showArrangeAdditionalPaymentModal = false"
                        (nzOnOk)="transitionOrderStateToArrangingAdditionalPayment()"
                    >
                        <div *nzModalContent>
                            <p>Please confirm you want to transition the order to the "ArrangingAdditionalPayment" state.</p>
                        </div>
                    </nz-modal>
                    <div style="width: 10px;"></div>
                    <button
                    *ngIf="validateForm.controls.sellerOrders.value.length === 0"
                    nz-button
                    nzType="primary"
                    (keydown.enter)="$event.preventDefault()"
                    (click)="showFulfillOrderModal = true"
                    >
                        Fulfill order
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    *ngIf="validateForm.controls.sellerOrders.value.length === 0"
                    nz-button
                    nzType="primary"
                    (click)="showModifyOrderModal = true"
                    >
                        Modify order
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    *ngIf="showArrangeAdditionalPaymentFlag && validateForm.controls.sellerOrders.value.length === 0"
                    nz-button
                    nzType="primary"
                    (click)="showCancelDeliveredOrderModal = true"
                    >
                        Cancel order or items
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    *ngIf="validateForm.controls.sellerOrders.value.length === 0 && !showArrangeAdditionalPaymentFlag"
                    nz-button
                    nzType="primary"
                    (click)="showRefundAndCancelOrderModal = true"
                    >
                        Refund & cancel order
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                        nz-button
                        nzType="primary"
                        (click)="showTransitionStateModal = true"
                    >
                        Manually transition to state...
                    </button>
                </div>
                <!-- Arranging additional payment -->
                <div class="flex-end" *ngIf="orderState === 'ArrangingAdditionalPayment'">
                    <button
                    *ngIf="addPaymentToOrder !== 0"
                    nz-button
                    nzType="primary"
                    (keydown.enter)="$event.preventDefault()"
                    (click)="openAddPaymentModal()"
                    >
                        Add payment to order ({{ addPaymentToOrder / 100 | currency:currencyCode }})
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    *ngIf="validateForm.controls.sellerOrders.value.length === 0"
                    nz-button
                    nzType="primary"
                    (keydown.enter)="$event.preventDefault()"
                    (click)="showFulfillOrderModal = true"
                    >
                        Fulfill order
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    *ngIf="showArrangeAdditionalPaymentFlag"
                    nz-button
                    nzType="primary"
                    (click)="showCancelDeliveredOrderModal = true"
                    >
                        Cancel order or items
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    *ngIf="!showArrangeAdditionalPaymentFlag"
                    nz-button
                    nzType="primary"
                    (click)="showRefundAndCancelOrderModal = true"
                    >
                        Refund & cancel order
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    nz-button
                    nzType="primary"
                    (click)="showTransitionStateModal = true"
                    >
                        Manually transition to state...
                    </button>
                </div>
                <!-- Partially Shipped or Shipped -->
                <div class="flex-end" *ngIf="orderState === 'PartiallyShipped' || orderState === 'Shipped'">
                    <button
                    *ngIf="validateForm.controls.sellerOrders.value.length === 0"
                    nz-button
                    nzType="primary"
                    (keydown.enter)="$event.preventDefault()"
                    (click)="showFulfillOrderModal = true"
                    >
                        Fulfill order
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    *ngIf="validateForm.controls.sellerOrders.value.length === 0"
                    nz-button
                    nzType="primary"
                    (click)="showModifyOrderModal = true"
                    >
                        Modify order
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    *ngIf="validateForm.controls.sellerOrders.value.length === 0"
                    nz-button
                    nzType="primary"
                    (click)="showRefundAndCancelOrderModal = true"
                    >
                        Refund & cancel order
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    nz-button
                    nzType="primary"
                    (click)="showTransitionStateModal = true"
                    >
                        Manually transition to state...
                    </button>
                </div>
                <!-- Partially Delivered -->
                <div class="flex-end" *ngIf="orderState === 'PartiallyDelivered'">
                    <button
                    *ngIf="validateForm.controls.sellerOrders.value.length === 0"
                    nz-button
                    nzType="primary"
                    (keydown.enter)="$event.preventDefault()"
                    (click)="showFulfillOrderModal = true"
                    >
                        Fulfill order
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    *ngIf="validateForm.controls.sellerOrders.value.length === 0"
                    nz-button
                    nzType="primary"
                    (click)="showModifyOrderModal = true"
                    >
                        Modify order
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    *ngIf="!showArrangeAdditionalPaymentFlag"
                    nz-button
                    nzType="primary"
                    (click)="showRefundAndCancelOrderModal = true"
                    >
                        Refund & cancel order
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    nz-button
                    nzType="primary"
                    (click)="showTransitionStateModal = true"
                    >
                        Manually transition to state...
                    </button>
                </div>
                <!-- Delivered -->
                <div class="flex-end" *ngIf="orderState === 'Delivered'">
                    <button
                    *ngIf="validateForm.controls.sellerOrders.value.length === 0"
                    nz-button
                    nzType="primary"
                    (keydown.enter)="$event.preventDefault()"
                    (click)="showFulfillOrderModal = true"
                    >
                        Fulfill order
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    *ngIf="validateForm.controls.sellerOrders.value.length === 0"
                    nz-button
                    nzType="primary"
                    (click)="showRefundAndCancelOrderModal = true"
                    >
                        Refund & cancel order
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    nz-button
                    nzType="primary"
                    (click)="showTransitionStateModal = true"
                    >
                        Manually transition to state...
                    </button>
                </div>
                <!-- Modifying -->
                <div class="flex-end" *ngIf="orderState === 'Modifying'">
                    <button
                    nz-button
                    nzType="primary"
                    (keydown.enter)="$event.preventDefault()"
                    (click)="showCancelModificationModal = true"
                    >
                        Cancel modification
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    nz-button
                    nzType="primary"
                    (click)="openPreviewChangesModal()"
                    >
                        Preview changes
                    </button>
                    <div style="width: 10px;"></div>
                    <button
                    nz-button
                    nzType="primary"
                    (click)="showTransitionStateModal = true"
                    >
                        Manually transition to state...
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Common Complete Draft Modal -->
    <nz-modal
        [(nzVisible)]="showCompleteDraftModal"
        nzTitle="Complete Draft"
        (nzOnCancel)="showCompleteDraftModal = false"
        (nzOnOk)="completeDraftOrder()">
        <div *nzModalContent>
            <p>Please confirm you want to complete the draft.</p>
        </div>
    </nz-modal>

    <!-- Common Remove Variant Modal -->
    <nz-modal
        [(nzVisible)]="showRemoveVariantModal"
        nzTitle="Remove Variant"
        (nzOnCancel)="showRemoveVariantModal = false"
        (nzOnOk)="removeVariant()">
        <div *nzModalContent>
            <p>Please confirm you want to remove the variant.</p>
        </div>
    </nz-modal>

    <!-- Common Shipping Method Select Picker -->
     <nz-modal
        [(nzVisible)]="showShippingMethodSelectModal"
        nzTitle="Select Shipping Method"
        (nzOnCancel)="showShippingMethodSelectModal = false"
        (nzOnOk)="showShippingMethodSelectModal = false">
        <div *nzModalContent>
            <div *ngFor="let shippingMethod of this.validateForm.controls.eligibleShippingMethods.controls" class="border-container">
                <div nz-row>
                    <nz-radio-group>
                        <label nz-radio [nzValue]="shippingMethod" (click)="selectShippingMethod(shippingMethod)" style="margin-top: 10px;">
                            Select this shipping method
                        </label>
                    </nz-radio-group>
                    <div nz-col nzSpan="24" style="margin-top: 20px;">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24"><b>Shipping Method</b></nz-form-label>
                            {{ shippingMethod.getRawValue().name }}
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="24">
                        <nz-form-item style="margin-top: 10px;">
                            <nz-form-label [nzSpan]="24"><b>Price</b></nz-form-label>
                            {{ shippingMethod.getRawValue().price / 100 | currency:currencyCode }}
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="24">
                        <nz-form-item style="margin-top: 10px;">
                            <nz-form-label [nzSpan]="24"><b>Price with tax</b></nz-form-label>
                            {{ shippingMethod.getRawValue().priceWithTax / 100 | currency:currencyCode }}
                        </nz-form-item>
                    </div>
                </div>
            </div>
        </div>
    </nz-modal>

    <!-- Common Add Payment Modal -->
    <nz-modal
        [(nzVisible)]="showAddPaymentModal"
        nzTitle="Add Payment"
        [nzWidth]="'80%'"
        (nzOnCancel)="showAddPaymentModal = false; this.validateForm.controls.addManualPaymentToOrder.reset()"
        (nzOnOk)="addManualPaymentToOrder()">
        <div *nzModalContent>
            <nz-form [formGroup]="validateForm.controls.addManualPaymentToOrder">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">Payment Method</nz-form-label>
                    <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                        <nz-select
                            nzPlaceHolder="Please select payment method"
                            [formControl]="validateForm.controls.addManualPaymentToOrder.controls.paymentMethodCode"
                        >
                            <nz-option *ngFor="let paymentMethodCode of paymentMethodCodes" [nzValue]="paymentMethodCode" [nzLabel]="paymentMethodCode"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">Transaction ID</nz-form-label>
                    <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                        <input nz-input [formControl]="validateForm.controls.addManualPaymentToOrder.controls.transactionId" />
                    </nz-form-control>
                </nz-form-item>
            </nz-form>
        </div>
    </nz-modal>

    <!-- Common Manually Transition State Modal -->
    <nz-modal
        [(nzVisible)]="showTransitionStateModal" 
        nzTitle="Select state" 
        [nzWidth]="'80%'"
        (nzOnCancel)="showTransitionStateModal = false; this.validateForm.controls.transitionOrderState.reset()" 
        (nzOnOk)="manuallyTransitionOrderState()">
        <div *nzModalContent>
            <nz-form [formGroup]="validateForm.controls.transitionOrderState">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">Manually transition the order to another state. Note that order states are governed by rules which may prevent certain transitions.</nz-form-label>
                    <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                        <nz-select
                            nzPlaceHolder="Please select state"
                            [formControl]="validateForm.controls.transitionOrderState.controls.state"
                        >
                            <nz-option *ngFor="let state of nextStates" [nzValue]="state" [nzLabel]="state"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </nz-form>
        </div>
    </nz-modal>

    <!-- Common Refund & Cancel Order Modal -->
    <nz-modal
        *ngIf="validateForm.controls.sellerOrders.value.length === 0"
        [(nzVisible)]="showRefundAndCancelOrderModal"
        nzTitle="Refund & cancel order"
        [nzWidth]="'80%'"
        (nzOnCancel)="showRefundAndCancelOrderModal = false"
        (nzOnOk)="refundAndCancelOrder()"
    >
        <div *nzModalContent>
            <nz-form [formGroup]="validateForm.controls.cancelOrder">
                <div nz-row>
                    <div nz-col nzSpan="24">
                        <nz-table
                            #rowSelectionTable
                            #sortTable
                            nzShowSizeChanger
                            [nzData]="validateForm.controls.variants.value"
                            [nzFrontPagination]="false"
                            [nzLoading]="appService.isLoading$ | async"
                            [nzTotal]="validateForm.controls.variants.value.length"
                            [nzShowPagination]="false"
                            class="order-table"
                        >
                            <thead>
                                <tr>
                                    <th>IMAGE</th>
                                    <th>NAME</th>
                                    <th>SKU</th>
                                    <th>UNIT PRICE</th>
                                    <th>QUANTITY</th>
                                    <th>REFUND</th>
                                    <th>Return to stock</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let variant of validateForm.controls.variants.controls">
                                    <td></td>
                                    <td>{{ variant.getRawValue().productVariantName }}</td>
                                    <td>{{ variant.getRawValue().sku }}</td>
                                    <td>{{ variant.getRawValue().price / 100 | currency:currencyCode }}</td>
                                    <td>{{ variant.getRawValue().quantity }}</td>
                                    <td>
                                        <input
                                        nz-input
                                        [formControl]="variant.controls.refund"
                                        [nzStatus]="variant.getRawValue().refund > variant.getRawValue().quantity ? 'error' : ''"
                                        (ngModelChange)="updateRefundAmount()"
                                        />
                                        <p *ngIf="variant.getRawValue().refund > variant.getRawValue().quantity" style="color: red;">
                                            Refund amount cannot exceed the quantity of the item.
                                        </p>
                                    </td>
                                    <td>
                                        <label nz-checkbox [nzChecked]="variant.getRawValue().returnToStock " (nzCheckedChange)="variant.controls.returnToStock.setValue(!variant.getRawValue().returnToStock)">Return to stock</label>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table> 
                    </div>
                    <div nz-col nzSpan="12" class="top-20">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24">Cancel shipping: {{ validateForm.controls.selectedShippingMethod.value?.name ?? '' }}</nz-form-label>
                            <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                                <label nz-checkbox [nzChecked]="validateForm.controls.cancelOrder.controls.cancelShipping.value" (nzCheckedChange)="validateForm.controls.cancelOrder.controls.cancelShipping.setValue(!validateForm.controls.cancelOrder.controls.cancelShipping.value)">Cancel shipping</label>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24">Refund/cancellation reason</nz-form-label>
                            <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                                <nz-select
                                    nzPlaceHolder="Refund/cancellation reason is required"
                                    [formControl]="validateForm.controls.cancelOrder.controls.reason"
                                >
                                    <nz-option nzValue="Customer request" nzLabel="Customer request"></nz-option>
                                    <nz-option nzValue="Not available" nzLabel="Not available"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24">Refund total</nz-form-label>
                            <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                                <input nz-input [formControl]="validateForm.controls.cancelOrder.controls.amount" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="1"></div>
                    <div nz-col nzSpan="11" class="top-20">
                        <div *ngFor="let payment of validateForm.controls.refundAndCancelOrders.controls" style="border: 1px solid #d9d9d9; padding: 10px; padding-left: 20px; margin-bottom: 20px;" class="refund-payment" [class.selected]="payment.getRawValue().selected">
                            <div style="margin-top: 20px">
                                <h3 style="display: inline;">Payment</h3>
                                <p style="display: inline;float: right;margin-right: 30px;">
                                    <label nz-checkbox [nzChecked]="payment.getRawValue().selected" (nzCheckedChange)="payment.controls.selected.setValue(!payment.getRawValue().selected)">Refund this payment</label>
                                </p>
                            </div>
                            <nz-form-item style="margin-top: 20px;">
                                <nz-form-label [nzSpan]="24"><b>Payment method</b></nz-form-label>
                                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                                    {{ payment.getRawValue().paymentMethod }}
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label [nzSpan]="24"><b>Transaction ID</b></nz-form-label>
                                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                                    {{ payment.getRawValue().transactionId }}
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label [nzSpan]="24"><b>Payment amount</b></nz-form-label>
                                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                                    {{ payment.getRawValue().paymentAmount / 100 | currency:currencyCode }}
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label [nzSpan]="24"><b>Refundable amount</b></nz-form-label>
                                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                                    {{ (payment.getRawValue().refundableAmount ) / 100 | currency:currencyCode }}
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label [nzSpan]="24"><b>Refund amount</b></nz-form-label>
                                <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                                    <input [formControl]="payment.controls.refundAmount">
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                </div>
            </nz-form>
        </div>
    </nz-modal>

    <!-- Common Modify Order Modal -->
    <nz-modal
        [(nzVisible)]="showModifyOrderModal"
        nzTitle="Modify order"
        (nzOnCancel)="showModifyOrderModal = false"
        (nzOnOk)="modifyOrder()"
    >
        <div *nzModalContent>
            Please confirm you want to modify the order.
        </div>
    </nz-modal>
    
    <!-- Common Cancel Modification Modal -->
    <nz-modal
        [(nzVisible)]="showCancelModificationModal"
        nzTitle="Cancel modification"
        (nzOnCancel)="showCancelModificationModal = false"
        (nzOnOk)="cancelModification()"
    >
        <div *nzModalContent>
            <p>Please confirm you want to cancel the modification.</p>
        </div>
    </nz-modal>

    <!-- Common Cancel Order Modal -->
    <nz-modal
        [(nzVisible)]="showCancelOrderModal" 
        nzTitle="Cancel Order" 
        [nzWidth]="'80%'"
        (nzOnCancel)="showCancelOrderModal = false; this.validateForm.controls.cancelOrder.reset()" 
        (nzOnOk)="cancelOrder()">
        <div *nzModalContent>
            <nz-form [formGroup]="validateForm.controls.cancelOrder">
                <div nz-row>
                    <div nz-col nzSpan="18">
                        <nz-table
                            #rowSelectionTable
                            #sortTable
                            nzShowSizeChanger
                            [nzData]="validateForm.controls.variants.value"
                            [nzFrontPagination]="false"
                            [nzLoading]="appService.isLoading$ | async"
                            [nzTotal]="validateForm.controls.variants.value.length"
                            [nzShowPagination]="false"
                            class="order-table"
                        >
                            <thead>
                                <tr>
                                    <th>IMAGE</th>
                                    <th>NAME</th>
                                    <th>SKU</th>
                                    <th>UNIT PRICE</th>
                                    <th>QUANTITY</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let variant of validateForm.controls.variants.controls">
                                    <td></td>
                                    <td>{{ variant.getRawValue().productVariantName }}</td>
                                    <td>{{ variant.getRawValue().sku }}</td>
                                    <td>{{ variant.getRawValue().price }}</td>
                                    <td>{{ variant.getRawValue().quantity }}</td>
                                    <td>{{ variant.getRawValue().total }}</td>
                                </tr>
                            </tbody>
                        </nz-table> 
                    </div>
                    <div nz-col nzSpan="6">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24">Cancellation reason</nz-form-label>
                            <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                                <nz-select
                                    nzPlaceHolder="Please select reason"
                                    [formControl]="validateForm.controls.cancelOrder.controls.reason"
                                >
                                    <nz-option nzValue="Customer request" nzLabel="Customer request"></nz-option>
                                    <nz-option nzValue="Not available" nzLabel="Not available"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </nz-form>
        </div>
    </nz-modal>

    <!-- Common Cancel Entired or items Order Modal -->
    <nz-modal
        [(nzVisible)]="showCancelDeliveredOrderModal" 
        nzTitle="Cancel Order" 
        [nzWidth]="'80%'"
        (nzOnCancel)="showCancelDeliveredOrderModal = false; this.validateForm.controls.cancelDeliveredOrder.reset()" 
        (nzOnOk)="cancelDeliveredOrder()">
        <div *nzModalContent>
            <nz-form [formGroup]="validateForm.controls.cancelDeliveredOrder">
                <div nz-row>
                    <div nz-col nzSpan="18">
                        <nz-table
                            #rowSelectionTable
                            #sortTable
                            nzShowSizeChanger
                            [nzData]="validateForm.controls.variants.value"
                            [nzFrontPagination]="false"
                            [nzLoading]="appService.isLoading$ | async"
                            [nzTotal]="validateForm.controls.variants.value.length"
                            [nzShowPagination]="false"
                            class="order-table"
                        >
                            <thead>
                                <tr>
                                    <th>IMAGE</th>
                                    <th>NAME</th>
                                    <th>SKU</th>
                                    <th>UNIT PRICE</th>
                                    <th>QUANTITY</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let variant of validateForm.controls.variants.controls">
                                    <td></td>
                                    <td>{{ variant.getRawValue().productVariantName }}</td>
                                    <td>{{ variant.getRawValue().sku }}</td>
                                    <td>{{ variant.getRawValue().price / 100 | currency:currencyCode }}</td>
                                    <td>
                                        <!-- TODO: add check, quantity can't exceed current quantity -->
                                        <input
                                        *ngIf="!validateForm.controls.cancelEntireOrder.value"
                                        nz-input
                                        [formControl]="variant.controls.quantity"
                                        [nzStatus]="checkStock(variant.getRawValue().stock, variant.getRawValue().quantity) ? '' : 'error'"
                                        />
                                        <p *ngIf="validateForm.controls.cancelEntireOrder.value">
                                            {{ variant.getRawValue().quantity }}
                                        </p>
                                    </td>
                                    <td>{{ variant.getRawValue().total / 100 | currency:currencyCode }}</td>
                                </tr>
                            </tbody>
                        </nz-table> 
                    </div>
                    <div nz-col nzSpan="6">
                        <nz-form-item>
                            <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                                <label nz-checkbox [nzChecked]="validateForm.controls.cancelEntireOrder.value" (nzCheckedChange)="validateForm.controls.cancelEntireOrder.setValue(!validateForm.controls.cancelEntireOrder.value)">Cancel entire order</label>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24">Cancellation reason</nz-form-label>
                            <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                                <nz-select
                                    nzPlaceHolder="Please select reason"
                                    [formControl]="validateForm.controls.cancelDeliveredOrder.controls.reason"
                                >
                                    <nz-option nzValue="Customer request" nzLabel="Customer request"></nz-option>
                                    <nz-option nzValue="Not available" nzLabel="Not available"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </nz-form>
        </div>
    </nz-modal>

    <!-- Common Fulfill Order Modal -->
    <nz-modal
        [(nzVisible)]="showFulfillOrderModal"
        nzTitle="Fulfill Order"
        (nzOnCancel)="showFulfillOrderModal = false"
        (nzOnOk)="fulfillOrder()"
        [nzWidth]="'80%'"
    >
        <div *nzModalContent>
            <div nz-row>
                <div nz-col nzSpan="24">
                    <h3>Fulfill Order</h3>
                </div>
            </div>
            <div nz-row>
                <div nz-col nzSpan="24">
                    <nz-table
                        #rowSelectionTable
                        #sortTable
                        nzShowSizeChanger
                        [nzData]="validateForm.controls.variants.value"
                        [nzFrontPagination]="false"
                    >
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>SKU</th>
                                <th>UNFULFILLED QUANTITY</th>
                                <th>STOCK</th>
                                <th>QUANTITY TO FULFILL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let variant of validateForm.controls.variants.controls">
                                <td>{{ variant.getRawValue().productVariantName }}</td>
                                <td>{{ variant.getRawValue().sku }}</td>
                                <td>
                                    <p style="margin-top: 10px">
                                        <span 
                                            nz-tooltip
                                            nzTooltipTitle="There is not enough quantity to fulfill order."
                                            nzTooltipPlacement="bottomLeft"
                                            *ngIf="0 > variant.getRawValue().unfulfilledQuantity" 
                                            nz-icon nzType="exclamation-circle" 
                                            [style.color]="'red'" 
                                            style="margin-right: 10px;"
                                            ></span>
                                        {{ variant.getRawValue().unfulfilledQuantity }}
                                    </p>
                                </td>
                                <td>{{ variant.getRawValue().stock }}</td>
                                <td>
                                    <input
                                    nz-input
                                    type="number"
                                    [nzStatus]="variant.controls.quantityToFulfill.value > variant.getRawValue().unfulfilledQuantity ? 'error' : ''"
                                    [formControl]="variant.controls.quantityToFulfill"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>
            <div nz-row class="top-20">
                <div nz-col nzSpan="24">
                    <h3>Shipping</h3>
                </div>
                <div nz-col nzSpan="24">
                    <nz-table
                    #rowSelectionTable
                    #sortTable
                    nzShowSizeChanger
                    [nzData]="[validateForm.controls.selectedShippingAddress.value]"
                    [nzFrontPagination]="false"
                >
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>City</th>
                                <th>Company</th>
                                <th>Street Line 1</th>
                                <th>Street Line 2</th>
                                <th>Postal Code</th>
                                <th>Province</th>
                                <th>Country</th>
                                <th>Phone Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let address of [validateForm.controls.selectedShippingAddress.value]">
                                <td>{{ address?.fullName ?? '' }}</td>
                                <td>{{ address?.city ?? '' }}</td>
                                <td>{{ address?.company ?? '' }}</td>
                                <td>{{ address?.streetLine1 ?? '' }}</td>
                                <td>{{ address?.streetLine2 ?? '' }}</td>
                                <td>{{ address?.postalCode ?? '' }}</td>
                                <td>{{ address?.province ?? '' }}</td>
                                <td>{{ address?.country ?? '' }}</td>
                                <td>{{ address?.phoneNumber ?? '' }}</td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>
            <div nz-row>
                <div nz-col nzSpan="8">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24"><b>Shipping method</b></nz-form-label>
                        <nz-form-control>
                            {{ validateForm.controls.selectedShippingMethod.value?.name ?? '' }}
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="8">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24"><b>Shipping</b></nz-form-label>
                        <nz-form-control>
                            {{ shipping | currency:currencyCode }}
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="8">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24"><b>Shipping With Tax</b></nz-form-label>
                        <nz-form-control>
                            {{ shippingWithTax | currency:currencyCode }}
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="border-container top-20">
                <div nz-row>
                    <div nz-col nzSpan="24">
                        <h4>Manually enter fulfillment details</h4>
                    </div>
                </div>
                <div nz-row>
                    <div nz-col nzSpan="24">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24">Method</nz-form-label>
                            <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                                <input nz-input [formControl]="validateForm.controls.fulfillmentDetails.controls.method" />
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24">Tracking Code</nz-form-label>
                            <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                                <input nz-input [formControl]="validateForm.controls.fulfillmentDetails.controls.trackingCode" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>
        </div>
    </nz-modal>

    <!-- Common Preview Changes Modal -->
    <nz-modal
        [nzFooter]="previewChangesFooter"
        [(nzVisible)]="showPreviewChangesModal"
        nzTitle="Preview changes"
        [nzWidth]="'80%'"
    >
        <ng-template #previewChangesFooter>
            <button 
                nz-button 
                nz-tooltip
                [nzTooltipTitle]="tooltipTemplate" 
                nzTooltipColor="green"
            >
                Price difference: {{ (totalWithTax - originalTotalWithTax) / 100 | currency:currencyCode }}
            </button>
            <ng-template #tooltipTemplate>
                Total With Tax Before Modification: {{originalTotalWithTax / 100 | currency:currencyCode}}
                <br>
                Total With Tax After Modification: {{totalWithTax / 100 | currency:currencyCode}}
            </ng-template>
            <button
                nz-button
                nzType="default"
                (click)="cancelPreviewChanges()"
            >
                Cancel
            </button>
            <button
                nz-button
                nzType="primary"
                (click)="previewChanges()"
            >
                Confirm
            </button>
        </ng-template>
        <div *nzModalContent>
            <div nz-row>
                <div nz-col nzSpan="24">
                    <h3>Order Items</h3>
                </div>
                <div nz-col nzSpan="24">
                    <nz-table
                        #rowSelectionTable
                        #sortTable
                        nzShowSizeChanger
                        [nzData]="variantsResponse"
                        [nzFrontPagination]="false"
                        [nzLoading]="appService.isLoading$ | async"
                        [nzTotal]="variantsResponse.length"
                        [nzShowPagination]="false"
                        class="order-table"
                    >
                        <thead>
                            <tr>
                                <th>IMAGE</th>
                                <th>PRODUCT NAME</th>
                                <th>SKU</th>
                                <th>UNIT PRICE</th>
                                <th>QUANTITY</th>
                                <th>TOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let variant of variantsResponse">
                                <td>{{ variant.image }}</td>
                                <td>{{ variant.name }}</td>
                                <td>{{ variant.sku }}</td>
                                <td>{{ variant.unitPrice / 100 | currency:currencyCode }}</td>
                                <td>{{ variant.quantity }}</td>
                                <td>{{ variant.total / 100 | currency:currencyCode }}</td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>
            <div nz-row class="top-20">
                <div nz-col nzSpan="24">
                    <h3>Surcharges</h3>
                </div>
                <div nz-col nzSpan="24">
                    <nz-table
                        #rowSelectionTable
                        #sortTable
                        nzShowSizeChanger
                        [nzData]="surchargesResponse"
                        [nzFrontPagination]="false"
                        [nzLoading]="appService.isLoading$ | async"
                        [nzTotal]="surchargesResponse.length"
                        [nzShowPagination]="false"
                        class="order-table"
                    >
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Sku</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let surcharge of surchargesResponse">
                                <td>{{ surcharge.description }}</td>
                                <td>{{ surcharge.sku }}</td>
                                <td>
                                    <p>{{ surcharge.priceWithTax / 100 | currency:currencyCode }}</p>
                                    <p class="net-price">
                                        {{ surcharge.price / 100 | currency:currencyCode }}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>
            <!-- Subtotal -->
            <div nz-col nzSpan="24" [class.top-24]="validateForm.controls.variants.value.length > 0">
                <div nz-row>
                    <div nz-col nzSpan="20" class="price-container">
                        <p style="margin: 0;">Subtotal</p>
                    </div>
                    <div nz-col nzSpan="4">
                        <p>{{ subTotalWithTax / 100 | currency:currencyCode }}</p>
                        <p class="net-price">{{ subTotal / 100 | currency:currencyCode }}</p>
                    </div>
                </div>
            </div>
            <nz-divider></nz-divider>
            <!-- Shipping -->
            <div nz-col nzSpan="24">
                <div nz-row>
                    <div nz-col nzSpan="6"  class="price-container">
                        <p>Shipping</p>
                    </div>
                    <div nz-col nzSpan="12"  class="price-container">
                        <p *ngFor="let shippingMethod of shippingMethodsResponse">{{ shippingMethod.name }}</p>
                    </div>
                    <div nz-col nzSpan="4">
                        <p>{{ shippingWithTax / 100 | currency:currencyCode }}</p>
                        <p class="net-price">{{ shipping / 100 | currency:currencyCode }}</p>
                    </div>
                </div>
            </div>
            <nz-divider></nz-divider>
            <!-- Total -->
            <div nz-col nzSpan="24">
                <div nz-row>
                    <div nz-col nzSpan="20"  class="price-container">
                        <p><b>Total</b></p>
                    </div>
                    <div nz-col nzSpan="4">
                        <p><b>{{ totalWithTax / 100 | currency:currencyCode }}</b></p>
                        <p class="net-price"><b>{{ total / 100 | currency:currencyCode }}</b></p>
                    </div>
                </div>
            </div>
            <div class="border-container top-20">
                <div nz-row>
                    <div nz-col nzSpan="24">
                        <h3>Modify Order</h3>
                    </div>
                </div>
                <!-- Surcharges -->
                <div nz-row *ngIf="validateForm.controls.surcharges.value.length > 0">
                    <div nz-col nzSpan="24" class="top-20">
                        <h3>Add Surcharges</h3>
                    </div>
                    <div nz-col nzSpan="24" class="top-20">
                        <nz-table
                            #rowSelectionTable
                            #sortTable
                            nzShowSizeChanger
                            [nzData]="validateForm.controls.surcharges.value"
                            [nzFrontPagination]="false"
                            [nzLoading]="appService.isLoading$ | async"
                            [nzTotal]="validateForm.controls.surcharges.value.length"
                            [nzShowPagination]="false"
                            class="order-table"
                        >
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Sku</th>
                                    <th>Price</th>
                                    <th>Includes Tax</th>
                                    <th>Tax Rate</th>
                                    <th>Tax Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let surcharge of validateForm.controls.surcharges.controls">
                                    <td>{{ surcharge.getRawValue().description }}</td>
                                    <td>{{ surcharge.getRawValue().sku }}</td>
                                    <td>{{ surcharge.getRawValue().price | currency:currencyCode }}</td>
                                    <td>{{ surcharge.getRawValue().includesTax }}</td>
                                    <td>{{ surcharge.getRawValue().taxRate }}</td>
                                    <td>{{ surcharge.getRawValue().taxDescription }}</td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                </div>
                <!-- Order Items -->
                <div nz-row *ngIf="modificationOrderInformation.variants.length > 0">
                    <div nz-col nzSpan="24" class="top-20">
                        <h3>Updated Order Items</h3>
                    </div>
                    <div nz-col nzSpan="24" class="top-20">
                        <nz-table
                            #rowSelectionTable
                            #sortTable
                            nzShowSizeChanger
                            [nzData]="modificationOrderInformation.variants"
                            [nzFrontPagination]="false"
                            [nzShowPagination]="false"
                            class="order-table"
                        >
                            <thead>
                                <tr>
                                    <th>Product Variant Name</th>
                                    <th>Modification Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let variant of modificationOrderInformation.variants">
                                    <td>{{ variant.productVariantName }}</td>
                                    <td>
                                        <p *ngIf="variant.modificationQuantity > 0">
                                            +{{ variant.modificationQuantity }}
                                        </p>
                                        <p *ngIf="variant.modificationQuantity < 0">
                                            {{ variant.modificationQuantity }}
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                </div>
                <!-- Billing Address -->
                <div nz-row *ngIf="displayModificationBillingAddress()">
                    <div nz-col nzSpan="24" class="top-20">
                        <h3>Updated Billing Address</h3>
                    </div>
                    <div nz-col nzSpan="24" class="top-20">
                        <nz-table
                            #sortTable
                            [nzFrontPagination]="false"
                            [nzData]="[modificationOrderInformation.billingAddress]"
                        >
                            <thead>
                                <tr>
                                    <th>Field Name</th>
                                    <th>From</th>
                                    <th>To</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="modificationOrderInformation.billingAddress.fullName.fromName !== modificationOrderInformation.billingAddress.fullName.toName">
                                    <td>Full Name</td>
                                    <td>{{ modificationOrderInformation.billingAddress.fullName.fromName }}</td>
                                    <td>{{ modificationOrderInformation.billingAddress.fullName.toName }}</td>
                                </tr>
                                <tr *ngIf="modificationOrderInformation.billingAddress.city.fromName !== modificationOrderInformation.billingAddress.city.toName">
                                    <td>City</td>
                                    <td>{{ modificationOrderInformation.billingAddress.city.fromName }}</td>
                                    <td>{{ modificationOrderInformation.billingAddress.city.toName }}</td>
                                </tr>
                                <tr *ngIf="modificationOrderInformation.billingAddress.company.fromName !== modificationOrderInformation.billingAddress.company.toName">
                                    <td>Company</td>
                                    <td>{{ modificationOrderInformation.billingAddress.company.fromName }}</td>
                                    <td>{{ modificationOrderInformation.billingAddress.company.toName }}</td>
                                </tr>
                                <tr *ngIf="modificationOrderInformation.billingAddress.streetLine1.fromName !== modificationOrderInformation.billingAddress.streetLine1.toName">
                                    <td>Street Line 1</td>
                                    <td>{{ modificationOrderInformation.billingAddress.streetLine1.fromName }}</td>
                                    <td>{{ modificationOrderInformation.billingAddress.streetLine1.toName }}</td>
                                </tr>
                                <tr *ngIf="modificationOrderInformation.billingAddress.streetLine2.fromName !== modificationOrderInformation.billingAddress.streetLine2.toName">
                                    <td>Street Line 2</td>
                                    <td>{{ modificationOrderInformation.billingAddress.streetLine2.fromName }}</td>
                                    <td>{{ modificationOrderInformation.billingAddress.streetLine2.toName }}</td>
                                </tr>
                                <tr *ngIf="modificationOrderInformation.billingAddress.postalCode.fromName !== modificationOrderInformation.billingAddress.postalCode.toName">
                                    <td>Postal Code</td>
                                    <td>{{ modificationOrderInformation.billingAddress.postalCode.fromName }}</td>
                                    <td>{{ modificationOrderInformation.billingAddress.postalCode.toName }}</td>
                                </tr>
                                <tr *ngIf="modificationOrderInformation.billingAddress.province.fromName !== modificationOrderInformation.billingAddress.province.toName">
                                    <td>Province</td>
                                    <td>{{ modificationOrderInformation.billingAddress.province.fromName }}</td>
                                    <td>{{ modificationOrderInformation.billingAddress.province.toName }}</td>
                                </tr>
                                <tr *ngIf="modificationOrderInformation.billingAddress.country.fromName !== modificationOrderInformation.billingAddress.country.toName">
                                    <td>Country</td>
                                    <td>{{ modificationOrderInformation.billingAddress.country.fromName }}</td>
                                    <td>{{ modificationOrderInformation.billingAddress.country.toName }}</td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                </div>
                <!-- Shipping Address -->
                <div nz-row *ngIf="displayModificationShippingAddress()">
                    <div nz-col nzSpan="24" class="top-20">
                        <h3>Updated Shipping Address</h3>
                    </div>
                    <div nz-col nzSpan="24" class="top-20">
                        <nz-table
                            #sortTable
                            [nzFrontPagination]="false"
                            [nzData]="[modificationOrderInformation.shippingAddress]"
                        >
                            <thead>
                                <tr>
                                    <th>Field Name</th>
                                    <th>From</th>
                                    <th>To</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="modificationOrderInformation.shippingAddress.fullName.fromName !== modificationOrderInformation.shippingAddress.fullName.toName">
                                    <td>Full Name</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.fullName.fromName }}</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.fullName.toName }}</td>
                                </tr>
                                <tr *ngIf="modificationOrderInformation.shippingAddress.city.fromName !== modificationOrderInformation.shippingAddress.city.toName">
                                    <td>City</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.city.fromName }}</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.city.toName }}</td>
                                </tr>
                                <tr *ngIf="modificationOrderInformation.shippingAddress.company.fromName !== modificationOrderInformation.shippingAddress.company.toName">
                                    <td>Company</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.company.fromName }}</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.company.toName }}</td>
                                </tr>
                                <tr *ngIf="modificationOrderInformation.shippingAddress.streetLine1.fromName !== modificationOrderInformation.shippingAddress.streetLine1.toName">
                                    <td>Street Line 1</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.streetLine1.fromName }}</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.streetLine1.toName }}</td>
                                </tr>
                                <tr *ngIf="modificationOrderInformation.shippingAddress.streetLine2.fromName !== modificationOrderInformation.shippingAddress.streetLine2.toName">
                                    <td>Street Line 2</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.streetLine2.fromName }}</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.streetLine2.toName }}</td>
                                </tr>
                                <tr *ngIf="modificationOrderInformation.shippingAddress.postalCode.fromName !== modificationOrderInformation.shippingAddress.postalCode.toName">
                                    <td>Postal Code</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.postalCode.fromName }}</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.postalCode.toName }}</td>
                                </tr>
                                <tr *ngIf="modificationOrderInformation.shippingAddress.province.fromName !== modificationOrderInformation.shippingAddress.province.toName">
                                    <td>Province</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.province.fromName }}</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.province.toName }}</td>
                                </tr>
                                <tr *ngIf="modificationOrderInformation.shippingAddress.country.fromName !== modificationOrderInformation.shippingAddress.country.toName">
                                    <td>Country</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.country.fromName }}</td>
                                    <td>{{ modificationOrderInformation.shippingAddress.country.toName }}</td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                </div>
                <!-- Shipping Method -->
                <div nz-row *ngIf="displayModificationShippingMethod()">
                    <div nz-col nzSpan="24" class="top-20">
                        <h3>Updated Shipping Method</h3>
                    </div>
                    <div nz-col nzSpan="24" class="top-20">
                        <nz-table
                            #sortTable
                            [nzFrontPagination]="false"
                            [nzData]="[modificationOrderInformation.shippingMethod]"
                        >
                            <thead>
                                <tr>
                                    <th>From</th>
                                    <th>To</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="modificationOrderInformation.shippingMethod.fromName !== modificationOrderInformation.shippingMethod.toName">
                                    <td>{{ modificationOrderInformation.shippingMethod.fromName }}</td>
                                    <td>{{ modificationOrderInformation.shippingMethod.toName }}</td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                    
                </div>
                <!-- Coupon -->
                <div nz-row *ngIf="modificationOrderInformation.promotions.length > 0">
                    <div nz-col nzSpan="24" class="top-20">
                        <h3>Updated Coupon</h3>
                    </div>
                    <div nz-col nzSpan="24" class="top-20">
                        <nz-table
                            #sortTable
                            [nzFrontPagination]="false"
                            [nzData]="modificationOrderInformation.promotions"
                        >
                            <thead>
                                <tr>
                                    <th>Coupon Code</th>
                                    <th>Remove or Add</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let promotion of modificationOrderInformation.promotions">
                                    <td>{{ promotion.couponCode }}</td>
                                    <td>
                                        <nz-tag [nzColor]="promotion.removeOrAdd === 'remove' ? 'red' : 'green'">
                                            {{ promotion.removeOrAdd }}
                                        </nz-tag>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                </div>
            </div>
        </div>
    </nz-modal>

    <!-- Settle Refund Modal -->
    <nz-modal
        [(nzVisible)]="showSettleRefundModal"
        nzTitle="Settle Refund"
        (nzOnCancel)="showSettleRefundModal = false; this.validateForm.controls.settleRefund.reset()"
        (nzOnOk)="settleRefund()"
    >
        <div *nzModalContent>
            After manually refunding via your payment provider ({{this.validateForm.controls.settleRefund.controls.paymentMethod.value}}), enter the transaction ID here.
            <nz-form [formGroup]="validateForm.controls.settleRefund">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">Transaction ID</nz-form-label>
                    <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                        <input nz-input [formControl]="validateForm.controls.settleRefund.controls.transactionId" />    
                    </nz-form-control>
                </nz-form-item>
            </nz-form>
        </div>
    </nz-modal>

    <!-- General Info -->
    <div class="page-container">
        <div class="page-container-inner">
          <div nz-row>
            <div nz-col nzSpan="24">
              <h2>General Info</h2>
            </div>
            <div nz-col nzSpan="24" class="top-20">
                <div nz-row>
                    <!-- Order ID -->
                    <div nz-col nzSpan="8">
                        <div nz-row>
                            <div nz-col nzSpan="24">
                                <p><b>Order ID</b></p>
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col nzSpan="24">
                                <p>{{ orderCode }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- Created At -->
                    <div nz-col nzSpan="8">
                        <div nz-row>
                            <div nz-col nzSpan="24">
                                <p><b>Created At</b></p>
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col nzSpan="24">
                                <p>{{ createdAt | date:'dd/MM/yyyy - hh:mm a' }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- Updated At -->
                    <div nz-col nzSpan="8">
                        <div nz-row>
                            <div nz-col nzSpan="24">
                                <p><b>Updated At</b></p>
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col nzSpan="24">
                                <p>{{ updatedAt | date:'dd/MM/yyyy - hh:mm a' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>

    <!-- Summary of modifications -->
    <div class="page-container" *ngIf="orderState === 'Modifying'">
        <div class="page-container-inner">
            <div nz-row style="margin-bottom: 20px;">
                <div nz-col nzSpan="24">
                    <h2>Summary of modifications</h2>
                </div>
            </div>
            <!-- Note -->
            <div nz-row>
                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">Note</nz-form-label>
                        <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                            <textarea nz-input [formControl]="validateForm.controls.confirmModification.controls.note" ></textarea>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <!-- Recalculate Shipping -->
            <div nz-row>
                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                            <label nz-checkbox [nzChecked]="validateForm.controls.confirmModification.controls.recalculateShipping.value" (nzCheckedChange)="updateRecalculateShipping()">Recalculate Shipping</label>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <!-- Surcharges -->
            <div nz-row *ngIf="validateForm.controls.surcharges.value.length > 0">
                <div nz-col nzSpan="24" class="top-20">
                    <h3>Add Surcharges</h3>
                </div>
                <div nz-col nzSpan="24" class="top-20">
                    <nz-table
                        #rowSelectionTable
                        #sortTable
                        nzShowSizeChanger
                        [nzData]="validateForm.controls.surcharges.value"
                        [nzFrontPagination]="false"
                        [nzLoading]="appService.isLoading$ | async"
                        [nzTotal]="validateForm.controls.surcharges.value.length"
                        [nzShowPagination]="false"
                        class="order-table"
                    >
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Sku</th>
                                <th>Price</th>
                                <th>Includes Tax</th>
                                <th>Tax Rate</th>
                                <th>Tax Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let surcharge of validateForm.controls.surcharges.controls">
                                <td>{{ surcharge.getRawValue().description }}</td>
                                <td>{{ surcharge.getRawValue().sku }}</td>
                                <td>{{ surcharge.getRawValue().price | currency:currencyCode }}</td>
                                <td>{{ surcharge.getRawValue().includesTax }}</td>
                                <td>{{ surcharge.getRawValue().taxRate }}</td>
                                <td>{{ surcharge.getRawValue().taxDescription }}</td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>
            <!-- Order Items -->
            <div nz-row *ngIf="modificationOrderInformation.variants.length > 0">
                <div nz-col nzSpan="24" class="top-20">
                    <h3>Updated Order Items</h3>
                </div>
                <div nz-col nzSpan="24" class="top-20">
                    <nz-table
                        #rowSelectionTable
                        #sortTable
                        nzShowSizeChanger
                        [nzData]="modificationOrderInformation.variants"
                        [nzFrontPagination]="false"
                        [nzShowPagination]="false"
                        class="order-table"
                    >
                        <thead>
                            <tr>
                                <th>Product Variant Name</th>
                                <th>Modification Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let variant of modificationOrderInformation.variants">
                                <td>{{ variant.productVariantName }}</td>
                                <td>
                                    <p *ngIf="variant.modificationQuantity > 0">
                                        +{{ variant.modificationQuantity }}
                                    </p>
                                    <p *ngIf="variant.modificationQuantity < 0">
                                        {{ variant.modificationQuantity }}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>
            <!-- Billing Address -->
            <div nz-row *ngIf="displayModificationBillingAddress()">
                <div nz-col nzSpan="24" class="top-20">
                    <h3>Updated Billing Address</h3>
                </div>
                <div nz-col nzSpan="24" class="top-20">
                    <nz-table
                        #sortTable
                        [nzFrontPagination]="false"
                        [nzData]="[modificationOrderInformation.billingAddress]"
                    >
                        <thead>
                            <tr>
                                <th>Field Name</th>
                                <th>From</th>
                                <th>To</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="modificationOrderInformation.billingAddress.fullName.fromName !== modificationOrderInformation.billingAddress.fullName.toName">
                                <td>Full Name</td>
                                <td>{{ modificationOrderInformation.billingAddress.fullName.fromName }}</td>
                                <td>{{ modificationOrderInformation.billingAddress.fullName.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.billingAddress.city.fromName !== modificationOrderInformation.billingAddress.city.toName">
                                <td>City</td>
                                <td>{{ modificationOrderInformation.billingAddress.city.fromName }}</td>
                                <td>{{ modificationOrderInformation.billingAddress.city.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.billingAddress.company.fromName !== modificationOrderInformation.billingAddress.company.toName">
                                <td>Company</td>
                                <td>{{ modificationOrderInformation.billingAddress.company.fromName }}</td>
                                <td>{{ modificationOrderInformation.billingAddress.company.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.billingAddress.streetLine1.fromName !== modificationOrderInformation.billingAddress.streetLine1.toName">
                                <td>Street Line 1</td>
                                <td>{{ modificationOrderInformation.billingAddress.streetLine1.fromName }}</td>
                                <td>{{ modificationOrderInformation.billingAddress.streetLine1.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.billingAddress.streetLine2.fromName !== modificationOrderInformation.billingAddress.streetLine2.toName">
                                <td>Street Line 2</td>
                                <td>{{ modificationOrderInformation.billingAddress.streetLine2.fromName }}</td>
                                <td>{{ modificationOrderInformation.billingAddress.streetLine2.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.billingAddress.postalCode.fromName !== modificationOrderInformation.billingAddress.postalCode.toName">
                                <td>Postal Code</td>
                                <td>{{ modificationOrderInformation.billingAddress.postalCode.fromName }}</td>
                                <td>{{ modificationOrderInformation.billingAddress.postalCode.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.billingAddress.province.fromName !== modificationOrderInformation.billingAddress.province.toName">
                                <td>Province</td>
                                <td>{{ modificationOrderInformation.billingAddress.province.fromName }}</td>
                                <td>{{ modificationOrderInformation.billingAddress.province.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.billingAddress.country.fromName !== modificationOrderInformation.billingAddress.country.toName">
                                <td>Country</td>
                                <td>{{ modificationOrderInformation.billingAddress.country.fromName }}</td>
                                <td>{{ modificationOrderInformation.billingAddress.country.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.billingAddress.phoneNumber.fromName !== modificationOrderInformation.billingAddress.phoneNumber.toName">
                                <td>Phone Number</td>
                                <td>{{ modificationOrderInformation.billingAddress.phoneNumber.fromName }}</td>
                                <td>{{ modificationOrderInformation.billingAddress.phoneNumber.toName }}</td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>
            <!-- Shipping Address -->
            <div nz-row *ngIf="displayModificationShippingAddress()">
                <div nz-col nzSpan="24" class="top-20">
                    <h3>Updated Shipping Address</h3>
                </div>
                <div nz-col nzSpan="24" class="top-20">
                    <nz-table
                        #sortTable
                        [nzFrontPagination]="false"
                        [nzData]="[modificationOrderInformation.shippingAddress]"
                    >
                        <thead>
                            <tr>
                                <th>Field Name</th>
                                <th>From</th>
                                <th>To</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="modificationOrderInformation.shippingAddress.fullName.fromName !== modificationOrderInformation.shippingAddress.fullName.toName">
                                <td>Full Name</td>
                                <td>{{ modificationOrderInformation.shippingAddress.fullName.fromName }}</td>
                                <td>{{ modificationOrderInformation.shippingAddress.fullName.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.shippingAddress.city.fromName !== modificationOrderInformation.shippingAddress.city.toName">
                                <td>City</td>
                                <td>{{ modificationOrderInformation.shippingAddress.city.fromName }}</td>
                                <td>{{ modificationOrderInformation.shippingAddress.city.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.shippingAddress.company.fromName !== modificationOrderInformation.shippingAddress.company.toName">
                                <td>Company</td>
                                <td>{{ modificationOrderInformation.shippingAddress.company.fromName }}</td>
                                <td>{{ modificationOrderInformation.shippingAddress.company.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.shippingAddress.streetLine1.fromName !== modificationOrderInformation.shippingAddress.streetLine1.toName">
                                <td>Street Line 1</td>
                                <td>{{ modificationOrderInformation.shippingAddress.streetLine1.fromName }}</td>
                                <td>{{ modificationOrderInformation.shippingAddress.streetLine1.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.shippingAddress.streetLine2.fromName !== modificationOrderInformation.shippingAddress.streetLine2.toName">
                                <td>Street Line 2</td>
                                <td>{{ modificationOrderInformation.shippingAddress.streetLine2.fromName }}</td>
                                <td>{{ modificationOrderInformation.shippingAddress.streetLine2.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.shippingAddress.postalCode.fromName !== modificationOrderInformation.shippingAddress.postalCode.toName">
                                <td>Postal Code</td>
                                <td>{{ modificationOrderInformation.shippingAddress.postalCode.fromName }}</td>
                                <td>{{ modificationOrderInformation.shippingAddress.postalCode.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.shippingAddress.province.fromName !== modificationOrderInformation.shippingAddress.province.toName">
                                <td>Province</td>
                                <td>{{ modificationOrderInformation.shippingAddress.province.fromName }}</td>
                                <td>{{ modificationOrderInformation.shippingAddress.province.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.shippingAddress.country.fromName !== modificationOrderInformation.shippingAddress.country.toName">
                                <td>Country</td>
                                <td>{{ modificationOrderInformation.shippingAddress.country.fromName }}</td>
                                <td>{{ modificationOrderInformation.shippingAddress.country.toName }}</td>
                            </tr>
                            <tr *ngIf="modificationOrderInformation.shippingAddress.phoneNumber.fromName !== modificationOrderInformation.shippingAddress.phoneNumber.toName">
                                <td>Phone Number</td>
                                <td>{{ modificationOrderInformation.shippingAddress.phoneNumber.fromName }}</td>
                                <td>{{ modificationOrderInformation.shippingAddress.phoneNumber.toName }}</td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>
            <!-- Shipping Method -->
            <div nz-row *ngIf="displayModificationShippingMethod()">
                <div nz-col nzSpan="24" class="top-20">
                    <h3>Updated Shipping Method</h3>
                </div>
                <div nz-col nzSpan="24" class="top-20">
                    <nz-table
                        #sortTable
                        [nzFrontPagination]="false"
                        [nzData]="[modificationOrderInformation.shippingMethod]"
                    >
                        <thead>
                            <tr>
                                <th>From</th>
                                <th>To</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="modificationOrderInformation.shippingMethod.fromName !== modificationOrderInformation.shippingMethod.toName">
                                <td>{{ modificationOrderInformation.shippingMethod.fromName }}</td>
                                <td>{{ modificationOrderInformation.shippingMethod.toName }}</td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
                
            </div>
            <!-- Coupon -->
            <div nz-row *ngIf="modificationOrderInformation.promotions.length > 0">
                <div nz-col nzSpan="24" class="top-20">
                    <h3>Updated Coupon</h3>
                </div>
                <div nz-col nzSpan="24" class="top-20">
                    <nz-table
                        #sortTable
                        [nzFrontPagination]="false"
                        [nzData]="modificationOrderInformation.promotions"
                    >
                        <thead>
                            <tr>
                                <th>Coupon Code</th>
                                <th>Remove or Add</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let promotion of modificationOrderInformation.promotions">
                                <td>{{ promotion.couponCode }}</td>
                                <td>
                                    <nz-tag [nzColor]="promotion.removeOrAdd === 'remove' ? 'red' : 'green'">
                                        {{ promotion.removeOrAdd }}
                                    </nz-tag>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>
        </div>
    </div>
 
    <!-- Seller Orders -->
    <div class="page-container" *ngIf="validateForm.controls.sellerOrders.value.length > 0 && orderState !== 'Modifying'">
        <div class="page-container-inner">
            <div nz-row>
                <div nz-col nzSpan="24">
                    <h2>Seller Orders</h2>
                </div>
                <div nz-col nzSpan="24" class="top-20">
                    <nz-table
                        #rowSelectionTable
                        #sortTable
                        nzShowSizeChanger
                        [nzData]="validateForm.controls.sellerOrders.value"
                        [nzFrontPagination]="false"
                        [nzShowPagination]="false"
                        class="order-table"
                    >
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Order Code</th>
                                <th>State</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let sellerOrder of validateForm.controls.sellerOrders.controls">
                                <td>{{ sellerOrder.getRawValue().id }}</td>
                                <td>{{ sellerOrder.getRawValue().code }}</td>
                                <td>
                                    <nz-tag [nzColor]="pickOrderStateColor(sellerOrder.getRawValue().state)" style="margin-top: 5px;">
                                        {{ sellerOrder.getRawValue().state }}
                                    </nz-tag>
                                </td>
                                <td>
                                    <button
                                        nz-button
                                        nzType="primary"
                                        (click)="goToSellerOrder(sellerOrder.getRawValue().id)"
                                    >
                                        Edit
                                    </button>
                                </td>
                        </tbody>
                    </nz-table>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Payments -->
    <div class="page-container" *ngIf="validateForm.controls.payments.value.length > 0 && orderState !== 'Modifying'">
        <div class="page-container-inner">
            <div nz-row>
                <div nz-col nzSpan="24">
                    <h2>Payments</h2>
                </div>
                <div nz-col nzSpan="24" class="top-20">
                    <nz-table
                        #rowSelectionTable
                        #sortTable
                        nzShowSizeChanger
                        [nzData]="validateForm.controls.payments.value"
                        [nzFrontPagination]="false"
                        [nzLoading]="appService.isLoading$ | async"
                        [nzTotal]="validateForm.controls.payments.value.length"
                        [nzShowPagination]="false"
                        class="order-table"
                    >
                        <thead>
                            <tr>
                                <th>Payment Method</th>
                                <th>Amount</th>
                                <th>Transaction ID</th>
                                <th>Status</th>
                                <th>Payment Metadata</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let payment of validateForm.controls.payments.controls">
                                <td>{{ payment.getRawValue().paymentMethod }}</td>
                                <td>{{ payment.getRawValue().amount / 100 | currency:currencyCode }}</td>
                                <td>{{ payment.getRawValue().transactionId }}</td>
                                <td>
                                    <nz-tag [nzColor]="pickPaymentOrFulfillmentStateColor(payment.getRawValue().status)" style="margin-top: 5px;">
                                        {{ payment.getRawValue().status }}
                                    </nz-tag>
                                </td>
                                <td>{{ payment.getRawValue().paymentMetadata }}</td>
                                <td>
                                    <button
                                        *ngIf="payment.getRawValue().status !== 'Cancelled'"
                                        nz-button
                                        nzDanger
                                        nzType="primary"
                                        (click)="showCancelPaymentModal = true; this.validateForm.controls.cancelPayment.controls.id.setValue(payment.getRawValue().id)"
                                    >
                                        <span nz-icon nzType="ng-zorro:trash"></span>
                                        Cancel Payment
                                    </button>
                                    <nz-modal
                                        [(nzVisible)]="showCancelPaymentModal"
                                        nzTitle="Cancel Payment"
                                        (nzOnCancel)="showCancelPaymentModal = false"
                                        (nzOnOk)="cancelPayment()"
                                    >
                                        <div *nzModalContent>
                                            <p>Please confirm you want to cancel this payment.</p>
                                        </div>
                                    </nz-modal>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>
        </div>
    </div>

    <!-- Refunds -->
    <div class="page-container" *ngIf="hasRefunds() && orderState !== 'Modifying'">
        <div class="page-container-inner">
            <div nz-row>
                <div nz-col nzSpan="24">
                    <h2>Refunds</h2>
                </div>
                <div nz-col nzSpan="24" class="top-20">
                    <nz-table
                        #rowSelectionTable
                        #sortTable
                        nzShowSizeChanger
                        [nzData]="allRefunds()"
                        [nzFrontPagination]="false"
                        [nzShowPagination]="false"
                        class="order-table"
                    >
                        <thead>
                            <tr>
                                <th>Payment Method</th>
                                <th>Payment Transaction ID</th>
                                <th>ID</th>
                                <th>Created At</th>
                                <th>Refund Total</th>
                                <th>Transaction ID</th>
                                <th>Reason</th>
                                <th>State</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let refund of allRefunds()">
                                <td>{{ refund.paymentMethod }}</td>
                                <td>{{ refund.paymentTransactionId }}</td>
                                <td>Refund #{{ refund.id }}</td>
                                <td>{{ refund.createdAt | date:'dd/MM/yyyy - hh:mm a' }}</td>
                                <td>{{ refund.refundTotal / 100 | currency:currencyCode }}</td>
                                <td>{{ refund.transactionId }}</td>
                                <td>{{ refund.reason }}</td>
                                <td>
                                    <nz-tag [nzColor]="pickPaymentOrFulfillmentStateColor(refund.state)" style="margin-top: 5px;">
                                        {{ refund.state }}
                                    </nz-tag>
                                </td>
                                <td>
                                    <button
                                        *ngIf="refund.state !== 'Settled'"
                                        nz-button
                                        nzType="primary"
                                        (click)="showSettleRefundModal = true; this.validateForm.controls.settleRefund.controls.id.setValue(refund.id); this.validateForm.controls.settleRefund.controls.paymentMethod.setValue(refund.paymentMethod)"
                                    >
                                        Settle Refund
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>
        </div>
    </div>

    <!-- Fulfillments -->
    <div class="page-container" *ngIf="validateForm.controls.fulfillments.value.length > 0 && orderState !== 'Modifying'">
        <div class="page-container-inner">
            <div nz-row>
                <div nz-col nzSpan="24">
                    <h2>Fulfillments</h2>
                </div>
                <div nz-col nzSpan="24" class="top-20">
                    <nz-table
                        #rowSelectionTable
                        #sortTable
                        nzShowSizeChanger
                        [nzData]="validateForm.controls.fulfillments.value"
                        [nzFrontPagination]="false"
                        [nzShowPagination]="false"
                        class="order-table"
                    >
                        <thead>
                            <tr>
                                <th>Created At</th>
                                <th>Method</th>
                                <th>Tracking Code</th>
                                <th>State</th>
                                <th>Contents</th>
                                <th *ngIf="orderState !== 'Delivered' && orderState !== 'Cancelled'"></th>
                                <th *ngIf="orderState !== 'Shipped' && orderState !== 'Delivered' && orderState !== 'Cancelled'"></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let fulfillment of validateForm.controls.fulfillments.controls">
                                <td>{{ fulfillment.getRawValue().createdAt | date:'dd/MM/yyyy - hh:mm a' }}</td>
                                <td>{{ fulfillment.getRawValue().method }}</td>
                                <td>{{ fulfillment.getRawValue().trackingCode }}</td>
                                <td>
                                    <nz-tag [nzColor]="pickPaymentOrFulfillmentStateColor(fulfillment.getRawValue().state)" style="margin-top: 5px;">
                                        {{ fulfillment.getRawValue().state }}
                                    </nz-tag>
                                </td>
                                <td>{{ fulfillment.getRawValue().contents }}</td>
                                <td *ngIf="orderState !== 'Delivered' && orderState !== 'Cancelled'">
                                    <button
                                        *ngIf="fulfillment.getRawValue().state !== 'Shipped' && fulfillment.getRawValue().state !== 'Delivered'"
                                        nz-button
                                        nzType="primary"
                                        (click)="showTransitionFulfillmentStateModal = true; this.validateForm.controls.fulfillmentMarkLine.controls.id.setValue(fulfillment.getRawValue().id)"
                                    >
                                        Mark as Shipped
                                    </button>
                                    <nz-modal
                                        *ngIf="fulfillment.getRawValue().state !== 'Shipped' && fulfillment.getRawValue().state !== 'Delivered'"
                                        [(nzVisible)]="showTransitionFulfillmentStateModal"
                                        nzTitle="Transition Fulfillment State"
                                        (nzOnCancel)="showTransitionFulfillmentStateModal = false"
                                        (nzOnOk)="transitionFulfillmentToState('Shipped')"
                                    >
                                        <div *nzModalContent>
                                            <p>Please confirm you want to transition the fulfillment to the "Shipped" state.</p>
                                        </div>
                                    </nz-modal>

                                    <button
                                        *ngIf="fulfillment.getRawValue().state === 'Shipped'"
                                        nz-button
                                        nzType="primary"
                                        (click)="showTransitionFulfillmentStateToDeliveredModal = true; this.validateForm.controls.fulfillmentMarkLine.controls.id.setValue(fulfillment.getRawValue().id)"
                                    >
                                        Mark as Delivered
                                    </button>
                                    <nz-modal
                                        *ngIf="fulfillment.getRawValue().state === 'Shipped'"
                                        [(nzVisible)]="showTransitionFulfillmentStateToDeliveredModal"
                                        nzTitle="Transition Fulfillment State"
                                        (nzOnCancel)="showTransitionFulfillmentStateToDeliveredModal = false"
                                        (nzOnOk)="transitionFulfillmentToState('Delivered')"
                                    >
                                        <div *nzModalContent>
                                            <p>Please confirm you want to transition the fulfillment to the "Delivered" state.</p>
                                        </div>
                                    </nz-modal>
                                </td>
                                <td *ngIf="fulfillment.getRawValue().state === 'Pending'">
                                    <button
                                        nz-button
                                        nzType="primary"
                                        (click)="showTransitionFulfillmentStateToDeliveredModal = true; this.validateForm.controls.fulfillmentMarkLine.controls.id.setValue(fulfillment.getRawValue().id)"
                                    >
                                        Transition to Delivered state
                                    </button>
                                    <nz-modal
                                        [(nzVisible)]="showTransitionFulfillmentStateToDeliveredModal"
                                        nzTitle="Transition Fulfillment State"
                                        (nzOnCancel)="showTransitionFulfillmentStateToDeliveredModal = false"
                                        (nzOnOk)="transitionFulfillmentToState('Delivered')"
                                    >
                                        <div *nzModalContent>
                                            <p>Please confirm you want to transition the fulfillment to the "Delivered" state.</p>
                                        </div>
                                    </nz-modal>
                                </td>
                                <td>
                                    <button
                                        *ngIf="fulfillment.getRawValue().state !== 'Cancelled'"
                                        nz-button
                                        nzDanger
                                        nzType="primary"
                                        (click)="showTransitionFulfillmentStateToCancelledModal = true; this.validateForm.controls.fulfillmentMarkLine.controls.id.setValue(fulfillment.getRawValue().id)"
                                    >
                                        <span nz-icon nzType="ng-zorro:trash"></span>
                                        Cancel Fulfillment
                                    </button>
                                    <nz-modal
                                        [(nzVisible)]="showTransitionFulfillmentStateToCancelledModal"
                                        nzTitle="Cancel Fulfillment"
                                        (nzOnCancel)="showTransitionFulfillmentStateToCancelledModal = false"
                                        (nzOnOk)="transitionFulfillmentToState('Cancelled')"
                                    >
                                        <div *nzModalContent>
                                            <p>Please confirm you want to cancel this fulfillment.</p>
                                        </div>
                                    </nz-modal>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>
        </div>
    </div>

    <!-- Add Items To Order -->
    <div class="page-container">
        <div class="page-container-inner">
            <div nz-row> 
                <div nz-col nzSpan="24">
                    <h2>Add Items To Order</h2>
                </div>
                <!-- Products -->
                <div nz-col nzSpan="24" class="top-20" *ngIf="orderState === 'Draft' || orderState === 'Modifying'">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">Products</nz-form-label>
                        <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                            <nz-select
                                nzShowSearch
                                nzServerSearch
                                nzPlaceHolder="Search product by SKU or name"
                                (nzOnSearch)="searchProduct($event)"
                                [formControl]="validateForm.controls.selectedProduct"
                                (ngModelChange)="addVariantToOrder($event)"
                                >
                                <nz-option *ngFor="let product of searchProducts" nzCustomContent="true" [nzValue]="product" [nzLabel]="product.productVariantName">
                                    <p class="inline-text">{{ product.productVariantName }}</p><p class="inline-text net-sku">{{ product.sku }}</p>
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="24" *ngIf="validateForm.controls.variants.value.length === 0">
                    <div nz-row>
                        <div nz-col nzSpan="24" class="text-center">
                            <img src="assets/images/noItemsAdded.jpeg" class="no-items-added">
                        </div>
                        <div nz-col nzSpan="24" class="text-center">
                            <p class="mt-2">No items added yet</p>
                        </div>
                    </div>
                </div>
                <div nz-col nzSpan="24" *ngIf="validateForm.controls.variants.value.length > 0" [class.top-24]="validateForm.controls.variants.value.length > 0">
                    <nz-table
                        #rowSelectionTable
                        #sortTable
                        nzShowSizeChanger
                        [nzData]="validateForm.controls.variants.value"
                        [nzFrontPagination]="false"
                        [nzLoading]="appService.isLoading$ | async"
                        [nzTotal]="validateForm.controls.variants.value.length"
                        [nzShowPagination]="false"
                        class="order-table"
                    >
                        <thead>
                            <tr>
                                <th>IMAGE</th>
                                <th>NAME</th>
                                <th>SKU</th>
                                <th>UNIT PRICE</th>
                                <th>QUANTITY</th>
                                <th *ngIf="orderState !== 'Modifying'">TOTAL</th>
                                <th *ngIf="orderState === 'Draft'"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let variant of validateForm.controls.variants.controls">
                                <td></td>
                                <td>{{ variant.getRawValue().productVariantName }}</td>
                                <td>{{ variant.getRawValue().sku }}</td>
                                <td>
                                    <p>{{ variant.getRawValue().priceWithTax / 100 | currency:currencyCode }}</p>
                                    <p class="net-price">{{ variant.getRawValue().price / 100 | currency:currencyCode }}</p>
                                </td>
                                <td>
                                    <input
                                    *ngIf="orderState === 'Draft' || orderState === 'Modifying'"
                                     nz-input
                                     [formControl]="variant.controls.quantity"
                                     [nzStatus]="checkStock(variant.getRawValue().stock, variant.getRawValue().quantity) ? '' : 'error'"
                                     (input)="adjustDraftOrderLine(variant.getRawValue().id, variant.getRawValue().quantity)"
                                     />
                                    <p *ngIf="orderState !== 'Draft' && orderState !== 'Modifying'">{{ variant.getRawValue().quantity }}</p>
                                </td>
                                <td *ngIf="orderState !== 'Modifying'">
                                    <p>{{ variant.getRawValue().totalWithTax / 100 | currency:currencyCode }}</p>
                                    <p class="net-price">{{ variant.getRawValue().total / 100 | currency:currencyCode }}</p>
                                </td>
                                <td *ngIf="orderState === 'Draft'" (click)="openRemoveVariantModal(variant.getRawValue().id)">
                                    <span nz-icon nzType="ng-zorro:trash" style="color: red; cursor: pointer;"></span>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table> 
                </div>
                <div nz-col nzSpan="24" class="top-20" *ngIf="surchargesResponse.length > 0">
                    <h4>Surcharges</h4>
                </div>
                <div nz-col nzSpan="24" *ngIf="surchargesResponse.length > 0">
                    <nz-table
                        #rowSelectionTable
                        #sortTable
                        nzShowSizeChanger
                        [nzData]="surchargesResponse"
                        [nzFrontPagination]="false"
                        [nzLoading]="appService.isLoading$ | async"
                        [nzTotal]="surchargesResponse.length"
                        [nzShowPagination]="false"
                        class="order-table"
                    >
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Sku</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let surcharge of surchargesResponse">
                                <td>{{ surcharge.description }}</td>
                                <td>{{ surcharge.sku }}</td>
                                <td>
                                    <p>{{ surcharge.priceWithTax / 100 | currency:currencyCode }}</p>
                                    <p class="net-price">
                                        {{ surcharge.price / 100 | currency:currencyCode }}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
                <div nz-col nzSpan="24" class="top-20" *ngIf="discountsResponse.length > 0">
                    <h4>Discounts</h4>
                </div>
                <div nz-col nzSpan="24" *ngIf="discountsResponse.length > 0">
                    <nz-table
                        #rowSelectionTable
                        #sortTable
                        nzShowSizeChanger
                        [nzData]="discountsResponse"
                        [nzFrontPagination]="false"
                        [nzLoading]="appService.isLoading$ | async"
                        [nzTotal]="discountsResponse.length"
                        [nzShowPagination]="false"
                        class="order-table"
                    >
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Coupon Code</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let discount of discountsResponse">
                                <td>{{ discount.description }}</td>
                                <td>{{ discount.couponCode }}</td>
                                <td>
                                    <p>{{ discount.amountWithTax / 100 | currency:currencyCode }}</p>
                                    <p class="net-price">{{ discount.amount / 100 | currency:currencyCode }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
                <!-- Subtotal -->
                <div nz-col nzSpan="24" [class.top-24]="validateForm.controls.variants.value.length > 0" *ngIf="orderState !== 'Modifying'">
                    <div nz-row>
                        <div nz-col nzSpan="20" class="price-container">
                            <p style="margin: 0;">Subtotal</p>
                        </div>
                        <div nz-col nzSpan="4">
                            <p>{{ subTotalWithTax / 100 | currency:currencyCode }}</p>
                            <p class="net-price">{{ subTotal / 100 | currency:currencyCode }}</p>
                        </div>
                    </div>
                </div>
                <nz-divider *ngIf="orderState !== 'Modifying'"></nz-divider>
                <!-- Shipping -->
                <div nz-col nzSpan="24" *ngIf="orderState !== 'Modifying'">
                    <div nz-row>
                        <div nz-col nzSpan="20"  class="price-container">
                            <p *ngIf="validateForm.controls.selectedShippingMethod.value">Shipping: {{ validateForm.controls.selectedShippingMethod.value?.name ?? '' }}</p>
                            <p *ngIf="!validateForm.controls.selectedShippingMethod.value">Shipping</p>
                        </div>
                        <div nz-col nzSpan="4">
                            <p>{{ shippingWithTax / 100 | currency:currencyCode }}</p>
                            <p class="net-price">{{ shipping / 100 | currency:currencyCode }}</p>
                        </div>
                    </div>
                </div>
                <nz-divider *ngIf="orderState !== 'Modifying'"></nz-divider>
                <!-- Total -->
                <div nz-col nzSpan="24" *ngIf="orderState !== 'Modifying'">
                    <div nz-row>
                        <div nz-col nzSpan="20"  class="price-container">
                            <p><b>Total</b></p>
                        </div>
                        <div nz-col nzSpan="4">
                            <p><b>{{ totalWithTax / 100 | currency:currencyCode }}</b></p>
                            <p class="net-price"><b>{{ total / 100 | currency:currencyCode }}</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Tax Summary -->
    <div class="page-container" *ngIf="validateForm.controls.variants.value.length > 0 && orderState !== 'Modifying'">
        <div class="page-container-inner">
            <div nz-row>
                <div nz-col nzSpan="24">
                    <h2>Tax Summary</h2>
                </div>
                <div nz-col nzSpan="24" class="top-20">
                    <nz-table
                        #rowSelectionTable
                        #sortTable
                        nzShowSizeChanger
                        [nzData]="taxSummary"
                        [nzFrontPagination]="false"
                        [nzLoading]="appService.isLoading$ | async"
                        [nzTotal]="taxSummary.length"
                        [nzShowPagination]="false"
                        class="order-table"
                    >
                    <thead>
                        <tr>
                            <th>DESCRIPTION</th>
                            <th>TAX RATE</th>
                            <th>TAX BASE</th>
                            <th>TAX TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let tax of taxSummary">
                            <td>{{ tax.description }}</td>
                            <td>{{ tax.taxRate }}%</td>
                            <td>{{ tax.taxBase / 100 | currency:currencyCode }}</td>
                            <td>{{ tax.taxTotal / 100 | currency:currencyCode }}</td>
                        </tr>
                    </tbody>
                    </nz-table>
                </div>
            </div>
        </div>
    </div>

    <!-- Customer -->
    <div class="page-container" *ngIf="orderState !== 'Modifying'">
        <div class="page-container-inner">
            <div nz-row>
                <div nz-col nzSpan="24">
                    <h2>Customer</h2>
                </div>
                <div nz-col nzSpan="24" class="top-20">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">Select Customer</nz-form-label>
                        <nz-form-control [nzHasFeedback]="true" [nzSpan]="20">
                            <nz-select
                                nzShowSearch
                                nzServerSearch
                                nzPlaceHolder="Search/select"
                                (nzOnSearch)="searchTerm = $event"
                                [formControl]="validateForm.controls.selectedCustomer"
                                (ngModelChange)="onCustomerSelect($event)"
                                >
                                <nz-option [nzValue]="null"></nz-option>
                                <nz-option *ngFor="let customer of searchCustomers()" [nzValue]="customer.getRawValue()" [nzLabel]="customer.getRawValue().firstName + ' ' + customer.getRawValue().lastName"></nz-option>
                            </nz-select>
                        </nz-form-control>
                        <nz-form-control [nzSpan]="4">
                            <button nz-button nzType="primary" (click)="showAddCustomerModal = true">Add Customer</button>
                        </nz-form-control>
                        <nz-modal
                         [(nzVisible)]="showAddCustomerModal" 
                         nzTitle="Add Customer" 
                         (nzOnCancel)="showAddCustomerModal = false" 
                         (nzOnOk)="addCustomer()">
                            <div *nzModalContent>
                                <nz-form [formGroup]="validateForm.controls.customer">
                                    <nz-form-item>
                                        <nz-form-label [nzSpan]="24">Title</nz-form-label>
                                        <nz-form-control [nzSpan]="24">
                                            <input nz-input formControlName="title" />
                                        </nz-form-control>
                                    </nz-form-item>
                                    <nz-form-item [nzGutter]="8">
                                            <div nz-col [nzSpan]="12">
                                                <nz-form-label>First Name</nz-form-label>
                                                <nz-form-control>
                                                    <input nz-input formControlName="firstName" />
                                                </nz-form-control>
                                            </div>
                                            <div nz-col [nzSpan]="12">
                                                <nz-form-label>Last Name</nz-form-label>
                                                <nz-form-control>
                                                    <input nz-input formControlName="lastName" />
                                                </nz-form-control>
                                            </div>
                                    </nz-form-item>
                                    <nz-form-item>
                                        <nz-form-label [nzSpan]="24">Email</nz-form-label>
                                        <nz-form-control [nzSpan]="24">
                                            <input nz-input formControlName="email" />
                                        </nz-form-control>
                                    </nz-form-item>
                                    <nz-form-item>
                                        <nz-form-label [nzSpan]="24">Phone Number</nz-form-label>
                                        <nz-form-control [nzSpan]="24">
                                            <input nz-input formControlName="phone" />
                                        </nz-form-control>
                                    </nz-form-item>
                                </nz-form>
                            </div>
                        </nz-modal>
                    </nz-form-item>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Billing Address -->
    <div class="page-container">
        <div class="page-container-inner">
            <div nz-row>
                <div nz-col nzSpan="24">
                    <h2>Billing Address</h2>
                </div>
                <div nz-col nzSpan="24" class="top-20">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">Select Address</nz-form-label>
                        <nz-form-control [nzHasFeedback]="true" [nzSpan]="20">
                        <nz-select
                            nzShowSearch
                            nzServerSearch
                            nzPlaceHolder="Search/select"
                            (nzOnSearch)="searchTerm = $event"
                            [formControl]="validateForm.controls.selectedBillingAddress"
                            (ngModelChange)="onBillingAddressSelect()"
                            >
                            <nz-option [nzValue]="null"></nz-option>
                            <nz-option *ngFor="let address of getAddresses()" [nzValue]="address.getRawValue()" [nzLabel]="address.getRawValue().fullName"></nz-option>
                        </nz-select>
                    </nz-form-control>
                    <div nz-col nzSpan="4">
                        <button nz-button nzType="primary" (click)="showAddAddressModal = true">Add New Address</button>
                    </div>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="24">
                    <div nz-row>
                        <div nz-col nzSpan="1">
                            <nz-switch [formControl]="validateForm.controls.sameAddress" (ngModelChange)="onSameAddressChange()"></nz-switch> 
                        </div>
                        <div nz-col nzSpan="20">
                            <p>Same billing and shipping address</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Shipping Address -->
    <div class="page-container">
        <div class="page-container-inner">
            <div nz-row>
                <div nz-col nzSpan="24">
                    <h2>Shipping</h2>
                </div>
            </div>
            <div nz-row>
                <div nz-col nzSpan="10">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">Method</nz-form-label>
                        <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                            <input nz-input [value]="validateForm.controls.selectedShippingMethod.value?.name" placeholder="Select shipping method" (click)="openShippingMethodSelectModal()" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div style="width: 20px;"></div>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">Address</nz-form-label>
                        <nz-form-control [nzHasFeedback]="true" [nzSpan]="18">
                            <nz-select
                                nzShowSearch
                                nzServerSearch
                                nzPlaceHolder="Search/select"
                                (nzOnSearch)="searchTerm = $event"
                                [formControl]="validateForm.controls.selectedShippingAddress"
                                >
                                <nz-option [nzValue]="null"></nz-option>
                                <nz-option *ngFor="let address of getAddresses()" [nzValue]="address.getRawValue()" [nzLabel]="address.getRawValue().fullName"></nz-option>
                            </nz-select>
                        </nz-form-control>
                        <div nz-col nzSpan="4">
                            <button nz-button nzType="primary" (click)="showAddAddressModal = true">Add New Address</button>
                        </div>
                    </nz-form-item>
                </div>
            </div>
        </div>
    </div>

    <nz-modal
        [(nzVisible)]="showAddAddressModal" 
        nzTitle="Add Address" 
        (nzOnCancel)="showAddAddressModal = false" 
        (nzOnOk)="addAddress()">
        <div *nzModalContent>
            <nz-form [formGroup]="validateForm.controls.address">
                <nz-form-item [nzGutter]="8">
                        <div nz-col [nzSpan]="12">
                            <nz-form-label>Full Name</nz-form-label>
                            <nz-form-control>
                                <input nz-input formControlName="fullName" />
                            </nz-form-control>
                        </div>
                        <div nz-col [nzSpan]="12">
                            <nz-form-label>Company</nz-form-label>
                            <nz-form-control>
                                <input nz-input formControlName="company" />
                            </nz-form-control>
                        </div>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">Street Line 1</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <input nz-input formControlName="streetLine1" />
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">Street Line 2</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <input nz-input formControlName="streetLine2" />
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item [nzGutter]="8">
                    <div nz-col [nzSpan]="8">
                        <nz-form-label>City</nz-form-label>
                        <nz-form-control>
                            <input nz-input formControlName="city" />
                        </nz-form-control>
                    </div>
                    <div nz-col [nzSpan]="8">
                        <nz-form-label>Province/State</nz-form-label>
                        <nz-form-control>
                            <input nz-input formControlName="province" />
                        </nz-form-control>
                    </div>
                    <div nz-col [nzSpan]="8">
                        <nz-form-label>Postal Code</nz-form-label>
                        <nz-form-control>
                            <input nz-input formControlName="postalCode" />
                        </nz-form-control>
                    </div>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">Country</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <input nz-input formControlName="country" />
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">Phone Number</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <input nz-input formControlName="phoneNumber" />
                    </nz-form-control>
                </nz-form-item>
            </nz-form>
        </div>
    </nz-modal>

    <!-- Discounts -->
    <div class="page-container">
        <div class="page-container-inner">
            <div nz-row>
                <div nz-col nzSpan="24">
                    <h2>Discounts</h2>
                </div>
                <div nz-col nzSpan="24" class="top-20">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">Discount coupon</nz-form-label>
                        <nz-form-control [nzHasFeedback]="true" [nzSpan]="24">
                            <nz-select
                                nzShowSearch
                                nzServerSearch
                                nzPlaceHolder="Search/select"
                                nzMode="multiple"
                                (nzOnSearch)="searchTerm = $event"
                                [formControl]="validateForm.controls.selectedPromotions"
                                >
                               <nz-option *ngFor="let promotion of searchPromotions()" [nzValue]="promotion.getRawValue()" [nzLabel]="promotion.getRawValue().couponCode"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>
    </div>

    <!-- Add Surcharge -->
    <div class="page-container" *ngIf="orderState === 'Modifying'">
        <div class="page-container-inner">
            <div nz-row>
                <div nz-col nzSpan="24">
                    <h2>Surcharges</h2>
                </div>
            </div>
            <div nz-row [nzGutter]="8">
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">Description</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <input nz-input [formControl]="validateForm.controls.tempSurcharge.controls.description" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">SKU</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <input nz-input [formControl]="validateForm.controls.tempSurcharge.controls.sku" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>    
            <div nz-row [nzGutter]="8">
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">Price</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <input nz-input [formControl]="validateForm.controls.tempSurcharge.controls.price" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">Includes Tax</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <nz-switch [formControl]="validateForm.controls.tempSurcharge.controls.includesTax" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div nz-row [nzGutter]="8">
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">Tax Rate</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <input nz-input [formControl]="validateForm.controls.tempSurcharge.controls.taxRate" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">Tax Description</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <input nz-input [formControl]="validateForm.controls.tempSurcharge.controls.taxDescription" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div nz-row [nzGutter]="8">
                <div nz-col nzSpan="12">
                    <button nz-button nzType="primary" (click)="addSurcharge()">Add Surcharge</button>
                </div>
            </div>
        </div>
    </div>
</form>
