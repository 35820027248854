import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { NzTableQueryParams } from 'ng-zorro-antd/table'
import { tap } from 'rxjs'
import { Order, OrderList } from '../../gql/shop/generated'
import { AppService } from '../../service/app.service'
import { OrderService } from '../../service/order.service'

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.less',
})
export class OrdersComponent {
  orders: OrderList = {
    items: [],
    totalItems: 0,
  }

  pageSize = 10
  pageIndex = 0

  setOfCheckedId = new Set<string>()
  checked = false
  indeterminate = false


  constructor(
    public appService: AppService,
    private orderService: OrderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setupListeners()

    this.orderService.fetchOrders().subscribe()
  }

  setupListeners() {
    this.orderService.orders$
      .pipe(
        tap((orders) => {
          console.log({ orders })

          this.orders = orders
        })
      )
      .subscribe()
  }

  onAllChecked(value: boolean): void {
    this.orders.items.forEach((item) => this.updateCheckedSet(item.id, value))
    this.refreshCheckedStatus()
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id)
    } else {
      this.setOfCheckedId.delete(id)
    }
  }

  refreshCheckedStatus(): void {
    this.checked = this.orders.items.every((item) =>
      this.setOfCheckedId.has(item.id)
    )
    this.indeterminate =
      this.orders.items.some((item) => this.setOfCheckedId.has(item.id)) &&
      !this.checked
  }

  onItemChecked(id, $event) {
    console.log(id, $event)
  }

  goToOrder(id: string) {
    this.router.navigate([`/admin/orders/${id}`])
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    console.log(params)
    const { pageSize, pageIndex, sort, filter } = params

    const currentSort = sort.find((item) => item.value !== null)
    const sortField = currentSort && currentSort.key
    const sortOrder = currentSort && currentSort.value
    this.pageIndex = pageIndex
    this.pageSize = pageSize

    this.orderService.updatePagination(pageIndex, pageSize)

    if (sortField && sortOrder) {
      this.orderService.updateSort(sortField, sortOrder)
    }

    this.orderService.goToPage(pageIndex).subscribe()
  }

  pickShippingColor(index: number) {
    const colors = ['blue', 'green', 'red', 'yellow', 'purple', 'orange']
    return colors[index % colors.length]
  }

  pickOrderStateColor(state: string) {
    if (state === 'Draft' || state === 'Cancelled') {
      return 'red'
    }
    if (state === "PaymentSettled" || state === "ArrangingAdditionalPayment" || state == "Modifying"){
      return 'yellow'
    }
    if (state === "Delivered"){
      return 'green'
    }
    return 'orange'
  }

  createDraftOrder(){
    this.orderService.createDraftOrder().subscribe(
        (response) => {
            const orderId = response?.data?.createDraftOrder?.id;
            if (orderId) {
               // go to order
               this.router.navigate([`/admin/orders/${orderId}`]);
            }
        }
    );
  }
}
